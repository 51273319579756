import React from "react";
import AdminLayout from "../../../components/AdminLayout";
import { useParams } from "react-router-dom";
import { cancelABookingEvent, getABookings } from "../../../apis/booking.api";
import {
  FiAlertCircle,
  FiArrowLeft,
  FiCheckCircle,
  FiSlash,
} from "react-icons/fi";
import { formatedTime } from "../../../utils/time";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { toastConf } from "../Dropins";

export default function ViewBooking() {
  let { id } = useParams();
  const [booking, setBooking] = React.useState();
  const [loading, setLoading] = React.useState(true);

  const initCall = async () => {
    const booking = await getABookings(id);
    setBooking(booking);

    setLoading(false);
    return;
  };

  React.useEffect(() => {
    initCall();

    //eslint-disable-next-line
  }, []);

  const onCancelEvent = async (id, eventId) => {
    try {
      await cancelABookingEvent(id, eventId);
      toast.success("Booking Event Cancelled.", toastConf);
      initCall();
    } catch (error) {
      toast.error(
        error?.response?.data?.message[0] ?? "Something went wrong.",
        toastConf
      );
    }
  };

  return (
    <AdminLayout>
      {" "}
      <div className="px-5">
        {loading ? (
          "Loading..."
        ) : (
          <>
            {/* {JSON.stringify(booking)} */}
            <Link to="/admin/bookings">
              <FiArrowLeft className="inline mr-2" /> Back
            </Link>
            <div className="rounded bg-white shadow mb-3">
              <table className="table-auto w-full rounded-lg border-collapse p-4 mt-4 mb-8">
                <thead>
                  <tr className="text-left">
                    <th className="px-2"></th>
                    <th className="py-3 px-2 mb-4"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="py-2">
                    <td className="px-2">Booked By</td>
                    <td className="px-2">
                      {booking.type === "user" ? (
                        <span className="font-semibold">
                          {booking.user.fullName}
                        </span>
                      ) : (
                        <span className="bg-red-50 px-2.5 py-1.5 rounded-lg shadow text-xs">
                          System/Admin
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr className="py-2">
                    <td className="px-2">Booked On</td>
                    <td className="px-2">
                      {format(
                        parseISO(booking.createdAt),
                        "dd MMM, yyyy HH:mm"
                      )}{" "}
                      UTC
                    </td>
                  </tr>
                  <tr className="py-2">
                    <td className="px-2">Booking Total</td>
                    <td className="px-2">
                      <span className="text-xs font-semibold mr-2">CAD</span>
                      {booking.grandTotal}
                    </td>
                  </tr>
                  <tr className="py-2">
                    <td className="px-2">Total Tax</td>
                    <td className="py-5 px-2"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="rounded bg-white shadow">
              <table className="table-auto w-full rounded-lg border-collapse p-4 mt-4 mb-8">
                <thead>
                  <tr className="text-left bg-neutral-200 p-8">
                    <th className="px-2">
                      <input type="checkbox" />
                    </th>
                    <th className="py-3 px-2 mb-4">Type</th>{" "}
                    <th className="py-3 px-2 mb-4">Status</th>
                    <th>Event Date</th>
                    <th>Event Time</th>
                    <th>Price</th>
                    <th>Court</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {booking.items.map((us, i) => (
                    <tr key={i} className="py-2">
                      <td className="px-2">
                        {/* <input type="checkbox" /> */}
                      </td>

                      <td className="py-2.5 px-2">
                        {/* {us.type} */}
                        {us.type === "court" && "Court Rental"}
                        {us.type === "membership" && "Membership"}
                        {us.type === "dropin" && "Drop-In"}
                        {us.type === "course" && "Course Enrollment"}
                      </td>
                      <td className="py-2.5 px-2">
                        {us.status === "cancelled" && (
                          <span className="inline-flex bg-red-500 text-white rounded-full p-2.5 mr-2">
                            <FiSlash />
                          </span>
                        )}
                        {us.status === "success" && (
                          <span className="inline-flex bg-green-500 text-white rounded-full p-2.5 mr-2">
                            <FiCheckCircle />
                          </span>
                        )}
                        {us.status === "pending" && (
                          <span className="inline-flex bg-yellow-500 text-white rounded-full p-2.5 mr-2">
                            <FiAlertCircle />
                          </span>
                        )}
                      </td>
                      {/* <td>Kundagol</td> */}
                      <td>
                        {us.type === "membership"
                          ? "-"
                          : format(parseISO(us?.date), "dd MMM, yyyy")}
                      </td>
                      <td>
                        {us.type === "membership" ? (
                          "-"
                        ) : (
                          <>
                            {us?.time ? (
                              <span>
                                {us?.time} - {us?.endTime}
                              </span>
                            ) : (
                              <span>
                                {formatedTime(
                                  us?.metadata?.hours[0].time ?? "23:59:59"
                                )}{" "}
                                -{" "}
                                {formatedTime(
                                  us?.metadata?.hours[
                                    us.metadata.hours.length - 1
                                  ].endTime ?? "23:59:59"
                                )}
                              </span>
                            )}
                          </>
                        )}
                      </td>
                      <td>
                        <span className="text-xs font-semibold mr-2">CAD</span>
                        {us?.price}
                      </td>
                      <td>{us?.court ?? "-NA-"}</td>
                      <td>
                        {
                          <button
                            className="inline-flex bg-red-100 rounded-full p-3 mr-2"
                            onClick={() => onCancelEvent(id, us.id)}
                            disabled={us.status === "cancelled"}
                          >
                            <FiSlash />
                          </button>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </AdminLayout>
  );
}
