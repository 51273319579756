import apis from "./api";

export const setPasswordActivate = (data) => {
  return apis.post("auth/invite", { data });
};
export const resetPassword = (data) => {
  return apis.post("auth/password/reset", { data });
};
export const requestPasswordReset = (data) => {
  return apis.post("auth/password/request", { data });
};

export const getAllUser = (params) => {
  return apis.get("user/", { params });
};

export const getAUser = (id) => {
  return apis.get(`user/${id}`, {});
};

export const inviteUser = (data) => {
  return apis.post(`user/invite`, { data });
};

export const getAUserMetadata = (id) => {
  return apis.get(`user/metadata/${id}`, {});
};

export const updateAUser = (id, data) => {
  return apis.patch(`user/${id}`, { data });
};

export const deleteAUser = (id) => {
  return apis.delete(`user/${id}`, {});
};

export const getMyself = () => {
  return apis.get("user/me", {});
};

export const getAdminMyself = () => {
  return apis.get("user/admin/me", {});
};

export const getMyMetadata = () => {
  return apis.get("user/metadata", {});
};

export const updatetMyself = (data) => {
  return apis.patch("user/", { data });
};

export const updateUserViaAdmin = (id, data) => {
  return apis.patch(`user/${id}`, { data });
};

export const updatetMetadata = (data) => {
  return apis.patch("user/metadata", { data });
};
export const updateUserMetadataViaAdmin = (id, data) => {
  return apis.patch(`user/metadata/${id}`, { data });
};

export const loginUser = (data) => {
  return apis.post("auth/login", { data });
};

export const registerUser = (data) => {
  return apis.post("auth/register", { data });
};

export const verifyUser = (data) => {
  return apis.post("auth/verify", { data });
};
export const googleAuth = (params) => {
  return apis.get("auth/google/callback", { params });
};
