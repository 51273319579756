import React, { useContext } from "react";
import { Routes, Route, Navigate, Outlet, useNavigate } from "react-router-dom";
import WDashboard from "./Dashboard/Dashboard";
import DropIn from "./DropIn/DropIn";
import CourtRental from "./CourtRental/CourtRental";
import Profile from "./Profile/Profile";
import MembershipPlans from "./Membership/MembershipPlans";
import CoachingPlans from "./Coaching/CoachingPlans";
import MyBookings from "./MyBookings";
import MySchedules from "./MySchedules";
import { AuthContext } from "../../context/Auth.context";
import CartProvider from "../../context/Cart.context";
import Checkout from "./Checkout/Checkout";
import Layout from "../../components/Layout";
import Transactions from "./Transactions/Transactions";

export default function UserRoute() {
  const { isAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isAuth) {
      navigate("/auth");
    }
    return;
  }, [navigate, isAuth]);
  return (
    <>
      <Outlet />

      <CartProvider>
        <Layout>
          <Routes>
            <Route path="/">
              <Route
                index
                element={<Navigate to="/user/dashboard" replace />}
              />
              <Route path="dashboard" element={<WDashboard />} />
              <Route path="drop-in" element={<DropIn />} />
              <Route path="court-rental" element={<CourtRental />} />
              <Route path="profile" element={<Profile />} />
              <Route path="membership" element={<MembershipPlans />} />
              <Route path="coaching" element={<CoachingPlans />} />
              <Route path="bookings" element={<MyBookings />} />
              <Route path="schedules" element={<MySchedules />} />
              <Route path="checkout" element={<Checkout />} />
              <Route path="transactions" element={<Transactions />} />
            </Route>
          </Routes>
        </Layout>
      </CartProvider>
    </>
  );
}
