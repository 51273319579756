import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React from "react";
import GoToTop from "./components/GoToTop";
import "./App.css";
import "./input.css";
import "./index.scss";
import AuthRoute from "./pages/Auth/auth.route";
import UserRoute from "./pages/UserDashboard/user.route";
import AdminRoute from "./pages/AdminDashboard/admin.route";
import AuthProvider from "./context/Auth.context";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CookiesProvider } from "react-cookie";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { AdminZoneContext } from "./context/AdminZone.context";

function App() {
  const stripePromise = loadStripe(
    "pk_test_51IFkcULmXxAJQGin8qPe3H7uKNJFDK51vPk4HSlLlhG3HrjRPdW528rxevcQEaMUzdVw2RbCWpEVYiDYRrcxcJZL00WxwHocRH"
  );

  return (
    <CookiesProvider>
      <Elements stripe={stripePromise}>
        <AuthProvider>
          <ToastContainer />
          <Router>
            <Routes>
              <Route path="/" element={<Navigate to="/auth" replace />} />
              <Route path="/auth/*" element={<AuthRoute />} />
              <Route path="/user/*" element={<UserRoute />} />
              <Route path="/admin/*" element={<AdminRoute />} />
            </Routes>
            {/* <GoToTop /> */}
          </Router>
        </AuthProvider>
      </Elements>
    </CookiesProvider>
  );
}

export default App;
