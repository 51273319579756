import React, { useContext } from "react";
import { AuthContext } from "../../context/Auth.context";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import Forgot from "./Forgot/Forgot";
import Login from "./Login/Login";
import Register from "./Register/Register";
import { useNavigate } from "react-router-dom";
import GoogleCallback from "./Callbacks/Google";
import Invite from "./Invite/Invite";
import ResetPassword from "./ResetPassword/ResetPassword";
import RequestPassword from "./RequestPassword/RequestPassword";

export default function AuthRoute() {
  const { isAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isAuth) {
      navigate("/user");
    }
    return;
  }, [navigate, isAuth]);
  return (
    <>
      <Outlet />
      <Routes>
        <Route index element={<Navigate to="/auth/login" replace />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="forgot" element={<Forgot />} />
        <Route path="request" element={<RequestPassword />} />
        <Route path="invite/:id" element={<Invite />} />
        <Route path="reset/:id" element={<ResetPassword />} />
        <Route path="callback/google" element={<GoogleCallback />} />
      </Routes>
    </>
  );
}
