import apis from "./api";

export const getWallet = () => {
  return apis.get("payment/wallet", {});
};

export const paymentIntent = (data) => {
  return apis.post("payment/intent", { data });
};

export const getMyCards = () => {
  return apis.get("payment/cards", {});
};

export const getMyTransactions = (params) => {
  return apis.get("payment/transactions/me", { params });
};

export const getPaymentStatus = (id) => {
  return apis.get(`payment/status/${id}`, {  });
};
