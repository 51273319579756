import React from "react";
import { getMyTransactions } from "../../../apis/payment.api";
import { format, parseISO } from "date-fns";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import { TbCurrencyDollarCanadian } from "react-icons/tb";
import { MdRedeem, MdOutlineAddCard } from "react-icons/md";
import { FiAlertCircle, FiAlertTriangle, FiCheck } from "react-icons/fi";

export default function Transactions() {
  const [transactions, setTransactions] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(1);

  const initCall = async (page) => {
    const transactions = await getMyTransactions({ page });
    setTransactions(transactions.data);
    setCount(transactions.count);
    setLoading(false);
    setPage(page ?? 1);
    return;
  };

  React.useEffect(() => {
    initCall();

    //eslint-disable-next-line
  }, []);

  const pager = () => {
    const _c = [];
    const c = Math.floor(count / 10);
    let i = 0;
    while (i <= c) {
      _c.push(i);
      i++;
    }
    return _c;
  };

  return (
    <div className="h-full bg-white shadow p-3">
      <table className="table-auto w-full rounded-lg border-collapse p-4 mb-8 bg-white shadow">
        <thead>
          <tr className="text-left bg-black text-white p-8">
            <th className="py-3 px-2 mb-4  font-light ">Transaction ID</th>
            <th className="py-3 px-2 mb-4  font-light ">Status</th>
            <th className="py-3 px-2 mb-4  font-light ">Type</th>
            <th className="font-light ">Transaction Date</th>
            <th className="font-light ">Amount</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((us, i) => (
            <tr key={i} className="py-2 border-b text-sm">
              <td className=" px-2 font-light">
                #TRD-{("0000000" + us.id).slice(-8)}
              </td>
              <td className="py-2 px-2">
                {/* {us.status} */}
                {us.status === "success" && (
                  <span className="bg-green-500 rounded-full w-4  h-4 flex justify-center items-center text-white">
                    <FiCheck />
                  </span>
                )}
                {us.status === "pending" && (
                  <FiAlertCircle className="text-yellow-500" />
                )}
                {us.status === "failed" && (
                  <FiAlertTriangle className="text-red-500" />
                )}
              </td>
              <td className="px-2">
                {us.type === "refund" && (
                  <>
                    <HiOutlineReceiptRefund className="text-md text-green-500 inline mr-2" />
                    Refund
                  </>
                )}
                {us.type === "booking" && (
                  <>
                    <MdRedeem className="text-md text-red-500 inline mr-2" />
                    Debit
                  </>
                )}
                {us.type === "topup" && (
                  <>
                    <MdOutlineAddCard className="text-md text-green-500 inline mr-2" />
                    Top-up
                  </>
                )}
              </td>
              {/* <td>Kundagol</td> */}
              <td>
                {format(parseISO(us.createdAt), " HH:mm, dd MMM yyyy ")} UTC
              </td>

              <td>
                <span className="text-xs font-semibold mr-2">
                  <TbCurrencyDollarCanadian className="inline" />
                </span>
                <span
                  className={`font-bold ${
                    us.type === "refund" || us.type === "topup"
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {us?.amount}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <nav className="flex justify-between flex-row navigation example ">
        <p className="text-xs">
          {Math.ceil(count / 10) === page ? (
            <span>
              Shwoing {count} of {count}
            </span>
          ) : (
            <span>
              {page * 10 - 10 + 1} of {count}
            </span>
          )}
        </p>
        <ul class="list-style-none flex border rounded">
          <li>
            <button
              onClick={() => initCall(page - 1)}
              disabled={page === 1}
              class={` text-black shadow-md bg-white relative block rounded-l  px-3 py-1.5 text-sm transition-all duration-300 hover:bg-neutral-100  dark:hover:bg-neutral-700 dark:hover:text-white`}
            >
              Previous
            </button>
          </li>
          {pager().map((p) => (
            <li key={p}>
              <button
                onClick={() => initCall(p + 1)}
                class={`${
                  p + 1 === page
                    ? "bg-gray-500 text-white"
                    : "bg-white text-black-600"
                } relative block px-3 shadow-md py-1.5 text-sm transition-all duration-300 hover:bg-neutral-100  dark:hover:bg-neutral-700 dark:hover:text-white`}
              >
                {p + 1}
              </button>
            </li>
          ))}
          <li>
            <button
              onClick={() => initCall(page + 1)}
              disabled={page === Math.ceil(count / 10)}
              class={` text-black bg-white shadow-md relative block rounded-r  px-3 py-1.5 text-sm transition-all duration-300 hover:bg-neutral-100  dark:hover:bg-neutral-700 dark:hover:text-white`}
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}
