import React, { useState } from "react";
import {
  getMyCards,
  getWallet,
  paymentIntent,
  getPaymentStatus,
} from "../../../apis/payment.api";
import {
  getMyMetadata,
  getMyself,
  updatetMetadata,
  updatetMyself,
} from "../../../apis/user.api";
import { Modal, Spinner } from "react-bootstrap";

import Prof from "../../../assets/default-user.jpg";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { getMyEnrollments } from "../../../apis/booking.api";
import { format, parseISO } from "date-fns";
import {
  FaCcAmex,
  FaCcDinersClub,
  FaCcDiscover,
  FaCcJcb,
  FaCcMastercard,
  FaCcVisa,
} from "react-icons/fa";

export default function Profile() {
  const stripe = useStripe();
  const elements = useElements();

  const [addBalance, setAddBalance] = useState(1);

  const [isLoading, setLoading] = useState(true);
  const [loadingState, setLoadingState] = useState({
    wallet: false,
    save: false,
  });

  const [paymentsMethod, setPaymentsMethod] = useState(null);

  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const [open, setOpen] = useState(1);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [user, setUser] = React.useState({});
  const [metadata, setMetadata] = React.useState({});
  const [memberships, setMemberships] = React.useState([]);

  const [balance, setBalance] = React.useState(0);
  const [myCards, setMyCards] = React.useState([]);

  const [isEditable, setEditable] = useState(false);

  const initCall = async () => {
    try {
      const myself = await getMyself();
      setUser(myself);
      const myMetadata = await getMyMetadata();
      setMetadata(myMetadata);
      const myMemberships = await getMyEnrollments({ type: "membership" });
      setMemberships(myMemberships);
      const cards = await getMyCards();
      setMyCards(cards);
      callWallet();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const callWallet = async () => {
    try {
      const wallet = await getWallet();
      setBalance(wallet.balance);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    initCall();
    //eslint-disable-next-line
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null || paymentsMethod === null) {
      return;
    }
    setLoadingState({
      ...loadingState,
      wallet: true,
    });

    const intentCall = async (id) => {
      try {
        const response = await paymentIntent({
          amount: parseFloat(addBalance * 100).toFixed(2),
          id,
          paymentMethod: id,
        });

        if (response.success) {
          const { error } = await stripe.confirmCardPayment(
            response.clientSecret
          );
          if (error) {
            setLoadingState({
              ...loadingState,
              wallet: false,
            });
            return toast.error(error.message);
          }

          var status = "pending";
          const interval = setInterval(async () => {
            if (status === "success" || status === "failed") {
              if (status === "failed") {
                // handleClose();
                setLoadingState({
                  ...loadingState,
                  wallet: false,
                });
                callWallet();
                toast.error("Payment Failed!");
              } else {
                handleClose();
                setLoadingState({
                  ...loadingState,
                  wallet: false,
                });
                callWallet();
                toast.success("Amount Added Successfully", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  progress: undefined,
                  theme: "light",
                });
              }
              clearInterval(interval);
            } else {
              const data = await getPaymentStatus(response.transID);
              status = data.status;
            }
          }, 3000);
        }
      } catch (error) {
        // console.log("Error", error);
        setLoadingState({
          ...loadingState,
          wallet: false,
        });
        toast.error(
          error.response.data.message ?? "Something went wrong, try again."
        );
      }
    };
    if (paymentsMethod === "new") {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });
      if (!error) {
        const { id } = paymentMethod;
        // console.log(id);
        await intentCall(id);
      }
    } else {
      intentCall(paymentsMethod);
    }

    // else {
    //   console.log(error.message);
    //   toast.error(
    //     error.response.data.message ?? "Something went wrong, try again.",
    //     {
    //       position: "top-right",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       progress: undefined,
    //       theme: "light",
    //     }
    //   );
    // }
  };

  const profileUpdate = async (data) => {
    setLoadingState({
      ...loadingState,
      save: true,
    });
    try {
      await updatetMyself(data);
      setLoadingState({
        ...loadingState,
        save: false,
      });
      toast.success("Profile Updated.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      setLoadingState({
        ...loadingState,
        save: false,
      });
      toast.error(
        error.response.data.message ?? "Something went wrong, try again.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  const metadataUpdate = async (data) => {
    setLoadingState({
      ...loadingState,
      save: true,
    });
    try {
      await updatetMetadata(data);
      setLoadingState({
        ...loadingState,
        save: false,
      });
      toast.success("Profile Updated.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      setLoadingState({
        ...loadingState,
        save: false,
      });
      toast.error(
        error.response.data.message ?? "Something went wrong, try again.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  return (
    <>
      <div className="py-2">
        {isLoading ? (
          <div className="min-h-[80vh] flex justify-center items-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <div className="container-fluid">
            <div className="mb-3 lg:mb-2 shadow md:p-4 p-2 bg-white relative">
              {loadingState.save && (
                <div className="absolute w-full h-full top-0 left-0 bg-[rgba(255,255,255,0.04)] z-100 backdrop-blur-sm flex justify-center items-center">
                  <Spinner />
                </div>
              )}
              <div className="md:flex justify-between border-b pb-5 mb-5">
                <div>
                  <h4 className="md:text-lg text-base md:mb-0 mb-2">
                    Wallet Balance :{" "}
                    <span className="text-theme font-semibold">
                      CAD ${balance}
                    </span>
                    <button
                      className="bg-theme mx-3 rounded-xl text-white text-xs px-2 py-1"
                      onClick={handleShow}
                    >
                      + Add
                    </button>
                  </h4>{" "}
                </div>
                {isEditable ? (
                  <button
                    className="bg-red-500 text-white rounded-full px-6 py-1"
                    onClick={() => setEditable(false)}
                  >
                    Cancel
                  </button>
                ) : (
                  <button
                    className="bg-sec text-white rounded-full px-6 py-1"
                    onClick={() => setEditable(true)}
                  >
                    Edit
                  </button>
                )}
              </div>
              <div className="md:flex items-center">
                <div className="lg:w-2/12 md:w-full md:mb-0 mb-4">
                  <img src={Prof} alt="ico" className="rounded" />
                </div>
                <div className="lg:w-10/12 md:w-full md:px-4 px-0">
                  <Formik
                    initialValues={{
                      fullName: user.fullName,
                      email: user.email,
                      address: user.address,
                      phone: user.phone,
                      birthday: user.birthday,
                    }}
                    // validationSchema={}
                    onSubmit={(values) => {
                      profileUpdate(values);
                    }}
                    validateOnBlur={false}
                  >
                    {({
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      values,
                    }) => (
                      <Form>
                        <div className="lg:flex lg:space-x-2 mb-4">
                          <div className="lg:w-6/12 md:mb-0 mb-2">
                            <label
                              htmlFor="first_name"
                              className="block mb-2 text-base font-semibold text-sec "
                            >
                              Full Name
                            </label>
                            <div className="relative flex w-full flex-wrap items-stretch">
                              <input
                                disabled={!isEditable}
                                type="text"
                                // placeholder=""
                                onChange={handleChange("fullName")}
                                handleBlur={handleBlur("fullName")}
                                value={values.fullName}
                                className="font-normal disabled:opacity-50 disabled:text-black h-12 w-full px-3 outline-0 text-sec rounded-lg bg-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                              />
                            </div>
                          </div>
                          <div className="lg:w-3/12 md:mb-0 mb-2">
                            <label
                              htmlFor="phone_no"
                              className="block mb-2 text-base font-semibold text-sec"
                            >
                              Date Of Birth
                            </label>
                            <div className="relative flex w-full flex-wrap items-stretch">
                              <input
                                disabled={!isEditable}
                                type="date"
                                placeholder="01/01/2000"
                                onChange={handleChange("birthday")}
                                handleBlur={handleBlur("birthday")}
                                value={values.birthday}
                                className="h-12 w-full px-3  disabled:opacity-50 disabled:text-black outline-0 text-sec rounded-lg bg-stone-200 placeholder-gray-400 hover:border-theme focus:border-theme"
                              />
                            </div>
                          </div>
                          <div className="lg:w-3/12 md:mb-0 mb-2">
                            <label
                              htmlFor="phone"
                              className="block mb-2 text-base font-semibold text-sec"
                            >
                              Phone
                            </label>
                            <div className="relative flex flex-wrap w-full items-stretch">
                              <select className="w-4/12 inline-flex bg-stone-200 rounded-l-lg px-3 py-2">
                                {/* <option value="in">IN +91</option> */}
                                <option value="ca">+1</option>
                              </select>
                              <input
                                disabled={!isEditable}
                                type="tel"
                                // placeholder="(778) 881 - 1132"
                                onChange={handleChange("phone")}
                                handleBlur={handleBlur("phone")}
                                value={values.phone}
                                className="w-8/12  disabled:opacity-50 disabled:text-black inline-flex font-normal h-12 px-3 outline-0 text-sec rounded-r-lg bg-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="md:flex md:space-x-2">
                          <div className="md:w-2/6 md:mb-0 mb-2">
                            <label
                              htmlFor="first_name"
                              className="block mb-2 text-base font-semibold text-sec"
                            >
                              Email Id
                            </label>
                            <div className="relative flex w-full flex-wrap items-stretch">
                              <input
                                disabled={!isEditable}
                                type="email"
                                onChange={handleChange("email")}
                                handleBlur={handleBlur("email")}
                                value={values.email}
                                placeholder="someone@domain.com"
                                className="font-normal  disabled:opacity-50 disabled:text-black h-12 w-full px-3 outline-0 text-sec rounded-lg bg-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                              />
                            </div>
                          </div>
                          <div className="md:w-4/6 md:mb-0 mb-2">
                            <label
                              htmlFor="address"
                              className="block mb-2 text-base font-semibold text-sec"
                            >
                              Address
                            </label>
                            <div className="relative flex w-full flex-wrap items-stretch">
                              <input
                                disabled={!isEditable}
                                type="text"
                                placeholder="ABC Lane, XYZ"
                                onChange={handleChange("address")}
                                handleBlur={handleBlur("address")}
                                value={values.address}
                                className="font-normal disabled:opacity-50 disabled:text-black h-12 w-full px-3 outline-0 text-sec rounded-lg bg-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                              />
                            </div>
                          </div>
                        </div>
                        {isEditable && (
                          <button
                            className=" text-white bg-theme rounded-full px-6 py-1 mt-6"
                            onClick={handleSubmit}
                          >
                            Save
                          </button>
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>

              <div>
                <div className="w-full space-y-2 mt-5">
                  <div className="py-2 rounded-lg">
                    <div
                      className="flex items-center font-semibold md:text-lg text-base cursor-pointer"
                      onClick={() => handleOpen(1)}
                    >
                      <span
                        className={`mr-3 ${open === 1 ? "op" : "cl"}`}
                      ></span>
                      EMERGENCY CONTACT DETAILS
                    </div>
                    <div className={`mt-3  ${open === 1 ? "block" : "hidden"}`}>
                      <Formik
                        initialValues={{
                          relation: metadata?.relation ?? null,
                          relationName: metadata?.relationName ?? null,
                          phone: metadata?.phone ?? null,
                          alternatePhone: metadata?.alternatePhone ?? null,
                        }}
                        // validationSchema={}
                        onSubmit={(values) => {
                          metadataUpdate(values);
                        }}
                        validateOnBlur={false}
                      >
                        {({
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          values,
                        }) => (
                          <Form>
                            <div className="lg:flex lg:space-x-2 mb-4">
                              <div className="lg:w-3/12 md:mb-0 mb-2">
                                <label
                                  htmlFor="first_name"
                                  className="block mb-2 text-base font-semibold text-sec"
                                >
                                  Relation With You
                                </label>
                                <div className="relative flex w-full flex-wrap items-stretch">
                                  <select
                                    onChange={handleChange("relation")}
                                    handleBlur={handleBlur("relation")}
                                    value={values.relation}
                                    disabled={!isEditable}
                                    className="h-12 w-full disabled:opacity-50 disabled:text-black px-3 outline-0 text-black rounded-lg bg-stone-200 placeholder-gray-400 hover:border-theme focus:border-theme"
                                  >
                                    <option value="father">Father</option>
                                    <option value="mother">Mother</option>
                                    <option value="brother">Brother</option>
                                    <option value="sister">Sister</option>
                                    <option value="other">Other</option>
                                  </select>
                                  {/* <input type="text" placeholder="Thilak" className="font-normal h-12 w-full px-3 outline-0 text-sec rounded-lg bg-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"/> */}
                                </div>
                              </div>
                              <div className="lg:w-3/12 md:mb-0 mb-2">
                                <label
                                  htmlFor="name"
                                  className="block mb-2 text-base font-semibold text-sec"
                                >
                                  Full Name
                                </label>
                                <div className="relative flex w-full flex-wrap items-stretch">
                                  <input
                                    type="text"
                                    placeholder="John Doe"
                                    disabled={!isEditable}
                                    onChange={handleChange("relationName")}
                                    handleBlur={handleBlur("relationName")}
                                    value={values.relationName}
                                    className="h-12 w-full disabled:opacity-50 disabled:text-black px-3 outline-0 text-black rounded-lg bg-stone-200 placeholder-gray-400 hover:border-theme focus:border-theme"
                                  />
                                </div>
                              </div>
                              <div className="lg:w-3/12 md:mb-0 mb-2">
                                <label
                                  htmlFor="first_name"
                                  className="block mb-2 text-base font-semibold text-sec"
                                >
                                  Phone Number
                                </label>
                                <div className="relative flex flex-wrap w-full items-stretch">
                                  <select className="w-4/12 inline-flex bg-stone-200 rounded-l-lg px-3 py-2">
                                    <option value="ca">+1</option>
                                  </select>
                                  <input
                                    type="tel"
                                    disabled={!isEditable}
                                    placeholder="(1234) 000 1234"
                                    onChange={handleChange("phone")}
                                    handleBlur={handleBlur("phone")}
                                    value={values.phone}
                                    className="w-8/12 inline-flex disabled:opacity-50 disabled:text-black font-normal h-12 px-3 outline-0 text-black rounded-r-lg bg-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                                  />
                                </div>
                              </div>
                              <div className="lg:w-3/12 md:mb-0 mb-2">
                                <label
                                  htmlFor="first_name"
                                  className="block mb-2 text-base font-semibold text-sec"
                                >
                                  Alternate Phone Number
                                </label>
                                <div className="relative flex flex-wrap w-full items-stretch">
                                  <select className="w-4/12 inline-flex bg-stone-200 rounded-l-lg px-3 py-2">
                                    <option value="+1">+1</option>
                                  </select>
                                  <input
                                    type="text"
                                    disabled={!isEditable}
                                    placeholder="(123) 000 6789"
                                    onChange={handleChange("alternatePhone")}
                                    handleBlur={handleBlur("alternatePhone")}
                                    value={values.alternatePhone}
                                    className="w-8/12 inline-flex  disabled:opacity-50 disabled:text-black font-normal h-12 px-3 outline-0 text-black rounded-r-lg bg-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                                  />
                                </div>
                              </div>
                            </div>
                            {isEditable && (
                              <button
                                className=" text-white bg-theme rounded-full px-6 py-1 mt-3"
                                onClick={handleSubmit}
                              >
                                Save
                              </button>
                            )}
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>

                  <div className="py-2 rounded-lg">
                    <div
                      className="flex items-center font-semibold md:text-lg text-base cursor-pointer"
                      onClick={() => handleOpen(2)}
                    >
                      <span
                        className={`mr-3 ${open === 2 ? "op" : "cl"}`}
                      ></span>
                      YOUR MEMBERSHIP PLAN
                    </div>
                    <div className={`mt-3  ${open === 2 ? "block" : "hidden"}`}>
                      {memberships.map((mem, i) => (
                        <div
                          className="border-2 border-stone-100 md:p-4 p-2 mb-3"
                          key={i}
                        >
                          <div className="md:flex justify-between">
                            <div className="md:text-lg text-md text-theme font-semibold inline-flex">
                              <svg
                                className="mr-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="14.889"
                                height="18.469"
                                viewBox="0 0 14.889 18.469"
                              >
                                <g
                                  id="Group_3609"
                                  data-name="Group 3609"
                                  transform="matrix(0.951, -0.309, 0.309, 0.951, 0, 2.439)"
                                >
                                  <path
                                    id="Path_5131"
                                    data-name="Path 5131"
                                    d="M3.442,6.974l.281-2.889a4.085,4.085,0,0,1,.4.592,8.514,8.514,0,0,1,1.185,5.547L5.886,8.64c.046.13.091.264.137.4h0A54.391,54.391,0,0,1,7.891,16.6a29.552,29.552,0,0,0-1.369-2.9,50.643,50.643,0,0,0-3.543-5.72C2.132,6.792,1.162,5.535.061,4.243c0,0,.713-1.2-.061-4.243C0,0,3.2,1.507,3.442,6.974Z"
                                    transform="translate(0)"
                                    fill="#ff6a00"
                                  />
                                  <path
                                    id="Path_5132"
                                    data-name="Path 5132"
                                    d="M1.977,6.428l.971-2.221a3.4,3.4,0,0,1,.164.574,7.111,7.111,0,0,1-.495,4.711l.87-1.108c0,.115,0,.233.005.353h0a45.448,45.448,0,0,1-.474,6.488,24.717,24.717,0,0,0-.335-2.66A42.294,42.294,0,0,0,1.349,7.108C.984,5.945.54,4.7,0,3.385,0,3.385.875,2.62,1.05,0,1.05,0,3.2,2.026,1.977,6.428Z"
                                    transform="translate(6.048 3.594)"
                                    fill="#222"
                                  />
                                </g>
                              </svg>
                              {mem.membership.title}
                            </div>
                            <div className="card-point w-fit">
                              <b>
                                Valid till :{" "}
                                {format(
                                  parseISO(`${mem.expireAt}`),
                                  "dd MMM, yyyy"
                                )}{" "}
                                {mem.expireTime}
                              </b>
                            </div>
                          </div>
                          <div className="md:flex">
                            <div className="md:w-3/12">
                              <p className="md:mb-2">
                                <b>Subscription Amount :</b>
                                {mem.membership.price} CAD
                              </p>
                              <p>
                                <b>Plan for :</b> Signal Adult
                              </p>
                            </div>
                            <div className="md:w-9/12">
                              <p className="md:mb-2">
                                <b>Description : </b>
                                {mem.membership.description}
                              </p>
                            </div>
                          </div>
                          <div className="flex justify-end">
                            <div className="card-point">
                              <button href="#" className="text-right flex-end">
                                <p className=" w-fit rounded-full border-2 border-theme text-base font-bold text-theme mb-1.5 px-3 py-1 hover:bg-theme hover:text-white md:hidden block">
                                  Active
                                </p>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="py-2 rounded-lg">
                    <div
                      className="flex items-center font-semibold md:text-lg text-base cursor-pointer"
                      onClick={() => handleOpen(3)}
                    >
                      <span
                        className={`mr-3 ${open === 3 ? "op" : "cl"}`}
                      ></span>
                      FINANCE INFO
                    </div>
                    <div className={`${open === 3 ? "block" : "hidden"}`}>
                      <div className="md:overflow-auto overflow-x-scroll">
                        <table className="table-auto w-full responsive rounded-lg border-collapse md:text-base text-sm p-4 mt-4 mb-4">
                          <thead>
                            <tr className="text-left bg-neutral-200 p-8">
                              <th>Type</th>
                              <th>Card Type</th>
                              <th>Expiration Date</th>
                              <th>Set Default</th>
                            </tr>
                          </thead>
                          <tbody>
                            {myCards.map((card) => (
                              <tr className="text-left">
                                <td className="uppercase">
                                  {card.card.funding} Card
                                </td>
                                <td>
                                  {card.card.brand === "visa" && (
                                    <FaCcVisa className="inline w-14 h-7 my-2" />
                                  )}
                                  {card.card.brand === "mastercard" && (
                                    <FaCcMastercard className="inline w-14 h-7 my-2" />
                                  )}
                                  {card.card.brand === "amex" && (
                                    <FaCcAmex className="inline w-14 h-7 my-2" />
                                  )}
                                  {card.card.brand === "jcb" && (
                                    <FaCcJcb className="inline w-14 h-7 my-2" />
                                  )}
                                  {card.card.brand === "discover" && (
                                    <FaCcDiscover className="inline w-14 h-7 my-2" />
                                  )}
                                  {card.card.brand === "diners" && (
                                    <FaCcDinersClub className="inline w-14 h-7 my-2" />
                                  )}
                                  ●●●● {card.card.last4}
                                </td>
                                <td>
                                  {card.card.exp_month}/{card.card.exp_year}
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    name="set1"
                                    id="set1"
                                    value="set1"
                                    checked={isChecked}
                                    onChange={handleOnChange}
                                  />
                                </td>
                              </tr>
                            ))}
                            {/* <tr className="text-center">
                                <td className="py-3 px-2">Thilak Nirmala</td>
                                <td>Credit Card</td>
                                <td>Visa</td>
                                <td>27/11/2027</td>
                                <td>
                                  <input
                                    type="checkbox"
                                    name="set2"
                                    id="set2"
                                    value="set2"
                                    checked={isChecked}
                                    onChange={handleOnChange}
                                  />
                                </td>
                              </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Balance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="lg:w-6/12 md:mb-0 mb-5 pb-4">
            <label
              htmlFor="first_name"
              className="block mb-2 text-base font-semibold text-sec"
            >
              Amount
            </label>
            <div className="relative flex w-full flex-wrap items-stretch">
              <input
                type="number"
                value={addBalance}
                min={1}
                onChange={(e) => setAddBalance(e.target.value)}
                className="font-normal h-12 px-3 outline-0 text-sec rounded-lg bg-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
              />
            </div>
          </div>

          {myCards.map((c) => (
            <label className="flex flex-row items-center" key={c.id}>
              <input
                type="radio"
                name="card"
                value={c.id}
                checked={paymentsMethod === c.id}
                onChange={() => setPaymentsMethod(c.id)}
              />{" "}
              <span className="text-sm">
                {c.card.brand === "visa" && (
                  <FaCcVisa className="inline w-14 h-7 my-2" />
                )}
                {c.card.brand === "mastercard" && (
                  <FaCcMastercard className="inline w-14 h-7 my-2" />
                )}
                {c.card.brand === "amex" && (
                  <FaCcAmex className="inline w-14 h-7 my-2" />
                )}
                {c.card.brand === "jcb" && (
                  <FaCcJcb className="inline w-14 h-7 my-2" />
                )}
                {c.card.brand === "discover" && (
                  <FaCcDiscover className="inline w-14 h-7 my-2" />
                )}
                {c.card.brand === "diners" && (
                  <FaCcDinersClub className="inline w-14 h-7 my-2" />
                )}
                ●●●● {c.card.last4}
                <span className="px-4 italic">
                  {c.card.exp_month}/{c.card.exp_year}
                </span>
              </span>
            </label>
          ))}
          <label className="flex flex-row items-center mb-3">
            <input
              type="radio"
              name="card"
              value={"new"}
              checked={paymentsMethod === "new"}
              onChange={() => setPaymentsMethod("new")}
            />{" "}
            <span className="text-sm px-3">New card</span>
          </label>
          {paymentsMethod === "new" && (
            <div className="font-normal h-10 w-full p-3 outline-0 text-sec rounded-lg bg-stone-200 placeholder-black-800 hover:border-theme focus:border-theme">
              <CardElement
                options={{
                  hidePostalCode: true,
                  // style: {
                  //   base: {
                  //     backgroundColor: "rgb(231, 229, 228)",
                  //     color: "#000",
                  //     padding: "15px 5px",
                  //     fontSize: "16px",
                  //     fontSmoothing: "antialiased",
                  //     // iconColor: "#c4f0ff",
                  //     // color: "#fff",
                  //     // fontWeight: "500",
                  //     // fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                  //     // fontSize: "16px",
                  //     // fontSmoothing: "antialiased",
                  //     // ":-webkit-autofill": {
                  //     //   color: "#fce883",
                  //     // },
                  //     // "::placeholder": {
                  //     //   color: "#87BBFD",
                  //     // },
                  //   },
                  //   invalid: {
                  //     iconColor: "#FFC7EE",
                  //     color: "#FFC7EE",
                  //   },
                  // },
                }}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose}>Close</button>
          <button
            onClick={handleSubmit}
            className="bg-theme ml-3 rounded px-3 py-1 text-white"
            disabled={loadingState.wallet || paymentsMethod === null}
          >
            {loadingState.wallet ? (
              <span>
                <Spinner size="sm" /> Processing...
              </span>
            ) : (
              <span>Add</span>
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
