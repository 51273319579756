import React from "react";
import AdminLayout from "../../../components/AdminLayout";
import { useParams } from "react-router-dom";
import {
  addDateTimeToCourse,
  getACourse,
  getAllHours,
  getSlotAvailability,
  getAllCourts,
  removeDateFromCourse,
  publishCourse,
  unpublishCourse,
  updateACourse,
} from "../../../apis/booking.api";
import { Modal, Spinner } from "react-bootstrap";
import { formatedTime } from "../../../utils/time";
import { Formik } from "formik";
import { toastConf } from "../Dropins";
import { toast } from "react-toastify";

export default function ViewCoaching() {
  let { id } = useParams();
  // const navigate = useNavigate();

  const [course, setCourse] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [hours, setHours] = React.useState([]);
  const [courts, setCourts] = React.useState([]);

  const [apiLoading, setAPILoading] = React.useState(false);

  const initCall = async () => {
    const courseAPI = await getACourse(id);
    setCourse(courseAPI);
    const hours = await getAllHours();
    setHours(hours);
    const courts = await getAllCourts();
    setCourts(courts);
    setLoading(false);
    return;
  };

  React.useEffect(() => {
    initCall();

    //eslint-disable-next-line
  }, []);

  const [addDateModal, setAddDateModal] = React.useState(false);
  //   const [courtSelected, setCourtSelected] = React.useState(null);

  const handleClose = () => {
    setAddDateModal(false);
    // setCourtSelected(null);
  };

  const onDateTimeAdd = async (data) => {
    try {
      await addDateTimeToCourse(id, data);
      initCall();
      toast.success("Date Time Created.", toastConf);
      handleClose();
    } catch (error) {
      toast.error("Something went wrong.", toastConf);
    }
  };

  const onDateRemove = async (date) => {
    try {
      await removeDateFromCourse(id, { date });
      initCall();
      toast.success("Date Removed.", toastConf);
    } catch (error) {
      toast.error("Something went wrong.", toastConf);
    }
  };

  const onCourseUpdate = async (data) => {
    try {
      await updateACourse(id, data);
      initCall();
      toast.success("Course Updated.", toastConf);
    } catch (error) {
      toast.error("Something went wrong.", toastConf);
    }
  };

  const onPublishCourse = async () => {
    setAPILoading(true);
    try {
      await publishCourse(id);
      initCall();
      toast.success("Course Published.", toastConf);
      setAPILoading(false);
    } catch (error) {
      toast.error("Something went wrong.", toastConf);
      setAPILoading(false);
    }
  };
  const onUnPublishCourse = async () => {
    setAPILoading(true);
    try {
      await unpublishCourse(id);
      initCall();
      toast.success("Course Un-Published.", toastConf);
      setAPILoading(false);
    } catch (error) {
      toast.error("Something went wrong.", toastConf);
      setAPILoading(false);
    }
  };
  return (
    <AdminLayout>
      <div className="px-5">
        {loading ? (
          "Loading..."
        ) : (
          <>
            <div className="rounded px-5 py-2 bg-white shadow">
              <div className="m-1 flex justify-end">
                {course.status === "published" && (
                  <button
                    className="text-white shadow rounded text-sm bg-theme px-3 py-1"
                    onClick={onUnPublishCourse}
                    disabled={apiLoading}
                  >
                    {apiLoading ? <Spinner size="sm" /> : "Unpublish"}
                  </button>
                )}
                {course.status === "draft" && (
                  <button
                    className="text-white shadow rounded text-sm bg-theme px-3 py-1"
                    onClick={onPublishCourse}
                    disabled={
                      apiLoading || Object.keys(course.chart).length === 0
                    }
                  >
                    {apiLoading ? <Spinner size="sm" /> : "Publish"}
                  </button>
                )}
              </div>
            </div>
            <div className="my-3 rounded p-5 bg-white shadow">
              <Formik
                initialValues={{
                  title: course.title,
                  price: course.price,
                  description: course.description,
                }}
                onSubmit={(values) => {
                  //   onSubmit(slots);
                  onCourseUpdate(values);
                }}
                validateOnBlur={false}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                }) => (
                  <>
                    <div className="md:grid md:gap-4 md:grid-cols-2">
                      <div>
                        <label
                          htmlFor="first_name"
                          className="block mb-2 text-base font-semibold text-sec"
                        >
                          Title*
                        </label>
                        <div className="relative flex w-full flex-wrap items-stretch">
                          <input
                            type="text"
                            value={values["title"]}
                            onChange={handleChange("title")}
                            placeholder=""
                            className="font-normal h-10 text-sm w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                          />
                          <span className="z-10 h-full leading-snug font-normal text-center text-white absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                            {/* <img src={User} /> */}
                          </span>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="first_name"
                          className="block mb-2 text-base font-semibold text-sec"
                        >
                          Price*
                        </label>
                        <div className="relative flex w-full flex-wrap items-stretch">
                          <input
                            type="number"
                            placeholder=""
                            value={values["price"]}
                            onChange={handleChange("price")}
                            className="font-normal h-10 text-sm w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                          />
                          <span className="z-10 h-full leading-snug font-normal text-center text-white absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                            {/* <img src={User} /> */}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <label
                        htmlFor="phone_no"
                        className="block mb-2 text-base font-semibold text-sec"
                      >
                        Description*
                      </label>
                      <div className="relative flex w-full flex-wrap items-stretch">
                        <textarea
                          id="message"
                          rows="4"
                          value={values["description"]}
                          onChange={handleChange("description")}
                          className="block p-2.5 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Write Description here..."
                        ></textarea>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="m-1 flex justify-end">
                        <button
                          className="text-white shadow rounded text-sm bg-theme px-3 py-1"
                          onClick={handleSubmit}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </Formik>
            </div>
            <div className="my-3 rounded px-5 py-2 bg-white shadow">
              <div className="m-1 flex justify-end">
                <button
                  className="text-white shadow rounded text-xs bg-theme px-3 py-1"
                  onClick={() => setAddDateModal(true)}
                >
                  Add Date & Time
                </button>
              </div>
              <table className="table-auto w-full rounded-lg border-collapse p-4 mt-4 mb-8">
                <thead>
                  <tr className="text-left bg-neutral-200 p-8">
                    <th className="py-3 px-2 mb-4">Date</th>
                    <th>Timing</th>
                    <th>Time Court</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(course.chart).map((key, i) => (
                    <tr key={i}>
                      <td className="py-5 px-2">{key}</td>
                      <td>
                        {formatedTime(course?.chart[key][0]?.hour?.time)} -{" "}
                        {formatedTime(
                          course?.chart[key][course?.chart[key].length - 1]
                            ?.hour?.endTime
                        )}
                      </td>
                      <td>
                        {course?.chart[key]
                          .map(
                            (h) =>
                              `${formatedTime(h.hour.time)} -> Court: ${
                                h.court
                              }`
                          )
                          .join(", ")}
                      </td>
                      <td>
                        <button
                          onClick={() => onDateRemove(key)}
                          className="inline-flex bg-red-100 rounded-full p-4 ml-4"
                        >
                          <svg
                            id="Group_2613"
                            data-name="Group 2613"
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 9.991 12.206"
                          >
                            <path
                              id="Path_4629"
                              data-name="Path 4629"
                              d="M-943.763,160.817c0-1.04,0-2.081,0-3.121a.54.54,0,0,1,.461-.6.547.547,0,0,1,.643.572c.007.555,0,1.11,0,1.665q0,2.185,0,4.37a1.065,1.065,0,0,0,1.149,1.152q2.172,0,4.343,0A1.068,1.068,0,0,0-936,163.7q0-2.978,0-5.956c0-.414.219-.658.573-.649a.526.526,0,0,1,.525.478c.015.146.008.294.008.442q0,2.835,0,5.67a2.156,2.156,0,0,1-2.282,2.281q-2.146,0-4.291,0a2.158,2.158,0,0,1-2.3-2.289Q-943.764,162.247-943.763,160.817Z"
                              transform="translate(944.321 -153.761)"
                              fill="#ec0000"
                            />
                            <path
                              id="Path_4630"
                              data-name="Path 4630"
                              d="M-947.264,94.716h1.724c.173,0,.347-.006.52,0a.542.542,0,0,1,.526.537.558.558,0,0,1-.506.562,1.709,1.709,0,0,1-.182,0h-8.607c-.052,0-.1,0-.156,0a.559.559,0,0,1-.54-.556.537.537,0,0,1,.544-.545c.624-.01,1.248,0,1.872,0h.353c0-.317,0-.616,0-.915,0-.575.173-.75.739-.75q1.521,0,3.042,0c.466,0,.665.2.669.664C-947.262,94.035-947.264,94.355-947.264,94.716Zm-3.32-.015h2.184v-.528h-2.184Z"
                              transform="translate(954.485 -93.05)"
                              fill="#ec0000"
                            />
                            <path
                              id="Path_4631"
                              data-name="Path 4631"
                              d="M-889.4,180.835c0,.615,0,1.23,0,1.845,0,.4-.216.646-.552.645s-.553-.253-.554-.647q0-1.858,0-3.717a.566.566,0,0,1,.542-.632c.337-.008.561.24.563.634C-889.4,179.587-889.4,180.211-889.4,180.835Z"
                              transform="translate(893.842 -173.892)"
                              fill="#ec0000"
                            />
                            <path
                              id="Path_4632"
                              data-name="Path 4632"
                              d="M-846.659,180.826c0,.641,0,1.282,0,1.923a.558.558,0,0,1-.443.565.54.54,0,0,1-.6-.3.771.771,0,0,1-.057-.3q-.005-1.884,0-3.769a.552.552,0,0,1,.557-.614.565.565,0,0,1,.548.626C-846.657,179.579-846.659,180.2-846.659,180.826Z"
                              transform="translate(853.32 -173.891)"
                              fill="#ec0000"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
      <Modal
        show={addDateModal}
        centered
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Date & Time</Modal.Title>
        </Modal.Header>

        <DateTimeFormContainer
          hours={hours}
          handleClose={handleClose}
          onSubmit={onDateTimeAdd}
          courts={courts}
        />
      </Modal>
    </AdminLayout>
  );
}

const DateTimeFormContainer = ({
  hours,
  handleClose,
  onSubmit,
  date,
  courts,
}) => {
  const [slots, setSlots] = React.useState([]);
  const renderedEnd = (startTime) => {
    const getIndex = hours.findIndex((hour) => hour.time === startTime);
    if (getIndex === -1) {
      return hours;
    }
    return hours.slice(getIndex);
  };

  const onSlotRenderer = (values) => {
    const getStartIndex = hours.findIndex(
      (hour) => hour.time === values.startTime
    );
    const getEndIndex = hours.findIndex(
      (hour) => hour.endTime === values.endTime
    );
    // console.log(getStartIndex, getEndIndex);
    // console.log(hours.slice(getStartIndex, getEndIndex + 1));
    setSlots(
      hours.slice(getStartIndex, getEndIndex + 1).map((i) => {
        return {
          time: i.id,
          ren: `${formatedTime(i.time)} - ${formatedTime(i.endTime)}`,
          court: null,
          startTime: i.time,
          date: values.date,
        };
      })
    );
  };

  const getDate = () => {
    const dt = new Date();
    return `${
      dt.getFullYear() +
      "-" +
      ("0" + (dt.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (dt.getDate() + 1)).slice(-2)
    }`;
  };
  const onCourtSelect = async (time, court, date, id) => {
    const av = await getSlotAvailability({ time, date });
    if (av[court] === true) {
      toast.success("Slot Available.", toastConf);
      setSlots((_slots) =>
        _slots.map((_slot) => {
          if (_slot.time === id) {
            return { ..._slot, court };
          }
          return _slot;
        })
      );
      return;
    } else {
      toast.error(`Court ${court} Unavailable.`, toastConf);
      return;
    }
  };
  return (
    <Formik
      initialValues={{
        date: getDate(),
        startTime: hours[0].time,
        endTime: hours[0].endTime,
      }}
      onSubmit={(values) => {
        onSubmit(slots);
      }}
      validateOnBlur={false}
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        values,
      }) => (
        <>
          <Modal.Body>
            <div className="md:grid md:gap-4 md:grid-cols-3 mt-3">
              <div>
                <label
                  htmlFor="first_name"
                  className="block mb-2 text-base font-semibold text-sec"
                >
                  Date*
                </label>
                <div className="relative flex w-full flex-wrap items-stretch">
                  <input
                    type="date"
                    value={values["date"]}
                    min={getDate()}
                    onChange={(e) => {
                      handleChange("date")(e.target.value);
                      setSlots([]);
                    }}
                    className="font-normal h-8 text-sm w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="startTime"
                  className="block mb-2 text-base font-semibold text-sec"
                >
                  Start Time
                </label>
                <div className="relative flex w-full flex-wrap items-stretch">
                  <select
                    value={values["startTime"]}
                    onChange={(e) => {
                      handleChange("startTime")(e.target.value);
                      handleChange("endTime")("");
                      setSlots([]);
                    }}
                    className="font-normal h-8 text-sm w-full px-2 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                  >
                    {hours.map((hour) => (
                      <option value={hour.time} key={hour.time}>
                        {formatedTime(hour.time)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <label
                  htmlFor="endTime"
                  className="block mb-2 text-base font-semibold text-sec"
                >
                  End Time
                </label>
                <div className="relative flex w-full flex-wrap items-stretch">
                  <select
                    value={values["endTime"]}
                    onChange={(e) => {
                      handleChange("endTime")(e.target.value);
                      onSlotRenderer({ ...values, endTime: e.target.value });
                    }}
                    className="font-normal h-8 text-sm w-full px-2 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                  >
                    <option value={null}>-</option>
                    {renderedEnd(values["startTime"]).map((hour) => (
                      <option value={hour.endTime} key={hour.time}>
                        {formatedTime(hour.endTime)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="md:grid md:gap-4 md:grid-cols-3 mt-3 w-[60%]">
              <>
                <label
                  htmlFor="startTime"
                  className="block mb-2 text-base font-semibold text-sec"
                >
                  Timing
                </label>
                <label
                  htmlFor="startTime"
                  className="block mb-2 text-base font-semibold text-sec"
                >
                  Court
                </label>
                <label
                  htmlFor="startTime"
                  className="block mb-2 text-base font-semibold text-sec"
                >
                  Selected
                </label>
              </>

              {slots.map((slot) => (
                <>
                  <p className="text-xs pt-2">{slot.ren}</p>
                  <select
                    value={slot.court ?? "-"}
                    onChange={(e) => {
                      onCourtSelect(
                        slot.startTime,
                        e.target.value,
                        values["date"],
                        slot.time
                      );
                    }}
                    className="font-[10px] py-1 h-8 w-16 px-3 outline-0 bg-transparent text-sec rounded-lg border border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                  >
                    {[null, ...courts.map((c, i) => i + 1)].map((court) => (
                      <option value={court} key={court ?? "-"}>
                        {court ?? "-"}
                      </option>
                    ))}
                  </select>
                  <p className="text-xs pt-2">Selected: {slot.court ?? "-"}</p>
                </>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button variant="secondary" onClick={handleClose}>
              Close
            </button>
            <button
              className={`bg-theme px-3 py-1 rounded shadow text-white ${
                //   courtSelected === null && " opacity-50"
                ""
              }`}
              onClick={handleSubmit}
            >
              Add
            </button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};
