import React, { useContext, useState } from "react";
import { getAllMembership, getMyEnrollments } from "../../../apis/booking.api";
import { CartContext } from "../../../context/Cart.context";
import { formatedTime } from "../../../utils/time";

export default function MembershipPlans() {
  const { addToCart, cart, removeFromCart } = useContext(CartContext);
  const [plans, setPlans] = useState([]);
  const [myEnrollment, setEnrollments] = useState([]);

  const initCall = async () => {
    const myEnrollments = await getMyEnrollments({ type: "membership" });
    setEnrollments(myEnrollments);
    const planAPI = await getAllMembership();
    setPlans(planAPI);
    return;
  };

  React.useEffect(() => {
    initCall();
  }, []);

  const isInCart = (id) => {
    const dropIn = cart.filter((item) => item.dependentID === id);

    if (dropIn.length > 0) {
      return true;
    }
    return false;
  };

  const getCartItemID = (id) => {
    const dropIn = cart.filter((item) => item.dependentID === id);

    return dropIn[0].id;
  };

  const BTNComponent = (item) => {
    if (isInCart(item.planID)) {
      return (
        <button
          className="text-right flex-end"
          onClick={() => {
            removeFromCart(getCartItemID(item.planID));
          }}
        >
          <p className=" w-fit rounded-full border-2 border-theme text-base font-bold mb-1.5 px-3 py-1 bg-theme text-white ">
            Remove From Cart
          </p>
        </button>
      );
    }
    if (myEnrollment.filter((f) => f.dependentID === item.planID).length > 0) {
      return (
        <button
          disabled
          className={`rounded-full border-2 border-theme text-base font-bold  mb-1.5 px-3 py-1 bg-theme text-white`}
        >
          Already Enrolled
        </button>
      );
    }
    return (
      <button
        className="text-right flex-end"
        onClick={() => {
          addToCart({ type: "membership", dependentID: item.planID });
        }}
      >
        <p className=" w-fit rounded-full border-2 border-theme text-base font-bold text-theme mb-1.5 px-3 py-1 hover:bg-theme hover:text-white ">
          Buy Membership
        </p>
      </button>
    );
  };

  return (
    <div className="py-2">
      <div className="container-fluid">
        {plans.map((i, index) => (
          <div className="bg-stone-100 md:p-4 p-2 mb-3" key={index}>
            <div className="md:flex justify-between mb-2">
              <div className="md:text-lg text-base text-theme font-semibold inline-flex">
                <svg
                  className="mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.889"
                  height="18.469"
                  viewBox="0 0 14.889 18.469"
                >
                  <g
                    id="Group_3609"
                    data-name="Group 3609"
                    transform="matrix(0.951, -0.309, 0.309, 0.951, 0, 2.439)"
                  >
                    <path
                      id="Path_5131"
                      data-name="Path 5131"
                      d="M3.442,6.974l.281-2.889a4.085,4.085,0,0,1,.4.592,8.514,8.514,0,0,1,1.185,5.547L5.886,8.64c.046.13.091.264.137.4h0A54.391,54.391,0,0,1,7.891,16.6a29.552,29.552,0,0,0-1.369-2.9,50.643,50.643,0,0,0-3.543-5.72C2.132,6.792,1.162,5.535.061,4.243c0,0,.713-1.2-.061-4.243C0,0,3.2,1.507,3.442,6.974Z"
                      transform="translate(0)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_5132"
                      data-name="Path 5132"
                      d="M1.977,6.428l.971-2.221a3.4,3.4,0,0,1,.164.574,7.111,7.111,0,0,1-.495,4.711l.87-1.108c0,.115,0,.233.005.353h0a45.448,45.448,0,0,1-.474,6.488,24.717,24.717,0,0,0-.335-2.66A42.294,42.294,0,0,0,1.349,7.108C.984,5.945.54,4.7,0,3.385,0,3.385.875,2.62,1.05,0,1.05,0,3.2,2.026,1.977,6.428Z"
                      transform="translate(6.048 3.594)"
                      fill="#222"
                    />
                  </g>
                </svg>
                {i.title} - [{formatedTime(i.startTime)} To{" "}
                {formatedTime(i.endTime)}]
              </div>
              <div className="card-point w-fit hidden md:block">
                {BTNComponent(i)}
                {/* <button
                  className=""
                  onClick={() => {
                    addToCart(null, null, "membership", i.planID);
                  }}
                >
                  <p className="rounded-full border-2 border-theme text-base font-bold text-theme mb-1.5 px-3 py-1 hover:bg-theme hover:text-white md:block hidden">
                    Buy Membership
                  </p>
                </button> */}
              </div>
            </div>
            <div className="md:flex">
              <div className="md:w-4/12 lg:w-3/12">
                <p className="mb-2">
                  <b>Subscription Amount :</b> {i.price} CAD
                </p>
                <p className="mb-2">
                  <b>Validity :</b> {i.days} days
                </p>
                <p className="md:mb-0 mb-2">
                  <b>Plan for :</b> Signal Adult
                </p>
              </div>
              <div className="md:w-8/12 lg:w-9/12">
                <p className="text-justify md:mb-0 mb-2">
                  <b>Description : </b>
                  {i.description}
                </p>
              </div>
            </div>
            <div className="flex justify-end md:hidden ">
              <div className="card-point pt-2">{BTNComponent(i)}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
