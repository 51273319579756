import Axios from "axios";
import { Cookies } from "react-cookie";

// var qs = require("qs");

const apis = {
  get: (path, options) => {
    return request(path, "get", options);
  },
  post: (path, options) => {
    return request(path, "post", options);
  },
  patch: (path, options) => {
    return request(path, "patch", options);
  },
  delete: (path, options) => {
    return request(path, "delete", options);
  },
};

async function request(path, method, options) {
  //   loadingStore.itemsLoading++;

  const headers = {
    "content-type": "application/json",
  };
  const token = localStorage.getItem("token");

  if (token) {
    headers["authorization"] = "Bearer " + token;
  }

  try {
    const response = await Axios.request({
      ...options,
      method,
      url: `${process.env.REACT_APP_API_URL}${path}`,
      paramsSerializer: options.params,
      //   function (params) {
      //     return qs.stringify(params, { arrayFormat: "comma" });
      //   }
      headers,
    }).finally(() => {
      //   loadingStore.itemsLoading--;
    });

    return response.data;
  } catch (error) {
    if (error.isAxiosError && error?.response?.status === 401 && token) {
      //   userStore.unloadUser();
      //   snackbarStore.message =
      //     "Your session has timed out. Please log in again.";

      // try {
      // getHistory().push("/");
      console.log(error?.response?.status);
      Cookies.remove("session_token", {
        path: "/",
        domain: process.env.REACT_APP_DOMAIN,
        maxAge: 0,
      });
      // } catch (error) {
      //   window.location.assign("/");
      throw error;
      // }
    }

    throw error;
  }
}

export default apis;
