import React, { useState } from "react";
import { useCookies } from "react-cookie";

export const AuthContext = React.createContext();

export default function AuthProvider({ children }) {
  const [isAuth, setAuth] = useState(false);
  const [user, setUser] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cookies, setCookies] = useCookies(["session_token"]);

  const logout = () => {
    setCookies("session_token", "", {
      path: "/",
      domain: process.env.REACT_APP_DOMAIN,
      maxAge: 0,
    });
    localStorage.removeItem("token");
    setUser(null);
    setAuth(false);
    setIsAdmin(false);
  };

  const onLogin = (token) => {
    setCookies("session_token", token, {
      path: "/",
      domain: process.env.REACT_APP_DOMAIN,
      maxAge: 60 * 60 * 3,
    });
    localStorage.setItem("token", token);
    setAuth(true);
  };

  // const getSession = () => {

  // }

  React.useEffect(() => {
    if (cookies.session_token) {
      // console.log(cookies.session_token);
      localStorage.setItem("token", cookies.session_token);
      setIsLoading(false);
      return setAuth(true);
    }
    setIsLoading(false);
    localStorage.removeItem("token");
    return;

    //eslint-disable-next-line
  }, []);
  return (
    <AuthContext.Provider
      value={{ isAuth, setAuth, user, logout, setUser, onLogin }}
    >
      {isLoading ? <>Loading...</> : <>{children}</>}
    </AuthContext.Provider>
  );
}
