import React from "react";
import AdminLayout from "../../../components/AdminLayout";
import { getADropin } from "../../../apis/booking.api.js";
import { useParams, useNavigate } from "react-router-dom";
import { formatedDate, formatedTime } from "../../../utils/time.js";
import { FiChevronLeft, FiMinusCircle, FiUserX } from "react-icons/fi";
import { formatInTimeZone } from "date-fns-tz";

const splitDate = (date) => date.split("-");

export default function ViewDropin() {
  let { id } = useParams();
  const navigate = useNavigate();

  const [dropin, setDropin] = React.useState();
  const [loading, setLoading] = React.useState(true);

  const initCall = async () => {
    const dropinAPI = await getADropin(id);
    setDropin(dropinAPI);
    // console.log(dropinAPI);
    setLoading(false);
    return;
  };

  React.useEffect(() => {
    initCall();
  }, []);
  return (
    <AdminLayout>
      {/* {JSON.stringify(dropin)} */}
      {loading ? (
        "Loading... "
      ) : (
        <>
          {" "}
          <div className="bg-white shadow-md mb-3 mx-5" key={0}>
            <div className="flex items-center justify-between bg-theme text-white p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-lg font-semibold">View Dropin</h3>
              <button
                className="p-1 flex flex-row items-center ml-auto bg-transparent text-white  border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => navigate(`/admin/dropins/`)}
              >
                <span className="bg-theme opacity-1 text-xl inline outline-none focus:outline-none">
                  <FiChevronLeft />
                </span>
                <span className="text-sm">Back</span>
              </button>
            </div>
            <div className="">
              <div className="w-full">
                <div className="rounded-lg">
                  <div className="border-b bg-gray-50 p-4">
                    <div
                      className={`flex items-center font-semibold md:text-lg text-base cursor-pointer mb-2 `}
                    >
                      {formatedDate(
                        splitDate(dropin.date)[2],
                        splitDate(dropin.date)[1],
                        splitDate(dropin.date)[0]
                      )}
                    </div>
                    <div>
                      <p>
                        {dropin.hours.length > 0 ? (
                          <span className="font-medium mr-5">
                            {formatedTime(dropin.hours[0].time)} To{" "}
                            {formatedTime(
                              dropin.hours[dropin.hours.length - 1].endTime
                            )}{" "}
                            ({dropin.hours.length}h)
                          </span>
                        ) : (
                          "-"
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="md:overflow-auto overflow-x-scroll block">
                    {dropin.enrollments.length === 0 && !loading && (
                      <div className="min-h-[60vh] flex justify-center items-center flex-col">
                        <FiUserX className="text-8xl" />
                        <h2 className="text-gray-200 text-4xl font-bold my-3">
                          No Enrollments Yet!
                        </h2>
                      </div>
                    )}
                    <table className="whitespace-nowrap w-full rounded-lg md:text-base text-sm p-4 mt-4">
                      <tbody>
                        {dropin.enrollments.map((user, i) => (
                          <tr
                            className="border-b border-gray-200 shadow-md bg-gray-50"
                            key={i}
                          >
                            <td className="p-2">
                              <div className="font-medium inline-flex items-center md:mr-5 mr-2">
                                <span>{user.user.fullName}</span>
                              </div>
                            </td>
                            <td className="p-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                viewBox="0 0 19 19"
                                className="inline-flex mr-2"
                              >
                                <g
                                  id="Group_3450"
                                  data-name="Group 3450"
                                  transform="translate(-169 -395)"
                                >
                                  <rect
                                    id="Rectangle_2254"
                                    data-name="Rectangle 2254"
                                    width="19"
                                    height="19"
                                    rx="4"
                                    transform="translate(169 395)"
                                    fill="#fff3eb"
                                  />
                                  <path
                                    id="Icon_material-access-time"
                                    data-name="Icon material-access-time"
                                    d="M8.432,3a5.437,5.437,0,1,0,5.443,5.437A5.435,5.435,0,0,0,8.432,3Zm.005,9.787a4.35,4.35,0,1,1,4.35-4.35A4.349,4.349,0,0,1,8.437,12.787Zm.272-7.068H7.893V8.981l2.855,1.713.408-.669L8.709,8.573Z"
                                    transform="translate(170.063 396.063)"
                                    fill="#ff6a00"
                                  />
                                </g>
                              </svg>
                              {formatInTimeZone(
                                user.addedAt,
                                "America/Toronto",
                                "dd MMM yyyy, HH:mm:ss zzz"
                              )}
                              {/* {user.addedAt} */}
                            </td>
                            <td className="p-2">
                              <span className="font-semibold">
                                <span className="text-theme">
                                  {user.price} CAD
                                </span>
                              </span>
                            </td>

                            <td className="p-2">
                              <div className="flex items-center">
                                <div className="md:w-full block md:justify-center mr-3"></div>
                                <div className="md:w-full md:min-w-88 block md:justify-center"></div>
                              </div>
                            </td>

                            <td className="p-2 text-center">
                              <button
                                className="inline-flex bg-white rounded-full p-3 shadow mr-2"
                                // onClick={() =>
                                //   navigate(`/admin/dropins/${user.userID}`)
                                // }
                              >
                                <FiMinusCircle />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </AdminLayout>
  );
}
