import React, { useState } from "react";
import {
  cancelABookingEvent,
  getMyScheduleBookings,
} from "../../apis/booking.api";
import { FiCheckCircle, FiSlash, FiXCircle } from "react-icons/fi";
import { format } from "date-fns";
import { formatedTime } from "../../utils/time";
import { toast } from "react-toastify";
import { toastConf } from "../AdminDashboard/Dropins";
const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function MyBookings() {
  const [showCancelModal, setCancelModal] = useState({
    show: false,
    bookingId: "",
    itemId: "",
  });
  const [showSuccessModal, setSuccessModal] = useState(false);

  const [activePage, setActivePage] = useState(1);
  const [oldPage, setOldPage] = useState(1);

  const [schedules, setSchedules] = useState({
    active: [],
    past: [],
  });

  const initCall = async () => {
    const active = await getMyScheduleBookings();
    const past = await getMyScheduleBookings({ old: true });
    setSchedules({
      active,
      past,
    });
  };

  React.useEffect(() => {
    initCall();
  }, []);

  const [open, setOpen] = useState(1);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const onCancelEvent = async () => {
    try {
      await cancelABookingEvent(
        showCancelModal.bookingId,
        showCancelModal.itemId
      );
      initCall();
      setCancelModal({
        show: false,
        bookingId: "",
        itemId: "",
      });
      setSuccessModal(true);
    } catch (error) {
      setCancelModal(false);
      toast.error(
        error?.response?.data?.message ?? "Something went wrong.",
        toastConf
      );
    }
  };

  const pager = (count) => {
    const _c = [];
    const c = Math.floor(count / 10);
    let i = 0;
    while (i <= c) {
      _c.push(i);
      i++;
    }
    return _c;
  };

  return (
    <>
      <div className="flex flex-wrap gap-y-4 mb-4 py-2">
        <div className="w-full">
          <div className="card-block bg-white border-2 border-white rounded hover:shadow-md focus:shadow-md position-relative mb-3">
            <div className="p-2">
              <div className="w-full space-y-2">
                <div className="rounded-lg">
                  <div
                    className="flex items-center font-semibold md:text-lg text-base cursor-pointer"
                    onClick={() => handleOpen(1)}
                  >
                    <span className={`mr-3 ${open === 1 ? "op" : "cl"}`}></span>
                    Upcoming Events
                  </div>
                  <div
                    className={`md:overflow-auto overflow-x-scroll ${
                      open === 1 ? "block" : "hidden"
                    }`}
                  >
                    <table className="table-auto whitespace-nowrap w-full rounded-lg border-collapse md:text-base text-sm p-4 mt-4 mb-8">
                      <thead>
                        <tr className="text-left bg-neutral-200 p-8">
                          <th className="py-1 px-2 mb-4 text-left">Name</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {schedules.active
                          .slice(activePage * 10 - 10, activePage * 10)
                          .map((item) => {
                            const date = item.expireAt
                              .split("-")
                              .map((e) => parseInt(e));
                            return (
                              <tr
                                key={item.id}
                                className="border-b border-gray-400"
                              >
                                <td className="py-2 px-2">
                                  {item.type === "dropin" && "Drop-In"}
                                  {item.type === "course" && "Course"}
                                  {item.type === "court" &&
                                    `Court (Space:${item.court})`}
                                </td>
                                <td>
                                  {/* {format(new Date(item.expireAt), "dd LLL, yyyy")} */}
                                  {date[2]} {month[date[1] - 1]}, {date[0]}
                                </td>
                                <td>{formatedTime(item.expireTime)}</td>
                                <td>
                                  {item.status === "success" && "Active"}
                                  {item.status === "cancelled" && "Cancelled"}
                                </td>
                                {/* {item.booking.bookingID},
                            {
                              item.booking.items.filter(
                                (f) => f.dependentID === item.dependentID
                              )[0].id
                            } */}
                                <td>
                                  {item.status === "success" && (
                                    <button
                                      className="bg-red-200 text-red-600 rounded-full p-1.5 shadow-md"
                                      onClick={() =>
                                        setCancelModal({
                                          show: true,
                                          bookingId: item.booking.bookingID,
                                          itemId:
                                            item.type === "court"
                                              ? item.id
                                              : item.booking.items.filter(
                                                  (f) =>
                                                    f.dependentID ===
                                                    item.dependentID
                                                )[0].id,
                                        })
                                      }
                                    >
                                      <FiSlash className="text-lg" />
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })}{" "}
                      </tbody>
                    </table>
                    <nav className="flex justify-between flex-row navigation example">
                      <p>
                        {Math.ceil(schedules.active.length / 10) ===
                        activePage ? (
                          <span>
                            {schedules.active.length} of{" "}
                            {schedules.active.length}
                          </span>
                        ) : (
                          <span>
                            {activePage * 10 - 10 + 1} of{" "}
                            {schedules.active.length}
                          </span>
                        )}
                      </p>
                      <ul class="list-style-none flex">
                        <li>
                          <button
                            onClick={() => setActivePage(activePage - 1)}
                            disabled={activePage === 1}
                            class={` text-black shadow-md bg-white relative block rounded-l  px-3 py-1.5 text-sm transition-all duration-300 hover:bg-neutral-100  dark:hover:bg-neutral-700 dark:hover:text-white`}
                          >
                            Previous
                          </button>
                        </li>
                        {pager(schedules.active.length).map((p) => (
                          <li key={p}>
                            <button
                              onClick={() => setActivePage(p + 1)}
                              class={`${
                                p + 1 === activePage
                                  ? "bg-gray-500 text-white"
                                  : "bg-white text-black-600"
                              } relative block px-3 shadow-md py-1.5 text-sm transition-all duration-300 hover:bg-neutral-100  dark:hover:bg-neutral-700 dark:hover:text-white`}
                            >
                              {p + 1}
                            </button>
                          </li>
                        ))}
                        <li>
                          <button
                            onClick={() => setActivePage(activePage + 1)}
                            disabled={
                              activePage ===
                              Math.ceil(schedules.active.length / 10)
                            }
                            class={` text-black bg-white shadow-md relative block rounded-r  px-3 py-1.5 text-sm transition-all duration-300 hover:bg-neutral-100  dark:hover:bg-neutral-700 dark:hover:text-white`}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-block bg-white border-2 border-white rounded hover:shadow-md focus:shadow-md position-relative mb-3">
            <div className="p-2">
              <div className="w-full space-y-2">
                <div className="rounded-lg">
                  <div
                    className="flex items-center font-semibold md:text-lg text-base cursor-pointer"
                    onClick={() => handleOpen(2)}
                  >
                    <span className={`mr-3 ${open === 2 ? "op" : "cl"}`}></span>
                    Past Events
                  </div>
                  <div
                    className={`md:overflow-auto overflow-x-scroll ${
                      open === 2 ? "block" : "hidden"
                    }`}
                  >
                    <table className="table-auto whitespace-nowrap w-full rounded-lg md:text-base text-sm p-4 mt-4 mb-8">
                      <thead>
                        <tr className="text-left bg-neutral-200 p-8">
                          <th className="py-3 px-2 mb-4">Name</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {schedules.past
                          .slice(oldPage * 10 - 10, oldPage * 10)
                          .map((item) => (
                            <tr className="border-b border-gray-400">
                              <td className="py-2 px-2">
                                {item.type === "dropin" && "Drop-In"}
                                {item.type === "course" && "Course"}
                                {item.type === "court" &&
                                  `Court (Space:${item.court})`}
                              </td>
                              <td>
                                {format(
                                  new Date(item.expireAt),
                                  "dd LLL, yyyy"
                                )}
                              </td>
                              <td>{formatedTime(item.expireTime)}</td>
                              <td>
                                {item.status === "success" && (
                                  <FiCheckCircle className="text-green-500" />
                                )}
                                {item.status === "cancelled" && (
                                  <FiXCircle className="text-red-500" />
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <nav className="flex justify-between flex-row navigation example">
                      <p>
                        {Math.ceil(schedules.past.length / 10) === oldPage ? (
                          <span>
                            {schedules.past.length} of {schedules.past.length}
                          </span>
                        ) : (
                          <span>
                            {oldPage * 10 - 10 + 1} of {schedules.past.length}
                          </span>
                        )}
                      </p>
                      <ul class="list-style-none flex">
                        <li>
                          <button
                            onClick={() => setOldPage(oldPage - 1)}
                            disabled={oldPage === 1}
                            class={` text-black shadow-md bg-white relative block rounded-l  px-3 py-1.5 text-sm transition-all duration-300 hover:bg-neutral-100  dark:hover:bg-neutral-700 dark:hover:text-white`}
                          >
                            Previous
                          </button>
                        </li>
                        {pager(schedules.past.length).map((p) => (
                          <li key={p}>
                            <button
                              onClick={() => setOldPage(p + 1)}
                              class={`${
                                p + 1 === oldPage
                                  ? "bg-gray-500 text-white"
                                  : "bg-white text-black-600"
                              } relative block px-3 shadow-md py-1.5 text-sm transition-all duration-300 hover:bg-neutral-100  dark:hover:bg-neutral-700 dark:hover:text-white`}
                            >
                              {p + 1}
                            </button>
                          </li>
                        ))}
                        <li>
                          <button
                            onClick={() => setOldPage(oldPage + 1)}
                            disabled={
                              oldPage === Math.ceil(schedules.past.length / 10)
                            }
                            class={` text-black bg-white shadow-md relative block rounded-r  px-3 py-1.5 text-sm transition-all duration-300 hover:bg-neutral-100  dark:hover:bg-neutral-700 dark:hover:text-white`}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showCancelModal.show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-md">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="flex justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="84.214"
                      height="84.214"
                      viewBox="0 0 84.214 84.214"
                    >
                      <g id="cross-circle" transform="translate(0.75 0.75)">
                        <path
                          id="Path_13181"
                          data-name="Path 13181"
                          d="M31.4,8.544a2.857,2.857,0,0,0-4.039,0L19.97,15.931,12.583,8.544a2.856,2.856,0,1,0-4.039,4.039l7.387,7.387L8.544,27.357A2.856,2.856,0,1,0,12.583,31.4l7.387-7.387L27.357,31.4A2.856,2.856,0,1,0,31.4,27.357L24.009,19.97,31.4,12.583A2.857,2.857,0,0,0,31.4,8.544Z"
                          transform="translate(21.387 21.387)"
                          fill="red"
                        />
                        <path
                          id="Path_13182"
                          data-name="Path 13182"
                          d="M41.907,0A41.907,41.907,0,1,0,83.814,41.907,41.907,41.907,0,0,0,41.907,0Zm0,76.83A34.923,34.923,0,1,1,76.83,41.907,34.923,34.923,0,0,1,41.907,76.83Z"
                          transform="translate(-0.55 -0.55)"
                          fill="red"
                          stroke="#ffe8e8"
                          strokeWidth="0.4"
                        />
                      </g>
                    </svg>
                  </p>
                  <p className="text-2xl font-semibold text-center text-red-600 mt-4 mb-4">
                    Are You Sure?
                  </p>
                  <p className="my-4 text-center">
                    The Amount Will Be Credited Into Your Wallet.
                  </p>
                  <div className="flex items-center justify-center rounded-b">
                    <button
                      className="min-w-130 bg-theme text-white active:bg-emerald-600 font-bold text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={onCancelEvent}
                    >
                      Yes
                    </button>
                    <button
                      className="min-w-130 bg-gray-200 text-black rounded-lg font-bold px-6 py-3 text-sm outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setCancelModal(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {showSuccessModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-md">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="flex justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="81.179"
                      height="80.95"
                      viewBox="0 0 81.179 80.95"
                    >
                      <g
                        id="Group_3595"
                        data-name="Group 3595"
                        transform="translate(1255.233 273.233)"
                      >
                        <path
                          id="Path_5123"
                          data-name="Path 5123"
                          d="M-1214.61-192.283a40.588,40.588,0,0,1-40.623-40.5,40.591,40.591,0,0,1,40.556-40.451,40.588,40.588,0,0,1,40.623,40.5A40.591,40.591,0,0,1-1214.61-192.283Zm-.022-7.352a33.3,33.3,0,0,0,33.208-33.106,33.3,33.3,0,0,0-33.174-33.14,33.3,33.3,0,0,0-33.266,33.163A33.3,33.3,0,0,0-1214.632-199.635Z"
                          transform="translate(0 0)"
                          fill="#ff6a00"
                        />
                        <path
                          id="Path_5124"
                          data-name="Path 5124"
                          d="M-1156.178-141.674c7.005-7.056,13.781-13.887,20.565-20.711a3.847,3.847,0,0,1,4.459-1.076,3.63,3.63,0,0,1,.987,6.013c-2.527,2.6-5.128,5.134-7.7,7.7q-7.626,7.6-15.254,15.205c-2.053,2.043-4,2.049-6.063.008q-5.735-5.686-11.446-11.4c-2.128-2.138-1.728-5.077.807-6.258a3.739,3.739,0,0,1,4.374,1.019q4.195,4.149,8.353,8.336A13.841,13.841,0,0,1-1156.178-141.674Z"
                          transform="translate(-64.144 -84.343)"
                          fill="#ff6a00"
                        />
                      </g>
                    </svg>
                  </p>
                  <p className="text-2xl font-semibold text-theme  text-center mt-4 ">
                    Your Booking Has
                  </p>
                  <p className="text-2xl font-semibold text-theme  text-center mb-4">
                    Cancel Successfully{" "}
                  </p>
                  <p className="mt-4 text-center">
                    Money Will Refunded In A Next
                  </p>
                  <p className="mb-4 text-center">2 Working Days</p>
                  <div className="flex items-center justify-center rounded-b">
                    <button
                      onClick={() => setSuccessModal(false)}
                      className="pl-1 pr-2 rounded w-40 h-12 border-2 border-theme font-medium inline-flex items-center justify-center shadow hover:bg-theme hover:text-white focus:bg-theme"
                    >
                      <span className="truncate text-sm ml-2">Done</span>
                      {/* <svg viewBox="0 0 9 6" width="9" height="6"><path d="m4.5 6l-4.5-6h9z" fill="#fff" /></svg> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
{
  /* 
  
  
  
  <div className="bg-white flex flex-wrap items-center relative mb-3 lg:mb-2 shadow p-2">
  <div className="flex items-center">
    <button
      className="py-2 rounded-md outline-none mr-2 text-bgdark hover:text-theme focus:text-theme md:hidden block"
      onClick={() => setNavbar(!navbar)}
    >
      <svg width="27" height="19" viewBox="0 0 27 19">
        <g transform="translate(-84 -23)">
          <line
            id="Line_2"
            data-name="Line 2"
            x2="17"
            transform="translate(85.5 24.5)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <line
            id="Line_3"
            data-name="Line 3"
            x2="24"
            transform="translate(85.5 32.5)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <line
            id="Line_4"
            data-name="Line 4"
            x2="20"
            transform="translate(85.5 40.5)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="3"
          />
        </g>
      </svg>
    </button>
    <div className="title-set">
      <h3 className="text-sec">
        <svg
          className="inline-flex md:mr-3 mr-1"
          width="18"
          height="18"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 18.392 18.386"
        >
          <path
            id="Path_13183"
            data-name="Path 13183"
            d="M94.552-1190.658h15.6a.16.16,0,0,1,.16.16v5.555a.16.16,0,0,1-.186.158,4.218,4.218,0,0,0-1.753.067.161.161,0,0,1-.2-.154v-1.328a.16.16,0,0,0-.16-.16H106.53a.16.16,0,0,0-.16.16v1.485a.16.16,0,0,0,.16.16h.717a.16.16,0,0,1,.09.292,3.984,3.984,0,0,0-1.7,4.721.16.16,0,0,1-.152.2h-9.8a1.236,1.236,0,0,1-1.29-1.3q0-4.911,0-9.821v-.04A.16.16,0,0,1,94.552-1190.658Zm3.358.929h-1.5a.16.16,0,0,0-.16.16v1.475a.16.16,0,0,0,.16.16h1.5a.16.16,0,0,0,.16-.16v-1.475A.16.16,0,0,0,97.91-1189.729Zm3.522,1.639v-1.477a.16.16,0,0,0-.16-.16H99.783a.16.16,0,0,0-.16.16v1.477a.16.16,0,0,0,.16.16h1.489A.16.16,0,0,0,101.432-1188.09Zm-3.519,1.732h-1.5a.16.16,0,0,0-.16.16v1.467a.16.16,0,0,0,.16.16h1.5a.16.16,0,0,0,.16-.16v-1.467A.16.16,0,0,0,97.913-1186.359Zm3.521,1.635v-1.479a.16.16,0,0,0-.16-.16H99.788a.16.16,0,0,0-.16.16v1.479a.16.16,0,0,0,.16.16h1.485A.16.16,0,0,0,101.434-1184.723ZM97.909-1183H96.415a.16.16,0,0,0-.16.16v1.476a.16.16,0,0,0,.16.16h1.494a.16.16,0,0,0,.16-.16v-1.476A.16.16,0,0,0,97.909-1183Zm3.38,1.8a.16.16,0,0,0,.16-.16c0-.517,0-1.018-.006-1.519a.19.19,0,0,0-.129-.134c-.507-.009-1.015-.007-1.529-.006a.161.161,0,0,0-.16.16v1.5a.16.16,0,0,0,.16.16Zm1.864-6.737h1.479a.16.16,0,0,0,.16-.16v-1.481a.16.16,0,0,0-.16-.16h-1.479a.16.16,0,0,0-.16.16v1.481A.16.16,0,0,0,103.154-1187.931Zm4.848-1.8h-1.477a.16.16,0,0,0-.16.16v1.473a.16.16,0,0,0,.16.16H108a.16.16,0,0,0,.16-.16v-1.473A.16.16,0,0,0,108-1189.728Zm-4.846,5.155h1.478a.16.16,0,0,0,.16-.16v-1.471a.16.16,0,0,0-.16-.16h-1.478a.16.16,0,0,0-.16.16v1.471A.16.16,0,0,0,103.155-1184.573Zm-.156,1.729v1.487a.16.16,0,0,0,.16.16h1.473a.16.16,0,0,0,.16-.16v-1.487a.16.16,0,0,0-.16-.16h-1.473A.16.16,0,0,0,103-1182.844Z"
            transform="translate(-94.386 1195.883)"
            fill="#ff6a00"
          />
          <path
            id="Path_13184"
            data-name="Path 13184"
            d="M94.294-1299.109c0-.658-.031-1.3.009-1.935a1.213,1.213,0,0,1,1.247-1.124c.29-.006.581,0,.9,0,0-.3,0-.58,0-.855a.62.62,0,0,1,.6-.676.626.626,0,0,1,.609.672c0,.532,0,1.063,0,1.595a.718.718,0,0,1-.329.757.656.656,0,0,0,.94-.637c0-.216.009-.432,0-.647-.008-.158.041-.218.207-.213.362.011.724,0,1.086,0,.075,0,.15-.007.248-.012,0-.277,0-.537,0-.8,0-.433.247-.719.609-.721a.643.643,0,0,1,.614.717q0,.786,0,1.572a.719.719,0,0,1-.333.733.654.654,0,0,0,.939-.635c0-.275,0-.551,0-.849h1.846c0-.3,0-.572,0-.846a.634.634,0,0,1,.616-.691.631.631,0,0,1,.606.678c0,.531,0,1.063,0,1.595a.711.711,0,0,1-.34.752.675.675,0,0,0,.96-.676c0-.261,0-.523,0-.812h1.53c0-.31,0-.593,0-.875a.62.62,0,0,1,.6-.662.621.621,0,0,1,.616.663c0,.516,0,1.032,0,1.548s0,.5-.329.824c.591.142.953-.132.955-.723,0-.252,0-.5,0-.75a1.227,1.227,0,0,1,1.513,1.076c.052.641.011,1.29.011,1.952Z"
            transform="translate(-94.282 1303.699)"
            fill="#ff6a00"
          />
          <path
            id="Path_13185"
            data-name="Path 13185"
            d="M352.175-1050.856a3.381,3.381,0,0,1-.213,4.967,3.383,3.383,0,0,1-4.349-.008,3.381,3.381,0,0,1-.2-4.966A3.367,3.367,0,0,1,352.175-1050.856Zm-.882.436c-.394.394-.777.771-1.152,1.155a.5.5,0,0,1-.413.195c-.1-.006-.207.082-.314.12a.83.83,0,0,1-.308.085c-.4-.03-.808-.082-1.228-.127l-.066.6c.416.047.813.083,1.207.142a.576.576,0,0,1,.3.158.62.62,0,0,0,.624.2.511.511,0,0,0,.437-.476.753.753,0,0,1,.311-.577c.257-.255.512-.511.768-.767l.275-.275Z"
            transform="translate(-334.768 1063.486)"
            fill="#ff6a00"
          />
        </svg>
        <span className="font-bold inline-flex md:text-lg text-base">
          My Schedules
        </span>
      </h3>
    </div>
  </div>
</div> */
}
