import apis from "./api";

const prefix = "booking";

/**
 * Cart APIs
 */

export const getAllHours = () => {
  return apis.get(`${prefix}/hours`, {});
};

export const createAHour = (data) => {
  return apis.post(`${prefix}/hour`, { data });
};
export const updateAHour = (id, data) => {
  return apis.patch(`${prefix}/hour/${id}`, { data });
};
export const deleteAHour = (id) => {
  return apis.delete(`${prefix}/hour/${id}`, {});
};

export const getSlotAvailability = (data) => {
  return apis.post(`${prefix}/court/slot`, { data });
};

export const getMyCart = () => {
  return apis.get(`${prefix}/cart`, {});
};

export const addToMyCart = (data) => {
  return apis.post(`${prefix}/cart`, { data });
};

export const deleteFromMyCart = (itemID) => {
  return apis.delete(`${prefix}/cart/${itemID}`, {});
};

export const getCheckout = () => {
  return apis.get(`${prefix}/checkout`, {});
};

export const goCheckout = () => {
  return apis.post(`${prefix}/checkout`, {});
};
/**
 * Availability APIs
 */

export const getAvailability = (month, year) => {
  return apis.get(`${prefix}/availability/${month}/${year}`, {});
};

/**
 * Booking APIs
 */

export const startBooking = (data) => {
  return apis.post(`${prefix}/book`, { data });
};
export const getAllBookings = (params) => {
  return apis.get(`${prefix}/book`, { params });
};
export const getABookings = (id) => {
  return apis.get(`${prefix}/book/${id}`, {});
};

export const getBookings = () => {
  return apis.get(`${prefix}/me`, {});
};
export const getUserBookingsByAdmin = (id) => {
  return apis.get(`${prefix}/user/${id}`, {});
};

export const getMyScheduleBookings = (params) => {
  return apis.get(`${prefix}/schedule/me`, { params });
};

export const cancelABookingEvent = (id, eventID) => {
  return apis.delete(`${prefix}/book/${id}/${eventID}`, {});
};

/**
 * Dropin APIs
 */

export const getDropin = (params) => {
  return apis.get(`${prefix}/dropin`, { params });
};
export const getADropin = (id, params) => {
  return apis.get(`${prefix}/dropin/${id}`, { params });
};

export const createDropin = (data) => {
  return apis.post(`${prefix}/dropin`, { data });
};

export const updateADropin = (id, data) => {
  return apis.put(`${prefix}/dropin/${id}`, { data });
};

export const cancelDropin = (id) => {
  return apis.delete(`${prefix}/dropin/${id}`, {});
};

export const createDropinEvent = (data) => {
  return apis.post(`${prefix}/dropin/event`, { data });
};

export const getAllDropinEvent = (data) => {
  return apis.get(`${prefix}/dropin/event`, { data });
};

export const updateADropinEvent = (id, data) => {
  return apis.put(`${prefix}/dropin/event/${id}`, { data });
};

export const deleteADropinEvent = (id) => {
  return apis.delete(`${prefix}/dropin/event/${id}`, {});
};

/**
 * Membership APIs
 */
export const getAllMembership = () => {
  return apis.get(`${prefix}/membership`, {});
};

export const getAMembership = (id) => {
  return apis.get(`${prefix}/membership/${id}`, {});
};

export const createMembership = (data) => {
  return apis.post(`${prefix}/membership`, { data });
};

export const patchMembership = (data) => {
  return apis.patch(`${prefix}/membership`, { data });
};

// export const enrollMembership = (data) => {
//   return apis.post(`${prefix}/membership/enroll`, { data });
// };

/**
 * Course APIs
 */
export const getAllCourses = (params) => {
  return apis.get(`${prefix}/course`, { params });
};

export const getACourse = (id, params) => {
  return apis.get(`${prefix}/course/${id}`, { params });
};

export const createACourse = (data) => {
  return apis.post(`${prefix}/course`, { data });
};

export const deleteACourse = (id) => {
  return apis.delete(`${prefix}/course/${id}`, {});
};

export const addDateTimeToCourse = (id, data) => {
  return apis.post(`${prefix}/course/${id}/add`, { data });
};

export const publishCourse = (id) => {
  return apis.post(`${prefix}/course/${id}/publish`, {});
};

export const unpublishCourse = (id) => {
  return apis.post(`${prefix}/course/${id}/unpublish`, {});
};

export const removeDateFromCourse = (id, data) => {
  return apis.delete(`${prefix}/course/${id}/remove`, { data });
};

export const updateACourse = (id, data) => {
  return apis.patch(`${prefix}/course/${id}`, { data });
};
// export const enrollCou = (data) => {
//   return apis.post(`${prefix}/membership/enroll`, { data });
// };

/**
 * COURT Block
 */
export const getAllCourts = (params) => {
  return apis.get(`${prefix}/court`, { params });
};

export const addCourt = (data) => {
  return apis.post(`${prefix}/court/add`, { data });
};

export const updateACourt = (id, data) => {
  return apis.patch(`${prefix}/court/${id}`, { data });
};

export const deleteACourt = (id) => {
  return apis.delete(`${prefix}/court/${id}`, {});
};

export const getCourtForDay = (data) => {
  return apis.post(`${prefix}/court`, { data });
};

export const getUserEnrollments = (id, params) => {
  return apis.get(`${prefix}/enrollments/${id}`, { params });
};

export const getMyEnrollments = (params) => {
  return apis.get(`${prefix}/enrollments/me`, { params });
};
