import React, { useState } from "react";
// import { NavLink, Link } from "react-router-dom";
// import Logo from "../assets/wlogo.svg";
// import LogoIco from "./assets/logoIco.svg";
// import CalendarIco from "./assets/calendarIco.svg";
// import SaveIco from "./assets/saveIco.svg";
// import UserIco from "./assets/userIco.svg";
// import UserImg from "./assets/userImg.png";
// import Graph from "../assets/graph.png";
// import Noti from "../assets/notification.svg";
// import WingsLogo from "../../assets/wings-logo.svg";
import AdminLayout from "../../../components/AdminLayout";
import defaultImage from "../../../assets/default-user.jpg";
import { getAllUser, inviteUser } from "../../../apis/user.api";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { toastConf } from "../Dropins";
import { useDebounce } from "react-use";

export default function Users() {
  // const [navbar, setNavbar] = useState(false);
  // const [ProfileDrop, setProfileDrop] = useState(false);
  const navigate = useNavigate();

  const [showNewUserModal, setNewUserModal] = useState(false);

  const [users, setUsers] = useState([]);

  const [userSearch, setUserSearch] = useState([]);
  const [search, setSearch] = useState(null);

  const initCall = async () => {
    const response = await getAllUser();
    setUsers(response);
  };

  const onSearch = async ({ search }) => {
    const response = await getAllUser({ search });
    setUserSearch(response);
  };

  React.useEffect(() => {
    initCall();
  }, []);

  useDebounce(
    () => {
      if (search.length === 0) {
        return setUserSearch([]);
      }
      onSearch({ search });
    },
    1000,
    [search]
  );
  const onInviteUser = async (data) => {
    try {
      await inviteUser(data);
      toast.success("User Invited", toastConf);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Something went wrong.",
        toastConf
      );
    }
  };
  return (
    <AdminLayout>
      <div className="bg-white rounded-lg m-4 p-5">
        <div className="flex items-center justify-between mb-4">
          <div className="text-xl text-black font-bold">User List</div>
        </div>
        <div>
          <div className="md:flex items-center justify-between">
            <div className="relative">
              <div className="relative flex w-full flex-wrap items-stretch">
                <input
                  type="text"
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-full border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                />
                <span className="z-10 h-full leading-snug font-normal text-center text-white absolute bg-transparent rounded text-base items-center justify-center w-8 right-2 pr-3 -bottom-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                  >
                    <g transform="translate(0.238)">
                      <circle
                        id="NoPath"
                        cx="15.5"
                        cy="15.5"
                        r="15.5"
                        transform="translate(-0.238)"
                        fill="#222"
                      />
                      <path
                        id="Icon_awesome-search"
                        data-name="Icon awesome-search"
                        d="M13.574,11.9l-2.68-2.68a.645.645,0,0,0-.457-.188H10A5.588,5.588,0,1,0,9.032,10v.438a.645.645,0,0,0,.188.457l2.68,2.68a.642.642,0,0,0,.911,0l.761-.761a.648.648,0,0,0,0-.914ZM5.591,9.032A3.441,3.441,0,1,1,9.032,5.591,3.439,3.439,0,0,1,5.591,9.032Z"
                        transform="translate(8.619 8.618)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </span>
              </div>
              {userSearch.length > 0 && (
                <div className="absolute top-14 rounded px-3 w-full bg-white z-100 shadow-md border-0.5">
                  {userSearch.map((u) => (
                    <button
                      className="w-full text-left flex text-sm my-3"
                      onClick={() => navigate(`/admin/users/${u.userID}`)}
                    >
                      <img
                        src={u.avatar ?? defaultImage}
                        alt={u.fullName}
                        className="w-5 h-5 object-cover mr-3 rounded-full shadow-lg"
                      />
                      {u.fullName}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <div>
              {/* <a
                className="inline-flex items-center bg-gray-100 rounded-l-full rounded-r-lg py-2 px-2 ml-4"
                href="#"
                onClick={() => setInviteModal(true)}
              >
                <div className="flex bg-theme rounded-full p-2 mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.738"
                    height="11.738"
                    viewBox="0 0 11.738 11.738"
                  >
                    <g
                      id="Icon_feather-send"
                      data-name="Icon feather-send"
                      transform="translate(-2.5 -2.293)"
                    >
                      <path
                        id="Path_13201"
                        data-name="Path 13201"
                        d="M22.292,3,16.5,8.792"
                        transform="translate(-8.761 0)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_13202"
                        data-name="Path 13202"
                        d="M13.531,3,9.845,13.531,7.739,8.792,3,6.686Z"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </svg>
                </div>
                <div className="text-base text-black">Send Invite Link</div>
              </a> */}
              <button
                className="inline-flex items-center bg-gray-100 rounded-l-full rounded-r-lg py-2 px-2 ml-4"
                onClick={() => setNewUserModal(true)}
              >
                <div className="flex bg-theme rounded-full p-2 mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9.382"
                    height="11.952"
                    viewBox="0 0 9.382 11.952"
                  >
                    <g
                      id="Icon_feather-user"
                      data-name="Icon feather-user"
                      transform="translate(-5.5 -4)"
                    >
                      <path
                        id="Path_13203"
                        data-name="Path 13203"
                        d="M14.382,27.166V24.6a2.1,2.1,0,0,0-2.1-2.1H8.1A2.1,2.1,0,0,0,6,24.6v2.57"
                        transform="translate(0 -11.713)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_13204"
                        data-name="Path 13204"
                        d="M16.191,6.6a2.1,2.1,0,1,1-2.1-2.1A2.1,2.1,0,0,1,16.191,6.6Z"
                        transform="translate(-3.904)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </svg>
                </div>
                <div className="text-base text-black">Add New User</div>
              </button>
              {/* <a
                className="inline-flex items-center bg-gray-100 rounded-l-full rounded-r-lg py-2 px-2 ml-4"
                href="#"
                onClick={() => setTagsModal(true)}
              >
                <div className="flex bg-theme rounded-full p-2 mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10.212"
                    height="10.123"
                    viewBox="0 0 10.212 10.123"
                  >
                    <g
                      id="Icon_feather-tag"
                      data-name="Icon feather-tag"
                      transform="translate(-2.5 -2.5)"
                    >
                      <path
                        id="Path_13205"
                        data-name="Path 13205"
                        d="M11.849,8.431,8.436,11.844a.952.952,0,0,1-1.347,0L3,7.76V3H7.76l4.089,4.089A.952.952,0,0,1,11.849,8.431Z"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </svg>
                </div>
                <div className="text-base text-black">Manage Tags</div>
              </a>
              <a
                className="inline-flex items-center bg-gray-100 rounded-l-full rounded-r-lg py-2 px-2 ml-4"
                href="#"
                onClick={() => setExportModal(true)}
              >
                <div className="flex bg-theme rounded-full p-2 mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10.294"
                    height="13.235"
                    viewBox="0 0 10.294 13.235"
                  >
                    <path
                      id="Icon_material-import-export"
                      data-name="Icon material-import-export"
                      d="M10.441,4.5,7.5,7.434H9.706v5.154h1.471V7.434h2.206Zm5.147,10.3V9.647H14.118V14.8H11.912l2.941,2.934L17.794,14.8Z"
                      transform="translate(-7.5 -4.5)"
                      fill="#fff"
                    />
                  </svg>
                </div>
                <div className="text-base text-black">Export User List</div>
              </a> */}
            </div>
          </div>
        </div>
        <table className="table-auto w-full rounded-lg border-collapse p-4 mt-4 mb-8">
          <thead>
            <tr className="text-left bg-neutral-200 p-8">
              <th className="py-3 px-2 mb-4">Avatar</th>
              <th className="py-3 px-2 mb-4">First Name</th>
              {/* <th>Last Name</th> */}
              {/* <th>Organisation</th> */}
              <th>Email</th>
              <th>Phone</th>
              <th>Type</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {users.map((us) => (
              <tr key={us.userID}>
                <td className="py-3 px-2">
                  <img
                    src={us.avatar ?? defaultImage}
                    className="w-10 h-10 object-cover rounded-full bg-slate-300 shadow"
                    alt={us.fullName}
                  />
                </td>
                <td className="py-5 px-2">{us.fullName}</td>
                {/* <td>Kundagol</td> */}
                <td>{us.email}</td>
                <td>{us.phone}</td>
                <td>
                  {us.type === "user" && "User"}{" "}
                  {us.type === "admin" && "Admin"}
                </td>
                <td>
                  {us.status === "active" && (
                    <span className="rounded-lg text-xs shadow bg-white px-2 py-1">
                      Active
                    </span>
                  )}
                  {us.status === "suspend" && (
                    <span className="rounded-lg text-xs shadow bg-red-100 px-2 py-1">
                      Suspended
                    </span>
                  )}
                  {us.status === "invite" && (
                    <span className="rounded-lg text-xs shadow bg-yellow-100 px-2 py-1">
                      Invited
                    </span>
                  )}
                </td>
                <td>
                  <button
                    className="inline-flex bg-orange-100 rounded-full p-4 mr-2"
                    onClick={() => navigate(`/admin/users/${us.userID}`)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 13.585 13.105"
                    >
                      <g
                        id="Icon_feather-edit-3"
                        data-name="Icon feather-edit-3"
                        transform="translate(0.6 0.725)"
                      >
                        <path
                          id="Path_13167"
                          data-name="Path 13167"
                          d="M18,30h6.192"
                          transform="translate(-11.808 -18.22)"
                          fill="none"
                          stroke="#ff6a00"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.2"
                        />
                        <path
                          id="Path_13168"
                          data-name="Path 13168"
                          d="M13.789,4.746A1.46,1.46,0,0,1,15.853,6.81l-8.6,8.6L4.5,16.1l.688-2.752Z"
                          transform="translate(-4.5 -4.318)"
                          fill="none"
                          stroke="#ff6a00"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.2"
                        />
                      </g>
                    </svg>
                  </button>
                  <button className="inline-flex bg-neutral-200 rounded-full p-4 mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 17 3"
                    >
                      <g
                        id="Group_2775"
                        data-name="Group 2775"
                        transform="translate(-8.5 -15.5)"
                      >
                        <circle
                          id="Ellipse_457"
                          data-name="Ellipse 457"
                          cx="1.5"
                          cy="1.5"
                          r="1.5"
                          transform="translate(8.5 15.5)"
                          fill="#222"
                        />
                        <circle
                          id="Ellipse_458"
                          data-name="Ellipse 458"
                          cx="1.5"
                          cy="1.5"
                          r="1.5"
                          transform="translate(15.5 15.5)"
                          fill="#222"
                        />
                        <circle
                          id="Ellipse_459"
                          data-name="Ellipse 459"
                          cx="1.5"
                          cy="1.5"
                          r="1.5"
                          transform="translate(22.5 15.5)"
                          fill="#222"
                        />
                      </g>
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showNewUserModal ? (
        <>
          <div className="justify-center items-center rounded-lg flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between bg-theme text-white p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-lg font-semibold ml-3">Add a User</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setNewUserModal(false)}
                  >
                    <span className="bg-theme text-white opacity-1 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <Formik
                  initialValues={{
                    fullName: null,
                    email: null,
                    type: "user",
                    phone: null,
                  }}
                  onSubmit={(values) => {
                    onInviteUser(values);
                  }}
                  validateOnBlur={false}
                >
                  {({
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                  }) => (
                    <>
                      <Form>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                          <form>
                            <div className="grid gap-4 ">
                              <div className="grid gap-4 grid-cols-2">
                                <div>
                                  <label
                                    htmlFor="first_name"
                                    className="block mb-2 text-base font-semibold text-sec"
                                  >
                                    Email ID
                                  </label>
                                  <div className="relative flex w-full flex-wrap items-stretch">
                                    <input
                                      type="text"
                                      placeholder="user@email.com"
                                      value={values["email"]}
                                      onChange={handleChange("email")}
                                      className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                                    />
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="first_name"
                                    className="block mb-2 text-base font-semibold text-sec"
                                  >
                                    Full Name
                                  </label>
                                  <div className="relative flex w-full flex-wrap items-stretch">
                                    <input
                                      type="text"
                                      placeholder="Full Name"
                                      value={values["fullName"]}
                                      onChange={handleChange("fullName")}
                                      className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                                    />
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="first_name"
                                    className="block mb-2 text-base font-semibold text-sec"
                                  >
                                    Phone
                                  </label>
                                  <div className="relative flex flex-wrap w-full items-stretch">
                                    <select className="inline-flex border-2 border-stone-200 rounded-lg px-3 py-2">
                                      <option value="all">+1</option>
                                    </select>
                                    <input
                                      type="number"
                                      placeholder="(778) 881 - 1132"
                                      value={values["phone"]}
                                      onChange={handleChange("phone")}
                                      className="inline-flex font-normal h-12 px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                                    />
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="phone_no"
                                    className="block mb-2 text-base font-semibold text-sec"
                                  >
                                    User Type
                                  </label>
                                  <div className="relative flex w-full flex-wrap items-stretch">
                                    <select
                                      value={values["type"]}
                                      onChange={handleChange("type")}
                                      className="w-full rounded-lg border-2 border-stone-200 placeholder-gray-400 px-3 py-3"
                                    >
                                      <option value="user">User</option>
                                      <option value="admin">Admin</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex items-center mt-3">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  viewBox="0 0 20.547 20.547"
                                >
                                  <g
                                    id="Icon_feather-info"
                                    data-name="Icon feather-info"
                                    transform="translate(-2.25 -2.25)"
                                  >
                                    <path
                                      id="Path_13206"
                                      data-name="Path 13206"
                                      d="M22.047,12.523A9.523,9.523,0,1,1,12.523,3,9.523,9.523,0,0,1,22.047,12.523Z"
                                      fill="none"
                                      stroke="#7d7d7d"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1.5"
                                    />
                                    <path
                                      id="Path_13207"
                                      data-name="Path 13207"
                                      d="M18,21.809V18"
                                      transform="translate(-5.477 -5.477)"
                                      fill="none"
                                      stroke="#7d7d7d"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1.5"
                                    />
                                    <path
                                      id="Path_13208"
                                      data-name="Path 13208"
                                      d="M18,12h0"
                                      transform="translate(-5.477 -3.286)"
                                      fill="none"
                                      stroke="#7d7d7d"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1.5"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className="ml-2">
                                <p className="text-xs">
                                  This New User Will Be Sent An Invitation Email
                                </p>
                                <p className="text-xs">
                                  (In Accordance With Your Venue's Notification
                                  Settings).
                                </p>
                              </div>
                            </div>
                          </form>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center pt-0 p-6 rounded-b">
                          <button
                            className="min-w-130 bg-theme text-white active:bg-emerald-600 font-bold text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={handleSubmit}
                          >
                            Invite User
                          </button>
                          <button
                            className="min-w-130 bg-gray-200 text-black rounded-lg font-bold px-6 py-3 text-sm outline-none focus:outline-none mr-3 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => setNewUserModal(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </AdminLayout>
  );
}
