import React, { useState } from "react";
import AdminLayout from "../../../components/AdminLayout";
import { FiDollarSign, FiEye } from "react-icons/fi";
import { Tabs, Tab, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  createDropinEvent,
  getAllDropinEvent,
  getAllHours,
  getDropin,
  deleteADropinEvent,
  createDropin,
  deleteADropin,
  cancelDropin,
} from "../../../apis/booking.api.js";
// import Switch from "../../../components/Switch.js";
// import { CartContext } from "../../../context/Cart.context";
import { formatedDate, formatedTime } from "../../../utils/time.js";
import { Formik } from "formik";
import DropinEvents from "./Events";
import { getSlotAvailability } from "../../../apis/booking.api.js";
import { toast } from "react-toastify";

export default function DropIn() {
  const [dropins, setDropins] = useState({
    active: [],
    inactive: [],
    events: [],
  });

  const [hours, setHours] = useState([]);

  const [recurringModal, setRecurringModal] = useState(false);
  const [dropinModal, setDropinModal] = useState(false);

  const initCall = async () => {
    const dropinAPI = await getDropin();
    const hours = await getAllHours();
    setHours(hours);
    const dropinOldAPI = await getDropin({ filter: "expired" });

    const allEvents = await getAllDropinEvent();
    setDropins({
      active: dropinAPI,
      inactive: dropinOldAPI,
      events: allEvents,
    });
    // console.log(dropinAPI);
    return;
  };

  React.useEffect(() => {
    initCall();
  }, []);

  const onCreateRecurringEvent = async (data) => {
    await createDropinEvent(data);
    initCall();
  };

  const onEventDelete = async (eventID) => {
    try {
      await deleteADropinEvent(eventID);
    } catch (error) {}
  };

  // const [value, setValue] = useState(false);

  // const [open, setOpen] = useState(0);

  // const handleOpen = (value) => {
  //   setOpen(open === value ? 0 : value);
  // };

  //   const renderedItem = () => {
  //     const groups = dropins.reduce((groups, dropin) => {
  //       const date = dropin.date;
  //       if (!groups[date]) {
  //         groups[date] = [];
  //       }
  //       groups[date].push(dropin);
  //       return groups;
  //     }, {});

  //     const groupArrays = Object.keys(groups).map((date) => {
  //       return {
  //         date: date,
  //         dropins: groups[date],
  //       };
  //     });
  //     return groupArrays;
  //   };

  //   const isInCart = (id) => {
  //     const dropIn = cart.filter((item) => item.dependentID === id);

  //     if (dropIn.length > 0) {
  //       return true;
  //     }
  //     return false;
  //   };

  //   const getCartItemID = (id) => {
  //     const dropIn = cart.filter((item) => item.dependentID === id);

  //     return dropIn[0].id;
  //   };

  return (
    <AdminLayout>
      <div className="flex flex-wrap mt-4 mb-4 mx-5">
        <div className="md:flex items-center justify-between mb-4 w-full">
          <div className="text-xl text-black font-bold">Drop-Ins</div>
          <div className="flex flex-row">
            <button
              className="flex items-center bg-theme rounded-l-full py-2 px-2 mr-2"
              onClick={() => setDropinModal(true)}
            >
              <div className="flex bg-white rounded-full p-2 mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 11.681 11.683"
                >
                  <path
                    id="Path_4758"
                    data-name="Path 4758"
                    d="M751.768,143.831h-.186q-1.869,0-3.739,0a.762.762,0,0,1-.829-.653,2.938,2.938,0,0,1,0-.816.759.759,0,0,1,.816-.653c.964,0,1.928,0,2.892,0h1.045v-.176q0-1.85,0-3.7a.807.807,0,0,1,.9-.9h.378a.794.794,0,0,1,.843.841c0,.881,0,1.763,0,2.644v1.29h3.879a.805.805,0,0,1,.9.892q0,.182,0,.365a.8.8,0,0,1-.87.866h-3.905V144q0,1.876,0,3.752a.767.767,0,0,1-.663.835,2.95,2.95,0,0,1-.791,0,.764.764,0,0,1-.669-.817c0-.769,0-1.537,0-2.306v-1.635Z"
                    transform="translate(-746.986 -136.932)"
                    fill="#ff6a00"
                  />
                </svg>
              </div>
              <div className="text-sm text-white">Create Drop-In</div>
            </button>
            <button
              className="flex items-center bg-theme  py-2 px-2"
              onClick={() => setRecurringModal(true)}
            >
              <div className="flex bg-white rounded-full p-2 mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 11.681 11.683"
                >
                  <path
                    id="Path_4758"
                    data-name="Path 4758"
                    d="M751.768,143.831h-.186q-1.869,0-3.739,0a.762.762,0,0,1-.829-.653,2.938,2.938,0,0,1,0-.816.759.759,0,0,1,.816-.653c.964,0,1.928,0,2.892,0h1.045v-.176q0-1.85,0-3.7a.807.807,0,0,1,.9-.9h.378a.794.794,0,0,1,.843.841c0,.881,0,1.763,0,2.644v1.29h3.879a.805.805,0,0,1,.9.892q0,.182,0,.365a.8.8,0,0,1-.87.866h-3.905V144q0,1.876,0,3.752a.767.767,0,0,1-.663.835,2.95,2.95,0,0,1-.791,0,.764.764,0,0,1-.669-.817c0-.769,0-1.537,0-2.306v-1.635Z"
                    transform="translate(-746.986 -136.932)"
                    fill="#ff6a00"
                  />
                </svg>
              </div>
              <div className="text-sm text-white">Create Recurring Drop-In</div>
            </button>
          </div>
        </div>
        <div className="w-full">
          <Tabs defaultActiveKey="active">
            <Tab eventKey="active" title="Active" className="bg-white pt-4">
              {/* Active */}
              {RenDropin(dropins.active, "active")}
            </Tab>
            <Tab eventKey="expired" title="Expired" className="bg-white pt-4">
              {RenDropin(dropins.inactive, "expired")}
            </Tab>
            <Tab
              eventKey="recurring"
              title="Recurring"
              className="bg-white pt-4"
            >
              {/* {RenDropin(dropins.inactive, "expired")} */}
              <DropinEvents events={dropins.events} onDelete={onEventDelete} />
            </Tab>
          </Tabs>
          {/* {renderedItem().map((_i, i) => ())} */}
        </div>
      </div>
      <Modal centered backdrop="static" show={dropinModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create Dropin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateDropinForm hours={hours} setDropinModal={setDropinModal} />
        </Modal.Body>
      </Modal>
      <Modal centered backdrop="static" show={recurringModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create Recurring Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RecurringForm
            hours={hours}
            setRecurringModal={setRecurringModal}
            onCreateRecurringEvent={onCreateRecurringEvent}
          />
        </Modal.Body>
      </Modal>
    </AdminLayout>
  );
}

const RecurringForm = ({
  hours,
  setRecurringModal,
  onCreateRecurringEvent,
}) => {
  const renderedEnd = (startTime) => {
    const getIndex = hours.findIndex((hour) => hour.time === startTime);
    if (getIndex === -1) {
      return hours;
    }
    return hours.slice(getIndex);
  };

  const onSubmit = (values) => {
    const getStartIndex = hours.findIndex(
      (hour) => hour.time === values.startTime
    );
    const getEndIndex = hours.findIndex(
      (hour) => hour.endTime === values.endTime
    );
    const _h = hours.slice(getStartIndex, getEndIndex + 1).map((d) => {
      return d.id;
    });

    onCreateRecurringEvent({ time: _h, price: values.price, day: values.day });
    // console.log(_h);
  };
  // const time =
  return (
    <>
      <Formik
        initialValues={{
          // title: "",
          // description: "",
          day: null,
          price: 0,
          startTime: hours[0].time,
          endTime: hours[0].endTime,
        }}
        // validationSchema={LoginSchema}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        validateOnBlur={false}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          values,
        }) => (
          <>
            <div className="grid gap-4">
              <div className="md:grid md:gap-4 md:grid-cols-2">
                <div>
                  <label
                    htmlFor="first_name"
                    className="block mb-2 text-base font-semibold text-sec"
                  >
                    Day*
                  </label>
                  <div className="relative flex w-full flex-wrap items-stretch">
                    <select
                      value={values["day"]}
                      onChange={handleChange("day")}
                      className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                    >
                      <option>-</option>
                      <option value={0}>Sunday</option>
                      <option value={1}>Monday</option>
                      <option value={2}>Tuesday</option>
                      <option value={3}>Wednesday</option>
                      <option value={4}>Thursday</option>
                      <option value={5}>Friday</option>
                      <option value={6}>Saturday</option>
                    </select>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="first_name"
                    className="block mb-2 text-base font-semibold text-sec"
                  >
                    Price*
                  </label>
                  <div className="relative flex w-full flex-wrap items-stretch">
                    <input
                      type="number"
                      placeholder="100 CAD"
                      value={values["price"]}
                      onChange={handleChange("price")}
                      className="pl-6 font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                    />
                    <FiDollarSign className="absolute top-4 left-2" />
                    <span className="z-10 h-full leading-snug font-normal absolute text-center text-white bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                      {/* <img src={User} /> */}
                    </span>
                  </div>
                </div>
              </div>
              <div className="md:grid md:gap-4 md:grid-cols-2">
                <div>
                  <label
                    htmlFor="startTime"
                    className="block mb-2 text-base font-semibold text-sec"
                  >
                    Start Time
                  </label>
                  <div className="relative flex w-full flex-wrap items-stretch">
                    <select
                      value={values["startTime"]}
                      onChange={handleChange("startTime")}
                      className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                    >
                      {hours.map((hour) => (
                        <option value={hour.time}>
                          {formatedTime(hour.time)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="endTime"
                    className="block mb-2 text-base font-semibold text-sec"
                  >
                    End Time
                  </label>
                  <div className="relative flex w-full flex-wrap items-stretch">
                    <select
                      value={values["endTime"]}
                      onChange={handleChange("endTime")}
                      className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                    >
                      {renderedEnd(values["startTime"]).map((hour) => (
                        <option value={hour.endTime}>
                          {formatedTime(hour.endTime)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              {/* <div>
          <label
            htmlFor="phone_no"
            className="block mb-2 text-base font-semibold text-sec"
          >
            Description*
          </label>
          <div className="relative flex w-full flex-wrap items-stretch">
            <textarea
              id="message"
              rows="4"
              // value={values["description"]}
              // onChange={handleChange("description")}
              className="block p-2.5 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Write Description here..."
            ></textarea>
          </div>
        </div>*/}
            </div>
            <div className="flex flex-row justify-end mt-5">
              <button
                className="border border-gray-100 px-3 py-1 shadow mr-3 rounded "
                onClick={() => setRecurringModal(false)}
              >
                Close
              </button>
              <button
                className="bg-theme px-3 py-1 shadow text-white rounded "
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

const CreateDropinForm = ({ hours, setDropinModal }) => {
  const [slots, setSlots] = React.useState([]);
  const renderedEnd = (startTime) => {
    const getIndex = hours.findIndex((hour) => hour.time === startTime);
    if (getIndex === -1) {
      return hours;
    }
    return hours.slice(getIndex);
  };

  const onSlotRenderer = (values) => {
    const getStartIndex = hours.findIndex(
      (hour) => hour.time === values.startTime
    );
    const getEndIndex = hours.findIndex(
      (hour) => hour.endTime === values.endTime
    );
    // console.log(getStartIndex, getEndIndex);
    // console.log(hours.slice(getStartIndex, getEndIndex + 1));
    setSlots(
      hours.slice(getStartIndex, getEndIndex + 1).map((i) => {
        return {
          time: i.id,
          ren: `${formatedTime(i.time)} - ${formatedTime(i.endTime)}`,
          court: null,
          startTime: i.time,
        };
      })
    );
  };

  const onCourtSelect = async (time, court, date, id) => {
    const av = await getSlotAvailability({ time, date });
    if (av[court] === true) {
      toast.success("Slot Available.", toastConf);
      setSlots((_slots) =>
        _slots.map((_slot) => {
          if (_slot.time === id) {
            return { ..._slot, court };
          }
          return _slot;
        })
      );
      return;
    } else {
      toast.error("Slot unavailable.", toastConf);
      return;
    }
  };

  const onSubmit = async (values, resetForm) => {
    const nulledCourt = slots.filter((_s) => _s.court === null);

    if (nulledCourt.length > 0) {
      return toast.error("Fill all the court.", toastConf);
    }
    try {
      await createDropin({
        date: values["date"],
        time: slots,
        price: values["price"],
      });
      setDropinModal(false);
      resetForm();
      setSlots([]);
      return toast.success("DropIn Created Successfully.", toastConf);
    } catch (error) {
      return toast.error("Failed to create DropIn.", toastConf);
    }
  };
  // const time =
  const getDate = () => {
    const dt = new Date();
    return `${
      dt.getFullYear() +
      "-" +
      ("0" + (dt.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (dt.getDate() + 1)).slice(-2)
    }`;
  };
  return (
    <>
      <Formik
        initialValues={{
          date: getDate(),
          price: 0,
          startTime: hours[0].time,
          endTime: null,
        }}
        // validationSchema={LoginSchema}
        onSubmit={(values, { resetForm }) => {
          // resetForm();
          onSubmit(values, resetForm);
        }}
        validateOnBlur={false}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          values,
        }) => (
          <>
            <div className="grid gap-4">
              <div className="md:grid md:gap-4 md:grid-cols-4">
                <div>
                  <label
                    htmlFor="first_name"
                    className="block mb-2 text-base font-semibold text-sec"
                  >
                    Date*
                  </label>
                  <div className="relative flex w-full flex-wrap items-stretch">
                    <input
                      type="date"
                      value={values["date"]}
                      min={getDate()}
                      onChange={(e) => {
                        handleChange("date")(e.target.value);
                        setSlots([]);
                      }}
                      className="text-sm font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="first_name"
                    className="block mb-2 text-base font-semibold text-sec"
                  >
                    Price*
                  </label>
                  <div className="relative flex w-full flex-wrap items-stretch">
                    <input
                      type="number"
                      placeholder="100 CAD"
                      value={values["price"]}
                      onChange={handleChange("price")}
                      className="pl-6 text-sm font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                    />
                    <FiDollarSign className="absolute top-4 left-2" />
                    <span className="z-10 h-full leading-snug font-normal absolute text-center text-white bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                      {/* <img src={User} /> */}
                    </span>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="startTime"
                    className="block mb-2 text-base font-semibold text-sec"
                  >
                    Start Time
                  </label>
                  <div className="relative flex w-full flex-wrap items-stretch">
                    <select
                      value={values["startTime"]}
                      onChange={(e) => {
                        handleChange("startTime")(e.target.value);
                        handleChange("endTime")("");
                        setSlots([]);
                      }}
                      className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                    >
                      {hours.map((hour) => (
                        <option value={hour.time} key={hour.time}>
                          {formatedTime(hour.time)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="endTime"
                    className="block mb-2 text-base font-semibold text-sec"
                  >
                    End Time
                  </label>
                  <div className="relative flex w-full flex-wrap items-stretch">
                    <select
                      value={values["endTime"]}
                      onChange={(e) => {
                        handleChange("endTime")(e.target.value);
                        onSlotRenderer({ ...values, endTime: e.target.value });
                      }}
                      className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                    >
                      <option value={null}>-</option>
                      {renderedEnd(values["startTime"]).map((hour) => (
                        <option value={hour.endTime} key={hour.time}>
                          {formatedTime(hour.endTime)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="md:grid md:gap-4 md:grid-cols-3 w-[50%]">
                <>
                  <label
                    htmlFor="startTime"
                    className="block mb-2 text-base font-semibold text-sec"
                  >
                    Timing
                  </label>
                  <label
                    htmlFor="startTime"
                    className="block mb-2 text-base font-semibold text-sec"
                  >
                    Court
                  </label>
                  <label
                    htmlFor="startTime"
                    className="block mb-2 text-base font-semibold text-sec"
                  >
                    Selected
                  </label>
                </>

                {slots.map((slot) => (
                  <>
                    <p className="text-xs pt-2">{slot.ren}</p>
                    <select
                      value={slot.court ?? "-"}
                      onChange={(e) => {
                        onCourtSelect(
                          slot.startTime,
                          e.target.value,
                          values["date"],
                          slot.time
                        );
                      }}
                      className="font-[10px] py-1 h-8 w-16 px-3 outline-0 bg-transparent text-sec rounded-lg border border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                    >
                      {[null, 1, 2, 3, 4, 5].map((court) => (
                        <option value={court} key={court ?? "-"}>
                          {court ?? "-"}
                        </option>
                      ))}
                    </select>
                    <p className="text-xs pt-2">
                      Selected: {slot.court ?? "-"}
                    </p>
                  </>
                ))}
              </div>

              {/* <div>
          <label
            htmlFor="phone_no"
            className="block mb-2 text-base font-semibold text-sec"
          >
            Description*
          </label>
          <div className="relative flex w-full flex-wrap items-stretch">
            <textarea
              id="message"
              rows="4"
              // value={values["description"]}
              // onChange={handleChange("description")}
              className="block p-2.5 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Write Description here..."
            ></textarea>
          </div>
        </div>*/}
            </div>
            <div className="flex flex-row justify-end mt-5">
              <button
                type="button"
                className="border border-gray-100 px-3 py-1 shadow mr-3 rounded "
                onClick={() => setDropinModal(false)}
              >
                Close
              </button>
              <button
                type="submit"
                className="bg-theme px-3 py-1 shadow text-white rounded "
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

const RenDropin = (items, filter) => {
  const navigate = useNavigate();
  const splitDate = (date) => date.split("-");
  const renderedItem = (item) => {
    // const courtItem = item.filter((item) => item.type === "court");
    // const nonCourtItem = item.filter((item) => item.type !== "court");

    const groups = item.reduce((groups, item) => {
      const date = item.date;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push({
        ...item,
        sortTime: 100, //parseInt(item.time.replace(":", "")),
      });
      return groups;
    }, {});

    // console.log(groups);

    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        items: groups[date],
      };
    });
    return [...groupArrays];
  };

  const onDropinCancel = async (dropinID) => {
    try {
      await cancelDropin(dropinID);
    } catch (error) {
      toast.error(error.response.data.message ?? "Something Went Wrong");
    }
  };
  return renderedItem(items).map((dp) => (
    <div
      className="card-block bg-white border-2 border-white rounded hover:shadow-md focus:shadow-md position-relative mb-3 shadow-sm"
      key={0}
    >
      <div className="p-2">
        <div className="w-full space-y-2">
          <div className="rounded-lg">
            <div
              className={`flex items-center font-semibold md:text-lg text-base cursor-pointer mb-2`}
              // onClick={() => handleOpen(0)}
            >
              {/* <span className={`mr-3 ${0 === 0 ? "op" : "cl"}`}></span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.837"
                height="14.671"
                viewBox="0 0 12.837 14.671"
                className="inline-flex ml-2 mr-2"
              >
                <path
                  id="Icon_awesome-calendar-alt"
                  data-name="Icon awesome-calendar-alt"
                  d="M0,13.3a1.376,1.376,0,0,0,1.375,1.375H11.462A1.376,1.376,0,0,0,12.837,13.3V5.5H0ZM9.169,7.679a.345.345,0,0,1,.344-.344h1.146A.345.345,0,0,1,11,7.679V8.825a.345.345,0,0,1-.344.344H9.513a.345.345,0,0,1-.344-.344Zm0,3.668A.345.345,0,0,1,9.513,11h1.146a.345.345,0,0,1,.344.344v1.146a.345.345,0,0,1-.344.344H9.513a.345.345,0,0,1-.344-.344ZM5.5,7.679a.345.345,0,0,1,.344-.344H6.992a.345.345,0,0,1,.344.344V8.825a.345.345,0,0,1-.344.344H5.845A.345.345,0,0,1,5.5,8.825Zm0,3.668A.345.345,0,0,1,5.845,11H6.992a.345.345,0,0,1,.344.344v1.146a.345.345,0,0,1-.344.344H5.845a.345.345,0,0,1-.344-.344ZM1.834,7.679a.345.345,0,0,1,.344-.344H3.324a.345.345,0,0,1,.344.344V8.825a.345.345,0,0,1-.344.344H2.178a.345.345,0,0,1-.344-.344Zm0,3.668A.345.345,0,0,1,2.178,11H3.324a.345.345,0,0,1,.344.344v1.146a.345.345,0,0,1-.344.344H2.178a.345.345,0,0,1-.344-.344Zm9.628-9.513H10.086V.458A.46.46,0,0,0,9.628,0H8.711a.46.46,0,0,0-.458.458V1.834H4.585V.458A.46.46,0,0,0,4.126,0H3.209a.46.46,0,0,0-.458.458V1.834H1.375A1.376,1.376,0,0,0,0,3.209V4.585H12.837V3.209A1.376,1.376,0,0,0,11.462,1.834Z"
                  fill="#ff6a00"
                />
              </svg> */}
              {/* April 30, 2023 */}
              {formatedDate(
                splitDate(dp.date)[2],
                splitDate(dp.date)[1],
                splitDate(dp.date)[0]
              )}
              {/* {JSON.stringify(_i.date)} */}
            </div>

            <div className="md:overflow-auto overflow-x-scroll block">
              <table className="whitespace-nowrap w-full rounded-lg md:text-base text-sm p-4 mt-4">
                <tbody>
                  {/* {_i.dropins.map((item) => ())} */}
                  {dp.items.map((dpis, i) => (
                    <tr
                      className="border-b border-gray-200 shadow-md bg-gray-50"
                      key={i}
                    >
                      <td className="p-2">
                        <div className="font-medium inline-flex items-center md:mr-5 mr-2">
                          <svg
                            className="mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14.889"
                            height="18.469"
                            viewBox="0 0 14.889 18.469"
                          >
                            <g
                              id="Group_3609"
                              data-name="Group 3609"
                              transform="matrix(0.951, -0.309, 0.309, 0.951, 0, 2.439)"
                            >
                              <path
                                id="Path_5131"
                                data-name="Path 5131"
                                d="M3.442,6.974l.281-2.889a4.085,4.085,0,0,1,.4.592,8.514,8.514,0,0,1,1.185,5.547L5.886,8.64c.046.13.091.264.137.4h0A54.391,54.391,0,0,1,7.891,16.6a29.552,29.552,0,0,0-1.369-2.9,50.643,50.643,0,0,0-3.543-5.72C2.132,6.792,1.162,5.535.061,4.243c0,0,.713-1.2-.061-4.243C0,0,3.2,1.507,3.442,6.974Z"
                                transform="translate(0)"
                                fill="#ff6a00"
                              />
                              <path
                                id="Path_5132"
                                data-name="Path 5132"
                                d="M1.977,6.428l.971-2.221a3.4,3.4,0,0,1,.164.574,7.111,7.111,0,0,1-.495,4.711l.87-1.108c0,.115,0,.233.005.353h0a45.448,45.448,0,0,1-.474,6.488,24.717,24.717,0,0,0-.335-2.66A42.294,42.294,0,0,0,1.349,7.108C.984,5.945.54,4.7,0,3.385,0,3.385.875,2.62,1.05,0,1.05,0,3.2,2.026,1.977,6.428Z"
                                transform="translate(6.048 3.594)"
                                fill="#222"
                              />
                            </g>
                          </svg>
                          <span>Drop-In #{i + 1}</span>
                        </div>
                      </td>
                      <td className="p-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 19 19"
                          className="inline-flex mr-2"
                        >
                          <g
                            id="Group_3450"
                            data-name="Group 3450"
                            transform="translate(-169 -395)"
                          >
                            <rect
                              id="Rectangle_2254"
                              data-name="Rectangle 2254"
                              width="19"
                              height="19"
                              rx="4"
                              transform="translate(169 395)"
                              fill="#fff3eb"
                            />
                            <path
                              id="Icon_material-access-time"
                              data-name="Icon material-access-time"
                              d="M8.432,3a5.437,5.437,0,1,0,5.443,5.437A5.435,5.435,0,0,0,8.432,3Zm.005,9.787a4.35,4.35,0,1,1,4.35-4.35A4.349,4.349,0,0,1,8.437,12.787Zm.272-7.068H7.893V8.981l2.855,1.713.408-.669L8.709,8.573Z"
                              transform="translate(170.063 396.063)"
                              fill="#ff6a00"
                            />
                          </g>
                        </svg>
                        {dpis.hours.length > 0 ? (
                          <span className="font-medium mr-5">
                            {formatedTime(dpis.hours[0].time)} To{" "}
                            {formatedTime(
                              dpis.hours[dpis.hours.length - 1].endTime
                            )}{" "}
                            ({dpis.hours.length}h)
                          </span>
                        ) : (
                          "-"
                        )}
                        {/* 19:00 - 23:00 */}
                      </td>
                      <td className="p-2">
                        <svg
                          className="inline-flex mr-2"
                          id="Group_3530"
                          data-name="Group 3530"
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 29 29"
                        >
                          <rect
                            id="Rectangle_2253"
                            data-name="Rectangle 2253"
                            width="29"
                            height="29"
                            rx="4"
                            fill="#fff3eb"
                          />
                          <path
                            id="Icon_awesome-dollar-sign"
                            data-name="Icon awesome-dollar-sign"
                            d="M6.72,7.5,3.25,6.483a.948.948,0,0,1,.267-1.857h2.13a1.961,1.961,0,0,1,1.1.337A.5.5,0,0,0,7.372,4.9L8.49,3.807a.521.521,0,0,0-.058-.787,4.543,4.543,0,0,0-2.779-.964V.514A.516.516,0,0,0,5.139,0H4.111A.516.516,0,0,0,3.6.514V2.056h-.08A3.52,3.52,0,0,0,.015,5.9,3.678,3.678,0,0,0,2.707,9L6,9.966a.948.948,0,0,1-.267,1.857H3.6a1.961,1.961,0,0,1-1.1-.337.5.5,0,0,0-.626.064L.76,12.642a.521.521,0,0,0,.058.787,4.543,4.543,0,0,0,2.779.964v1.542a.516.516,0,0,0,.514.514H5.139a.516.516,0,0,0,.514-.514V14.387a3.629,3.629,0,0,0,3.4-2.336A3.528,3.528,0,0,0,6.72,7.5Z"
                            transform="translate(10.625 6.275)"
                            fill="#ff6a00"
                          />
                        </svg>
                        <span className="font-semibold">
                          <span className="text-theme">{dpis.price} CAD</span>
                        </span>
                      </td>
                      {/* <td className="p-2">
              <p className="inline-flex font-medium">
                <Switch
                  isOn={value}
                  onColor="#FF6A00"
                  handleToggle={() => setValue(!value)}
                />
                <span className="ml-2">Subscribe Weekly Game</span>
              </p>
            </td> */}
                      <td className="p-2">
                        <div className="flex items-center">
                          <div className="md:w-full block md:justify-center mr-3">
                            <p className="w-full flex justify-center">
                              <button className="rounded-full border-2 border-theme flex justify-center p-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  fill="#fff3eb"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                                    fill="#ff6a00"
                                  />{" "}
                                  <path
                                    fillRule="evenodd"
                                    d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                                    fill="#ff6a00"
                                  />{" "}
                                  <path
                                    d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                    fill="#ff6a00"
                                  />
                                </svg>
                              </button>
                            </p>
                            <p className="w-full flex justify-center text-xs">
                              {dpis.booked + dpis.left}/
                              <span className="font-bold">{dpis.booked}</span>
                            </p>
                          </div>
                          <div className="md:w-full md:min-w-88 block md:justify-center">
                            <p className="w-full flex justify-center">
                              <button className="rounded-full border-2 border-black flex justify-center p-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  fill="#333"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                                    fill="#333"
                                  />{" "}
                                  <path
                                    fillRule="evenodd"
                                    d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                                    fill="#333"
                                  />{" "}
                                  <path
                                    d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                    fill="#333"
                                  />
                                </svg>
                              </button>
                            </p>
                            <p className="w-full flex justify-center text-xs">
                              <span className="font-bold pr-1">
                                {dpis.left}
                              </span>{" "}
                              Spot(s) Left
                            </p>
                          </div>
                        </div>
                      </td>
                      <td width="90px">
                        {dpis.status ? (
                          <span className="text-[12px] px-2 py-1 text-green-500 bg-green-50 rounded shadow">
                            {filter === "expired" ? "COMPLETED" : "Active"}
                          </span>
                        ) : (
                          <span className="text-[12px] px-2 py-1 text-red-500 bg-red-50 rounded shadow">
                            CANCELLED
                          </span>
                        )}
                      </td>
                      <td className="p-2 text-center">
                        <button
                          className="inline-flex bg-white rounded-full p-3 shadow mr-2"
                          onClick={() =>
                            navigate(`/admin/dropins/${dpis.dropinID}`)
                          }
                        >
                          <FiEye />
                        </button>
                        {filter !== "expired" && (
                          <button
                            className="inline-flex bg-white rounded-full p-3 shadow"
                            onClick={() => onDropinCancel(dpis.dropinID)}
                          >
                            <svg
                              id="Group_2613"
                              data-name="Group 2613"
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 9.991 12.206"
                            >
                              <path
                                id="Path_4629"
                                data-name="Path 4629"
                                d="M-943.763,160.817c0-1.04,0-2.081,0-3.121a.54.54,0,0,1,.461-.6.547.547,0,0,1,.643.572c.007.555,0,1.11,0,1.665q0,2.185,0,4.37a1.065,1.065,0,0,0,1.149,1.152q2.172,0,4.343,0A1.068,1.068,0,0,0-936,163.7q0-2.978,0-5.956c0-.414.219-.658.573-.649a.526.526,0,0,1,.525.478c.015.146.008.294.008.442q0,2.835,0,5.67a2.156,2.156,0,0,1-2.282,2.281q-2.146,0-4.291,0a2.158,2.158,0,0,1-2.3-2.289Q-943.764,162.247-943.763,160.817Z"
                                transform="translate(944.321 -153.761)"
                                fill="#ec0000"
                              />
                              <path
                                id="Path_4630"
                                data-name="Path 4630"
                                d="M-947.264,94.716h1.724c.173,0,.347-.006.52,0a.542.542,0,0,1,.526.537.558.558,0,0,1-.506.562,1.709,1.709,0,0,1-.182,0h-8.607c-.052,0-.1,0-.156,0a.559.559,0,0,1-.54-.556.537.537,0,0,1,.544-.545c.624-.01,1.248,0,1.872,0h.353c0-.317,0-.616,0-.915,0-.575.173-.75.739-.75q1.521,0,3.042,0c.466,0,.665.2.669.664C-947.262,94.035-947.264,94.355-947.264,94.716Zm-3.32-.015h2.184v-.528h-2.184Z"
                                transform="translate(954.485 -93.05)"
                                fill="#ec0000"
                              />
                              <path
                                id="Path_4631"
                                data-name="Path 4631"
                                d="M-889.4,180.835c0,.615,0,1.23,0,1.845,0,.4-.216.646-.552.645s-.553-.253-.554-.647q0-1.858,0-3.717a.566.566,0,0,1,.542-.632c.337-.008.561.24.563.634C-889.4,179.587-889.4,180.211-889.4,180.835Z"
                                transform="translate(893.842 -173.892)"
                                fill="#ec0000"
                              />
                              <path
                                id="Path_4632"
                                data-name="Path 4632"
                                d="M-846.659,180.826c0,.641,0,1.282,0,1.923a.558.558,0,0,1-.443.565.54.54,0,0,1-.6-.3.771.771,0,0,1-.057-.3q-.005-1.884,0-3.769a.552.552,0,0,1,.557-.614.565.565,0,0,1,.548.626C-846.657,179.579-846.659,180.2-846.659,180.826Z"
                                transform="translate(853.32 -173.891)"
                                fill="#ec0000"
                              />
                            </svg>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));
};
export const toastConf = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  progress: undefined,
  theme: "light",
};
