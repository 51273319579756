import React, { useState, useContext } from "react";
import { getDropin, getMyEnrollments } from "../../../apis/booking.api.js";
// import Switch from "../../../components/Switch.js";
import { CartContext } from "../../../context/Cart.context";
import { formatedDate, formatedTime } from "../../../utils/time.js";

export default function DropIn() {
  const { addToCart, cart, removeFromCart } = useContext(CartContext);
  const [dropins, setDropins] = useState([]);
  const [myDropins, setMyDropins] = useState([]);

  const initCall = async () => {
    const myDropins = await getMyEnrollments({ type: "dropin" });
    setMyDropins(myDropins);
    const dropinAPI = await getDropin();

    setDropins(dropinAPI);

    return;
  };

  React.useEffect(() => {
    initCall();
  }, []);
  // const [value, setValue] = useState(false);

  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const renderedItem = () => {
    const groups = dropins.reduce((groups, dropin) => {
      const date = dropin.date;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(dropin);
      return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date: date,
        dropins: groups[date],
      };
    });
    return groupArrays;
  };

  const isInCart = (id) => {
    const dropIn = cart.filter((item) => item.dependentID === id);

    if (dropIn.length > 0) {
      return true;
    }
    return false;
  };

  const getCartItemID = (id) => {
    const dropIn = cart.filter((item) => item.dependentID === id);

    return dropIn[0].id;
  };

  const splitDate = (date) => date.split("-");

  const getButton = (item) => {
    if (!item.status) {
      return (
        <button
          disabled
          className={`rounded-full border-2 border-theme text-base font-bold  mb-1.5 px-3 py-1 bg-theme text-white`}
        >
          Cancelled
        </button>
      );
    }
    if (isInCart(item.dropinID)) {
      return (
        <button
          onClick={() => removeFromCart(getCartItemID(item.dropinID))}
          className={`rounded-full border-2 border-theme text-base font-bold  mb-1.5 px-3 py-1 ${
            isInCart(item.dropinID)
              ? "bg-theme text-white"
              : "text-theme hover:bg-theme hover:text-white"
          }`}
        >
          Remove From Cart
        </button>
      );
    }
    if (myDropins.filter((f) => f.dependentID === item.dropinID).length > 0) {
      return (
        <button
          disabled
          className={`rounded-full border-2 border-theme text-base font-bold  mb-1.5 px-3 py-1 bg-theme text-white`}
        >
          Already Enrolled
        </button>
      );
    }
    return (
      <button
        onClick={() =>
          addToCart({
            type: "dropin",
            dependentID: item.dropinID,
          })
        }
        disabled={item.left === 0}
        className={`rounded-full border-2 border-theme text-base font-bold  mb-1.5 px-3 py-1 ${
          isInCart(item.dropinID)
            ? "bg-theme text-white"
            : "text-theme hover:bg-theme hover:text-white"
        }`}
      >
        {item.left === 0 ? "Slot Full" : "Join This Game"}
      </button>
    );
  };
  return (
    <div className="flex flex-wrap mt-4 mb-4">
      <div className="w-full">
        {renderedItem().map((_i, i) => (
          <div
            className="card-block bg-white border-2 border-white rounded hover:shadow-md focus:shadow-md position-relative mb-3"
            key={i}
          >
            <div className="p-2">
              <div className="w-full space-y-2">
                <div className="rounded-lg">
                  <div
                    className={`flex items-center font-semibold md:text-lg text-base cursor-pointer mb-2`}
                    onClick={() => handleOpen(i)}
                  >
                    <span className={`mr-3 ${open === i ? "op" : "cl"}`}></span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.837"
                      height="14.671"
                      viewBox="0 0 12.837 14.671"
                      className="inline-flex ml-2 mr-2"
                    >
                      <path
                        id="Icon_awesome-calendar-alt"
                        data-name="Icon awesome-calendar-alt"
                        d="M0,13.3a1.376,1.376,0,0,0,1.375,1.375H11.462A1.376,1.376,0,0,0,12.837,13.3V5.5H0ZM9.169,7.679a.345.345,0,0,1,.344-.344h1.146A.345.345,0,0,1,11,7.679V8.825a.345.345,0,0,1-.344.344H9.513a.345.345,0,0,1-.344-.344Zm0,3.668A.345.345,0,0,1,9.513,11h1.146a.345.345,0,0,1,.344.344v1.146a.345.345,0,0,1-.344.344H9.513a.345.345,0,0,1-.344-.344ZM5.5,7.679a.345.345,0,0,1,.344-.344H6.992a.345.345,0,0,1,.344.344V8.825a.345.345,0,0,1-.344.344H5.845A.345.345,0,0,1,5.5,8.825Zm0,3.668A.345.345,0,0,1,5.845,11H6.992a.345.345,0,0,1,.344.344v1.146a.345.345,0,0,1-.344.344H5.845a.345.345,0,0,1-.344-.344ZM1.834,7.679a.345.345,0,0,1,.344-.344H3.324a.345.345,0,0,1,.344.344V8.825a.345.345,0,0,1-.344.344H2.178a.345.345,0,0,1-.344-.344Zm0,3.668A.345.345,0,0,1,2.178,11H3.324a.345.345,0,0,1,.344.344v1.146a.345.345,0,0,1-.344.344H2.178a.345.345,0,0,1-.344-.344Zm9.628-9.513H10.086V.458A.46.46,0,0,0,9.628,0H8.711a.46.46,0,0,0-.458.458V1.834H4.585V.458A.46.46,0,0,0,4.126,0H3.209a.46.46,0,0,0-.458.458V1.834H1.375A1.376,1.376,0,0,0,0,3.209V4.585H12.837V3.209A1.376,1.376,0,0,0,11.462,1.834Z"
                        fill="#ff6a00"
                      />
                    </svg>
                    {/* April 30, 2023 */}
                    {formatedDate(
                      splitDate(_i.date)[2],
                      splitDate(_i.date)[1],
                      splitDate(_i.date)[0]
                    )}
                    {/* {JSON.stringify(_i.date)} */}
                  </div>

                  <div
                    className={`md:overflow-auto overflow-x-scroll ${
                      open === i ? "block" : "hidden"
                    }`}
                  >
                    <table className="whitespace-nowrap w-full rounded-lg md:text-base text-sm p-4 mt-4 mb-8">
                      <tbody>
                        {_i.dropins.map((item) => (
                          <tr className="border-b border-gray-400">
                            <td className="p-2">
                              <div className="font-medium inline-flex items-center md:mr-5 mr-2">
                                <svg
                                  className="mr-2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14.889"
                                  height="18.469"
                                  viewBox="0 0 14.889 18.469"
                                >
                                  <g
                                    id="Group_3609"
                                    data-name="Group 3609"
                                    transform="matrix(0.951, -0.309, 0.309, 0.951, 0, 2.439)"
                                  >
                                    <path
                                      id="Path_5131"
                                      data-name="Path 5131"
                                      d="M3.442,6.974l.281-2.889a4.085,4.085,0,0,1,.4.592,8.514,8.514,0,0,1,1.185,5.547L5.886,8.64c.046.13.091.264.137.4h0A54.391,54.391,0,0,1,7.891,16.6a29.552,29.552,0,0,0-1.369-2.9,50.643,50.643,0,0,0-3.543-5.72C2.132,6.792,1.162,5.535.061,4.243c0,0,.713-1.2-.061-4.243C0,0,3.2,1.507,3.442,6.974Z"
                                      transform="translate(0)"
                                      fill="#ff6a00"
                                    />
                                    <path
                                      id="Path_5132"
                                      data-name="Path 5132"
                                      d="M1.977,6.428l.971-2.221a3.4,3.4,0,0,1,.164.574,7.111,7.111,0,0,1-.495,4.711l.87-1.108c0,.115,0,.233.005.353h0a45.448,45.448,0,0,1-.474,6.488,24.717,24.717,0,0,0-.335-2.66A42.294,42.294,0,0,0,1.349,7.108C.984,5.945.54,4.7,0,3.385,0,3.385.875,2.62,1.05,0,1.05,0,3.2,2.026,1.977,6.428Z"
                                      transform="translate(6.048 3.594)"
                                      fill="#222"
                                    />
                                  </g>
                                </svg>
                                <span>Drop-In</span>
                              </div>
                            </td>
                            <td className="p-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                viewBox="0 0 19 19"
                                className="inline-flex mr-2"
                              >
                                <g
                                  id="Group_3450"
                                  data-name="Group 3450"
                                  transform="translate(-169 -395)"
                                >
                                  <rect
                                    id="Rectangle_2254"
                                    data-name="Rectangle 2254"
                                    width="19"
                                    height="19"
                                    rx="4"
                                    transform="translate(169 395)"
                                    fill="#fff3eb"
                                  />
                                  <path
                                    id="Icon_material-access-time"
                                    data-name="Icon material-access-time"
                                    d="M8.432,3a5.437,5.437,0,1,0,5.443,5.437A5.435,5.435,0,0,0,8.432,3Zm.005,9.787a4.35,4.35,0,1,1,4.35-4.35A4.349,4.349,0,0,1,8.437,12.787Zm.272-7.068H7.893V8.981l2.855,1.713.408-.669L8.709,8.573Z"
                                    transform="translate(170.063 396.063)"
                                    fill="#ff6a00"
                                  />
                                </g>
                              </svg>
                              {item.hours.length > 0 && (
                                <span className="font-medium mr-5">
                                  {formatedTime(item.hours[0].time)} To{" "}
                                  {formatedTime(
                                    item.hours[item.hours.length - 1].endTime
                                  )}{" "}
                                  ({item.hours.length}h)
                                </span>
                              )}
                            </td>
                            <td className="p-2">
                              <svg
                                className="inline-flex mr-2"
                                id="Group_3530"
                                data-name="Group 3530"
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                viewBox="0 0 29 29"
                              >
                                <rect
                                  id="Rectangle_2253"
                                  data-name="Rectangle 2253"
                                  width="29"
                                  height="29"
                                  rx="4"
                                  fill="#fff3eb"
                                />
                                <path
                                  id="Icon_awesome-dollar-sign"
                                  data-name="Icon awesome-dollar-sign"
                                  d="M6.72,7.5,3.25,6.483a.948.948,0,0,1,.267-1.857h2.13a1.961,1.961,0,0,1,1.1.337A.5.5,0,0,0,7.372,4.9L8.49,3.807a.521.521,0,0,0-.058-.787,4.543,4.543,0,0,0-2.779-.964V.514A.516.516,0,0,0,5.139,0H4.111A.516.516,0,0,0,3.6.514V2.056h-.08A3.52,3.52,0,0,0,.015,5.9,3.678,3.678,0,0,0,2.707,9L6,9.966a.948.948,0,0,1-.267,1.857H3.6a1.961,1.961,0,0,1-1.1-.337.5.5,0,0,0-.626.064L.76,12.642a.521.521,0,0,0,.058.787,4.543,4.543,0,0,0,2.779.964v1.542a.516.516,0,0,0,.514.514H5.139a.516.516,0,0,0,.514-.514V14.387a3.629,3.629,0,0,0,3.4-2.336A3.528,3.528,0,0,0,6.72,7.5Z"
                                  transform="translate(10.625 6.275)"
                                  fill="#ff6a00"
                                />
                              </svg>
                              <span className="font-semibold">
                                <span className="text-theme">{item.price}</span>
                              </span>
                            </td>
                            {/* <td className="p-2">
                          <p className="inline-flex font-medium">
                            <Switch
                              isOn={value}
                              onColor="#FF6A00"
                              handleToggle={() => setValue(!value)}
                            />
                            <span className="ml-2">Subscribe Weekly Game</span>
                          </p>
                        </td> */}
                            <td className="p-2">
                              <div className="flex items-center">
                                <div className="md:w-full block md:justify-center mr-3">
                                  <p className="w-full flex justify-center">
                                    <button className="rounded-full border-2 border-theme flex justify-center p-1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        fill="#fff3eb"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                                          fill="#ff6a00"
                                        />{" "}
                                        <path
                                          fillRule="evenodd"
                                          d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                                          fill="#ff6a00"
                                        />{" "}
                                        <path
                                          d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                          fill="#ff6a00"
                                        />
                                      </svg>
                                    </button>
                                  </p>
                                  {item.status && (
                                    <p className="w-full flex justify-center text-xs">
                                      {item.booked + item.left}/
                                      <span className="font-bold">
                                        {item.booked}
                                      </span>
                                    </p>
                                  )}
                                </div>
                                <div className="md:w-full md:min-w-88 block md:justify-center">
                                  <p className="w-full flex justify-center">
                                    <button
                                      className="rounded-full border-2 border-black flex justify-center p-1"
                                      href="#"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        fill="#333"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                                          fill="#333"
                                        />{" "}
                                        <path
                                          fillRule="evenodd"
                                          d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                                          fill="#333"
                                        />{" "}
                                        <path
                                          d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                          fill="#333"
                                        />
                                      </svg>
                                    </button>
                                  </p>
                                  {item.status && (
                                    <p className="w-full flex justify-center text-xs">
                                      <span className="font-bold pr-1">
                                        {item.left}
                                      </span>{" "}
                                      Spot(s) Left
                                    </p>
                                  )}
                                </div>
                              </div>
                            </td>
                            <td className="p-2 text-center">
                              {getButton(item)}
                            </td>
                          </tr>
                        ))}
                        {/* <tr className="border-b border-gray-400">
                        <td className="p-2">
                          <div className="font-medium inline-flex items-center md:mr-5 mr-2">
                            <svg
                              className="mr-2"
                              xmlns="http://www.w3.org/2000/svg"
                              width="14.889"
                              height="18.469"
                              viewBox="0 0 14.889 18.469"
                            >
                              <g
                                id="Group_3609"
                                data-name="Group 3609"
                                transform="matrix(0.951, -0.309, 0.309, 0.951, 0, 2.439)"
                              >
                                <path
                                  id="Path_5131"
                                  data-name="Path 5131"
                                  d="M3.442,6.974l.281-2.889a4.085,4.085,0,0,1,.4.592,8.514,8.514,0,0,1,1.185,5.547L5.886,8.64c.046.13.091.264.137.4h0A54.391,54.391,0,0,1,7.891,16.6a29.552,29.552,0,0,0-1.369-2.9,50.643,50.643,0,0,0-3.543-5.72C2.132,6.792,1.162,5.535.061,4.243c0,0,.713-1.2-.061-4.243C0,0,3.2,1.507,3.442,6.974Z"
                                  transform="translate(0)"
                                  fill="#ff6a00"
                                />
                                <path
                                  id="Path_5132"
                                  data-name="Path 5132"
                                  d="M1.977,6.428l.971-2.221a3.4,3.4,0,0,1,.164.574,7.111,7.111,0,0,1-.495,4.711l.87-1.108c0,.115,0,.233.005.353h0a45.448,45.448,0,0,1-.474,6.488,24.717,24.717,0,0,0-.335-2.66A42.294,42.294,0,0,0,1.349,7.108C.984,5.945.54,4.7,0,3.385,0,3.385.875,2.62,1.05,0,1.05,0,3.2,2.026,1.977,6.428Z"
                                  transform="translate(6.048 3.594)"
                                  fill="#222"
                                />
                              </g>
                            </svg>
                            <span>Drop-In</span>
                          </div>
                        </td>
                        <td className="p-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 19 19"
                            className="inline-flex mr-2"
                          >
                            <g
                              id="Group_3450"
                              data-name="Group 3450"
                              transform="translate(-169 -395)"
                            >
                              <rect
                                id="Rectangle_2254"
                                data-name="Rectangle 2254"
                                width="19"
                                height="19"
                                rx="4"
                                transform="translate(169 395)"
                                fill="#fff3eb"
                              />
                              <path
                                id="Icon_material-access-time"
                                data-name="Icon material-access-time"
                                d="M8.432,3a5.437,5.437,0,1,0,5.443,5.437A5.435,5.435,0,0,0,8.432,3Zm.005,9.787a4.35,4.35,0,1,1,4.35-4.35A4.349,4.349,0,0,1,8.437,12.787Zm.272-7.068H7.893V8.981l2.855,1.713.408-.669L8.709,8.573Z"
                                transform="translate(170.063 396.063)"
                                fill="#ff6a00"
                              />
                            </g>
                          </svg>
                          <span className="font-medium mr-5">
                            04:00 PM To 06:00 PM
                          </span>
                        </td>
                        <td className="p-2">
                          <svg
                            className="inline-flex mr-2"
                            id="Group_3530"
                            data-name="Group 3530"
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 29 29"
                          >
                            <rect
                              id="Rectangle_2253"
                              data-name="Rectangle 2253"
                              width="29"
                              height="29"
                              rx="4"
                              fill="#fff3eb"
                            />
                            <path
                              id="Icon_awesome-dollar-sign"
                              data-name="Icon awesome-dollar-sign"
                              d="M6.72,7.5,3.25,6.483a.948.948,0,0,1,.267-1.857h2.13a1.961,1.961,0,0,1,1.1.337A.5.5,0,0,0,7.372,4.9L8.49,3.807a.521.521,0,0,0-.058-.787,4.543,4.543,0,0,0-2.779-.964V.514A.516.516,0,0,0,5.139,0H4.111A.516.516,0,0,0,3.6.514V2.056h-.08A3.52,3.52,0,0,0,.015,5.9,3.678,3.678,0,0,0,2.707,9L6,9.966a.948.948,0,0,1-.267,1.857H3.6a1.961,1.961,0,0,1-1.1-.337.5.5,0,0,0-.626.064L.76,12.642a.521.521,0,0,0,.058.787,4.543,4.543,0,0,0,2.779.964v1.542a.516.516,0,0,0,.514.514H5.139a.516.516,0,0,0,.514-.514V14.387a3.629,3.629,0,0,0,3.4-2.336A3.528,3.528,0,0,0,6.72,7.5Z"
                              transform="translate(10.625 6.275)"
                              fill="#ff6a00"
                            />
                          </svg>
                          <span className="font-semibold">
                            <span className="text-theme">10.00</span>
                          </span>
                        </td>
                        <td className="p-2">
                          <p className="inline-flex font-medium">
                            <Switch
                              isOn={value}
                              onColor="#FF6A00"
                              handleToggle={() => setValue(!value)}
                            />
                            <span className="ml-2">Subscribe Weekly Game</span>
                          </p>
                        </td>
                        <td className="p-2">
                          <div className="flex items-center">
                            <div className="md:w-full block md:justify-center mr-3">
                              <p className="w-full flex justify-center">
                                <button className="rounded-full border-2 border-theme flex justify-center p-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    fill="#fff3eb"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                                      fill="#ff6a00"
                                    />{" "}
                                    <path
                                      fillRule="evenodd"
                                      d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                                      fill="#ff6a00"
                                    />{" "}
                                    <path
                                      d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                      fill="#ff6a00"
                                    />
                                  </svg>
                                </button>
                              </p>
                              <p className="w-full flex justify-center text-xs">
                                6/5
                              </p>
                            </div>
                            <div className="md:w-full md:min-w-88 block md:justify-center">
                              <p className="w-full flex justify-center">
                                <button
                                  className="rounded-full border-2 border-black flex justify-center p-1"
                                  href="#"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    fill="#333"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                                      fill="#333"
                                    />{" "}
                                    <path
                                      fillRule="evenodd"
                                      d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                                      fill="#333"
                                    />{" "}
                                    <path
                                      d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                      fill="#333"
                                    />
                                  </svg>
                                </button>
                              </p>
                              <p className="w-full flex justify-center text-xs">
                                1 Spot(s) Left
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="p-2 text-center">
                          <button className="rounded-full border-2 border-theme text-base font-bold text-theme mb-1.5 px-3 py-1 hover:bg-theme hover:text-white">
                            Already Full
                          </button>
                        </td>
                      </tr>
                      <tr className="border-b border-gray-400">
                        <td className="p-2">
                          <div className="font-medium inline-flex items-center md:mr-5 mr-2">
                            <svg
                              className="mr-2"
                              xmlns="http://www.w3.org/2000/svg"
                              width="14.889"
                              height="18.469"
                              viewBox="0 0 14.889 18.469"
                            >
                              <g
                                id="Group_3609"
                                data-name="Group 3609"
                                transform="matrix(0.951, -0.309, 0.309, 0.951, 0, 2.439)"
                              >
                                <path
                                  id="Path_5131"
                                  data-name="Path 5131"
                                  d="M3.442,6.974l.281-2.889a4.085,4.085,0,0,1,.4.592,8.514,8.514,0,0,1,1.185,5.547L5.886,8.64c.046.13.091.264.137.4h0A54.391,54.391,0,0,1,7.891,16.6a29.552,29.552,0,0,0-1.369-2.9,50.643,50.643,0,0,0-3.543-5.72C2.132,6.792,1.162,5.535.061,4.243c0,0,.713-1.2-.061-4.243C0,0,3.2,1.507,3.442,6.974Z"
                                  transform="translate(0)"
                                  fill="#ff6a00"
                                />
                                <path
                                  id="Path_5132"
                                  data-name="Path 5132"
                                  d="M1.977,6.428l.971-2.221a3.4,3.4,0,0,1,.164.574,7.111,7.111,0,0,1-.495,4.711l.87-1.108c0,.115,0,.233.005.353h0a45.448,45.448,0,0,1-.474,6.488,24.717,24.717,0,0,0-.335-2.66A42.294,42.294,0,0,0,1.349,7.108C.984,5.945.54,4.7,0,3.385,0,3.385.875,2.62,1.05,0,1.05,0,3.2,2.026,1.977,6.428Z"
                                  transform="translate(6.048 3.594)"
                                  fill="#222"
                                />
                              </g>
                            </svg>
                            <span>Drop-In</span>
                          </div>
                        </td>
                        <td className="p-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 19 19"
                            className="inline-flex mr-2"
                          >
                            <g
                              id="Group_3450"
                              data-name="Group 3450"
                              transform="translate(-169 -395)"
                            >
                              <rect
                                id="Rectangle_2254"
                                data-name="Rectangle 2254"
                                width="19"
                                height="19"
                                rx="4"
                                transform="translate(169 395)"
                                fill="#fff3eb"
                              />
                              <path
                                id="Icon_material-access-time"
                                data-name="Icon material-access-time"
                                d="M8.432,3a5.437,5.437,0,1,0,5.443,5.437A5.435,5.435,0,0,0,8.432,3Zm.005,9.787a4.35,4.35,0,1,1,4.35-4.35A4.349,4.349,0,0,1,8.437,12.787Zm.272-7.068H7.893V8.981l2.855,1.713.408-.669L8.709,8.573Z"
                                transform="translate(170.063 396.063)"
                                fill="#ff6a00"
                              />
                            </g>
                          </svg>
                          <span className="font-medium mr-5">
                            08:00 PM To 12:00 PM
                          </span>
                        </td>
                        <td className="p-2">
                          <svg
                            className="inline-flex mr-2"
                            id="Group_3530"
                            data-name="Group 3530"
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 29 29"
                          >
                            <rect
                              id="Rectangle_2253"
                              data-name="Rectangle 2253"
                              width="29"
                              height="29"
                              rx="4"
                              fill="#fff3eb"
                            />
                            <path
                              id="Icon_awesome-dollar-sign"
                              data-name="Icon awesome-dollar-sign"
                              d="M6.72,7.5,3.25,6.483a.948.948,0,0,1,.267-1.857h2.13a1.961,1.961,0,0,1,1.1.337A.5.5,0,0,0,7.372,4.9L8.49,3.807a.521.521,0,0,0-.058-.787,4.543,4.543,0,0,0-2.779-.964V.514A.516.516,0,0,0,5.139,0H4.111A.516.516,0,0,0,3.6.514V2.056h-.08A3.52,3.52,0,0,0,.015,5.9,3.678,3.678,0,0,0,2.707,9L6,9.966a.948.948,0,0,1-.267,1.857H3.6a1.961,1.961,0,0,1-1.1-.337.5.5,0,0,0-.626.064L.76,12.642a.521.521,0,0,0,.058.787,4.543,4.543,0,0,0,2.779.964v1.542a.516.516,0,0,0,.514.514H5.139a.516.516,0,0,0,.514-.514V14.387a3.629,3.629,0,0,0,3.4-2.336A3.528,3.528,0,0,0,6.72,7.5Z"
                              transform="translate(10.625 6.275)"
                              fill="#ff6a00"
                            />
                          </svg>
                          <span className="font-semibold">
                            <span className="text-theme">10.00</span>
                          </span>
                        </td>
                        <td className="p-2">
                          <p className="inline-flex font-medium">
                            <Switch
                              isOn={value}
                              onColor="#FF6A00"
                              handleToggle={() => setValue(!value)}
                            />
                            <span className="ml-2">Subscribe Weekly Game</span>
                          </p>
                        </td>
                        <td className="p-2">
                          <div className="flex items-center">
                            <div className="md:w-full block md:justify-center mr-3">
                              <p className="w-full flex justify-center">
                                <button className="rounded-full border-2 border-theme flex justify-center p-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    fill="#fff3eb"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                                      fill="#ff6a00"
                                    />{" "}
                                    <path
                                      fillRule="evenodd"
                                      d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                                      fill="#ff6a00"
                                    />{" "}
                                    <path
                                      d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                      fill="#ff6a00"
                                    />
                                  </svg>
                                </button>
                              </p>
                              <p className="w-full flex justify-center text-xs">
                                6/5
                              </p>
                            </div>
                            <div className="md:w-full md:min-w-88 block md:justify-center">
                              <p className="w-full flex justify-center">
                                <button
                                  className="rounded-full border-2 border-black flex justify-center p-1"
                                  href="#"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    fill="#333"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                                      fill="#333"
                                    />{" "}
                                    <path
                                      fillRule="evenodd"
                                      d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                                      fill="#333"
                                    />{" "}
                                    <path
                                      d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                      fill="#333"
                                    />
                                  </svg>
                                </button>
                              </p>
                              <p className="w-full flex justify-center text-xs">
                                1 Spot(s) Left
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="p-2 text-center">
                          <button className="rounded-full border-2 border-theme text-base font-bold text-theme mb-1.5 px-3 py-1 hover:bg-theme hover:text-white">
                            Already Joined
                          </button>
                        </td>
                      </tr>
                      <tr className="border-b border-gray-400">
                        <td className="p-2">
                          <div className="font-medium inline-flex items-center md:mr-5 mr-2">
                            <svg
                              className="mr-2"
                              xmlns="http://www.w3.org/2000/svg"
                              width="14.889"
                              height="18.469"
                              viewBox="0 0 14.889 18.469"
                            >
                              <g
                                id="Group_3609"
                                data-name="Group 3609"
                                transform="matrix(0.951, -0.309, 0.309, 0.951, 0, 2.439)"
                              >
                                <path
                                  id="Path_5131"
                                  data-name="Path 5131"
                                  d="M3.442,6.974l.281-2.889a4.085,4.085,0,0,1,.4.592,8.514,8.514,0,0,1,1.185,5.547L5.886,8.64c.046.13.091.264.137.4h0A54.391,54.391,0,0,1,7.891,16.6a29.552,29.552,0,0,0-1.369-2.9,50.643,50.643,0,0,0-3.543-5.72C2.132,6.792,1.162,5.535.061,4.243c0,0,.713-1.2-.061-4.243C0,0,3.2,1.507,3.442,6.974Z"
                                  transform="translate(0)"
                                  fill="#ff6a00"
                                />
                                <path
                                  id="Path_5132"
                                  data-name="Path 5132"
                                  d="M1.977,6.428l.971-2.221a3.4,3.4,0,0,1,.164.574,7.111,7.111,0,0,1-.495,4.711l.87-1.108c0,.115,0,.233.005.353h0a45.448,45.448,0,0,1-.474,6.488,24.717,24.717,0,0,0-.335-2.66A42.294,42.294,0,0,0,1.349,7.108C.984,5.945.54,4.7,0,3.385,0,3.385.875,2.62,1.05,0,1.05,0,3.2,2.026,1.977,6.428Z"
                                  transform="translate(6.048 3.594)"
                                  fill="#222"
                                />
                              </g>
                            </svg>
                            <span>Drop-In</span>
                          </div>
                        </td>
                        <td className="p-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 19 19"
                            className="inline-flex mr-2"
                          >
                            <g
                              id="Group_3450"
                              data-name="Group 3450"
                              transform="translate(-169 -395)"
                            >
                              <rect
                                id="Rectangle_2254"
                                data-name="Rectangle 2254"
                                width="19"
                                height="19"
                                rx="4"
                                transform="translate(169 395)"
                                fill="#fff3eb"
                              />
                              <path
                                id="Icon_material-access-time"
                                data-name="Icon material-access-time"
                                d="M8.432,3a5.437,5.437,0,1,0,5.443,5.437A5.435,5.435,0,0,0,8.432,3Zm.005,9.787a4.35,4.35,0,1,1,4.35-4.35A4.349,4.349,0,0,1,8.437,12.787Zm.272-7.068H7.893V8.981l2.855,1.713.408-.669L8.709,8.573Z"
                                transform="translate(170.063 396.063)"
                                fill="#ff6a00"
                              />
                            </g>
                          </svg>
                          <span className="font-medium mr-5">
                            08:00 PM To 10:00 PM
                          </span>
                        </td>
                        <td className="p-2">
                          <svg
                            className="inline-flex mr-2"
                            id="Group_3530"
                            data-name="Group 3530"
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 29 29"
                          >
                            <rect
                              id="Rectangle_2253"
                              data-name="Rectangle 2253"
                              width="29"
                              height="29"
                              rx="4"
                              fill="#fff3eb"
                            />
                            <path
                              id="Icon_awesome-dollar-sign"
                              data-name="Icon awesome-dollar-sign"
                              d="M6.72,7.5,3.25,6.483a.948.948,0,0,1,.267-1.857h2.13a1.961,1.961,0,0,1,1.1.337A.5.5,0,0,0,7.372,4.9L8.49,3.807a.521.521,0,0,0-.058-.787,4.543,4.543,0,0,0-2.779-.964V.514A.516.516,0,0,0,5.139,0H4.111A.516.516,0,0,0,3.6.514V2.056h-.08A3.52,3.52,0,0,0,.015,5.9,3.678,3.678,0,0,0,2.707,9L6,9.966a.948.948,0,0,1-.267,1.857H3.6a1.961,1.961,0,0,1-1.1-.337.5.5,0,0,0-.626.064L.76,12.642a.521.521,0,0,0,.058.787,4.543,4.543,0,0,0,2.779.964v1.542a.516.516,0,0,0,.514.514H5.139a.516.516,0,0,0,.514-.514V14.387a3.629,3.629,0,0,0,3.4-2.336A3.528,3.528,0,0,0,6.72,7.5Z"
                              transform="translate(10.625 6.275)"
                              fill="#ff6a00"
                            />
                          </svg>
                          <span className="font-semibold">
                            <span className="text-theme">10.00</span>
                          </span>
                        </td>
                        <td className="p-2">
                          <p className="inline-flex font-medium">
                            <Switch
                              isOn={value}
                              onColor="#FF6A00"
                              handleToggle={() => setValue(!value)}
                            />
                            <span className="ml-2">Subscribe Weekly Game</span>
                          </p>
                        </td>
                        <td className="p-2">
                          <div className="flex items-center">
                            <div className="md:w-full block md:justify-center mr-3">
                              <p className="w-full flex justify-center">
                                <button className="rounded-full border-2 border-theme flex justify-center p-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    fill="#fff3eb"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                                      fill="#ff6a00"
                                    />{" "}
                                    <path
                                      fillRule="evenodd"
                                      d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                                      fill="#ff6a00"
                                    />{" "}
                                    <path
                                      d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                      fill="#ff6a00"
                                    />
                                  </svg>
                                </button>
                              </p>
                              <p className="w-full flex justify-center text-xs">
                                6/5
                              </p>
                            </div>
                            <div className="md:w-full md:min-w-88 block md:justify-center">
                              <p className="w-full flex justify-center">
                                <button
                                  className="rounded-full border-2 border-black flex justify-center p-1"
                                  href="#"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    fill="#333"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                                      fill="#333"
                                    />{" "}
                                    <path
                                      fillRule="evenodd"
                                      d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                                      fill="#333"
                                    />{" "}
                                    <path
                                      d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                      fill="#333"
                                    />
                                  </svg>
                                </button>
                              </p>
                              <p className="w-full flex justify-center text-xs">
                                1 Spot(s) Left
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="p-2 text-center">
                          <button className="rounded-full border-2 border-theme text-base font-bold text-theme mb-1.5 px-3 py-1 hover:bg-theme hover:text-white">
                            Wait for Joining
                          </button>
                        </td>
                      </tr>
                      <tr className="border-b border-gray-400">
                        <td className="p-2">
                          <div className="font-medium inline-flex items-center md:mr-5 mr-2">
                            <svg
                              className="mr-2"
                              xmlns="http://www.w3.org/2000/svg"
                              width="14.889"
                              height="18.469"
                              viewBox="0 0 14.889 18.469"
                            >
                              <g
                                id="Group_3609"
                                data-name="Group 3609"
                                transform="matrix(0.951, -0.309, 0.309, 0.951, 0, 2.439)"
                              >
                                <path
                                  id="Path_5131"
                                  data-name="Path 5131"
                                  d="M3.442,6.974l.281-2.889a4.085,4.085,0,0,1,.4.592,8.514,8.514,0,0,1,1.185,5.547L5.886,8.64c.046.13.091.264.137.4h0A54.391,54.391,0,0,1,7.891,16.6a29.552,29.552,0,0,0-1.369-2.9,50.643,50.643,0,0,0-3.543-5.72C2.132,6.792,1.162,5.535.061,4.243c0,0,.713-1.2-.061-4.243C0,0,3.2,1.507,3.442,6.974Z"
                                  transform="translate(0)"
                                  fill="#ff6a00"
                                />
                                <path
                                  id="Path_5132"
                                  data-name="Path 5132"
                                  d="M1.977,6.428l.971-2.221a3.4,3.4,0,0,1,.164.574,7.111,7.111,0,0,1-.495,4.711l.87-1.108c0,.115,0,.233.005.353h0a45.448,45.448,0,0,1-.474,6.488,24.717,24.717,0,0,0-.335-2.66A42.294,42.294,0,0,0,1.349,7.108C.984,5.945.54,4.7,0,3.385,0,3.385.875,2.62,1.05,0,1.05,0,3.2,2.026,1.977,6.428Z"
                                  transform="translate(6.048 3.594)"
                                  fill="#222"
                                />
                              </g>
                            </svg>
                            <span>Drop-In</span>
                          </div>
                        </td>
                        <td className="p-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 19 19"
                            className="inline-flex mr-2"
                          >
                            <g
                              id="Group_3450"
                              data-name="Group 3450"
                              transform="translate(-169 -395)"
                            >
                              <rect
                                id="Rectangle_2254"
                                data-name="Rectangle 2254"
                                width="19"
                                height="19"
                                rx="4"
                                transform="translate(169 395)"
                                fill="#fff3eb"
                              />
                              <path
                                id="Icon_material-access-time"
                                data-name="Icon material-access-time"
                                d="M8.432,3a5.437,5.437,0,1,0,5.443,5.437A5.435,5.435,0,0,0,8.432,3Zm.005,9.787a4.35,4.35,0,1,1,4.35-4.35A4.349,4.349,0,0,1,8.437,12.787Zm.272-7.068H7.893V8.981l2.855,1.713.408-.669L8.709,8.573Z"
                                transform="translate(170.063 396.063)"
                                fill="#ff6a00"
                              />
                            </g>
                          </svg>
                          <span className="font-medium mr-5">
                            04:00 PM To 06:00 PM
                          </span>
                        </td>
                        <td className="p-2">
                          <svg
                            className="inline-flex mr-2"
                            id="Group_3530"
                            data-name="Group 3530"
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 29 29"
                          >
                            <rect
                              id="Rectangle_2253"
                              data-name="Rectangle 2253"
                              width="29"
                              height="29"
                              rx="4"
                              fill="#fff3eb"
                            />
                            <path
                              id="Icon_awesome-dollar-sign"
                              data-name="Icon awesome-dollar-sign"
                              d="M6.72,7.5,3.25,6.483a.948.948,0,0,1,.267-1.857h2.13a1.961,1.961,0,0,1,1.1.337A.5.5,0,0,0,7.372,4.9L8.49,3.807a.521.521,0,0,0-.058-.787,4.543,4.543,0,0,0-2.779-.964V.514A.516.516,0,0,0,5.139,0H4.111A.516.516,0,0,0,3.6.514V2.056h-.08A3.52,3.52,0,0,0,.015,5.9,3.678,3.678,0,0,0,2.707,9L6,9.966a.948.948,0,0,1-.267,1.857H3.6a1.961,1.961,0,0,1-1.1-.337.5.5,0,0,0-.626.064L.76,12.642a.521.521,0,0,0,.058.787,4.543,4.543,0,0,0,2.779.964v1.542a.516.516,0,0,0,.514.514H5.139a.516.516,0,0,0,.514-.514V14.387a3.629,3.629,0,0,0,3.4-2.336A3.528,3.528,0,0,0,6.72,7.5Z"
                              transform="translate(10.625 6.275)"
                              fill="#ff6a00"
                            />
                          </svg>
                          <span className="font-semibold">
                            <span className="text-theme">10.00</span>
                          </span>
                        </td>
                        <td className="p-2">
                          <p className="inline-flex font-medium">
                            <Switch
                              isOn={value}
                              onColor="#FF6A00"
                              handleToggle={() => setValue(!value)}
                            />
                            <span className="ml-2">Subscribe Weekly Game</span>
                          </p>
                        </td>
                        <td className="p-2">
                          <div className="flex items-center">
                            <div className="md:w-full block md:justify-center mr-3">
                              <p className="w-full flex justify-center">
                                <button className="rounded-full border-2 border-theme flex justify-center p-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    fill="#fff3eb"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                                      fill="#ff6a00"
                                    />{" "}
                                    <path
                                      fillRule="evenodd"
                                      d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                                      fill="#ff6a00"
                                    />{" "}
                                    <path
                                      d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                      fill="#ff6a00"
                                    />
                                  </svg>
                                </button>
                              </p>
                              <p className="w-full flex justify-center text-xs">
                                6/5
                              </p>
                            </div>
                            <div className="md:w-full md:min-w-88 block md:justify-center">
                              <p className="w-full flex justify-center">
                                <button
                                  className="rounded-full border-2 border-black flex justify-center p-1"
                                  href="#"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    fill="#333"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                                      fill="#333"
                                    />{" "}
                                    <path
                                      fillRule="evenodd"
                                      d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                                      fill="#333"
                                    />{" "}
                                    <path
                                      d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                      fill="#333"
                                    />
                                  </svg>
                                </button>
                              </p>
                              <p className="w-full flex justify-center text-xs">
                                1 Spot(s) Left
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="p-2 text-center">
                          <button className="rounded-full border-2 border-theme text-base font-bold text-theme mb-1.5 px-3 py-1 hover:bg-theme hover:text-white">
                            Join This Game
                          </button>
                        </td>
                      </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
