const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const day = ["S", "M", "T", "W", "T", "F", "S"];
const longDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const formatedTime = (time) => {
  const _c = time.split(":").map((i) => {
    return parseInt(i);
  });
  return `${("0" + _c[0]).slice(-2)}:${("0" + _c[1]).slice(-2)}`;
};

export const formatedDate = (date, month, year) => {
  return `${date} ${months[month - 1]}${year ? `, ${year}` : ""}`;
};

export const getDay = (day) => {
  return longDay[day];
};
