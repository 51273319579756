// import { format } from "date-fns";
// import { formatInTimeZone } from "date-fns-tz";
import React, { useContext, useState } from "react";
// import { Link, useLocation } from "react-router-dom";

import { NavLink } from "react-router-dom";
import LogoIco from "../pages/AdminDashboard/assets/logoIco.svg";
// import UserImg from "../pages/AdminDashboard/assets/userImg.png";
import WingsLogo from "../assets/wings-logo.svg";
import Prof from "../assets/default-user.jpg";
import { FiList } from "react-icons/fi";
import { AdminZoneContext } from "../context/AdminZone.context";
import { AuthContext } from "../context/Auth.context";

export default function AdminLayout({ children }) {
  const [navbar, setNavbar] = useState(false);
  const [ProfileDrop, setProfileDrop] = useState(false);

  const { logout } = useContext(AuthContext);

  const { user } = useContext(AdminZoneContext);
  return (
    <>
      <div className="dash wrapper flex  w-full h-screen bg-light">
        <nav
          id="sidebar"
          className={`h-full max-w-260 w-full z-10 ${
            navbar
              ? "block lg:min-w-260 w-full Fullmenu absolute md:relative"
              : "md:w-14 w-0 overflow-hidden  Iconmenu"
          }`}
        >
          {/* <a href="#" className={`logo text-center flex py-4 hidden md:block ${navbar ? "px-5" : "px-3"}`} name="logo">
						<img src={Logo} className={`h-12 ${navbar ? "block" : "hidden"}`} />
						<img src={LogoIco} className={`h-12 ${navbar ? "hidden" : "block"}`} />
					</a> */}
          <ul
            //   className={`h-12 ${navbar ? "block" : "hidden"}`}
            className="sideNav b-5 text-white gap-y-2 bg-sec h-full fixed shadow-xl pt-4 flex flex-col mt-16 md:mt-0"
          >
            <li className="sm:min-h-58 min-h-48">
              <div className="flex justify-center items-center">
                <span className="h-12">
                  <img
                    src={WingsLogo}
                    className={`w-12 ${navbar ? "block" : "hidden"}`}
                  />
                </span>
                <span className="logoIco h-10">
                  <img
                    src={WingsLogo}
                    className={`w-10 ${navbar ? "hidden" : "block"}`}
                  />
                </span>
              </div>
            </li>
            <li className="min-h-42 overflow-hidden md:overflow-none">
              <NavLink
                to="/admin/"
                className="leading-4 whitespace-nowrap overflow-hidden px-3 py-3 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
              >
                <svg
                  opacity="0.5"
                  className="mx-2"
                  width="18"
                  height="18"
                  viewBox="0 0 15.634 15.634"
                >
                  <path
                    d="M4.5,13.186h6.948V4.5H4.5Zm0,6.948h6.948V14.923H4.5Zm8.686,0h6.948V11.448H13.186Zm0-15.634V9.711h6.948V4.5Z"
                    transform="translate(-4.5 -4.5)"
                    fill="#fff"
                  />
                </svg>
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li className="min-h-42">
              <NavLink
                to="/admin/court/"
                className="leading-4 whitespace-nowrap overflow-hidden px-3 py-3 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
              >
                <svg
                  opacity="0.5"
                  className="mx-2"
                  width="18"
                  height="18"
                  viewBox="0 0 15.317 15.317"
                >
                  <path
                    d="M4.532,6.063H3V16.785a1.536,1.536,0,0,0,1.532,1.532H15.254V16.785H4.532ZM16.785,3H7.6A1.536,1.536,0,0,0,6.063,4.532v9.19A1.536,1.536,0,0,0,7.6,15.254h9.19a1.536,1.536,0,0,0,1.532-1.532V4.532A1.536,1.536,0,0,0,16.785,3Zm-.766,6.893H8.361V8.361h7.659Zm-3.063,3.063h-4.6V11.424h4.6Zm3.063-6.127H8.361V5.3h7.659Z"
                    transform="translate(-3 -3)"
                    fill="#fff"
                  />
                </svg>
                <span>Court Booking</span>
              </NavLink>
            </li>
            <li className="min-h-42">
              <NavLink
                to="/admin/users/"
                className="leading-4 whitespace-nowrap overflow-hidden px-3 py-3 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
              >
                <svg
                  opacity="0.5"
                  className="mx-2"
                  width="18"
                  height="18"
                  viewBox="0 0 25.038 20.902"
                >
                  <g transform="translate(931.156 -39.894)">
                    <path
                      d="M-927.069,127.41c-1,0-2.008,0-3.013,0a.977.977,0,0,1-.993-1.4,4.263,4.263,0,0,1,4.047-2.863,4.259,4.259,0,0,1,3.989,2.9.969.969,0,0,1-.96,1.357C-925.021,127.415-926.045,127.41-927.069,127.41Z"
                      transform="translate(0 -78.383)"
                      fill="#fff"
                    />
                    <path
                      d="M-927.047,328.53c-.984,0-1.969,0-2.953,0a.986.986,0,0,1-1.021-1.439A4.244,4.244,0,0,1-927,324.275a4.239,4.239,0,0,1,4.008,2.91.967.967,0,0,1-.951,1.343C-924.98,328.537-926.014,328.53-927.047,328.53Z"
                      transform="translate(-0.037 -267.737)"
                      fill="#fff"
                    />
                    <path
                      d="M-895.725,44.187a2.139,2.139,0,0,1-2.166-2.139,2.15,2.15,0,0,1,2.128-2.154,2.143,2.143,0,0,1,2.164,2.145A2.131,2.131,0,0,1-895.725,44.187Z"
                      transform="translate(-31.318 0)"
                      fill="#fff"
                    />
                    <path
                      d="M-893.6,243.026a2.152,2.152,0,0,1-2.146,2.168,2.158,2.158,0,0,1-2.145-2.142,2.153,2.153,0,0,1,2.148-2.164A2.146,2.146,0,0,1-893.6,243.026Z"
                      transform="translate(-31.322 -189.231)"
                      fill="#fff"
                    />
                    <path
                      d="M-723.075,289.173h-5.879a1.616,1.616,0,0,1-.292-.009.487.487,0,0,1-.428-.452.471.471,0,0,1,.326-.528,1.1,1.1,0,0,1,.372-.051q5.923,0,11.846,0a1.089,1.089,0,0,1,.345.043.472.472,0,0,1,.344.543.484.484,0,0,1-.463.449,2.784,2.784,0,0,1-.292.005Z"
                      transform="translate(-189.686 -233.708)"
                      fill="#fff"
                    />
                    <path
                      d="M-723.093,88.278h-5.819a2.632,2.632,0,0,1-.321-.009.515.515,0,0,1-.468-.51.5.5,0,0,1,.439-.5,1.942,1.942,0,0,1,.32-.016h11.726c.508,0,.761.173.762.519s-.255.519-.762.519Z"
                      transform="translate(-189.665 -44.575)"
                      fill="#fff"
                    />
                    <path
                      d="M-725.388,141.79q-1.783,0-3.566,0a1.162,1.162,0,0,1-.347-.033.476.476,0,0,1-.363-.531.476.476,0,0,1,.448-.461,2.3,2.3,0,0,1,.292-.008h7.1a2.756,2.756,0,0,1,.292.008.485.485,0,0,1,.45.46.5.5,0,0,1-.366.532,1.372,1.372,0,0,1-.347.033Q-723.591,141.792-725.388,141.79Z"
                      transform="translate(-189.696 -94.959)"
                      fill="#fff"
                    />
                    <path
                      d="M-725.4,341.718q1.856,0,3.712,0c.236,0,.454.073.507.306a.768.768,0,0,1-.079.533.654.654,0,0,1-.429.189,4.824,4.824,0,0,1-.671.009h-6.519c-.107,0-.215,0-.321-.007a.521.521,0,0,1-.511-.53.512.512,0,0,1,.539-.5c.507-.008,1.013,0,1.52,0Z"
                      transform="translate(-189.654 -284.158)"
                      fill="#fff"
                    />
                  </g>
                </svg>

                <span>User List</span>
              </NavLink>
            </li>
            <li className="min-h-42">
              <NavLink
                to="/admin/spaces"
                className="leading-4 whitespace-nowrap overflow-hidden px-3 py-3 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
              >
                <svg
                  opacity="0.5"
                  className="mx-2"
                  width="18"
                  height="18"
                  viewBox="0 0 17.154 12.988"
                >
                  <g transform="translate(-600.459 166.418)">
                    <path
                      id="Path_2265"
                      data-name="Path 2265"
                      d="M642.545-81.99H636.03c-.35,0-.439-.089-.439-.438,0-.467,0-.935,0-1.4a.328.328,0,0,1,.368-.376h13.066a1.3,1.3,0,0,1,.19.006.3.3,0,0,1,.282.317q.006.788,0,1.576a.3.3,0,0,1-.315.317c-.161.006-.323,0-.484,0Z"
                      transform="translate(-33.509 -78.413)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2266"
                      data-name="Path 2266"
                      d="M658.4,68.813c.141-.511.278-1,.415-1.5.051-.183.113-.364.152-.549.027-.129.086-.16.211-.16q2.99.006,5.98,0c1.417,0,2.834,0,4.252,0a.2.2,0,0,1,.232.179c.167.629.347,1.255.522,1.882.012.044.02.089.032.148Z"
                      transform="translate(-55.261 -222.261)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2267"
                      data-name="Path 2267"
                      d="M680.36-22.378v3.455h-4.784l.3-1.065q.313-1.123.624-2.247c.027-.1.052-.158.177-.157,1.187.005,2.373,0,3.56,0C680.272-22.389,680.311-22.382,680.36-22.378Z"
                      transform="translate(-71.648 -137.373)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2268"
                      data-name="Path 2268"
                      d="M793.169-18.919v-3.459c.061,0,.117-.01.172-.01,1.158,0,2.315,0,3.473,0,.131,0,.187.033.223.167.285,1.05.581,2.1.873,3.147.014.049.024.1.038.159Z"
                      transform="translate(-183.809 -137.375)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2269"
                      data-name="Path 2269"
                      d="M604.235-22.321c-.228.822-.448,1.616-.668,2.409-.346,1.243-.7,2.485-1.035,3.729a.218.218,0,0,1-.258.194c-.478-.012-.956,0-1.434-.005-.322,0-.451-.186-.341-.489q1.033-2.851,2.072-5.7c.02-.054.08-.132.122-.133C603.2-22.325,603.7-22.321,604.235-22.321Z"
                      transform="translate(0 -137.441)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2270"
                      data-name="Path 2270"
                      d="M890.4-22.322c.521,0,1.027,0,1.533.007.044,0,.1.09.126.15q.813,2.223,1.619,4.448c.147.406.3.812.443,1.218.116.323-.011.5-.356.5-.49,0-.979,0-1.469,0-.1,0-.153-.015-.184-.127q-.848-3.064-1.7-6.126A.586.586,0,0,1,890.4-22.322Z"
                      transform="translate(-276.552 -137.441)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2271"
                      data-name="Path 2271"
                      d="M730.948-165.476c.081-.293.153-.577.243-.855.014-.043.114-.08.174-.081.576-.006,1.151,0,1.727,0,.84,0,1.681,0,2.521,0,.132,0,.194.033.225.167.059.257.137.51.21.775Z"
                      transform="translate(-124.462)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2272"
                      data-name="Path 2272"
                      d="M796.07-130.362h-2.846v-1.568h2.406Z"
                      transform="translate(-183.862 -32.896)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2273"
                      data-name="Path 2273"
                      d="M720.477-132.209v1.573h-2.843l.146-.527c.085-.305.166-.612.258-.915.016-.051.079-.125.121-.126C718.924-132.211,719.69-132.209,720.477-132.209Z"
                      transform="translate(-111.763 -32.629)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2274"
                      data-name="Path 2274"
                      d="M677.154-166.324l-.294,1.054c-.179.644-.361,1.288-.535,1.933-.03.111-.069.16-.2.157-.38-.01-.759,0-1.157,0,.024-.07.039-.124.06-.176q.532-1.351,1.066-2.7c.094-.239.16-.285.411-.286.2,0,.392,0,.588,0A.281.281,0,0,1,677.154-166.324Z"
                      transform="translate(-71.071 -0.068)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2275"
                      data-name="Path 2275"
                      d="M850.435-166.287c.26,0,.519-.015.774.009a.335.335,0,0,1,.224.183c.391.965.769,1.935,1.15,2.9a.212.212,0,0,1,0,.062c-.4,0-.805,0-1.207,0a.141.141,0,0,1-.1-.082q-.424-1.5-.838-3.009A.287.287,0,0,1,850.435-166.287Z"
                      transform="translate(-238.43 -0.12)"
                      fill="#fff"
                    />
                  </g>
                </svg>
                <span>Manage Spaces</span>
              </NavLink>
            </li>
            <li className="min-h-42">
              <NavLink
                to="/admin/hours"
                className="leading-4 whitespace-nowrap overflow-hidden px-3 py-3 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
              >
                <svg
                  opacity="0.5"
                  className="mx-2"
                  width="18"
                  height="18"
                  viewBox="0 0 15.147 16.012"
                >
                  <path
                    d="M9.018,0H.6A.6.6,0,0,0,0,.6V1a.6.6,0,0,0,.6.6,4.971,4.971,0,0,0,3.027,4.81A4.971,4.971,0,0,0,.6,11.223a.6.6,0,0,0-.6.6v.4a.6.6,0,0,0,.6.6H9.018a.6.6,0,0,0,.6-.6v-.4a.6.6,0,0,0-.6-.6,4.971,4.971,0,0,0-3.027-4.81A4.971,4.971,0,0,0,9.018,1.6a.6.6,0,0,0,.6-.6V.6A.6.6,0,0,0,9.018,0Zm-1.6,11.223H2.2c0-1.94,1.157-3.607,2.605-3.607S7.415,9.282,7.415,11.223Z"
                    transform="translate(0 5.379) rotate(-34)"
                    fill="#fff"
                  />
                </svg>
                <span>Hours of availability</span>
              </NavLink>
            </li>
            {/* <li className="min-h-42">
              <NavLink
                to="/admin/Booking8"
                className="leading-4 whitespace-nowrap overflow-hidden px-3 py-3 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
              >
                <svg
                  opacity="0.5"
                  className="mx-2"
                  width="18"
                  height="18"
                  viewBox="0 0 16.837 16.841"
                >
                  <g transform="translate(-1184.523 -81.41)">
                    <path
                      id="Path_2220"
                      data-name="Path 2220"
                      d="M1200.554,91.433a4.324,4.324,0,0,0-5.416.311,4.226,4.226,0,0,0-1.374,2.729,4.367,4.367,0,0,0,.8,2.977c-.161.021-.276.045-.391.049a28.68,28.68,0,0,1-5.564-.249,4.459,4.459,0,0,1-3.835-3.983,28.363,28.363,0,0,1,.061-7.967,4.5,4.5,0,0,1,3.526-3.564,27.639,27.639,0,0,1,8.352-.012,4.5,4.5,0,0,1,3.634,3.66,30.412,30.412,0,0,1,.267,5.529c0,.118-.014.236-.026.354A1.647,1.647,0,0,1,1200.554,91.433Zm-7.993-4.256h3.729c.106,0,.214,0,.319-.006a.93.93,0,0,0-.007-1.858c-.082-.008-.166-.006-.248-.006q-3.764,0-7.528,0a1.979,1.979,0,0,0-.492.051.922.922,0,0,0,.007,1.769,2.061,2.061,0,0,0,.492.048C1190.075,87.179,1191.318,87.177,1192.561,87.177Zm-1.607,3.885c.745,0,1.491,0,2.236,0a.935.935,0,0,0,1.02-.938.951.951,0,0,0-1.014-.918q-2.254-.008-4.507,0a.928.928,0,0,0-1.012.947.917.917,0,0,0,1.006.909C1189.44,91.066,1190.2,91.062,1190.954,91.062Z"
                      transform="translate(0 0)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2221"
                      data-name="Path 2221"
                      d="M1333.732,227.341a3.352,3.352,0,0,1-6.7-.054,3.352,3.352,0,1,1,6.7.054Zm-2.727.841c0-.283.009-.567,0-.85a.6.6,0,0,0-.6-.616.581.581,0,0,0-.631.578c-.021.577-.022,1.157,0,1.734a.579.579,0,0,0,.643.563A.6.6,0,0,0,1331,229C1331.016,228.726,1331.005,228.454,1331.006,228.182Zm0-2.52c0-.511-.116-.632-.613-.635-.517,0-.651.13-.647.644,0,.49.14.621.638.619S1331.005,226.165,1331.005,225.662Z"
                      transform="translate(-132.372 -132.412)"
                      fill="#fff"
                    />
                  </g>
                </svg>
                <span>Booking Conditions</span>
              </NavLink>
            </li> */}
            {/* <li className="min-h-42">
              <NavLink
                to="/admin/Booking9"
                className="leading-4 whitespace-nowrap overflow-hidden px-3 py-3 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
              >
                <svg
                  opacity="0.5"
                  className="mx-2"
                  width="18"
                  height="18"
                  viewBox="0 0 16.837 15.634"
                >
                  <g transform="translate(-2.249 -3.375)">
                    <path
                      id="Path_2218"
                      data-name="Path 2218"
                      d="M24.113,7.523v5.3l-8,7.655a1.713,1.713,0,0,0,1.082.462,1.872,1.872,0,0,0,1.18-.462l7.163-6.753V8.914Z"
                      transform="translate(-6.455 -1.931)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2219"
                      data-name="Path 2219"
                      d="M15.478,3.375H9.465l-6.753,7.05a1.538,1.538,0,0,0-.462,1.067,1.644,1.644,0,0,0,.462,1.2l4.653,4.645a1.725,1.725,0,0,0,1.082.47,1.876,1.876,0,0,0,1.176-.47l7.058-6.746V4.578Zm-1.154,3.86a1.2,1.2,0,1,1,.8-.8A1.17,1.17,0,0,1,14.325,7.235Z"
                      fill="#fff"
                    />
                  </g>
                </svg>
                <span>Pricing Conditions</span>
              </NavLink>
            </li> */}
            <li className="min-h-42">
              <NavLink
                to="/admin/membership"
                className="leading-4 whitespace-nowrap overflow-hidden px-3 py-3 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
              >
                <svg
                  opacity="0.5"
                  className="mx-2"
                  width="18"
                  height="18"
                  viewBox="0 0 16.837 18.103"
                >
                  <g transform="translate(-1329.246 -499.485)">
                    <path
                      id="Path_2222"
                      data-name="Path 2222"
                      d="M1412.427,499.485h4.369a.773.773,0,0,1,.869.865q0,2.493,0,4.986a.773.773,0,0,1-.858.858h-8.8a.779.779,0,0,1-.854-.847q0-2.513,0-5.026a.773.773,0,0,1,.843-.835Zm-2.06,4.59a1.652,1.652,0,0,1,.206.087c.214.125.424.256.636.385.116.07.255.168.361.044a.474.474,0,0,0,.053-.343,6.328,6.328,0,0,0-.171-.722.339.339,0,0,1,.124-.39,6.522,6.522,0,0,0,.553-.5.4.4,0,0,0,.113-.306c-.016-.071-.162-.144-.259-.16a6.343,6.343,0,0,0-.76-.07.346.346,0,0,1-.344-.252c-.095-.25-.189-.5-.308-.74-.043-.087-.156-.192-.237-.191a.356.356,0,0,0-.238.192,7.683,7.683,0,0,0-.32.779.29.29,0,0,1-.3.212c-.268.013-.536.032-.8.071-.091.014-.222.079-.245.15a.339.339,0,0,0,.112.264c.207.2.43.383.65.57a.293.293,0,0,1,.107.336c-.072.257-.124.52-.192.779-.033.125-.06.264.073.316a.454.454,0,0,0,.327-.035c.227-.114.438-.258.658-.387A2.141,2.141,0,0,1,1410.367,504.075Zm4.325.748c.429,0,.859,0,1.288,0,.222,0,.349-.121.35-.3s-.123-.3-.348-.3c-.141,0-.282,0-.423,0-.711,0-1.423,0-2.134,0-.247,0-.367.1-.368.3s.126.306.365.308C1413.846,504.827,1414.269,504.824,1414.692,504.823Zm0-3.359c.423,0,.846,0,1.268,0,.242,0,.371-.108.374-.3s-.133-.311-.384-.311q-1.248,0-2.5,0c-.256,0-.389.105-.392.3s.125.306.382.308C1413.856,501.466,1414.272,501.465,1414.688,501.464Zm-.008,1.68c.423,0,.846,0,1.268,0,.249,0,.386-.115.381-.312s-.135-.295-.376-.295q-1.258,0-2.517,0c-.255,0-.383.108-.379.31s.133.3.374.3C1413.848,503.146,1414.264,503.144,1414.68,503.144Z"
                      transform="translate(-74.769)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2223"
                      data-name="Path 2223"
                      d="M1463.068,841.28a13.73,13.73,0,0,1-2.759-.279.424.424,0,0,1-.4-.49c.071-.686.124-1.378.256-2.053a2.14,2.14,0,0,1,2.064-1.688c.5-.01.994,0,1.49,0a2.213,2.213,0,0,1,2.309,2.1c.053.527.119,1.052.181,1.578a.449.449,0,0,1-.389.555A13.407,13.407,0,0,1,1463.068,841.28Z"
                      transform="translate(-125.401 -323.692)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2224"
                      data-name="Path 2224"
                      d="M1335.048,812a2.9,2.9,0,0,0-1.014,1.932c-.053.452-.11.9-.166,1.356,0,.026-.01.052-.019.1-.209.018-.422.045-.634.054a14.4,14.4,0,0,1-3.484-.235c-.4-.08-.525-.26-.473-.666.074-.578.123-1.159.214-1.734a2.114,2.114,0,0,1,1.949-1.838,14.244,14.244,0,0,1,2.13.022A1.992,1.992,0,0,1,1335.048,812Z"
                      transform="translate(0 -298.909)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2225"
                      data-name="Path 2225"
                      d="M1602.806,811.476a2.022,2.022,0,0,1,1.558-1.016,12.666,12.666,0,0,1,2.172,0,2.107,2.107,0,0,1,1.838,1.793c.1.629.172,1.261.246,1.893a.423.423,0,0,1-.368.512,13.488,13.488,0,0,1-4.165.208.617.617,0,0,1-.076-.02c-.044-.345-.1-.695-.129-1.047A3.163,3.163,0,0,0,1602.806,811.476Z"
                      transform="translate(-262.54 -298.404)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2226"
                      data-name="Path 2226"
                      d="M1488.413,720.011a2.074,2.074,0,1,1-2.094,2.074A2.076,2.076,0,0,1,1488.413,720.011Z"
                      transform="translate(-150.746 -211.643)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2227"
                      data-name="Path 2227"
                      d="M1358.155,694.009a2.075,2.075,0,1,1-.015,4.149,2.075,2.075,0,1,1,.015-4.149Z"
                      transform="translate(-25.745 -186.688)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2228"
                      data-name="Path 2228"
                      d="M1616.694,696.083a2.078,2.078,0,0,1,4.156-.013,2.078,2.078,0,1,1-4.156.013Z"
                      transform="translate(-275.869 -186.689)"
                      fill="#fff"
                    />
                  </g>
                </svg>
                <span>Membership</span>
              </NavLink>
            </li>
            <li className="min-h-42">
              <NavLink
                to="/admin/coaching"
                className="leading-4 whitespace-nowrap overflow-hidden px-3 py-3 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  opacity="0.5"
                  className="mx-2"
                  width="18"
                  height="18"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  {" "}
                  <path
                    d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                    fill="#fff"
                  ></path>{" "}
                  <path
                    fillRule="evenodd"
                    d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                    fill="#fff"
                  ></path>{" "}
                  <path
                    d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                    fill="#fff"
                  ></path>{" "}
                </svg>
                <span>Coaching</span>
              </NavLink>
            </li>
            <li className="min-h-42">
              <NavLink
                to="/admin/dropins"
                className="leading-4 whitespace-nowrap overflow-hidden px-3 py-3 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-2"
                  width="18"
                  height="18"
                  viewBox="0 0 27.072 28.28"
                  opacity="0.5"
                  fill="currentColor"
                >
                  <g transform="translate(-686.031 -229.899)">
                    <path
                      id="Path_5092"
                      data-name="Path 5092"
                      d="M720.024,250.547a1.211,1.211,0,0,0-.094.21q-.534,2.287-1.062,4.575c-.144.624-.28,1.249-.436,1.87a1.238,1.238,0,0,1-1.288.974,1.315,1.315,0,0,1-1.207-1.15,1.472,1.472,0,0,1,.041-.48q.711-3.135,1.431-6.27a1.408,1.408,0,0,0,.033-.411q-.27-3.567-.55-7.133c-.025-.323-.062-.646-.066-.969,0-.2-.071-.284-.272-.314-.4-.059-.8-.145-1.207-.217a.8.8,0,0,1-.67-.6c-.456-1.522-.913-3.043-1.352-4.569a1.722,1.722,0,0,1,.031-.54,1.249,1.249,0,0,0,.013-.391c-.1-.364-.23-.719-.339-1.081-.036-.121-.083-.177-.223-.17a1.831,1.831,0,0,1-1.825-1.138,2.059,2.059,0,0,1,.171-2.233,1.57,1.57,0,0,1,1.808-.517,2.169,2.169,0,0,1,1.174,2.977,2.628,2.628,0,0,1-.6.655c-.11.1-.161.155-.111.3.131.377.251.758.373,1.134a.926.926,0,0,1,1.215.773c.358,1.228.715,2.458,1.083,3.683a.335.335,0,0,0,.208.2c.444.1.892.18,1.341.256a.47.47,0,0,0,.265-.041,4.549,4.549,0,0,1,4.108.053,12.324,12.324,0,0,1,1.358.79.992.992,0,0,1,.312.391c.531,1.062,1.057,2.128,1.565,3.2a.881.881,0,0,1,.058.536q-.421,1.8-.881,3.6a.861.861,0,0,1-1.033.636.878.878,0,0,1-.582-1.068c.254-1.017.518-2.032.775-3.049a.338.338,0,0,0,.016-.2,8,8,0,0,0-.541-1.029c-.014.128-.03.255-.04.383-.147,1.939-.3,3.877-.43,5.818a9.647,9.647,0,0,0,.082,1.361c.046.584.092,1.169.154,1.753a1.887,1.887,0,0,0,.143.529c.389.915.791,1.826,1.183,2.74a1.226,1.226,0,0,1-.191,1.326,1.178,1.178,0,0,1-1.2.435,1.217,1.217,0,0,1-.94-.745c-.46-1.061-.94-2.116-1.342-3.2a5.561,5.561,0,0,1-.191-1.39c-.071-.737-.125-1.475-.186-2.212Z"
                      transform="translate(-12.223)"
                    />
                    <path
                      id="Path_5093"
                      data-name="Path 5093"
                      d="M691.278,240.432c.187-.094.352-.175.514-.259a5.263,5.263,0,0,1,5.143.033,1.719,1.719,0,0,1,.452.352c.39.427.768.866,1.142,1.308a.256.256,0,0,0,.334.094c.433-.161.872-.308,1.312-.451a.815.815,0,0,1,.914.214.832.832,0,0,1,.221.854.774.774,0,0,1-.507.5c-.707.245-1.414.488-2.125.72a.8.8,0,0,1-.892-.285c-.22-.245-.43-.5-.678-.788-.009.114-.014.179-.019.243a13.257,13.257,0,0,0,.43,3.709c.29,1.452.568,2.907.857,4.359a.2.2,0,0,1-.12.257,7.727,7.727,0,0,1-7.721.053.313.313,0,0,1-.2-.4q.614-3.087,1.213-6.176a3.538,3.538,0,0,0,.065-.619c.009-.586,0-1.173,0-1.76,0-.082-.007-.164-.013-.283-.143.07-.251.124-.359.175a.874.874,0,0,1-1.333-.588c-.41-1.39-.812-2.783-1.223-4.173a.968.968,0,0,1,.17-.915.337.337,0,0,0,.053-.262c-.2-.647-.422-1.29-.632-1.935a.177.177,0,0,0-.222-.139,1.991,1.991,0,0,1-2.017-2.219,1.774,1.774,0,0,1,1.333-1.674,1.61,1.61,0,0,1,1.3.241,2.252,2.252,0,0,1,.811,2.7,1.591,1.591,0,0,1-.664.751c-.112.064-.152.119-.106.254.219.634.423,1.273.643,1.907a.284.284,0,0,0,.173.171.882.882,0,0,1,.786.712c.292,1,.576,2,.863,3C691.214,240.22,691.243,240.314,691.278,240.432Z"
                      transform="translate(0 -0.217)"
                    />
                    <path
                      id="Path_5094"
                      data-name="Path 5094"
                      d="M695.089,273.978a6.87,6.87,0,0,0,2.461.475c-.057.428-.15.891-.173,1.357a3.022,3.022,0,0,1-.676,1.707c-.473.628-.9,1.292-1.357,1.93a1.264,1.264,0,0,1-2.237-.427,1.307,1.307,0,0,1,.24-1.136c.434-.609.852-1.231,1.291-1.835a1.355,1.355,0,0,0,.25-.656C694.95,274.911,695.024,274.433,695.089,273.978Z"
                      transform="translate(-3.48 -21.813)"
                    />
                    <path
                      id="Path_5095"
                      data-name="Path 5095"
                      d="M703.682,274.279a6.284,6.284,0,0,0,2.458-.581c.016.036.055.116.085.2q.755,2.062,1.507,4.124a1.311,1.311,0,0,1-.424,1.554,1.267,1.267,0,0,1-1.9-.536C704.823,277.451,704.252,275.855,703.682,274.279Z"
                      transform="translate(-8.735 -21.675)"
                    />
                    <path
                      id="Path_5096"
                      data-name="Path 5096"
                      d="M729.154,241.548a2.116,2.116,0,1,1-2.091-2.149A2.12,2.12,0,0,1,729.154,241.548Z"
                      transform="translate(-19.246 -4.701)"
                    />
                    <path
                      id="Path_5097"
                      data-name="Path 5097"
                      d="M702.547,241.531a2.129,2.129,0,0,1-2.13,2.111,2.155,2.155,0,0,1-2.1-2.127,2.115,2.115,0,1,1,4.23.016Z"
                      transform="translate(-6.08 -4.701)"
                    />
                    <path
                      id="Path_5098"
                      data-name="Path 5098"
                      d="M699.355,230.513l1.095,2.368c-.373.055-.712.108-1.052.155-.389.054-.778.1-1.168.154a.584.584,0,0,1-.666-.337.62.62,0,0,1,.125-.818q.728-.676,1.461-1.348C699.2,230.636,699.264,230.589,699.355,230.513Z"
                      transform="translate(-5.659 -0.304)"
                    />
                  </g>
                </svg>
                <span>Drop-Ins</span>
              </NavLink>
            </li>
            <li className="min-h-42">
              <NavLink
                to="/admin/bookings"
                className="leading-4 whitespace-nowrap overflow-hidden px-3 py-3 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
              >
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-2"
                  width="18"
                  height="18"
                  viewBox="0 0 27.072 28.28"
                  opacity="0.5"
                  fill="currentColor"
                >
                  <g transform="translate(-686.031 -229.899)">
                    <path
                      id="Path_5092"
                      data-name="Path 5092"
                      d="M720.024,250.547a1.211,1.211,0,0,0-.094.21q-.534,2.287-1.062,4.575c-.144.624-.28,1.249-.436,1.87a1.238,1.238,0,0,1-1.288.974,1.315,1.315,0,0,1-1.207-1.15,1.472,1.472,0,0,1,.041-.48q.711-3.135,1.431-6.27a1.408,1.408,0,0,0,.033-.411q-.27-3.567-.55-7.133c-.025-.323-.062-.646-.066-.969,0-.2-.071-.284-.272-.314-.4-.059-.8-.145-1.207-.217a.8.8,0,0,1-.67-.6c-.456-1.522-.913-3.043-1.352-4.569a1.722,1.722,0,0,1,.031-.54,1.249,1.249,0,0,0,.013-.391c-.1-.364-.23-.719-.339-1.081-.036-.121-.083-.177-.223-.17a1.831,1.831,0,0,1-1.825-1.138,2.059,2.059,0,0,1,.171-2.233,1.57,1.57,0,0,1,1.808-.517,2.169,2.169,0,0,1,1.174,2.977,2.628,2.628,0,0,1-.6.655c-.11.1-.161.155-.111.3.131.377.251.758.373,1.134a.926.926,0,0,1,1.215.773c.358,1.228.715,2.458,1.083,3.683a.335.335,0,0,0,.208.2c.444.1.892.18,1.341.256a.47.47,0,0,0,.265-.041,4.549,4.549,0,0,1,4.108.053,12.324,12.324,0,0,1,1.358.79.992.992,0,0,1,.312.391c.531,1.062,1.057,2.128,1.565,3.2a.881.881,0,0,1,.058.536q-.421,1.8-.881,3.6a.861.861,0,0,1-1.033.636.878.878,0,0,1-.582-1.068c.254-1.017.518-2.032.775-3.049a.338.338,0,0,0,.016-.2,8,8,0,0,0-.541-1.029c-.014.128-.03.255-.04.383-.147,1.939-.3,3.877-.43,5.818a9.647,9.647,0,0,0,.082,1.361c.046.584.092,1.169.154,1.753a1.887,1.887,0,0,0,.143.529c.389.915.791,1.826,1.183,2.74a1.226,1.226,0,0,1-.191,1.326,1.178,1.178,0,0,1-1.2.435,1.217,1.217,0,0,1-.94-.745c-.46-1.061-.94-2.116-1.342-3.2a5.561,5.561,0,0,1-.191-1.39c-.071-.737-.125-1.475-.186-2.212Z"
                      transform="translate(-12.223)"
                    />
                    <path
                      id="Path_5093"
                      data-name="Path 5093"
                      d="M691.278,240.432c.187-.094.352-.175.514-.259a5.263,5.263,0,0,1,5.143.033,1.719,1.719,0,0,1,.452.352c.39.427.768.866,1.142,1.308a.256.256,0,0,0,.334.094c.433-.161.872-.308,1.312-.451a.815.815,0,0,1,.914.214.832.832,0,0,1,.221.854.774.774,0,0,1-.507.5c-.707.245-1.414.488-2.125.72a.8.8,0,0,1-.892-.285c-.22-.245-.43-.5-.678-.788-.009.114-.014.179-.019.243a13.257,13.257,0,0,0,.43,3.709c.29,1.452.568,2.907.857,4.359a.2.2,0,0,1-.12.257,7.727,7.727,0,0,1-7.721.053.313.313,0,0,1-.2-.4q.614-3.087,1.213-6.176a3.538,3.538,0,0,0,.065-.619c.009-.586,0-1.173,0-1.76,0-.082-.007-.164-.013-.283-.143.07-.251.124-.359.175a.874.874,0,0,1-1.333-.588c-.41-1.39-.812-2.783-1.223-4.173a.968.968,0,0,1,.17-.915.337.337,0,0,0,.053-.262c-.2-.647-.422-1.29-.632-1.935a.177.177,0,0,0-.222-.139,1.991,1.991,0,0,1-2.017-2.219,1.774,1.774,0,0,1,1.333-1.674,1.61,1.61,0,0,1,1.3.241,2.252,2.252,0,0,1,.811,2.7,1.591,1.591,0,0,1-.664.751c-.112.064-.152.119-.106.254.219.634.423,1.273.643,1.907a.284.284,0,0,0,.173.171.882.882,0,0,1,.786.712c.292,1,.576,2,.863,3C691.214,240.22,691.243,240.314,691.278,240.432Z"
                      transform="translate(0 -0.217)"
                    />
                    <path
                      id="Path_5094"
                      data-name="Path 5094"
                      d="M695.089,273.978a6.87,6.87,0,0,0,2.461.475c-.057.428-.15.891-.173,1.357a3.022,3.022,0,0,1-.676,1.707c-.473.628-.9,1.292-1.357,1.93a1.264,1.264,0,0,1-2.237-.427,1.307,1.307,0,0,1,.24-1.136c.434-.609.852-1.231,1.291-1.835a1.355,1.355,0,0,0,.25-.656C694.95,274.911,695.024,274.433,695.089,273.978Z"
                      transform="translate(-3.48 -21.813)"
                    />
                    <path
                      id="Path_5095"
                      data-name="Path 5095"
                      d="M703.682,274.279a6.284,6.284,0,0,0,2.458-.581c.016.036.055.116.085.2q.755,2.062,1.507,4.124a1.311,1.311,0,0,1-.424,1.554,1.267,1.267,0,0,1-1.9-.536C704.823,277.451,704.252,275.855,703.682,274.279Z"
                      transform="translate(-8.735 -21.675)"
                    />
                    <path
                      id="Path_5096"
                      data-name="Path 5096"
                      d="M729.154,241.548a2.116,2.116,0,1,1-2.091-2.149A2.12,2.12,0,0,1,729.154,241.548Z"
                      transform="translate(-19.246 -4.701)"
                    />
                    <path
                      id="Path_5097"
                      data-name="Path 5097"
                      d="M702.547,241.531a2.129,2.129,0,0,1-2.13,2.111,2.155,2.155,0,0,1-2.1-2.127,2.115,2.115,0,1,1,4.23.016Z"
                      transform="translate(-6.08 -4.701)"
                    />
                    <path
                      id="Path_5098"
                      data-name="Path 5098"
                      d="M699.355,230.513l1.095,2.368c-.373.055-.712.108-1.052.155-.389.054-.778.1-1.168.154a.584.584,0,0,1-.666-.337.62.62,0,0,1,.125-.818q.728-.676,1.461-1.348C699.2,230.636,699.264,230.589,699.355,230.513Z"
                      transform="translate(-5.659 -0.304)"
                    />
                  </g>
                </svg> */}
                <FiList className="mx-2" />
                <span>Bookings</span>
              </NavLink>
            </li>
          </ul>
        </nav>

        <div
          id="content"
          className={`min-h-screen relative w-full ${
            "" // navbar ? "Fullview" : "Halfview"
          }`}
        >
          <div className="pl-3 lg:pt-4 lg:pb-4 py-2.5 mb-3 relative z-50">
            <div className="container-fluid">
              <div className="flex flex-wrap items-center relative mb-3 lg:mb-2">
                <div className="w-1/2 flex items-center">
                  <button
                    className="py-2 rounded-md outline-none mr-2 text-bgdark hover:text-theme focus:text-theme"
                    onClick={() => setNavbar(!navbar)}
                  >
                    <svg width="27" height="19" viewBox="0 0 27 19">
                      <g transform="translate(-84 -23)">
                        <line
                          id="Line_2"
                          data-name="Line 2"
                          x2="17"
                          transform="translate(85.5 24.5)"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeWidth="3"
                        />
                        <line
                          id="Line_3"
                          data-name="Line 3"
                          x2="24"
                          transform="translate(85.5 32.5)"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeWidth="3"
                        />
                        <line
                          id="Line_4"
                          data-name="Line 4"
                          x2="20"
                          transform="translate(85.5 40.5)"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeWidth="3"
                        />
                      </g>
                    </svg>
                  </button>
                  <img src={LogoIco} className="h-12 md:hidden" />
                  <div className="title-set md:block hidden">
                    <h3 className="text-sec">
                      <span className="font-bold">Welcome</span> To Dashboard
                    </h3>
                  </div>
                </div>
                <div className="w-1/2">
                  <ul className="mb-0 flex justify-end items-center w-full">
                    <li>
                      <a
                        href="#"
                        className="h-11 w-11 rounded-full inline-flex items-center justify-center text-theme bg-lgtheme hover:bg-theme hover:text-white focus:bg-theme focus:text-white"
                      >
                        <svg width="13.949" height="17" viewBox="0 0 13.949 17">
                          <path
                            d="M12.974,20.75a1.749,1.749,0,0,0,1.744-1.744H11.231A1.749,1.749,0,0,0,12.974,20.75Zm5.231-5.231V11.16c0-2.676-1.421-4.917-3.923-5.51V5.058a1.308,1.308,0,1,0-2.615,0v.593c-2.493.593-3.923,2.825-3.923,5.51v4.359L6,17.263v.872H19.949v-.872Zm-1.744.872H9.487V11.16C9.487,9,10.8,7.237,12.974,7.237S16.462,9,16.462,11.16Z"
                            transform="translate(-6 -3.75)"
                            fill="currentColor"
                          />
                        </svg>
                      </a>
                    </li>
                    <li className="ml-3">
                      <button
                        onClick={() => setProfileDrop(!ProfileDrop)}
                        className="bg-sec pl-1 pr-2 rounded-tl-3xl rounded-bl-3xl  h-12 text-white font-medium inline-flex items-center justify-center hover:bg-theme focus:bg-theme"
                      >
                        <img
                          src={Prof}
                          alt=""
                          className="rounded-full h-10 w-10 object-cover mr-2 border border-white shadow"
                        />
                        <span className="truncate text-sm pr-2">
                          {user.fullName}
                        </span>
                        <svg viewBox="0 0 9 6" width="9" height="6">
                          <path d="m4.5 6l-4.5-6h9z" fill="#fff" />
                        </svg>
                      </button>
                      <div
                        className={`bg-white rounded-md w-40 absolute z-10 shadow-xl mt-2 right-3 overflow-hidden ${
                          ProfileDrop ? "block" : "hidden"
                        }`}
                      >
                        <ul>
                          <li>
                            <button
                              onClick={logout}
                              className="leading-4 px-4 py-3 flex items-center w-full text-red-500 hover:bg-red-100 focus:bg-red-100 focus:text-white"
                            >
                              <svg
                                className="mr-1.5"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M13 12H22M22 12L18.6667 8M22 12L18.6667 16"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M14 7V5.1736C14 4.00352 12.9999 3.08334 11.8339 3.18051L3.83391 3.84717C2.79732 3.93356 2 4.80009 2 5.84027V18.1597C2 19.1999 2.79733 20.0664 3.83391 20.1528L11.8339 20.8195C12.9999 20.9167 14 19.9965 14 18.8264V17"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              Logout
                            </button>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {children}
          <div></div>
        </div>
      </div>
    </>
  );
}

// const menuItems = {
//   "/user/dashboard": {
//     icon: (
//       <svg
//         className="inline-flex md:mr-3 mr-1"
//         width="18"
//         height="18"
//         viewBox="0 0 15.634 15.634"
//       >
//         <path
//           d="M4.5,13.186h6.948V4.5H4.5Zm0,6.948h6.948V14.923H4.5Zm8.686,0h6.948V11.448H13.186Zm0-15.634V9.711h6.948V4.5Z"
//           transform="translate(-4.5 -4.5)"
//           fill="#ff6a00"
//         />
//       </svg>
//     ),
//     title: "Welcome to Dashboard",
//   },
//   "/user/court-rental": {
//     icon: (
//       <svg
//         className="inline-flex mr-3"
//         width="18"
//         height="18"
//         viewBox="0 0 17.154 12.988"
//       >
//         <g transform="translate(-600.459 166.418)">
//           <path
//             id="Path_2265"
//             data-name="Path 2265"
//             d="M642.545-81.99H636.03c-.35,0-.439-.089-.439-.438,0-.467,0-.935,0-1.4a.328.328,0,0,1,.368-.376h13.066a1.3,1.3,0,0,1,.19.006.3.3,0,0,1,.282.317q.006.788,0,1.576a.3.3,0,0,1-.315.317c-.161.006-.323,0-.484,0Z"
//             transform="translate(-33.509 -78.413)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_2266"
//             data-name="Path 2266"
//             d="M658.4,68.813c.141-.511.278-1,.415-1.5.051-.183.113-.364.152-.549.027-.129.086-.16.211-.16q2.99.006,5.98,0c1.417,0,2.834,0,4.252,0a.2.2,0,0,1,.232.179c.167.629.347,1.255.522,1.882.012.044.02.089.032.148Z"
//             transform="translate(-55.261 -222.261)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_2267"
//             data-name="Path 2267"
//             d="M680.36-22.378v3.455h-4.784l.3-1.065q.313-1.123.624-2.247c.027-.1.052-.158.177-.157,1.187.005,2.373,0,3.56,0C680.272-22.389,680.311-22.382,680.36-22.378Z"
//             transform="translate(-71.648 -137.373)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_2268"
//             data-name="Path 2268"
//             d="M793.169-18.919v-3.459c.061,0,.117-.01.172-.01,1.158,0,2.315,0,3.473,0,.131,0,.187.033.223.167.285,1.05.581,2.1.873,3.147.014.049.024.1.038.159Z"
//             transform="translate(-183.809 -137.375)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_2269"
//             data-name="Path 2269"
//             d="M604.235-22.321c-.228.822-.448,1.616-.668,2.409-.346,1.243-.7,2.485-1.035,3.729a.218.218,0,0,1-.258.194c-.478-.012-.956,0-1.434-.005-.322,0-.451-.186-.341-.489q1.033-2.851,2.072-5.7c.02-.054.08-.132.122-.133C603.2-22.325,603.7-22.321,604.235-22.321Z"
//             transform="translate(0 -137.441)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_2270"
//             data-name="Path 2270"
//             d="M890.4-22.322c.521,0,1.027,0,1.533.007.044,0,.1.09.126.15q.813,2.223,1.619,4.448c.147.406.3.812.443,1.218.116.323-.011.5-.356.5-.49,0-.979,0-1.469,0-.1,0-.153-.015-.184-.127q-.848-3.064-1.7-6.126A.586.586,0,0,1,890.4-22.322Z"
//             transform="translate(-276.552 -137.441)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_2271"
//             data-name="Path 2271"
//             d="M730.948-165.476c.081-.293.153-.577.243-.855.014-.043.114-.08.174-.081.576-.006,1.151,0,1.727,0,.84,0,1.681,0,2.521,0,.132,0,.194.033.225.167.059.257.137.51.21.775Z"
//             transform="translate(-124.462)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_2272"
//             data-name="Path 2272"
//             d="M796.07-130.362h-2.846v-1.568h2.406Z"
//             transform="translate(-183.862 -32.896)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_2273"
//             data-name="Path 2273"
//             d="M720.477-132.209v1.573h-2.843l.146-.527c.085-.305.166-.612.258-.915.016-.051.079-.125.121-.126C718.924-132.211,719.69-132.209,720.477-132.209Z"
//             transform="translate(-111.763 -32.629)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_2274"
//             data-name="Path 2274"
//             d="M677.154-166.324l-.294,1.054c-.179.644-.361,1.288-.535,1.933-.03.111-.069.16-.2.157-.38-.01-.759,0-1.157,0,.024-.07.039-.124.06-.176q.532-1.351,1.066-2.7c.094-.239.16-.285.411-.286.2,0,.392,0,.588,0A.281.281,0,0,1,677.154-166.324Z"
//             transform="translate(-71.071 -0.068)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_2275"
//             data-name="Path 2275"
//             d="M850.435-166.287c.26,0,.519-.015.774.009a.335.335,0,0,1,.224.183c.391.965.769,1.935,1.15,2.9a.212.212,0,0,1,0,.062c-.4,0-.805,0-1.207,0a.141.141,0,0,1-.1-.082q-.424-1.5-.838-3.009A.287.287,0,0,1,850.435-166.287Z"
//             transform="translate(-238.43 -0.12)"
//             fill="#ff6a00"
//           />
//         </g>
//       </svg>
//     ),
//     title: "Court Rental",
//   },
//   "/user/drop-in": {
//     icon: (
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         className="inline-flex mr-3"
//         width="18"
//         height="18"
//         viewBox="0 0 27.072 28.28"
//       >
//         <g transform="translate(-686.031 -229.899)">
//           <path
//             id="Path_5092"
//             data-name="Path 5092"
//             d="M720.024,250.547a1.211,1.211,0,0,0-.094.21q-.534,2.287-1.062,4.575c-.144.624-.28,1.249-.436,1.87a1.238,1.238,0,0,1-1.288.974,1.315,1.315,0,0,1-1.207-1.15,1.472,1.472,0,0,1,.041-.48q.711-3.135,1.431-6.27a1.408,1.408,0,0,0,.033-.411q-.27-3.567-.55-7.133c-.025-.323-.062-.646-.066-.969,0-.2-.071-.284-.272-.314-.4-.059-.8-.145-1.207-.217a.8.8,0,0,1-.67-.6c-.456-1.522-.913-3.043-1.352-4.569a1.722,1.722,0,0,1,.031-.54,1.249,1.249,0,0,0,.013-.391c-.1-.364-.23-.719-.339-1.081-.036-.121-.083-.177-.223-.17a1.831,1.831,0,0,1-1.825-1.138,2.059,2.059,0,0,1,.171-2.233,1.57,1.57,0,0,1,1.808-.517,2.169,2.169,0,0,1,1.174,2.977,2.628,2.628,0,0,1-.6.655c-.11.1-.161.155-.111.3.131.377.251.758.373,1.134a.926.926,0,0,1,1.215.773c.358,1.228.715,2.458,1.083,3.683a.335.335,0,0,0,.208.2c.444.1.892.18,1.341.256a.47.47,0,0,0,.265-.041,4.549,4.549,0,0,1,4.108.053,12.324,12.324,0,0,1,1.358.79.992.992,0,0,1,.312.391c.531,1.062,1.057,2.128,1.565,3.2a.881.881,0,0,1,.058.536q-.421,1.8-.881,3.6a.861.861,0,0,1-1.033.636.878.878,0,0,1-.582-1.068c.254-1.017.518-2.032.775-3.049a.338.338,0,0,0,.016-.2,8,8,0,0,0-.541-1.029c-.014.128-.03.255-.04.383-.147,1.939-.3,3.877-.43,5.818a9.647,9.647,0,0,0,.082,1.361c.046.584.092,1.169.154,1.753a1.887,1.887,0,0,0,.143.529c.389.915.791,1.826,1.183,2.74a1.226,1.226,0,0,1-.191,1.326,1.178,1.178,0,0,1-1.2.435,1.217,1.217,0,0,1-.94-.745c-.46-1.061-.94-2.116-1.342-3.2a5.561,5.561,0,0,1-.191-1.39c-.071-.737-.125-1.475-.186-2.212Z"
//             transform="translate(-12.223)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_5093"
//             data-name="Path 5093"
//             d="M691.278,240.432c.187-.094.352-.175.514-.259a5.263,5.263,0,0,1,5.143.033,1.719,1.719,0,0,1,.452.352c.39.427.768.866,1.142,1.308a.256.256,0,0,0,.334.094c.433-.161.872-.308,1.312-.451a.815.815,0,0,1,.914.214.832.832,0,0,1,.221.854.774.774,0,0,1-.507.5c-.707.245-1.414.488-2.125.72a.8.8,0,0,1-.892-.285c-.22-.245-.43-.5-.678-.788-.009.114-.014.179-.019.243a13.257,13.257,0,0,0,.43,3.709c.29,1.452.568,2.907.857,4.359a.2.2,0,0,1-.12.257,7.727,7.727,0,0,1-7.721.053.313.313,0,0,1-.2-.4q.614-3.087,1.213-6.176a3.538,3.538,0,0,0,.065-.619c.009-.586,0-1.173,0-1.76,0-.082-.007-.164-.013-.283-.143.07-.251.124-.359.175a.874.874,0,0,1-1.333-.588c-.41-1.39-.812-2.783-1.223-4.173a.968.968,0,0,1,.17-.915.337.337,0,0,0,.053-.262c-.2-.647-.422-1.29-.632-1.935a.177.177,0,0,0-.222-.139,1.991,1.991,0,0,1-2.017-2.219,1.774,1.774,0,0,1,1.333-1.674,1.61,1.61,0,0,1,1.3.241,2.252,2.252,0,0,1,.811,2.7,1.591,1.591,0,0,1-.664.751c-.112.064-.152.119-.106.254.219.634.423,1.273.643,1.907a.284.284,0,0,0,.173.171.882.882,0,0,1,.786.712c.292,1,.576,2,.863,3C691.214,240.22,691.243,240.314,691.278,240.432Z"
//             transform="translate(0 -0.217)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_5094"
//             data-name="Path 5094"
//             d="M695.089,273.978a6.87,6.87,0,0,0,2.461.475c-.057.428-.15.891-.173,1.357a3.022,3.022,0,0,1-.676,1.707c-.473.628-.9,1.292-1.357,1.93a1.264,1.264,0,0,1-2.237-.427,1.307,1.307,0,0,1,.24-1.136c.434-.609.852-1.231,1.291-1.835a1.355,1.355,0,0,0,.25-.656C694.95,274.911,695.024,274.433,695.089,273.978Z"
//             transform="translate(-3.48 -21.813)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_5095"
//             data-name="Path 5095"
//             d="M703.682,274.279a6.284,6.284,0,0,0,2.458-.581c.016.036.055.116.085.2q.755,2.062,1.507,4.124a1.311,1.311,0,0,1-.424,1.554,1.267,1.267,0,0,1-1.9-.536C704.823,277.451,704.252,275.855,703.682,274.279Z"
//             transform="translate(-8.735 -21.675)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_5096"
//             data-name="Path 5096"
//             d="M729.154,241.548a2.116,2.116,0,1,1-2.091-2.149A2.12,2.12,0,0,1,729.154,241.548Z"
//             transform="translate(-19.246 -4.701)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_5097"
//             data-name="Path 5097"
//             d="M702.547,241.531a2.129,2.129,0,0,1-2.13,2.111,2.155,2.155,0,0,1-2.1-2.127,2.115,2.115,0,1,1,4.23.016Z"
//             transform="translate(-6.08 -4.701)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_5098"
//             data-name="Path 5098"
//             d="M699.355,230.513l1.095,2.368c-.373.055-.712.108-1.052.155-.389.054-.778.1-1.168.154a.584.584,0,0,1-.666-.337.62.62,0,0,1,.125-.818q.728-.676,1.461-1.348C699.2,230.636,699.264,230.589,699.355,230.513Z"
//             transform="translate(-5.659 -0.304)"
//             fill="#ff6a00"
//           />
//         </g>
//       </svg>
//     ),
//     title: "Drop-In",
//   },
//   "/user/membership": {
//     icon: (
//       <svg
//         className="inline-flex md:mr-3 mr-1"
//         xmlns="http://www.w3.org/2000/svg"
//         width="16.838"
//         height="18.103"
//         viewBox="0 0 16.838 18.103"
//       >
//         <g
//           id="Group_3567"
//           data-name="Group 3567"
//           transform="translate(-4672 758.552)"
//         >
//           <path
//             id="Path_2222"
//             data-name="Path 2222"
//             d="M1412.427,499.485h4.369a.773.773,0,0,1,.869.865q0,2.493,0,4.986a.773.773,0,0,1-.858.858h-8.8a.779.779,0,0,1-.854-.847q0-2.513,0-5.026a.773.773,0,0,1,.843-.835Zm-2.06,4.59a1.652,1.652,0,0,1,.206.087c.214.125.424.256.636.385.116.07.255.168.361.044a.474.474,0,0,0,.053-.343,6.328,6.328,0,0,0-.171-.722.339.339,0,0,1,.124-.39,6.522,6.522,0,0,0,.553-.5.4.4,0,0,0,.113-.306c-.016-.071-.162-.144-.259-.16a6.343,6.343,0,0,0-.76-.07.346.346,0,0,1-.344-.252c-.095-.25-.189-.5-.308-.74-.043-.087-.156-.192-.237-.191a.356.356,0,0,0-.238.192,7.683,7.683,0,0,0-.32.779.29.29,0,0,1-.3.212c-.268.013-.536.032-.8.071-.091.014-.222.079-.245.15a.339.339,0,0,0,.112.264c.207.2.43.383.65.57a.293.293,0,0,1,.107.336c-.072.257-.124.52-.192.779-.033.125-.06.264.073.316a.454.454,0,0,0,.327-.035c.227-.114.438-.258.658-.387A2.141,2.141,0,0,1,1410.367,504.075Zm4.325.748c.429,0,.859,0,1.288,0,.222,0,.349-.121.35-.3s-.123-.3-.348-.3c-.141,0-.282,0-.423,0-.711,0-1.423,0-2.134,0-.247,0-.367.1-.368.3s.126.306.365.308C1413.846,504.827,1414.269,504.824,1414.692,504.823Zm0-3.359c.423,0,.846,0,1.268,0,.242,0,.371-.108.374-.3s-.133-.311-.384-.311q-1.248,0-2.5,0c-.256,0-.389.105-.392.3s.125.306.382.308C1413.856,501.466,1414.272,501.465,1414.688,501.464Zm-.008,1.68c.423,0,.846,0,1.268,0,.249,0,.386-.115.381-.312s-.135-.295-.376-.295q-1.258,0-2.517,0c-.255,0-.383.108-.379.31s.133.3.374.3C1413.848,503.146,1414.264,503.144,1414.68,503.144Z"
//             transform="translate(3267.985 -1258.036)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_2223"
//             data-name="Path 2223"
//             d="M1463.068,841.28a13.73,13.73,0,0,1-2.759-.279.424.424,0,0,1-.4-.49c.071-.686.124-1.378.256-2.053a2.14,2.14,0,0,1,2.064-1.688c.5-.01.994,0,1.49,0a2.213,2.213,0,0,1,2.309,2.1c.053.527.119,1.052.181,1.578a.449.449,0,0,1-.389.555A13.407,13.407,0,0,1,1463.068,841.28Z"
//             transform="translate(3217.353 -1581.729)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_2224"
//             data-name="Path 2224"
//             d="M1335.048,812a2.9,2.9,0,0,0-1.014,1.932c-.053.452-.11.9-.166,1.356,0,.026-.01.052-.019.1-.209.018-.422.045-.634.054a14.4,14.4,0,0,1-3.484-.235c-.4-.08-.525-.26-.473-.666.074-.578.123-1.159.214-1.734a2.114,2.114,0,0,1,1.949-1.838,14.244,14.244,0,0,1,2.13.022A1.992,1.992,0,0,1,1335.048,812Z"
//             transform="translate(3342.754 -1556.945)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_2225"
//             data-name="Path 2225"
//             d="M1602.806,811.476a2.022,2.022,0,0,1,1.558-1.016,12.666,12.666,0,0,1,2.172,0,2.107,2.107,0,0,1,1.838,1.793c.1.629.172,1.261.246,1.893a.423.423,0,0,1-.368.512,13.488,13.488,0,0,1-4.165.208.617.617,0,0,1-.076-.02c-.044-.345-.1-.695-.129-1.047A3.163,3.163,0,0,0,1602.806,811.476Z"
//             transform="translate(3080.214 -1556.439)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_2226"
//             data-name="Path 2226"
//             d="M1488.413,720.011a2.074,2.074,0,1,1-2.094,2.074A2.076,2.076,0,0,1,1488.413,720.011Z"
//             transform="translate(3192.009 -1469.679)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_2227"
//             data-name="Path 2227"
//             d="M1358.155,694.009a2.075,2.075,0,1,1-.015,4.149,2.075,2.075,0,1,1,.015-4.149Z"
//             transform="translate(3317.009 -1444.724)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_2228"
//             data-name="Path 2228"
//             d="M1616.694,696.083a2.078,2.078,0,0,1,4.156-.013,2.078,2.078,0,1,1-4.156.013Z"
//             transform="translate(3066.886 -1444.725)"
//             fill="#ff6a00"
//           />
//         </g>
//       </svg>
//     ),
//     title: "Membership Plans",
//   },
//   "/user/coaching": {
//     icon: (
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         width="16"
//         height="16"
//         fill="currentColor"
//         className="inline-flex md:mr-3 mr-1"
//         viewBox="0 0 16 16"
//       >
//         {" "}
//         <path
//           d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
//           fill="#ff6a00"
//         />{" "}
//         <path
//           fillRule="evenodd"
//           d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
//           fill="#ff6a00"
//         />{" "}
//         <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" fill="#ff6a00" />{" "}
//       </svg>
//     ),
//     title: "Coaching Plans",
//   },
//   "/user/bookings": {
//     icon: (
//       <svg
//         className="inline-flex md:mr-3 mr-1"
//         width="18"
//         height="18"
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 18.392 18.386"
//       >
//         <path
//           id="Path_13183"
//           data-name="Path 13183"
//           d="M94.552-1190.658h15.6a.16.16,0,0,1,.16.16v5.555a.16.16,0,0,1-.186.158,4.218,4.218,0,0,0-1.753.067.161.161,0,0,1-.2-.154v-1.328a.16.16,0,0,0-.16-.16H106.53a.16.16,0,0,0-.16.16v1.485a.16.16,0,0,0,.16.16h.717a.16.16,0,0,1,.09.292,3.984,3.984,0,0,0-1.7,4.721.16.16,0,0,1-.152.2h-9.8a1.236,1.236,0,0,1-1.29-1.3q0-4.911,0-9.821v-.04A.16.16,0,0,1,94.552-1190.658Zm3.358.929h-1.5a.16.16,0,0,0-.16.16v1.475a.16.16,0,0,0,.16.16h1.5a.16.16,0,0,0,.16-.16v-1.475A.16.16,0,0,0,97.91-1189.729Zm3.522,1.639v-1.477a.16.16,0,0,0-.16-.16H99.783a.16.16,0,0,0-.16.16v1.477a.16.16,0,0,0,.16.16h1.489A.16.16,0,0,0,101.432-1188.09Zm-3.519,1.732h-1.5a.16.16,0,0,0-.16.16v1.467a.16.16,0,0,0,.16.16h1.5a.16.16,0,0,0,.16-.16v-1.467A.16.16,0,0,0,97.913-1186.359Zm3.521,1.635v-1.479a.16.16,0,0,0-.16-.16H99.788a.16.16,0,0,0-.16.16v1.479a.16.16,0,0,0,.16.16h1.485A.16.16,0,0,0,101.434-1184.723ZM97.909-1183H96.415a.16.16,0,0,0-.16.16v1.476a.16.16,0,0,0,.16.16h1.494a.16.16,0,0,0,.16-.16v-1.476A.16.16,0,0,0,97.909-1183Zm3.38,1.8a.16.16,0,0,0,.16-.16c0-.517,0-1.018-.006-1.519a.19.19,0,0,0-.129-.134c-.507-.009-1.015-.007-1.529-.006a.161.161,0,0,0-.16.16v1.5a.16.16,0,0,0,.16.16Zm1.864-6.737h1.479a.16.16,0,0,0,.16-.16v-1.481a.16.16,0,0,0-.16-.16h-1.479a.16.16,0,0,0-.16.16v1.481A.16.16,0,0,0,103.154-1187.931Zm4.848-1.8h-1.477a.16.16,0,0,0-.16.16v1.473a.16.16,0,0,0,.16.16H108a.16.16,0,0,0,.16-.16v-1.473A.16.16,0,0,0,108-1189.728Zm-4.846,5.155h1.478a.16.16,0,0,0,.16-.16v-1.471a.16.16,0,0,0-.16-.16h-1.478a.16.16,0,0,0-.16.16v1.471A.16.16,0,0,0,103.155-1184.573Zm-.156,1.729v1.487a.16.16,0,0,0,.16.16h1.473a.16.16,0,0,0,.16-.16v-1.487a.16.16,0,0,0-.16-.16h-1.473A.16.16,0,0,0,103-1182.844Z"
//           transform="translate(-94.386 1195.883)"
//           fill="#ff6a00"
//         />
//         <path
//           id="Path_13184"
//           data-name="Path 13184"
//           d="M94.294-1299.109c0-.658-.031-1.3.009-1.935a1.213,1.213,0,0,1,1.247-1.124c.29-.006.581,0,.9,0,0-.3,0-.58,0-.855a.62.62,0,0,1,.6-.676.626.626,0,0,1,.609.672c0,.532,0,1.063,0,1.595a.718.718,0,0,1-.329.757.656.656,0,0,0,.94-.637c0-.216.009-.432,0-.647-.008-.158.041-.218.207-.213.362.011.724,0,1.086,0,.075,0,.15-.007.248-.012,0-.277,0-.537,0-.8,0-.433.247-.719.609-.721a.643.643,0,0,1,.614.717q0,.786,0,1.572a.719.719,0,0,1-.333.733.654.654,0,0,0,.939-.635c0-.275,0-.551,0-.849h1.846c0-.3,0-.572,0-.846a.634.634,0,0,1,.616-.691.631.631,0,0,1,.606.678c0,.531,0,1.063,0,1.595a.711.711,0,0,1-.34.752.675.675,0,0,0,.96-.676c0-.261,0-.523,0-.812h1.53c0-.31,0-.593,0-.875a.62.62,0,0,1,.6-.662.621.621,0,0,1,.616.663c0,.516,0,1.032,0,1.548s0,.5-.329.824c.591.142.953-.132.955-.723,0-.252,0-.5,0-.75a1.227,1.227,0,0,1,1.513,1.076c.052.641.011,1.29.011,1.952Z"
//           transform="translate(-94.282 1303.699)"
//           fill="#ff6a00"
//         />
//         <path
//           id="Path_13185"
//           data-name="Path 13185"
//           d="M352.175-1050.856a3.381,3.381,0,0,1-.213,4.967,3.383,3.383,0,0,1-4.349-.008,3.381,3.381,0,0,1-.2-4.966A3.367,3.367,0,0,1,352.175-1050.856Zm-.882.436c-.394.394-.777.771-1.152,1.155a.5.5,0,0,1-.413.195c-.1-.006-.207.082-.314.12a.83.83,0,0,1-.308.085c-.4-.03-.808-.082-1.228-.127l-.066.6c.416.047.813.083,1.207.142a.576.576,0,0,1,.3.158.62.62,0,0,0,.624.2.511.511,0,0,0,.437-.476.753.753,0,0,1,.311-.577c.257-.255.512-.511.768-.767l.275-.275Z"
//           transform="translate(-334.768 1063.486)"
//           fill="#ff6a00"
//         />
//       </svg>
//     ),
//     title: "My Booking",
//   },
//   "/user/schedules": {
//     icon: (
//       <svg
//         className="inline-flex md:mr-3 mr-1"
//         width="18"
//         height="18"
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 18.392 18.386"
//       >
//         <path
//           id="Path_13183"
//           data-name="Path 13183"
//           d="M94.552-1190.658h15.6a.16.16,0,0,1,.16.16v5.555a.16.16,0,0,1-.186.158,4.218,4.218,0,0,0-1.753.067.161.161,0,0,1-.2-.154v-1.328a.16.16,0,0,0-.16-.16H106.53a.16.16,0,0,0-.16.16v1.485a.16.16,0,0,0,.16.16h.717a.16.16,0,0,1,.09.292,3.984,3.984,0,0,0-1.7,4.721.16.16,0,0,1-.152.2h-9.8a1.236,1.236,0,0,1-1.29-1.3q0-4.911,0-9.821v-.04A.16.16,0,0,1,94.552-1190.658Zm3.358.929h-1.5a.16.16,0,0,0-.16.16v1.475a.16.16,0,0,0,.16.16h1.5a.16.16,0,0,0,.16-.16v-1.475A.16.16,0,0,0,97.91-1189.729Zm3.522,1.639v-1.477a.16.16,0,0,0-.16-.16H99.783a.16.16,0,0,0-.16.16v1.477a.16.16,0,0,0,.16.16h1.489A.16.16,0,0,0,101.432-1188.09Zm-3.519,1.732h-1.5a.16.16,0,0,0-.16.16v1.467a.16.16,0,0,0,.16.16h1.5a.16.16,0,0,0,.16-.16v-1.467A.16.16,0,0,0,97.913-1186.359Zm3.521,1.635v-1.479a.16.16,0,0,0-.16-.16H99.788a.16.16,0,0,0-.16.16v1.479a.16.16,0,0,0,.16.16h1.485A.16.16,0,0,0,101.434-1184.723ZM97.909-1183H96.415a.16.16,0,0,0-.16.16v1.476a.16.16,0,0,0,.16.16h1.494a.16.16,0,0,0,.16-.16v-1.476A.16.16,0,0,0,97.909-1183Zm3.38,1.8a.16.16,0,0,0,.16-.16c0-.517,0-1.018-.006-1.519a.19.19,0,0,0-.129-.134c-.507-.009-1.015-.007-1.529-.006a.161.161,0,0,0-.16.16v1.5a.16.16,0,0,0,.16.16Zm1.864-6.737h1.479a.16.16,0,0,0,.16-.16v-1.481a.16.16,0,0,0-.16-.16h-1.479a.16.16,0,0,0-.16.16v1.481A.16.16,0,0,0,103.154-1187.931Zm4.848-1.8h-1.477a.16.16,0,0,0-.16.16v1.473a.16.16,0,0,0,.16.16H108a.16.16,0,0,0,.16-.16v-1.473A.16.16,0,0,0,108-1189.728Zm-4.846,5.155h1.478a.16.16,0,0,0,.16-.16v-1.471a.16.16,0,0,0-.16-.16h-1.478a.16.16,0,0,0-.16.16v1.471A.16.16,0,0,0,103.155-1184.573Zm-.156,1.729v1.487a.16.16,0,0,0,.16.16h1.473a.16.16,0,0,0,.16-.16v-1.487a.16.16,0,0,0-.16-.16h-1.473A.16.16,0,0,0,103-1182.844Z"
//           transform="translate(-94.386 1195.883)"
//           fill="#ff6a00"
//         />
//         <path
//           id="Path_13184"
//           data-name="Path 13184"
//           d="M94.294-1299.109c0-.658-.031-1.3.009-1.935a1.213,1.213,0,0,1,1.247-1.124c.29-.006.581,0,.9,0,0-.3,0-.58,0-.855a.62.62,0,0,1,.6-.676.626.626,0,0,1,.609.672c0,.532,0,1.063,0,1.595a.718.718,0,0,1-.329.757.656.656,0,0,0,.94-.637c0-.216.009-.432,0-.647-.008-.158.041-.218.207-.213.362.011.724,0,1.086,0,.075,0,.15-.007.248-.012,0-.277,0-.537,0-.8,0-.433.247-.719.609-.721a.643.643,0,0,1,.614.717q0,.786,0,1.572a.719.719,0,0,1-.333.733.654.654,0,0,0,.939-.635c0-.275,0-.551,0-.849h1.846c0-.3,0-.572,0-.846a.634.634,0,0,1,.616-.691.631.631,0,0,1,.606.678c0,.531,0,1.063,0,1.595a.711.711,0,0,1-.34.752.675.675,0,0,0,.96-.676c0-.261,0-.523,0-.812h1.53c0-.31,0-.593,0-.875a.62.62,0,0,1,.6-.662.621.621,0,0,1,.616.663c0,.516,0,1.032,0,1.548s0,.5-.329.824c.591.142.953-.132.955-.723,0-.252,0-.5,0-.75a1.227,1.227,0,0,1,1.513,1.076c.052.641.011,1.29.011,1.952Z"
//           transform="translate(-94.282 1303.699)"
//           fill="#ff6a00"
//         />
//         <path
//           id="Path_13185"
//           data-name="Path 13185"
//           d="M352.175-1050.856a3.381,3.381,0,0,1-.213,4.967,3.383,3.383,0,0,1-4.349-.008,3.381,3.381,0,0,1-.2-4.966A3.367,3.367,0,0,1,352.175-1050.856Zm-.882.436c-.394.394-.777.771-1.152,1.155a.5.5,0,0,1-.413.195c-.1-.006-.207.082-.314.12a.83.83,0,0,1-.308.085c-.4-.03-.808-.082-1.228-.127l-.066.6c.416.047.813.083,1.207.142a.576.576,0,0,1,.3.158.62.62,0,0,0,.624.2.511.511,0,0,0,.437-.476.753.753,0,0,1,.311-.577c.257-.255.512-.511.768-.767l.275-.275Z"
//           transform="translate(-334.768 1063.486)"
//           fill="#ff6a00"
//         />
//       </svg>
//     ),
//     title: "My Schedules",
//   },
//   "/user/profile": {
//     icon: (
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         width="20"
//         height="18"
//         viewBox="0 0 10.276 15.012"
//         className="inline-flex md:mr-3 mr-1"
//       >
//         <g
//           id="Group_3614"
//           data-name="Group 3614"
//           transform="translate(-172.281 -169.525)"
//         >
//           <path
//             id="Path_5133"
//             data-name="Path 5133"
//             d="M1465.052,844.117a22.357,22.357,0,0,1-4.493-.454.691.691,0,0,1-.643-.8c.116-1.117.2-2.244.417-3.343a3.485,3.485,0,0,1,3.362-2.749c.809-.017,1.618-.007,2.427-.005a3.6,3.6,0,0,1,3.759,3.421c.086.858.193,1.713.3,2.569a.731.731,0,0,1-.633.9A21.827,21.827,0,0,1,1465.052,844.117Z"
//             transform="translate(-1287.63 -659.581)"
//             fill="#ff6a00"
//           />
//           <path
//             id="Path_5134"
//             data-name="Path 5134"
//             d="M1489.728,720.011a3.378,3.378,0,1,1-3.409,3.377A3.381,3.381,0,0,1,1489.728,720.011Z"
//             transform="translate(-1312.306 -550.486)"
//             fill="#ff6a00"
//           />
//         </g>
//       </svg>
//     ),
//     title: "My Info",
//   },
// };
