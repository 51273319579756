import React, { useState } from "react";
import { addToMyCart, deleteFromMyCart, getMyCart } from "../apis/booking.api";
import { toast } from "react-toastify";

export const CartContext = React.createContext();

const toastConf = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  progress: undefined,
  theme: "light",
};

export default function CartProvider({ children }) {
  const [cart, setCart] = useState([]);

  const [isLoading, setLoading] = useState(false);

  const initCall = async () => {
    const getCart = await getMyCart();

    setCart(getCart);
  };
  React.useEffect(() => {
    initCall();
  }, []);

  const addToCart = async ({ date, time, type, dependentID, court }) => {
    // const _c = [...cart];

    if (isLoading) {
      return;
    }

    setLoading(true);
    toast.success("Adding To Cart.", toastConf);
    try {
      const cartItems = await addToMyCart({
        date,
        time,
        type,
        dependentID,
        court,
      });
      setCart(cartItems);
      // setLoading(false);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      toast.error("Something went wrong while adding.", toastConf);
      setLoading(false);
    }

    // _c.push({
    //   type: "court",
    //   ...cartItem,
    // });
  };

  const removeFromCart = async (id, isLoad) => {
    if (isLoading) {
      return;
    }

    var _c = [...cart];
    setLoading(true);
    try {
      toast.success("Removing From Cart.", toastConf);
      await deleteFromMyCart(id);
      if (isLoad === true) {
        return initCall();
      }
      _c = _c.filter((item) => item.id !== id);
      setCart(_c);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      toast.error("Something went wrong.", toastConf);
      setLoading(false);
    }
  };

  const renderedCart = () => {
    const courtItem = cart.filter((item) => item.type === "court");
    const nonCourtItem = cart.filter((item) => item.type !== "court");

    const groups = courtItem.reduce((groups, item) => {
      const date = item.date;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push({
        ...item,
        sortTime: parseInt(item.time.replace(":", "")),
      });
      return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        type: "court",
        items: groups[date]
          .sort((a, b) => a.sortTime - b.sortTime)
          .reduce((r, n) => {
            const lastSubArray = r[r.length - 1];
            const currentSlot = parseInt(n.time.split(":")[0]);

            if (
              !lastSubArray ||
              parseInt(
                lastSubArray[lastSubArray?.length - 1].time.split(":")[0]
              ) !==
                currentSlot - 1
            ) {
              r.push([]);
            }

            r[r.length - 1].push(n);

            return r;
          }, []),
      };
    });
    // console.log([...groupArrays, ...nonCourtItem]);
    return [...groupArrays, ...nonCourtItem];
  };

  const getTotal = () => {
    var total = 0;

    cart.map((_i) => {
      return (total += parseFloat(_i.price));
    });
    return total;
  };

  React.useEffect(() => {
    return;
  }, []);
  return (
    <CartContext.Provider
      value={{
        cart,
        renderedCart,
        addToCart,
        removeFromCart,
        total: getTotal(),
        initCall,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
