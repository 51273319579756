import React, { useState } from "react";
import AdminLayout from "../../../components/AdminLayout";
import { useNavigate } from "react-router-dom";
import { getAllBookings } from "../../../apis/booking.api";
import { format, parseISO } from "date-fns";
import { FiEye } from "react-icons/fi";

export default function Bookings() {
  const navigate = useNavigate();

  const [bookings, setBookings] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = React.useState(1);

  const initCall = async (page) => {
    const response = await getAllBookings({
      page: page ?? 1,
    });
    setPage(page ?? 1);
    setBookings(response.data);
    setCount(response.count);
  };

  const pager = () => {
    const _c = [];
    const c = Math.floor(count / 10);
    let i = 0;
    while (i <= c) {
      _c.push(i);
      i++;
    }
    return _c;
  };

  React.useEffect(() => {
    initCall();
  }, []);

  return (
    <AdminLayout>
      <div className="bg-white rounded-lg m-4 p-5">
        <div className="flex items-center justify-between mb-4">
          <div className="text-xl text-black font-bold">Bookings</div>
        </div>
        
        <table className="table-auto w-full rounded-lg border-collapse p-4 mt-4 mb-8">
          <thead>
            <tr className="text-left bg-neutral-200 p-8">
              <th className="py-3 px-2 mb-4">Booking ID</th>
              <th className="py-3 px-2 mb-4">Booked By</th>
              <th>Booking Date</th>
              <th>Total</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {bookings.map((us) => (
              <tr key={us.userID} className="py-2">
                <td className="py-5 px-2">#{("OD0000" + us.id).slice(-8)}</td>
                <td className="py-5 px-2">
                  {us.type === "user" ? (
                    <span className="font-semibold">{us.user.fullName}</span>
                  ) : (
                    <span className="bg-red-50 px-2.5 py-1.5 rounded-lg shadow text-xs">
                      System/Admin
                    </span>
                  )}
                </td>
                {/* <td>Kundagol</td> */}
                <td>
                  {format(parseISO(us.createdAt), "dd-MM-yyyy | HH:mm")} UTC
                </td>
                <td>
                  <span className="text-xs font-semibold mr-2">CAD</span>
                  {us.grandTotal}
                </td>
                <td>
                  <button
                    className="inline-flex bg-orange-100 rounded-full p-4 mr-2"
                    onClick={() => navigate(`/admin/bookings/${us.bookingID}`)}
                  >
                    <FiEye />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <nav aria-label="Page navigation example">
          <ul class="list-style-none flex">
            {pager().map((p) => (
              <li>
                <button
                  onClick={() => initCall(p + 1)}
                  class={`${
                    p + 1 === page
                      ? "bg-gray-500 text-white"
                      : "bg-transparent text-neutral-600"
                  } relative block rounded  px-3 py-1.5 text-sm transition-all duration-300 hover:bg-neutral-100  dark:hover:bg-neutral-700 dark:hover:text-white`}
                >
                  {p + 1}
                </button>
              </li>
            ))}
            {/* <li aria-current="page">
              <button class="relative block rounded bg-primary-100 px-3 py-1.5 text-sm font-medium text-primary-700 transition-all duration-300">
                2
                <span class="absolute -m-px h-px w-px overflow-hidden whitespace-nowrap border-0 p-0 [clip:rect(0,0,0,0)]">
                  (current)
                </span>
              </button>
            </li>
            <li>
              <button class="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100">
                3
              </button>
            </li> */}
          </ul>
        </nav>
      </div>
    </AdminLayout>
  );
}
