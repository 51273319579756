import React, { useState } from "react";
import AdminLayout from "../../../components/AdminLayout";
import { getAllMembership, createMembership } from "../../../apis/booking.api";
import { useNavigate } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import { Formik } from "formik";
import { formatedTime } from "../../../utils/time";

export default function Membership() {
  const navigate = useNavigate();

  const [showCreateMembershipModal, setCreateMembershipModal] = useState(false);

  // const [currentVisibility, setCurrentVisibility] = useState("all");
  // const changeVisibility = (newVisibility) => {
  //   setCurrentVisibility(newVisibility);
  // };

  const [memberships, setMemberships] = useState([]);

  const initCall = async () => {
    const response = await getAllMembership();
    setMemberships(response);
  };

  React.useEffect(() => {
    initCall();
  }, []);

  const onSubmit = async (data) => {
    await createMembership(data);
    setCreateMembershipModal(false);
    initCall();
  };

  return (
    <AdminLayout>
      <div className="bg-white rounded-lg m-4 p-5 shadow">
        <div className="md:flex items-center justify-between mb-4">
          <div className="text-xl text-black font-bold">Membership</div>
          <div>
            <button
              className="flex items-center bg-theme rounded-l-full py-2 px-2"
              onClick={() => setCreateMembershipModal(true)}
            >
              <div className="flex bg-white rounded-full p-2 mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 11.681 11.683"
                >
                  <path
                    id="Path_4758"
                    data-name="Path 4758"
                    d="M751.768,143.831h-.186q-1.869,0-3.739,0a.762.762,0,0,1-.829-.653,2.938,2.938,0,0,1,0-.816.759.759,0,0,1,.816-.653c.964,0,1.928,0,2.892,0h1.045v-.176q0-1.85,0-3.7a.807.807,0,0,1,.9-.9h.378a.794.794,0,0,1,.843.841c0,.881,0,1.763,0,2.644v1.29h3.879a.805.805,0,0,1,.9.892q0,.182,0,.365a.8.8,0,0,1-.87.866h-3.905V144q0,1.876,0,3.752a.767.767,0,0,1-.663.835,2.95,2.95,0,0,1-.791,0,.764.764,0,0,1-.669-.817c0-.769,0-1.537,0-2.306v-1.635Z"
                    transform="translate(-746.986 -136.932)"
                    fill="#ff6a00"
                  />
                </svg>
              </div>
              <div className="text-xl text-white">Create Membership</div>
            </button>
          </div>
        </div>

        {memberships.map((mb) => (
          <div className="bg-stone-100 rounded-lg shadow p-4 mb-4" key={mb.id}>
            <div className="md:flex items-center justify-between">
              <div>
                <svg
                  className="inline-flex mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.889"
                  height="18.469"
                  viewBox="0 0 14.889 18.469"
                >
                  <g
                    id="Group_3609"
                    data-name="Group 3609"
                    transform="matrix(0.951, -0.309, 0.309, 0.951, 0, 2.439)"
                  >
                    <path
                      id="Path_5131"
                      data-name="Path 5131"
                      d="M3.442,6.974l.281-2.889a4.085,4.085,0,0,1,.4.592,8.514,8.514,0,0,1,1.185,5.547L5.886,8.64c.046.13.091.264.137.4h0A54.391,54.391,0,0,1,7.891,16.6a29.552,29.552,0,0,0-1.369-2.9,50.643,50.643,0,0,0-3.543-5.72C2.132,6.792,1.162,5.535.061,4.243c0,0,.713-1.2-.061-4.243C0,0,3.2,1.507,3.442,6.974Z"
                      transform="translate(0)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_5132"
                      data-name="Path 5132"
                      d="M1.977,6.428l.971-2.221a3.4,3.4,0,0,1,.164.574,7.111,7.111,0,0,1-.495,4.711l.87-1.108c0,.115,0,.233.005.353h0a45.448,45.448,0,0,1-.474,6.488,24.717,24.717,0,0,0-.335-2.66A42.294,42.294,0,0,0,1.349,7.108C.984,5.945.54,4.7,0,3.385,0,3.385.875,2.62,1.05,0,1.05,0,3.2,2.026,1.977,6.428Z"
                      transform="translate(6.048 3.594)"
                      fill="#222"
                    />
                  </g>
                </svg>
                <h4 className="inline-flex text-lg text-theme font-semibold md:mb-0 mb-2">
                  {mb.title}
                </h4>
              </div>
              <div>
                {/* {JSON.stringify(mb)} */}
                <button
                  className="inline-flex bg-white rounded-full p-4 mr-2"
                  onClick={() => navigate(`/admin/membership/${mb.planID}`)}
                >
                  <FiEye />
                </button>
                <button className="inline-flex bg-white rounded-full p-4">
                  <svg
                    id="Group_2613"
                    data-name="Group 2613"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 9.991 12.206"
                  >
                    <path
                      id="Path_4629"
                      data-name="Path 4629"
                      d="M-943.763,160.817c0-1.04,0-2.081,0-3.121a.54.54,0,0,1,.461-.6.547.547,0,0,1,.643.572c.007.555,0,1.11,0,1.665q0,2.185,0,4.37a1.065,1.065,0,0,0,1.149,1.152q2.172,0,4.343,0A1.068,1.068,0,0,0-936,163.7q0-2.978,0-5.956c0-.414.219-.658.573-.649a.526.526,0,0,1,.525.478c.015.146.008.294.008.442q0,2.835,0,5.67a2.156,2.156,0,0,1-2.282,2.281q-2.146,0-4.291,0a2.158,2.158,0,0,1-2.3-2.289Q-943.764,162.247-943.763,160.817Z"
                      transform="translate(944.321 -153.761)"
                      fill="#ec0000"
                    />
                    <path
                      id="Path_4630"
                      data-name="Path 4630"
                      d="M-947.264,94.716h1.724c.173,0,.347-.006.52,0a.542.542,0,0,1,.526.537.558.558,0,0,1-.506.562,1.709,1.709,0,0,1-.182,0h-8.607c-.052,0-.1,0-.156,0a.559.559,0,0,1-.54-.556.537.537,0,0,1,.544-.545c.624-.01,1.248,0,1.872,0h.353c0-.317,0-.616,0-.915,0-.575.173-.75.739-.75q1.521,0,3.042,0c.466,0,.665.2.669.664C-947.262,94.035-947.264,94.355-947.264,94.716Zm-3.32-.015h2.184v-.528h-2.184Z"
                      transform="translate(954.485 -93.05)"
                      fill="#ec0000"
                    />
                    <path
                      id="Path_4631"
                      data-name="Path 4631"
                      d="M-889.4,180.835c0,.615,0,1.23,0,1.845,0,.4-.216.646-.552.645s-.553-.253-.554-.647q0-1.858,0-3.717a.566.566,0,0,1,.542-.632c.337-.008.561.24.563.634C-889.4,179.587-889.4,180.211-889.4,180.835Z"
                      transform="translate(893.842 -173.892)"
                      fill="#ec0000"
                    />
                    <path
                      id="Path_4632"
                      data-name="Path 4632"
                      d="M-846.659,180.826c0,.641,0,1.282,0,1.923a.558.558,0,0,1-.443.565.54.54,0,0,1-.6-.3.771.771,0,0,1-.057-.3q-.005-1.884,0-3.769a.552.552,0,0,1,.557-.614.565.565,0,0,1,.548.626C-846.657,179.579-846.659,180.2-846.659,180.826Z"
                      transform="translate(853.32 -173.891)"
                      fill="#ec0000"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div>
              <div className="md:flex w-full">
                <div className="md:w-2/5">
                  <ul className="md:ml-5">
                    <li className="mb-2">
                      <b>Plan :</b> Monday to Friday (
                      {formatedTime(mb.startTime)} - {formatedTime(mb.endTime)})
                    </li>
                    <li className="mb-2">
                      <b>Subscription Amount :</b> {mb.price} CAD
                    </li>
                    <li className="mb-2">
                      <b>Plan for :</b> Signal Adult
                    </li>
                    <li className="mb-2">
                      <b>Validity :</b> {mb.days} days
                    </li>
                    <li className="mb-2">
                      <b>Created On :</b> {mb.createdAt}
                    </li>
                  </ul>
                </div>
                <div className="md:w-3/5">
                  <ul>
                    <li className="mb-2">
                      <b>Facility :</b> Racket, shuttlecock, court, posts and
                      the net.
                    </li>
                    <li className="mb-2">
                      <b>Description :</b>{" "}
                    </li>
                    <li className="mb-2">{mb.description}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {showCreateMembershipModal ? (
        <>
          <div className="justify-center items-center rounded-lg flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between bg-theme text-white p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-lg font-semibold ml-3">
                    Create Membership
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setCreateMembershipModal(false)}
                  >
                    <span className="bg-theme text-white opacity-1 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <Formik
                  initialValues={{
                    title: "",
                    description: "",
                    days: 0,
                    price: 0,
                    startTime: "00:00",
                    endTime: "00:00",
                  }}
                  // validationSchema={LoginSchema}
                  onSubmit={(values) => {
                    onSubmit(values);
                  }}
                  validateOnBlur={false}
                >
                  {({
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                  }) => (
                    <>
                      {/*body*/}
                      <div className="relative p-6 flex-auto">
                        <form>
                          <div className="grid gap-4">
                            <div>
                              <label
                                htmlFor="first_name"
                                className="block mb-2 text-base font-semibold text-sec"
                              >
                                Membership Title*
                              </label>
                              <div className="relative flex w-full flex-wrap items-stretch">
                                <input
                                  type="text"
                                  placeholder="Enter Membership Title"
                                  value={values["title"]}
                                  onChange={handleChange("title")}
                                  className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                                />
                              </div>
                            </div>
                            <div className="md:grid md:gap-4 md:grid-cols-2">
                              <div>
                                <label
                                  htmlFor="first_name"
                                  className="block mb-2 text-base font-semibold text-sec"
                                >
                                  Days*
                                </label>
                                <div className="relative flex w-full flex-wrap items-stretch">
                                  <input
                                    type="number"
                                    placeholder="120"
                                    value={values["days"]}
                                    onChange={handleChange("days")}
                                    className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                                  />
                                  <span className="z-10 h-full leading-snug font-normal absolute text-center text-white bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                                    {/* <img src={User} /> */}
                                  </span>
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="first_name"
                                  className="block mb-2 text-base font-semibold text-sec"
                                >
                                  Price*
                                </label>
                                <div className="relative flex w-full flex-wrap items-stretch">
                                  <input
                                    type="number"
                                    placeholder="$ 100 CAD"
                                    value={values["price"]}
                                    onChange={handleChange("price")}
                                    className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                                  />
                                  <span className="z-10 h-full leading-snug font-normal absolute text-center text-white bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                                    {/* <img src={User} /> */}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="md:grid md:gap-4 md:grid-cols-2">
                              <div>
                                <label
                                  htmlFor="startTime"
                                  className="block mb-2 text-base font-semibold text-sec"
                                >
                                  Start Time
                                </label>
                                <div className="relative flex w-full flex-wrap items-stretch">
                                  <input
                                    type="time"
                                    placeholder="00:00"
                                    value={values["startTime"]}
                                    onChange={handleChange("startTime")}
                                    className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                                  />
                                  <span className="z-10 h-full leading-snug font-normal absolute text-center text-white bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                                    {/* <img src={User} /> */}
                                  </span>
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="endTime"
                                  className="block mb-2 text-base font-semibold text-sec"
                                >
                                  End Time
                                </label>
                                <div className="relative flex w-full flex-wrap items-stretch">
                                  <input
                                    type="time"
                                    placeholder="00:00"
                                    value={values["endTime"]}
                                    onChange={handleChange("endTime")}
                                    className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                                  />
                                  <span className="z-10 h-full leading-snug font-normal absolute text-center text-white bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                                    {/* <img src={User} /> */}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* <div className="md:grid md:gap-4 md:grid-cols-2">
                        <div>
                          <label
                            htmlFor="first_name"
                            className="block mb-2 text-base font-semibold text-sec"
                          >
                            Bird*
                          </label>
                          <select
                            onChange={(event) =>
                              changeVisibility(event.target.value)
                            }
                            value={currentVisibility}
                            className="w-full rounded-lg border-2 border-stone-200 placeholder-gray-400 px-3 py-2"
                          >
                            <option value="no">Select Bird</option>
                            <option value="tag1">Tag 1</option>
                            <option value="tag2">Tag 2</option>
                          </select>
                        </div>
                                  </div>
                                  <div className="md:grid md:gap-4 md:grid-cols-2">
                                    <div>
                                      <label
                                        htmlFor="first_name"
                                        className="block mb-2 text-base font-semibold text-sec"
                                      >
                                        Select Plan Duration*
                                      </label>
                                      <div className="relative flex flex-wrap w-full items-stretch">
                                        <select
                                          onChange={(event) =>
                                            changeVisibility(event.target.value)
                                          }
                                          value={currentVisibility}
                                          className="w-full rounded-lg border-2 border-stone-200 placeholder-gray-400 px-3 py-2"
                                        >
                                          <option value="allusers">Monthly</option>
                                          <option value="tag1">Tag 1</option>
                                          <option value="tag2">Tag 2</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div>
                                      <label
                                        htmlFor="first_name"
                                        className="block mb-2 text-base font-semibold text-sec"
                                      >
                                        Plan For*
                                      </label>
                                      <select
                                        onChange={(event) =>
                                          changeVisibility(event.target.value)
                                        }
                                        value={currentVisibility}
                                        className="w-full rounded-lg border-2 border-stone-200 placeholder-gray-400 px-3 py-2"
                                      >
                                        <option value="no">Select Plan</option>
                                        <option value="tag1">Tag 1</option>
                                        <option value="tag2">Tag 2</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div>
                                    <label
                                      htmlFor="phone_no"
                                      className="block mb-2 text-base font-semibold text-sec"
                                    >
                                      Facility*{" "}
                                    </label>
                                    <div className="relative flex w-full flex-wrap items-stretch">
                                      <select
                                        onChange={(event) =>
                                          changeVisibility(event.target.value)
                                        }
                                        value={currentVisibility}
                                        className="w-full rounded-lg border-2 border-stone-200 placeholder-gray-400 px-3 py-2"
                                      >
                                        <option value="allusers">Select Facility</option>
                                        <option value="tag1">Tag 1</option>
                                        <option value="tag2">Tag 2</option>
                                      </select>
                                    </div>
                                  </div> 
                            */}
                            <div>
                              <label
                                htmlFor="phone_no"
                                className="block mb-2 text-base font-semibold text-sec"
                              >
                                Description*
                              </label>
                              <div className="relative flex w-full flex-wrap items-stretch">
                                <textarea
                                  id="message"
                                  rows="4"
                                  value={values["description"]}
                                  onChange={handleChange("description")}
                                  className="block p-2.5 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="Write Description here..."
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      {/*footer*/}
                      <div className="flex flex-wrap items-center pt-0 p-6 rounded-b">
                        <button
                          className="min-w-130 bg-theme text-white active:bg-emerald-600 font-bold text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={handleSubmit}
                        >
                          Create Membership
                        </button>
                        <button
                          className="min-w-130 bg-gray-200 text-black rounded-lg font-bold px-6 py-3 text-sm outline-none focus:outline-none mr-3 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => setCreateMembershipModal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </AdminLayout>
  );
}
