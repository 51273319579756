import { React, useState } from "react";
import { Link } from "react-router-dom";

export default function Dashboard() {
  const [showCancelModal, setCancelModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);

  return (
    <>
      <div className="flex flex-wrap pt-4 gap-y-4 ">
        <div className="xl:w-3/12 lg:w-4/12 sm:w-6/12 w-full px-3">
          <Link
            to="/user/court-rental"
            className="card-block flex overflow-hidden pr-3 bg-white shadow transition duration-300 border hover:shadow-md focus:shadow-md"
          >
            <div className="flex items-center">
              <div className="h-18 w-18  bg-gray-50 border-r mr-5 border-gray-100 flex items-center justify-center">
                <svg
                  className="mx-2"
                  width="32"
                  height="32"
                  viewBox="0 0 17.154 12.988"
                >
                  <g transform="translate(-600.459 166.418)">
                    <path
                      id="Path_2265"
                      data-name="Path 2265"
                      d="M642.545-81.99H636.03c-.35,0-.439-.089-.439-.438,0-.467,0-.935,0-1.4a.328.328,0,0,1,.368-.376h13.066a1.3,1.3,0,0,1,.19.006.3.3,0,0,1,.282.317q.006.788,0,1.576a.3.3,0,0,1-.315.317c-.161.006-.323,0-.484,0Z"
                      transform="translate(-33.509 -78.413)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_2266"
                      data-name="Path 2266"
                      d="M658.4,68.813c.141-.511.278-1,.415-1.5.051-.183.113-.364.152-.549.027-.129.086-.16.211-.16q2.99.006,5.98,0c1.417,0,2.834,0,4.252,0a.2.2,0,0,1,.232.179c.167.629.347,1.255.522,1.882.012.044.02.089.032.148Z"
                      transform="translate(-55.261 -222.261)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_2267"
                      data-name="Path 2267"
                      d="M680.36-22.378v3.455h-4.784l.3-1.065q.313-1.123.624-2.247c.027-.1.052-.158.177-.157,1.187.005,2.373,0,3.56,0C680.272-22.389,680.311-22.382,680.36-22.378Z"
                      transform="translate(-71.648 -137.373)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_2268"
                      data-name="Path 2268"
                      d="M793.169-18.919v-3.459c.061,0,.117-.01.172-.01,1.158,0,2.315,0,3.473,0,.131,0,.187.033.223.167.285,1.05.581,2.1.873,3.147.014.049.024.1.038.159Z"
                      transform="translate(-183.809 -137.375)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_2269"
                      data-name="Path 2269"
                      d="M604.235-22.321c-.228.822-.448,1.616-.668,2.409-.346,1.243-.7,2.485-1.035,3.729a.218.218,0,0,1-.258.194c-.478-.012-.956,0-1.434-.005-.322,0-.451-.186-.341-.489q1.033-2.851,2.072-5.7c.02-.054.08-.132.122-.133C603.2-22.325,603.7-22.321,604.235-22.321Z"
                      transform="translate(0 -137.441)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_2270"
                      data-name="Path 2270"
                      d="M890.4-22.322c.521,0,1.027,0,1.533.007.044,0,.1.09.126.15q.813,2.223,1.619,4.448c.147.406.3.812.443,1.218.116.323-.011.5-.356.5-.49,0-.979,0-1.469,0-.1,0-.153-.015-.184-.127q-.848-3.064-1.7-6.126A.586.586,0,0,1,890.4-22.322Z"
                      transform="translate(-276.552 -137.441)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_2271"
                      data-name="Path 2271"
                      d="M730.948-165.476c.081-.293.153-.577.243-.855.014-.043.114-.08.174-.081.576-.006,1.151,0,1.727,0,.84,0,1.681,0,2.521,0,.132,0,.194.033.225.167.059.257.137.51.21.775Z"
                      transform="translate(-124.462)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_2272"
                      data-name="Path 2272"
                      d="M796.07-130.362h-2.846v-1.568h2.406Z"
                      transform="translate(-183.862 -32.896)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_2273"
                      data-name="Path 2273"
                      d="M720.477-132.209v1.573h-2.843l.146-.527c.085-.305.166-.612.258-.915.016-.051.079-.125.121-.126C718.924-132.211,719.69-132.209,720.477-132.209Z"
                      transform="translate(-111.763 -32.629)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_2274"
                      data-name="Path 2274"
                      d="M677.154-166.324l-.294,1.054c-.179.644-.361,1.288-.535,1.933-.03.111-.069.16-.2.157-.38-.01-.759,0-1.157,0,.024-.07.039-.124.06-.176q.532-1.351,1.066-2.7c.094-.239.16-.285.411-.286.2,0,.392,0,.588,0A.281.281,0,0,1,677.154-166.324Z"
                      transform="translate(-71.071 -0.068)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_2275"
                      data-name="Path 2275"
                      d="M850.435-166.287c.26,0,.519-.015.774.009a.335.335,0,0,1,.224.183c.391.965.769,1.935,1.15,2.9a.212.212,0,0,1,0,.062c-.4,0-.805,0-1.207,0a.141.141,0,0,1-.1-.082q-.424-1.5-.838-3.009A.287.287,0,0,1,850.435-166.287Z"
                      transform="translate(-238.43 -0.12)"
                      fill="#ff6a00"
                    />
                  </g>
                </svg>
              </div>
              <div className="card-point">
                <h2 className="sm:text-2xl text-xl font-light text-theme">
                  Court Rental
                </h2>
              </div>
            </div>
          </Link>
        </div>
        <div className="xl:w-3/12 lg:w-4/12 sm:w-6/12 w-full px-3">
          <Link
            to="/user/drop-in"
            className="card-block flex overflow-hidden pr-3 bg-white shadow transition duration-300 border hover:shadow-md focus:shadow-md"
          >
            <div className="flex items-center">
              <div className="h-18 w-18  bg-gray-50 border-r mr-5 border-gray-100 flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-2"
                  width="32"
                  height="32"
                  viewBox="0 0 27.072 28.28"
                >
                  <g transform="translate(-686.031 -229.899)">
                    <path
                      id="Path_5092"
                      data-name="Path 5092"
                      d="M720.024,250.547a1.211,1.211,0,0,0-.094.21q-.534,2.287-1.062,4.575c-.144.624-.28,1.249-.436,1.87a1.238,1.238,0,0,1-1.288.974,1.315,1.315,0,0,1-1.207-1.15,1.472,1.472,0,0,1,.041-.48q.711-3.135,1.431-6.27a1.408,1.408,0,0,0,.033-.411q-.27-3.567-.55-7.133c-.025-.323-.062-.646-.066-.969,0-.2-.071-.284-.272-.314-.4-.059-.8-.145-1.207-.217a.8.8,0,0,1-.67-.6c-.456-1.522-.913-3.043-1.352-4.569a1.722,1.722,0,0,1,.031-.54,1.249,1.249,0,0,0,.013-.391c-.1-.364-.23-.719-.339-1.081-.036-.121-.083-.177-.223-.17a1.831,1.831,0,0,1-1.825-1.138,2.059,2.059,0,0,1,.171-2.233,1.57,1.57,0,0,1,1.808-.517,2.169,2.169,0,0,1,1.174,2.977,2.628,2.628,0,0,1-.6.655c-.11.1-.161.155-.111.3.131.377.251.758.373,1.134a.926.926,0,0,1,1.215.773c.358,1.228.715,2.458,1.083,3.683a.335.335,0,0,0,.208.2c.444.1.892.18,1.341.256a.47.47,0,0,0,.265-.041,4.549,4.549,0,0,1,4.108.053,12.324,12.324,0,0,1,1.358.79.992.992,0,0,1,.312.391c.531,1.062,1.057,2.128,1.565,3.2a.881.881,0,0,1,.058.536q-.421,1.8-.881,3.6a.861.861,0,0,1-1.033.636.878.878,0,0,1-.582-1.068c.254-1.017.518-2.032.775-3.049a.338.338,0,0,0,.016-.2,8,8,0,0,0-.541-1.029c-.014.128-.03.255-.04.383-.147,1.939-.3,3.877-.43,5.818a9.647,9.647,0,0,0,.082,1.361c.046.584.092,1.169.154,1.753a1.887,1.887,0,0,0,.143.529c.389.915.791,1.826,1.183,2.74a1.226,1.226,0,0,1-.191,1.326,1.178,1.178,0,0,1-1.2.435,1.217,1.217,0,0,1-.94-.745c-.46-1.061-.94-2.116-1.342-3.2a5.561,5.561,0,0,1-.191-1.39c-.071-.737-.125-1.475-.186-2.212Z"
                      transform="translate(-12.223)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_5093"
                      data-name="Path 5093"
                      d="M691.278,240.432c.187-.094.352-.175.514-.259a5.263,5.263,0,0,1,5.143.033,1.719,1.719,0,0,1,.452.352c.39.427.768.866,1.142,1.308a.256.256,0,0,0,.334.094c.433-.161.872-.308,1.312-.451a.815.815,0,0,1,.914.214.832.832,0,0,1,.221.854.774.774,0,0,1-.507.5c-.707.245-1.414.488-2.125.72a.8.8,0,0,1-.892-.285c-.22-.245-.43-.5-.678-.788-.009.114-.014.179-.019.243a13.257,13.257,0,0,0,.43,3.709c.29,1.452.568,2.907.857,4.359a.2.2,0,0,1-.12.257,7.727,7.727,0,0,1-7.721.053.313.313,0,0,1-.2-.4q.614-3.087,1.213-6.176a3.538,3.538,0,0,0,.065-.619c.009-.586,0-1.173,0-1.76,0-.082-.007-.164-.013-.283-.143.07-.251.124-.359.175a.874.874,0,0,1-1.333-.588c-.41-1.39-.812-2.783-1.223-4.173a.968.968,0,0,1,.17-.915.337.337,0,0,0,.053-.262c-.2-.647-.422-1.29-.632-1.935a.177.177,0,0,0-.222-.139,1.991,1.991,0,0,1-2.017-2.219,1.774,1.774,0,0,1,1.333-1.674,1.61,1.61,0,0,1,1.3.241,2.252,2.252,0,0,1,.811,2.7,1.591,1.591,0,0,1-.664.751c-.112.064-.152.119-.106.254.219.634.423,1.273.643,1.907a.284.284,0,0,0,.173.171.882.882,0,0,1,.786.712c.292,1,.576,2,.863,3C691.214,240.22,691.243,240.314,691.278,240.432Z"
                      transform="translate(0 -0.217)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_5094"
                      data-name="Path 5094"
                      d="M695.089,273.978a6.87,6.87,0,0,0,2.461.475c-.057.428-.15.891-.173,1.357a3.022,3.022,0,0,1-.676,1.707c-.473.628-.9,1.292-1.357,1.93a1.264,1.264,0,0,1-2.237-.427,1.307,1.307,0,0,1,.24-1.136c.434-.609.852-1.231,1.291-1.835a1.355,1.355,0,0,0,.25-.656C694.95,274.911,695.024,274.433,695.089,273.978Z"
                      transform="translate(-3.48 -21.813)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_5095"
                      data-name="Path 5095"
                      d="M703.682,274.279a6.284,6.284,0,0,0,2.458-.581c.016.036.055.116.085.2q.755,2.062,1.507,4.124a1.311,1.311,0,0,1-.424,1.554,1.267,1.267,0,0,1-1.9-.536C704.823,277.451,704.252,275.855,703.682,274.279Z"
                      transform="translate(-8.735 -21.675)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_5096"
                      data-name="Path 5096"
                      d="M729.154,241.548a2.116,2.116,0,1,1-2.091-2.149A2.12,2.12,0,0,1,729.154,241.548Z"
                      transform="translate(-19.246 -4.701)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_5097"
                      data-name="Path 5097"
                      d="M702.547,241.531a2.129,2.129,0,0,1-2.13,2.111,2.155,2.155,0,0,1-2.1-2.127,2.115,2.115,0,1,1,4.23.016Z"
                      transform="translate(-6.08 -4.701)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_5098"
                      data-name="Path 5098"
                      d="M699.355,230.513l1.095,2.368c-.373.055-.712.108-1.052.155-.389.054-.778.1-1.168.154a.584.584,0,0,1-.666-.337.62.62,0,0,1,.125-.818q.728-.676,1.461-1.348C699.2,230.636,699.264,230.589,699.355,230.513Z"
                      transform="translate(-5.659 -0.304)"
                      fill="#ff6a00"
                    />
                  </g>
                </svg>
              </div>
              <div className="card-point">
                <h2 className="sm:text-2xl text-xl font-light text-theme">
                  Drop In
                </h2>
              </div>
            </div>
          </Link>
        </div>
        <div className="xl:w-3/12 lg:w-4/12 sm:w-6/12 w-full px-3">
          <Link
            to="/user/membership"
            className="card-block flex overflow-hidden pr-3 bg-white shadow transition duration-300 border hover:shadow-md focus:shadow-md"
          >
            <div className="flex items-center">
              <div className="h-18 w-18  bg-gray-50 border-r mr-5 border-gray-100 flex items-center justify-center">
                <svg
                  className="mx-2"
                  width="32"
                  height="32"
                  viewBox="0 0 16.837 18.103"
                >
                  <g transform="translate(-1329.246 -499.485)">
                    <path
                      id="Path_2222"
                      data-name="Path 2222"
                      d="M1412.427,499.485h4.369a.773.773,0,0,1,.869.865q0,2.493,0,4.986a.773.773,0,0,1-.858.858h-8.8a.779.779,0,0,1-.854-.847q0-2.513,0-5.026a.773.773,0,0,1,.843-.835Zm-2.06,4.59a1.652,1.652,0,0,1,.206.087c.214.125.424.256.636.385.116.07.255.168.361.044a.474.474,0,0,0,.053-.343,6.328,6.328,0,0,0-.171-.722.339.339,0,0,1,.124-.39,6.522,6.522,0,0,0,.553-.5.4.4,0,0,0,.113-.306c-.016-.071-.162-.144-.259-.16a6.343,6.343,0,0,0-.76-.07.346.346,0,0,1-.344-.252c-.095-.25-.189-.5-.308-.74-.043-.087-.156-.192-.237-.191a.356.356,0,0,0-.238.192,7.683,7.683,0,0,0-.32.779.29.29,0,0,1-.3.212c-.268.013-.536.032-.8.071-.091.014-.222.079-.245.15a.339.339,0,0,0,.112.264c.207.2.43.383.65.57a.293.293,0,0,1,.107.336c-.072.257-.124.52-.192.779-.033.125-.06.264.073.316a.454.454,0,0,0,.327-.035c.227-.114.438-.258.658-.387A2.141,2.141,0,0,1,1410.367,504.075Zm4.325.748c.429,0,.859,0,1.288,0,.222,0,.349-.121.35-.3s-.123-.3-.348-.3c-.141,0-.282,0-.423,0-.711,0-1.423,0-2.134,0-.247,0-.367.1-.368.3s.126.306.365.308C1413.846,504.827,1414.269,504.824,1414.692,504.823Zm0-3.359c.423,0,.846,0,1.268,0,.242,0,.371-.108.374-.3s-.133-.311-.384-.311q-1.248,0-2.5,0c-.256,0-.389.105-.392.3s.125.306.382.308C1413.856,501.466,1414.272,501.465,1414.688,501.464Zm-.008,1.68c.423,0,.846,0,1.268,0,.249,0,.386-.115.381-.312s-.135-.295-.376-.295q-1.258,0-2.517,0c-.255,0-.383.108-.379.31s.133.3.374.3C1413.848,503.146,1414.264,503.144,1414.68,503.144Z"
                      transform="translate(-74.769)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_2223"
                      data-name="Path 2223"
                      d="M1463.068,841.28a13.73,13.73,0,0,1-2.759-.279.424.424,0,0,1-.4-.49c.071-.686.124-1.378.256-2.053a2.14,2.14,0,0,1,2.064-1.688c.5-.01.994,0,1.49,0a2.213,2.213,0,0,1,2.309,2.1c.053.527.119,1.052.181,1.578a.449.449,0,0,1-.389.555A13.407,13.407,0,0,1,1463.068,841.28Z"
                      transform="translate(-125.401 -323.692)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_2224"
                      data-name="Path 2224"
                      d="M1335.048,812a2.9,2.9,0,0,0-1.014,1.932c-.053.452-.11.9-.166,1.356,0,.026-.01.052-.019.1-.209.018-.422.045-.634.054a14.4,14.4,0,0,1-3.484-.235c-.4-.08-.525-.26-.473-.666.074-.578.123-1.159.214-1.734a2.114,2.114,0,0,1,1.949-1.838,14.244,14.244,0,0,1,2.13.022A1.992,1.992,0,0,1,1335.048,812Z"
                      transform="translate(0 -298.909)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_2225"
                      data-name="Path 2225"
                      d="M1602.806,811.476a2.022,2.022,0,0,1,1.558-1.016,12.666,12.666,0,0,1,2.172,0,2.107,2.107,0,0,1,1.838,1.793c.1.629.172,1.261.246,1.893a.423.423,0,0,1-.368.512,13.488,13.488,0,0,1-4.165.208.617.617,0,0,1-.076-.02c-.044-.345-.1-.695-.129-1.047A3.163,3.163,0,0,0,1602.806,811.476Z"
                      transform="translate(-262.54 -298.404)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_2226"
                      data-name="Path 2226"
                      d="M1488.413,720.011a2.074,2.074,0,1,1-2.094,2.074A2.076,2.076,0,0,1,1488.413,720.011Z"
                      transform="translate(-150.746 -211.643)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_2227"
                      data-name="Path 2227"
                      d="M1358.155,694.009a2.075,2.075,0,1,1-.015,4.149,2.075,2.075,0,1,1,.015-4.149Z"
                      transform="translate(-25.745 -186.688)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_2228"
                      data-name="Path 2228"
                      d="M1616.694,696.083a2.078,2.078,0,0,1,4.156-.013,2.078,2.078,0,1,1-4.156.013Z"
                      transform="translate(-275.869 -186.689)"
                      fill="#ff6a00"
                    />
                  </g>
                </svg>
              </div>
              <div className="card-point">
                <h2 className="sm:text-2xl text-xl font-light text-theme">
                  Membership Plans
                </h2>
              </div>
            </div>
          </Link>
        </div>
        <div className="xl:w-3/12 lg:w-4/12 sm:w-6/12 w-full px-3">
          <Link
            to="/user/coaching"
            className="card-block flex overflow-hidden pr-3 bg-white shadow transition duration-300 border hover:shadow-md focus:shadow-md"
          >
            <div className="flex items-center">
              <div className="h-18 w-18  bg-gray-50 border-r mr-5 border-gray-100 flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-2"
                  width="32"
                  height="32"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  {" "}
                  <path
                    d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                    fill="#ff6a00"
                  />{" "}
                  <path
                    fillRule="evenodd"
                    d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                    fill="#ff6a00"
                  />{" "}
                  <path
                    d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                    fill="#ff6a00"
                  />{" "}
                </svg>
              </div>
              <div className="card-point">
                <h2 className="sm:text-2xl text-xl font-light text-theme">
                  Coaching Plans
                </h2>
              </div>
            </div>
          </Link>
        </div>
        <div className="xl:w-3/12 lg:w-4/12 sm:w-6/12 w-full px-3">
          <Link
            to="/user/bookings"
            className="card-block flex overflow-hidden pr-3 bg-white shadow transition duration-300 border hover:shadow-md focus:shadow-md"
          >
            <div className="flex items-center">
              <div className="h-18 w-18  bg-gray-50 border-r mr-5 border-gray-100 flex items-center justify-center">
                <svg
                  className="mx-2"
                  width="32"
                  height="32"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 18.392 18.386"
                >
                  <path
                    id="Path_13183"
                    data-name="Path 13183"
                    d="M94.552-1190.658h15.6a.16.16,0,0,1,.16.16v5.555a.16.16,0,0,1-.186.158,4.218,4.218,0,0,0-1.753.067.161.161,0,0,1-.2-.154v-1.328a.16.16,0,0,0-.16-.16H106.53a.16.16,0,0,0-.16.16v1.485a.16.16,0,0,0,.16.16h.717a.16.16,0,0,1,.09.292,3.984,3.984,0,0,0-1.7,4.721.16.16,0,0,1-.152.2h-9.8a1.236,1.236,0,0,1-1.29-1.3q0-4.911,0-9.821v-.04A.16.16,0,0,1,94.552-1190.658Zm3.358.929h-1.5a.16.16,0,0,0-.16.16v1.475a.16.16,0,0,0,.16.16h1.5a.16.16,0,0,0,.16-.16v-1.475A.16.16,0,0,0,97.91-1189.729Zm3.522,1.639v-1.477a.16.16,0,0,0-.16-.16H99.783a.16.16,0,0,0-.16.16v1.477a.16.16,0,0,0,.16.16h1.489A.16.16,0,0,0,101.432-1188.09Zm-3.519,1.732h-1.5a.16.16,0,0,0-.16.16v1.467a.16.16,0,0,0,.16.16h1.5a.16.16,0,0,0,.16-.16v-1.467A.16.16,0,0,0,97.913-1186.359Zm3.521,1.635v-1.479a.16.16,0,0,0-.16-.16H99.788a.16.16,0,0,0-.16.16v1.479a.16.16,0,0,0,.16.16h1.485A.16.16,0,0,0,101.434-1184.723ZM97.909-1183H96.415a.16.16,0,0,0-.16.16v1.476a.16.16,0,0,0,.16.16h1.494a.16.16,0,0,0,.16-.16v-1.476A.16.16,0,0,0,97.909-1183Zm3.38,1.8a.16.16,0,0,0,.16-.16c0-.517,0-1.018-.006-1.519a.19.19,0,0,0-.129-.134c-.507-.009-1.015-.007-1.529-.006a.161.161,0,0,0-.16.16v1.5a.16.16,0,0,0,.16.16Zm1.864-6.737h1.479a.16.16,0,0,0,.16-.16v-1.481a.16.16,0,0,0-.16-.16h-1.479a.16.16,0,0,0-.16.16v1.481A.16.16,0,0,0,103.154-1187.931Zm4.848-1.8h-1.477a.16.16,0,0,0-.16.16v1.473a.16.16,0,0,0,.16.16H108a.16.16,0,0,0,.16-.16v-1.473A.16.16,0,0,0,108-1189.728Zm-4.846,5.155h1.478a.16.16,0,0,0,.16-.16v-1.471a.16.16,0,0,0-.16-.16h-1.478a.16.16,0,0,0-.16.16v1.471A.16.16,0,0,0,103.155-1184.573Zm-.156,1.729v1.487a.16.16,0,0,0,.16.16h1.473a.16.16,0,0,0,.16-.16v-1.487a.16.16,0,0,0-.16-.16h-1.473A.16.16,0,0,0,103-1182.844Z"
                    transform="translate(-94.386 1195.883)"
                    fill="#ff6a00"
                  />
                  <path
                    id="Path_13184"
                    data-name="Path 13184"
                    d="M94.294-1299.109c0-.658-.031-1.3.009-1.935a1.213,1.213,0,0,1,1.247-1.124c.29-.006.581,0,.9,0,0-.3,0-.58,0-.855a.62.62,0,0,1,.6-.676.626.626,0,0,1,.609.672c0,.532,0,1.063,0,1.595a.718.718,0,0,1-.329.757.656.656,0,0,0,.94-.637c0-.216.009-.432,0-.647-.008-.158.041-.218.207-.213.362.011.724,0,1.086,0,.075,0,.15-.007.248-.012,0-.277,0-.537,0-.8,0-.433.247-.719.609-.721a.643.643,0,0,1,.614.717q0,.786,0,1.572a.719.719,0,0,1-.333.733.654.654,0,0,0,.939-.635c0-.275,0-.551,0-.849h1.846c0-.3,0-.572,0-.846a.634.634,0,0,1,.616-.691.631.631,0,0,1,.606.678c0,.531,0,1.063,0,1.595a.711.711,0,0,1-.34.752.675.675,0,0,0,.96-.676c0-.261,0-.523,0-.812h1.53c0-.31,0-.593,0-.875a.62.62,0,0,1,.6-.662.621.621,0,0,1,.616.663c0,.516,0,1.032,0,1.548s0,.5-.329.824c.591.142.953-.132.955-.723,0-.252,0-.5,0-.75a1.227,1.227,0,0,1,1.513,1.076c.052.641.011,1.29.011,1.952Z"
                    transform="translate(-94.282 1303.699)"
                    fill="#ff6a00"
                  />
                  <path
                    id="Path_13185"
                    data-name="Path 13185"
                    d="M352.175-1050.856a3.381,3.381,0,0,1-.213,4.967,3.383,3.383,0,0,1-4.349-.008,3.381,3.381,0,0,1-.2-4.966A3.367,3.367,0,0,1,352.175-1050.856Zm-.882.436c-.394.394-.777.771-1.152,1.155a.5.5,0,0,1-.413.195c-.1-.006-.207.082-.314.12a.83.83,0,0,1-.308.085c-.4-.03-.808-.082-1.228-.127l-.066.6c.416.047.813.083,1.207.142a.576.576,0,0,1,.3.158.62.62,0,0,0,.624.2.511.511,0,0,0,.437-.476.753.753,0,0,1,.311-.577c.257-.255.512-.511.768-.767l.275-.275Z"
                    transform="translate(-334.768 1063.486)"
                    fill="#ff6a00"
                  />
                </svg>
              </div>
              <div className="card-point">
                <h2 className="sm:text-2xl text-xl font-light text-theme">
                  My Bookings
                </h2>
              </div>
            </div>
          </Link>
        </div>
        <div className="xl:w-3/12 lg:w-4/12 sm:w-6/12 w-full px-3">
          <Link
            to="/user/schedules"
            className="card-block flex overflow-hidden pr-3 bg-white shadow transition duration-300 border hover:shadow-md focus:shadow-md"
          >
            <div className="flex items-center">
              <div className="h-18 w-18  bg-gray-50 border-r mr-5 border-gray-100 flex items-center justify-center">
                <svg
                  className="mx-2"
                  width="32"
                  height="32"
                  xmlns="http://www.w3.org/2000/svg"
                  enableBackground="new 0 0 24 24"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M19,4h-1V3c0-0.6-0.4-1-1-1s-1,0.4-1,1v1H8V3c0-0.6-0.4-1-1-1S6,2.4,6,3v1H5C3.3,4,2,5.3,2,7v1h20V7C22,5.3,20.7,4,19,4z M2,19c0,1.7,1.3,3,3,3h14c1.7,0,3-1.3,3-3v-9H2V19z M17,12c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S16.4,12,17,12z M17,16c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S16.4,16,17,16z M12,12c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S11.4,12,12,12z M12,16c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S11.4,16,12,16z M7,12c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S6.4,12,7,12z M7,16c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S6.4,16,7,16z"
                    fill="#ff6a00"
                  />
                </svg>
              </div>
              <div className="card-point">
                <h2 className="sm:text-2xl text-xl font-light text-theme">
                  My Schedules
                </h2>
              </div>
            </div>
          </Link>
        </div>
        <div className="xl:w-3/12 lg:w-4/12 sm:w-6/12 w-full px-3">
          <Link
            to="/user/profile"
            className="card-block flex overflow-hidden pr-3 bg-white shadow transition duration-300 border hover:shadow-md focus:shadow-md"
          >
            <div className="flex items-center">
              <div className="h-18 w-18  bg-gray-50 border-r mr-5 border-gray-100 flex items-center justify-center">
                <svg
                  className="mx-2"
                  width="32"
                  height="32"
                  viewBox="0 0 14.883 17.857"
                >
                  <g transform="translate(-28.558 -10.572)">
                    <path
                      d="M1491.35,264.453c-1.453,0-2.905.008-4.358,0a3.057,3.057,0,0,1-2.295-.923,2.8,2.8,0,0,1-.772-1.884,10.164,10.164,0,0,1,.436-3.531,4.162,4.162,0,0,1,1.6-2.35,3.206,3.206,0,0,1,1.892-.529.8.8,0,0,1,.357.105c.3.174.6.365.895.551a4.07,4.07,0,0,0,2.51.688,4.328,4.328,0,0,0,2.145-.787c.116-.079.245-.141.352-.23a1.63,1.63,0,0,1,1.472-.273,3.338,3.338,0,0,1,2.37,1.873,8.645,8.645,0,0,1,.833,3.785,4.149,4.149,0,0,1-.162,1.559,2.753,2.753,0,0,1-2.421,1.9,14.857,14.857,0,0,1-1.7.049C1493.455,264.458,1492.4,264.454,1491.35,264.453Z"
                      transform="translate(-1455.358 -236.027)"
                      fill="#ff6a00"
                    />
                    <path
                      d="M1522.654,165.908a4.3,4.3,0,1,1,4.307-4.277A4.273,4.273,0,0,1,1522.654,165.908Z"
                      transform="translate(-1486.775 -146.738)"
                      fill="#ff6a00"
                    />
                  </g>
                </svg>
              </div>
              <div className="card-point">
                <h2 className="sm:text-2xl text-xl font-light text-theme">
                  Profile
                </h2>
              </div>
            </div>
          </Link>
        </div>
      </div>

      {showCancelModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-md">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="flex justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="84.214"
                      height="84.214"
                      viewBox="0 0 84.214 84.214"
                    >
                      <g id="cross-circle" transform="translate(0.75 0.75)">
                        <path
                          id="Path_13181"
                          data-name="Path 13181"
                          d="M31.4,8.544a2.857,2.857,0,0,0-4.039,0L19.97,15.931,12.583,8.544a2.856,2.856,0,1,0-4.039,4.039l7.387,7.387L8.544,27.357A2.856,2.856,0,1,0,12.583,31.4l7.387-7.387L27.357,31.4A2.856,2.856,0,1,0,31.4,27.357L24.009,19.97,31.4,12.583A2.857,2.857,0,0,0,31.4,8.544Z"
                          transform="translate(21.387 21.387)"
                          fill="red"
                        />
                        <path
                          id="Path_13182"
                          data-name="Path 13182"
                          d="M41.907,0A41.907,41.907,0,1,0,83.814,41.907,41.907,41.907,0,0,0,41.907,0Zm0,76.83A34.923,34.923,0,1,1,76.83,41.907,34.923,34.923,0,0,1,41.907,76.83Z"
                          transform="translate(-0.55 -0.55)"
                          fill="red"
                          stroke="#ffe8e8"
                          strokeWidth="0.4"
                        />
                      </g>
                    </svg>
                  </p>
                  <p className="text-2xl font-semibold text-center text-red-600 mt-4 mb-4">
                    Are You Sure?
                  </p>
                  <p className="mt-4 text-center">
                    If You Paid Your Money Will Refund In A Next
                  </p>
                  <p className="mb-4 text-center">5 Working Days</p>
                  <div className="flex items-center justify-center rounded-b">
                    <button
                      className="min-w-130 bg-theme text-white active:bg-emerald-600 font-bold text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setSuccessModal(true)}
                    >
                      Yes
                    </button>
                    <button
                      className="min-w-130 bg-gray-200 text-black rounded-lg font-bold px-6 py-3 text-sm outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setCancelModal(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {showSuccessModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-md">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="flex justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="81.179"
                      height="80.95"
                      viewBox="0 0 81.179 80.95"
                    >
                      <g
                        id="Group_3595"
                        data-name="Group 3595"
                        transform="translate(1255.233 273.233)"
                      >
                        <path
                          id="Path_5123"
                          data-name="Path 5123"
                          d="M-1214.61-192.283a40.588,40.588,0,0,1-40.623-40.5,40.591,40.591,0,0,1,40.556-40.451,40.588,40.588,0,0,1,40.623,40.5A40.591,40.591,0,0,1-1214.61-192.283Zm-.022-7.352a33.3,33.3,0,0,0,33.208-33.106,33.3,33.3,0,0,0-33.174-33.14,33.3,33.3,0,0,0-33.266,33.163A33.3,33.3,0,0,0-1214.632-199.635Z"
                          transform="translate(0 0)"
                          fill="#ff6a00"
                        />
                        <path
                          id="Path_5124"
                          data-name="Path 5124"
                          d="M-1156.178-141.674c7.005-7.056,13.781-13.887,20.565-20.711a3.847,3.847,0,0,1,4.459-1.076,3.63,3.63,0,0,1,.987,6.013c-2.527,2.6-5.128,5.134-7.7,7.7q-7.626,7.6-15.254,15.205c-2.053,2.043-4,2.049-6.063.008q-5.735-5.686-11.446-11.4c-2.128-2.138-1.728-5.077.807-6.258a3.739,3.739,0,0,1,4.374,1.019q4.195,4.149,8.353,8.336A13.841,13.841,0,0,1-1156.178-141.674Z"
                          transform="translate(-64.144 -84.343)"
                          fill="#ff6a00"
                        />
                      </g>
                    </svg>
                  </p>
                  <p className="text-2xl font-semibold text-theme  text-center mt-4 ">
                    Your Booking Has
                  </p>
                  <p className="text-2xl font-semibold text-theme  text-center mb-4">
                    Cancel Successfully{" "}
                  </p>
                  <p className="mt-4 text-center">
                    If You Paid Your Money Will Refund In A Next
                  </p>
                  <p className="mb-4 text-center">5 Working Days</p>
                  <div className="flex items-center justify-center rounded-b">
                    <Link
                      to="/"
                      className="pl-1 pr-2 rounded w-40 h-12 border-2 border-theme font-medium inline-flex items-center justify-center shadow hover:bg-theme hover:text-white focus:bg-theme"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.752"
                        height="13.027"
                        viewBox="0 0 16.752 13.027"
                        className=""
                      >
                        <path
                          id="Icon_awesome-home"
                          data-name="Icon awesome-home"
                          d="M8.153,5.633,2.791,10.049v4.766a.465.465,0,0,0,.465.465l3.259-.008a.465.465,0,0,0,.463-.465V12.023a.465.465,0,0,1,.465-.465H9.3a.465.465,0,0,1,.465.465V14.8a.465.465,0,0,0,.465.467l3.258.009a.465.465,0,0,0,.465-.465V10.046L8.6,5.633A.355.355,0,0,0,8.153,5.633Zm8.469,3-2.431-2V2.6a.349.349,0,0,0-.349-.349H12.214a.349.349,0,0,0-.349.349V4.714l-2.6-2.142a1.4,1.4,0,0,0-1.774,0L.126,8.635a.349.349,0,0,0-.047.491l.742.9a.349.349,0,0,0,.492.047l6.84-5.634a.355.355,0,0,1,.445,0l6.841,5.634a.349.349,0,0,0,.491-.047l.742-.9a.349.349,0,0,0-.049-.492Z"
                          transform="translate(0.001 -2.254)"
                          fill="#ff6a00"
                        />
                      </svg>

                      <span className="truncate text-sm ml-2">
                        Back To Home
                      </span>
                      {/* <svg viewBox="0 0 9 6" width="9" height="6"><path d="m4.5 6l-4.5-6h9z" fill="#fff" /></svg> */}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
