import React, { useState, useEffect, useContext } from "react";
// import Tooltip from "../../../components/Tooltip";
import {
  // getAllCourts,
  getAllHours,
  getAvailability,
  getSlotAvailability,
  // startBooking,
} from "../../../apis/booking.api";
import { CartContext } from "../../../context/Cart.context";
import { Spinner, OverlayTrigger, Tooltip as TP, Modal } from "react-bootstrap";
import { formatedTime } from "../../../utils/time";
import { formatInTimeZone } from "date-fns-tz";
import { FiCalendar } from "react-icons/fi";

const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const dayDefault = ["S", "M", "T", "W", "T", "F", "S"];

const Booking = ({ openSidebar }) => {
  const { addToCart, cart, removeFromCart } = useContext(CartContext);
  // const [MonthDrop, setMonthDrop] = useState(false);

  const date = new Date();
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [selectedYear, setSelectedYear] = useState(date.getFullYear());
  const [available, setAvailable] = useState({});
  const [isLoading, setLoading] = useState(true);

  const [apiLoading, setAPILoading] = useState(false);

  const [showCourtModal, setShowCourtModal] = useState(false);
  const [courtSelected, setCourtSelected] = useState(null);

  const [slotAvailability, setSlotAvailabiliy] = React.useState(null);

  // const [courts, setCourts] = React.useState([]);
  const handleClose = () => {
    setShowCourtModal(false);
    setCourtSelected(null);
  };

  const [slotData, setSlotData] = useState({});
  const handleShow = async (date, time, endTime) => {
    // setAPILoading(true);
    try {
      const availability = await getSlotAvailability({ date, time });
      setSlotAvailabiliy(availability);
      setAPILoading(false);
      setShowCourtModal(true);
      setSlotData({
        date,
        time,
        endTime,
      });
    } catch (error) {
      setAPILoading(false);
      setShowCourtModal(false);
    }
  };

  const [hours, setHours] = useState([]);

  const getDates = () => {
    const arr = [];
    const mon = new Date(selectedYear, selectedMonth + 1, 0);
    for (let index = 0; index < mon.getDate(); index++) {
      // const date = `${
      //  '0' + (index + 1 )+ "-" + selectedMonth + 1 + "-" + selectedYear
      // }`;
      const date = `${selectedYear}-${("0" + parseInt(selectedMonth + 1)).slice(
        -2
      )}-${("0" + (index + 1)).slice(-2)}T12:00:00Z`;
      const ud = new Date(date);

      const weekend = ud.getDay() === 0 || ud.getDay() === 6 ? true : false;

      arr.push({
        date: index + 1,
        day: dayDefault[ud.getDay()],
        weekend,
      });
    }
    return arr;
  };

  const availCall = async (month, year) => {
    setLoading(true);
    try {
      const call = await getAvailability(month, year);
      setAvailable(call);
      setLoading(false);
    } catch (error) {}
  };
  useEffect(() => {
    const date = new Date();
    setSelectedMonth(date.getMonth());
    setSelectedYear(date.getFullYear());
    availCall(date.getMonth() + 1, date.getFullYear());
  }, []);

  const initCall = async () => {
    const getHours = await getAllHours();

    setHours(getHours);

    // const courts = await getAllCourts();
    // setCourts(courts);
  };

  useEffect(() => {
    initCall();
  }, []);

  const NextMonth = () => {
    if (selectedMonth === 11) {
      setSelectedMonth(0);
      setSelectedYear(selectedYear + 1);
      availCall(1, selectedYear + 1);
    } else {
      setSelectedMonth(selectedMonth + 1);
      availCall(selectedMonth + 2, selectedYear);
    }

    // selMonth = selMonth + 1;
    // Jump(selMonth);
  };

  const PrevMonth = () => {
    if (selectedMonth === 0) {
      setSelectedMonth(11);
      setSelectedYear(selectedYear - 1);
      availCall(12, selectedYear - 1);
    } else {
      setSelectedMonth(selectedMonth - 1);
      availCall(selectedMonth, selectedYear);
    }
  };

  // const onItemAdd = (date, time) => {
  //   addToCart(date, time, "court");
  // };

  const onCourtConfirmed = async () => {
    setAPILoading(true);
    try {
      addToCart({ ...slotData, type: "court", court: courtSelected });
      handleClose();
      setAPILoading(false);
    } catch (error) {}
  };

  // const createBooking = async () => {
  //   await startBooking();
  // };

  return (
    <>
      <div className="flex w-full justify-between items-center bg-white shadow rounded mb-2 md:p-4">
        <div className="h-8 flex justify-center items-center  mt-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 13.097 13"
            className="font-bold"
          >
            <path
              id="Icon_material-access-time"
              data-name="Icon material-access-time"
              d="M9.542,3A6.5,6.5,0,1,0,16.1,9.5,6.521,6.521,0,0,0,9.542,3Zm.007,11.7a5.2,5.2,0,1,1,5.239-5.2A5.218,5.218,0,0,1,9.549,14.7Zm.327-8.45H8.894v3.9L12.332,12.2l.491-.8L9.876,9.663Z"
              transform="translate(-3 -3)"
              fill="#222"
            />
          </svg>
          <span className="font-bold ml-1 text-sm">TIME SLOT</span>
        </div>
        <div className="courtInfo flex justify-center items-center">
          <div className="court flex items-center justify-center">
            <div className="w-5 h-5 rounded-0.35 border-2 border-gray-300 sm:mr-2"></div>
            <span className="sm:block hidden text-xs mr-3">Available</span>
          </div>
          <div className="court flex items-center justify-center">
            <div className="w-5 h-5 rounded-0.35 border-2 bg-gray-400 border-gray-400 sm:mr-2"></div>
            <span className="sm:block hidden text-xs mr-3">Not Available</span>
          </div>
          <div className="court flex items-center justify-center">
            <div className="w-5 h-5 rounded-0.35 border-2 bg-red-500 border-red-500 sm:mr-2"></div>
            <span className="sm:block hidden text-xs mr-3">Booked</span>
          </div>
          <div className="court flex items-center justify-center">
            <div className="w-5 h-5 rounded-0.35 border-2 bg-green-700 border-green-700 sm:mr-2"></div>
            <span className="sm:block hidden text-xs mr-3">Selected</span>
          </div>
          {/* <Tooltip text="Available"></Tooltip>
          <Tooltip text="Not Available"></Tooltip>
          <Tooltip text="Booked"></Tooltip>
          <Tooltip text="Selected"></Tooltip> */}
        </div>
      </div>

      {isLoading ? (
        <div className="min-h-[80vh] flex justify-center items-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <div className="booktable table-container bg-white shadow rounded p-1 relative mb-10">
            <div className="table-horizontal-container">
              <table className="unfixed-table w-full">
                <thead className="bg-white">
                  <tr>
                    <td>
                      <table width="100%">
                        <thead>
                          <tr>
                            <th>
                              <div className="inline-flex justify-center flex-col min-w-100 h-16 shadow bg-sec text-white text-sm items-center  py-2 z-50">
                                <div className="flex justify-center items-center mt-2">
                                  <FiCalendar className="mr-1 inline" />

                                  <span
                                    id="monthview"
                                    className="font-extralight ml-1 text-xs uppercase"
                                  >
                                    {month[selectedMonth]}, {selectedYear}
                                  </span>
                                </div>
                                <div className="flex justify-center items-center mt-1">
                                  <button
                                    id="prevMonth"
                                    className="mr-2"
                                    onClick={() => PrevMonth()}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="flex rotate-180"
                                      width="18"
                                      height="18"
                                      fill="currentColor"
                                      viewBox="0 0 16 16"
                                    >
                                      {" "}
                                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />{" "}
                                    </svg>
                                  </button>
                                  <button
                                    id="nextMonth"
                                    onClick={() => NextMonth()}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="flex"
                                      width="18"
                                      height="18"
                                      fill="currentColor"
                                      viewBox="0 0 16 16"
                                    >
                                      {" "}
                                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />{" "}
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </th>
                            <td >
                              <table width="100%" id="calendar-body">
                                <thead>
                                  <tr >
                                    {getDates().map((date, i) => (
                                      <td key={i + "day"}>
                                        <div className="w-full inline-flex flex-wrap justify-center">
                                          <div className="bg-white court flex flex-wrap items-center justify-center w-8 h-16 rounded text-xs text-center">
                                            <div className="bg-white w-5 text-theme">
                                              {date.day}
                                            </div>
                                            <div className="bg-white align-middle w-5 rounded-full hover:bg-theme hover:text-white">
                                              {date.date}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    ))}
                                  </tr>
                                </thead>
                              </table>
                            </td>
                          </tr>
                        </thead>
                      </table>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <table width="100%">
                        <tbody>
                          {hours.map((hour, i) => (
                            <tr key={i + "hour"} className="border-t">
                              <td className="fixedtab ">
                                <div className="w-full h-8 bg-orange-100 shadow flex justify-center items-center text-black">
                                  {formatedTime(hour.time)}
                                </div>
                              </td>
                              {getDates().map((date, key) => {
                                // const now = Date.now();
                                const _dt = `${selectedMonth + 1}/${
                                  date.date
                                }/${selectedYear}`;
                                // const ren = new Date(
                                //   `${selectedYear}-${(
                                //     "0" + parseInt(selectedMonth + 1)
                                //   ).slice(-2)}-${("0" + date.date).slice(
                                //     -2
                                //   )}T${"12:00:00"}Z`
                                // );
                                // const renzz = new Date(
                                //   `${selectedYear}-${(
                                //     "0" + parseInt(selectedMonth + 1)
                                //   ).slice(-2)}-${date.date}T${hour.time}Z`
                                // );
                                // console.log(renzz.getDate());
                                // console.log(
                                //   `${selectedYear}-${(
                                //     "0" + parseInt(selectedMonth + 1)
                                //   ).slice(-2)}-${date.date}T${hour.time}Z`
                                // );
                                const today = new Date();
                                // const dayRen = parseInt(
                                //   formatInTimeZone(ren, "America/Toronto", "dd")
                                // );
                                const dayToday = formatInTimeZone(
                                  today,
                                  "America/Toronto",
                                  "dd-MM-yyyy-HH-mm"
                                )
                                  .split("-")
                                  .map((i) => parseInt(i));
                                const time = hour.time
                                  .split(":")
                                  .map((_t) => parseInt(_t));

                                const slotAvailable =
                                  available[
                                    `${selectedYear}-${(
                                      "0" + parseInt(selectedMonth + 1)
                                    ).slice(-2)}-${("0" + date.date).slice(-2)}`
                                  ][hour.time];

                                const isBookingTimeAvailable = () => {
                                  if (
                                    selectedYear <= dayToday[2] &&
                                    selectedMonth + 1 < dayToday[1]
                                  ) {
                                    return false;
                                  }

                                  if (
                                    selectedMonth + 1 === dayToday[1] &&
                                    date.date < dayToday[0]
                                  ) {
                                    return false;
                                  }

                                  if (
                                    date.date === dayToday[0] &&
                                    selectedMonth + 1 === dayToday[1]
                                  ) {
                                    // if (ren.valueOf() > today.valueOf()) {
                                    if (time[0] > dayToday[3]) {
                                      // console.log(today.valueOf());
                                      if (date.weekend) {
                                        return hour.dayAvailable === "both" ||
                                          hour.dayAvailable === "weekend"
                                          ? true
                                          : false;
                                      }
                                      if (!date.weekend) {
                                        return hour.dayAvailable === "both" ||
                                          hour.dayAvailable === "weekday"
                                          ? true
                                          : false;
                                      }
                                      return true;
                                    }
                                    return false;
                                  }
                                  if (date.weekend) {
                                    return hour.dayAvailable === "both" ||
                                      hour.dayAvailable === "weekend"
                                      ? true
                                      : false;
                                  }
                                  if (!date.weekend) {
                                    return hour.dayAvailable === "both" ||
                                      hour.dayAvailable === "weekday"
                                      ? true
                                      : false;
                                  }
                                  return true;
                                };

                                const isInCart = () => {
                                  const item = cart.filter(
                                    (_i) =>
                                      _i.date ===
                                        `${selectedYear}-${(
                                          "0" + parseInt(selectedMonth + 1)
                                        ).slice(-2)}-${("0" + date.date).slice(
                                          -2
                                        )}` && _i.time === hour.time
                                  );
                                  // console.log(item);
                                  if (item.length > 0) {
                                    return true;
                                  }
                                  return false;

                                  // for (
                                  //   let index = 0;
                                  //   index < cart.length;
                                  //   index++
                                  // ) {
                                  //   const _ct = cart[index];
                                  //   if (_ct.type !== "court") {
                                  //     return false;
                                  //   }
                                  //   // const _dts = _ct.date.split("-");
                                  //   // const _tui = new Date(
                                  //   //   `${_dts[1]}/${_dts[2]}/${_dts[0]}`
                                  //   // );
                                  //   const _tui = new Date(
                                  //     `${_ct.date}T${_ct.time}Z`
                                  //   );
                                  //   // console.log(_tui, ren);
                                  //   // const _time = _ct.time
                                  //   //   .split(":")
                                  //   //   .map((_t) => parseInt(_t));

                                  //   if (
                                  //     ren.valueOf() === _tui.valueOf()
                                  //     // (time[0] * 60 + time[1]) * 60 * 1000 ===

                                  //     // (_time[0] * 60 + _time[1]) * 60 * 1000
                                  //   ) {
                                  //     return true;
                                  //   }
                                  // }
                                };
                                const getCartID = (isItem, time) => {
                                  // var status = false;
                                  if (!isItem) {
                                    return;
                                  }
                                  const item = cart.filter(
                                    (_i) =>
                                      _i.date ===
                                        `${selectedYear}-${(
                                          "0" + parseInt(selectedMonth + 1)
                                        ).slice(-2)}-${("0" + date.date).slice(
                                          -2
                                        )}` && _i.time === hour.time
                                  );
                                  // console.log(item);
                                  if (item.length > 0) {
                                    return item[0].id;
                                  }
                                  // for (
                                  //   let index = 0;
                                  //   index < cart.length;
                                  //   index++
                                  // ) {
                                  //   const _ct = cart[index];
                                  //   // const _dts = _ct.date.split("-");
                                  //   // const _tui = new Date(
                                  //   //   `${_dts[1]}/${_dts[2]}/${_dts[0]}`
                                  //   // );
                                  //   // const _time = _ct.time
                                  //   //   .split(":")
                                  //   //   .map((_t) => parseInt(_t));

                                  //   const _tui = new Date(
                                  //     `${_ct.date}T${_ct.time}Z`
                                  //   );
                                  //   // console.log(_tui, ren);

                                  //   if (
                                  //     ren.valueOf() === _tui.valueOf()
                                  //     // (time[0] * 60 + time[1]) *
                                  //     //   60 *
                                  //     //   1000 +
                                  //     //   (_time[0] * 60 + _time[1]) * 60 * 1000
                                  //   ) {
                                  //     return cart[index].id;
                                  //   }
                                  // }
                                };
                                const isChecked = isInCart(time);
                                return (
                                  <td className="date-picker border-l" key={key}>
                                    <div
                                      className={`${
                                        !isBookingTimeAvailable()
                                          ? "notavail"
                                          : slotAvailable
                                          ? null
                                          : "booked"
                                      }  court w-8 h-8 flex items-center justify-center mt-1`}
                                    >
                                      <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 150, hide: 200 }}
                                        overlay={
                                          <TP id="button-tooltip">
                                            {!isBookingTimeAvailable() ||
                                            !slotAvailable
                                              ? "Not Available"
                                              : `$${hour.price}`}
                                          </TP>
                                        }
                                      >
                                        {({ ref, ...triggerHandler }) => (
                                          <div
                                            className="relative"
                                            {...triggerHandler}
                                          >
                                            <input
                                              type="checkbox"
                                              ref={ref}
                                              value=""
                                              className="absolute h-full w-full opacity-0 cursor-pointer"
                                              checked={
                                                isBookingTimeAvailable()
                                                  ? isChecked
                                                  : false
                                              }
                                              disabled={
                                                !isBookingTimeAvailable() ||
                                                !slotAvailable
                                              }
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  // onItemAdd(
                                                  //   _dt,
                                                  //   hour.time,
                                                  //   hour.price
                                                  // );
                                                  handleShow(
                                                    _dt,
                                                    hour.time,
                                                    hour.endTime
                                                  );
                                                } else {
                                                  removeFromCart(
                                                    getCartID(isChecked, time)
                                                  );
                                                }
                                              }}
                                            />

                                            <label className="w-5 h-5 mx-auto flex rounded-0.35 border-2 border-gray-300 bg-white shadow" />
                                          </div>
                                        )}
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>{" "}
          <Modal
            show={showCourtModal}
            centered
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Select Court</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {slotData?.time && (
                <>
                  <p>
                    Date: <span className="font-semibold">{slotData.date}</span>
                  </p>
                  <p>
                    Time:{" "}
                    <span className="font-semibold">
                      {formatedTime(slotData?.time)} -{" "}
                      {formatedTime(slotData?.endTime)} (1 Hour)
                    </span>
                  </p>
                </>
              )}
              <div className="grid grid-cols-3 md:grid-cols-5 gap-x-4 gap-y-4 mt-5">
                {slotAvailability &&
                  Object.keys(slotAvailability).map((court) => (
                    <div key={court}>
                      <button
                        disabled={slotAvailability && !slotAvailability[court]}
                        className={`p-4 border rounded-lg shadow-md w-full ${
                          slotAvailability && !slotAvailability[court]
                            ? "bg-red-300 border-red-300"
                            : "border-gray-300"
                        } ${
                          court === courtSelected
                            ? "bg-green-300 border-green-300"
                            : "border-gray-300"
                        }`}
                        onClick={() => setCourtSelected(court)}
                      >
                        Court {court}
                      </button>
                    </div>
                  ))}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button variant="secondary" onClick={handleClose}>
                Close
              </button>
              <button
                className={`bg-theme px-3 py-1 rounded shadow text-white ${
                  courtSelected === null && " opacity-50"
                }`}
                disabled={courtSelected === null || apiLoading}
                onClick={onCourtConfirmed}
              >
                {apiLoading ? <Spinner /> : "Add"}
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default Booking;
