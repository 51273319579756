import React, { useContext } from "react";
import { googleAuth } from "../../../apis/user.api";
import { useSearchParams } from "react-router-dom";
import { AuthContext } from "../../../context/Auth.context";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function GoogleCallback() {
  const [searchParams] = useSearchParams();
  const [setCookies] = useCookies(["session_token"]);
  const navigate = useNavigate();

  const { setAuth, onLogin } = useContext(AuthContext);
  let initialized = false;

  // console.log(searchParams.get("code"));
  const callGAuthService = async () => {
    try {
      const loginAPI = await googleAuth({
        code: searchParams.get("code"),
        scope: searchParams.get("scope"),
      });
      onLogin(loginAPI.access_token);
      // setCookies("session_token", loginAPI.access_token, {
      //   path: "/",
      //   domain: process.env.REACT_APP_DOMAIN,
      //   maxAge: 60 * 60 * 3,
      // });
      // localStorage.setItem("token", loginAPI.access_token);
      // setAuth(true);
      // navigate("/user");
      // navigate("/user");
    } catch (error) {
      // console.log(error);
      toast.error("Something went wrong, try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        progress: undefined,
        theme: "light",
      });
      navigate("/");
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (!initialized) {
      initialized = true;
      callGAuthService();
    }
  }, []);
  return <div>Loading</div>;
}
