import React, { useContext, useState } from "react";
import User from "../../../assets/username.svg";
import Password from "../../../assets/password.svg";
// import Apple from "../../../assets/apple.svg";
// import Facebook from "../../../assets/facebook.svg";
// import Twitter from "../../../assets/twitter.svg";
import Google from "../../../assets/google.svg";
// import { GoogleLogin } from "react-google-login";
import { Link } from "react-router-dom";
// import { useGoogleLogin } from "@react-oauth/google";
// import { GoogleOAuthProvider } from "@react-oauth/google";
import { loginUser, verifyUser } from "../../../apis/user.api";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/Auth.context";
// import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { toastConf } from "../../AdminDashboard/Dropins";

function Login() {
  const navigate = useNavigate();
  // const [cookies, setCookies] = useCookies(["session_token"]);

  const { onLogin } = useContext(AuthContext);

  const [loading, setLoading] = React.useState(false);

  const [otpScreen, setOTPScreen] = useState(false);

  const onLoginCall = async (data) => {
    setLoading(true);
    try {
      const loginAPI = await loginUser(data);
      onLogin(loginAPI.access_token);
      setLoading(false);
      // setCookies("session_token", loginAPI.access_token, {
      //   path: "/",
      //   domain: process.env.REACT_APP_DOMAIN,
      //   maxAge: 60 * 60 * 3,
      // });
      // localStorage.setItem("token", loginAPI.access_token);
      // setAuth(true);

      // navigate("/user");
    } catch (error) {
      // console.log(error);
      setLoading(false);
      if (error.response.status === 403) {
        setOTPScreen(true);
      }
      toast.error(
        error.response.data.message ?? "Something went wrong, try again.",
        toastConf
      );
    }
  };

  const onVerifyCall = async (data) => {
    setLoading(true);
    try {
      await verifyUser(data);
      // onLogin(verifyAPI.access_token);
      toast.success("Account Activated.", toastConf);
      onLoginCall(data);
    } catch (error) {
      setLoading(false);
      toast.error(
        error.response.data.message ?? "Something went wrong, try again.",
        toastConf
      );
    }
  };

  // const login = useGoogleLogin({
  //   onSuccess: (tokenResponse) => console.log(tokenResponse),
  // });
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto max-w-md">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="modal-bg bg-cover flex items-center justify-between p-5 bg-sec text-white border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl font-semibold">
                LOG IN TO WINGS BADMINTON CLUB
              </h3>
              {/* <button
                className="p-1 ml-auto border-0 text-white flex justify-center items-center text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setLoginModal(false)}
              >
                ×
              </button> */}
            </div>

            {/*body*/}
            <div className="relative p-6 flex-auto">
              <Formik
                initialValues={{
                  username: "",
                  password: "",
                  otp: "",
                }}
                validationSchema={LoginSchema}
                onSubmit={(values) => {
                  otpScreen ? onVerifyCall(values) : onLoginCall(values);
                  // console.log(values);
                }}
                validateOnBlur={false}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                }) => (
                  <Form>
                    {otpScreen ? (
                      <>
                        <p className="mb-3 text-center">
                          <sm>
                            A OTP has been sent to your email{" "}
                            {values["username"]}.
                          </sm>
                        </p>
                        <OtpInput
                          value={values["otp"]}
                          onChange={handleChange("otp")}
                          numInputs={6}
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => (
                            <input
                              className="w-full border border-gray-50"
                              {...props}
                            />
                          )}
                          inputStyle={{
                            width: "2rem",
                            border: "1px solid #cfcfcf",
                            borderRadius: 5,
                            margin: "auto 5px",
                            padding: "5px",
                          }}
                          placeholder="00000000"
                          containerStyle={{ justifyContent: "center" }}
                        />
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          disabled={loading}
                          className="login w-full flex items-center justify-center relative h-14 text-lg text-white rounded-lg bg-theme mt-3"
                        >
                          {loading ? <Spinner /> : "Verify"}
                          <svg
                            width="8.227"
                            height="14.388"
                            viewBox="0 0 8.227 14.388"
                            className="absolute right-5"
                          >
                            <path
                              id="Icon_ionic-ios-arrow-forward"
                              data-name="Icon ionic-ios-arrow-forward"
                              d="M16.993,13.388,11.548,7.947a1.024,1.024,0,0,1,0-1.452,1.037,1.037,0,0,1,1.457,0l6.169,6.164a1.026,1.026,0,0,1,.03,1.418l-6.194,6.207a1.028,1.028,0,1,1-1.457-1.452Z"
                              transform="translate(-11.246 -6.196)"
                              fill="#fff"
                            />
                          </svg>
                        </button>
                      </>
                    ) : (
                      <div className="grid gap-4  grid-cols-1">
                        <div>
                          {/* <label htmlFor="first_name" className="block mb-2 text-base font-semibold text-sec">Username / username Id</label> */}
                          <div className="relative flex w-full flex-wrap items-stretch">
                            <input
                              type="email"
                              onChange={handleChange("username")}
                              onBlur={handleBlur("username")}
                              value={values.username}
                              placeholder="Enter your email"
                              className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                            />
                            <span className="z-10 h-full leading-snug font-normal absolute text-center text-white bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                              <img src={User} alt="" />
                            </span>
                          </div>
                          {errors.username && touched.username && (
                            <span className="text-red-600 text-xs">
                              {errors.username}
                            </span>
                          )}
                        </div>

                        <div>
                          {/* <label htmlFor="password" className="block mb-2 text-base font-semibold text-sec">Password</label> */}
                          <div className="relative flex w-full flex-wrap items-stretch">
                            <input
                              type="password"
                              onChange={handleChange("password")}
                              onBlur={handleBlur("password")}
                              value={values.password}
                              placeholder="Enter your password"
                              className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                            />
                            <span className="z-10 h-full leading-snug font-normal absolute text-center text-white bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                              <img src={Password} alt="" />
                            </span>
                          </div>
                          {errors.password && touched.password && (
                            <span className="text-red-600 text-xs">
                              {errors["password"]}
                            </span>
                          )}
                        </div>
                        <div className="flex justify-between">
                          <div className="form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label inline-block text-sec text-sm"
                              htmlFor="flexCheckDefault"
                            >
                              Remember
                            </label>
                          </div>
                          <button
                            className="text-theme text-sm"
                            onClick={() => navigate("/auth/request")}
                          >
                            Forgot Password?
                          </button>
                        </div>

                        <button
                          type="submit"
                          onClick={handleSubmit}
                          disabled={loading}
                          className="login flex items-center justify-center relative h-14 text-lg text-white rounded-lg bg-theme mt-3"
                        >
                          {loading ? <Spinner /> : "Login"}
                          <svg
                            width="8.227"
                            height="14.388"
                            viewBox="0 0 8.227 14.388"
                            className="absolute right-5"
                          >
                            <path
                              id="Icon_ionic-ios-arrow-forward"
                              data-name="Icon ionic-ios-arrow-forward"
                              d="M16.993,13.388,11.548,7.947a1.024,1.024,0,0,1,0-1.452,1.037,1.037,0,0,1,1.457,0l6.169,6.164a1.026,1.026,0,0,1,.03,1.418l-6.194,6.207a1.028,1.028,0,1,1-1.457-1.452Z"
                              transform="translate(-11.246 -6.196)"
                              fill="#fff"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
            {otpScreen ? null : (
              <>
                {/*footer*/}
                <div className="flex flex-wrap items-center justify-center pb-4">
                  <ul className="inline-flex gap-6">
                    <li>
                      {/* <button className="border-2 border-solid rounded-lg hover:border-theme h-10 w-10 inline-flex p-2">
                    <img src={Google} alt="" />
                  </button> */}

                      <button
                        // onClick={() => login()}
                        onClick={() =>
                          window.location.replace(
                            `${process.env.REACT_APP_API_URL}auth/google`
                          )
                        }
                        className="border-2 border-solid rounded-lg hover:border-theme h-10 w-10 inline-flex p-2"
                      >
                        <img src={Google} alt="" />
                      </button>
                    </li>
                    {/* <li>
                      <button className="border-2 border-solid rounded-lg hover:border-theme h-10 w-10 inline-flex p-2">
                        <img src={Facebook} alt="" />
                      </button>
                    </li> */}
                    {/* <li>
                  <button className="border-2 border-solid rounded-lg hover:border-theme h-10 w-10 inline-flex p-2">
                    <img src={Apple} alt="" />
                  </button>
                </li> */}
                    {/* <li>
                      <button className="border-2 border-solid rounded-lg hover:border-theme h-10 w-10 inline-flex p-2">
                        <img src={Twitter} alt="" />
                      </button>
                    </li> */}
                  </ul>
                </div>

                <div className="flex items-center justify-center p-2 ">
                  <button
                    className="text-sec background-transparent font-bold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    // onClick={() => setSignupModal(false)}
                  >
                    Don't have an accounts ? Go to{" "}
                    <Link to="/auth/register" className="text-theme">
                      Register
                    </Link>
                  </button>
                </div>
              </>
            )}
            {/* <div className="flex items-center justify-center p-6 ">
                <button
                  className="text-sec background-transparent font-bold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setLoginModal(false)}
                >
                  No account ? Go to <a href="#"className="text-theme" onClick={() => setSignupModal(true)}>Sign Up</a>
                </button>
                
              </div> */}
          </div>
        </div>
      </div>
      {/* <div className="opacity-50 fixed inset-0 z-40 bg-black"></div> */}
    </>
  );
}

export default Login;
const LoginSchema = Yup.object().shape({
  username: Yup.string().email("Invalid email").required("Email is Required."),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars minimum"),
});
