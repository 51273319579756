import React, { useContext, useState } from "react";
import { Routes, Route, Outlet, useNavigate } from "react-router-dom";
import Login from "./AdminLogin";
import CourtLayout from "./CourtLayout";
import Users from "./Users/Users";
import Dashboard from "./dashboard";
// import Booking3 from "./booking-list-3";
// import Booking5 from "./booking-list-5";
// import Booking6 from "./booking-list-6";
// import Booking8 from "./booking-list-8";
// import Booking9 from "./booking-list-9";
// import Booking10 from "./booking-list-10";
import Membership from "./Memberships/Membership";
import Coaching from "./Coaching/Coaching";
import DropIn from "./Dropins";
import ViewDropin from "./Dropins/ViewDropin";
import ViewUser from "./Users/ViewUser";
import ViewMembership from "./Memberships/ViewMembership";
import HourChart from "./HourChart";
import ViewCoaching from "./Coaching/ViewCoaching";
import Spaces from "./Spaces/Spaces";
import Bookings from "./Bookings/Bookings";
import ViewBooking from "./Bookings/ViewBooking";
import { AuthContext } from "../../context/Auth.context";
import AdminZoneProvider from "../../context/AdminZone.context";
// import Head from "./head";
// import Side from "./side";

export default function AdminRoute() {
  const { isAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // const getAdminDetail =() => {
  //   const
  // }

  React.useEffect(() => {
    if (!isAuth) {
      return navigate("/admin/login");
    }
    // console.log("Trial");
    setLoading(false);
    return;
  }, [navigate, isAuth]);
  return (
    <>
      <AdminZoneProvider>
        <>
          <Outlet />
          <Routes>
            <Route path="/">
              <Route path="login" element={<Login />} />
              <Route index element={<Dashboard />} />
              <Route path="login" element={<Login />} />
              <Route path="court" element={<CourtLayout />} />
              <Route path="users">
                <Route index element={<Users />} />
                <Route path=":id" element={<ViewUser />} />
              </Route>
              <Route path="membership">
                <Route index element={<Membership />} />
                <Route path=":id" element={<ViewMembership />} />
              </Route>
              <Route path="coaching">
                <Route index element={<Coaching />} />
                <Route path=":id" element={<ViewCoaching />} />
              </Route>
              <Route path="dropins">
                <Route index element={<DropIn />} />
                <Route path=":id" element={<ViewDropin />} />
              </Route>
              <Route path="bookings">
                <Route index element={<Bookings />} />
                <Route path=":id" element={<ViewBooking />} />
              </Route>
              <Route path="hours" element={<HourChart />} />
              <Route path="spaces" element={<Spaces />} />
              {/* <Route path="booking3" element={<Booking3 />} />
          
          
          <Route path="booking8" element={<Booking8 />} />
          <Route path="booking9" element={<Booking9 />} />
          
          
          <Route path="head" element={<Head />} />
          <Route path="side" element={<Side />} /> */}
            </Route>
          </Routes>
        </>
      </AdminZoneProvider>
    </>
  );
}
