import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import WingsLogo from "../assets/wings-logo.svg";
import { AuthContext } from "../context/Auth.context";
import {
  FiAward,
  FiCalendar,
  FiCast,
  FiClock,
  FiHome,
  FiLock,
  FiLogOut,
  FiShoppingCart,
  FiUser,
} from "react-icons/fi";

const SideMenu = (props) => {
  const show = props.show;

  const { logout } = useContext(AuthContext);
  return (
    <>
      <nav
        id="sidebar"
        className={`h-full max-w-182 lg:max-w-260 w-full  relative ${
          show
            ? "z-[100] block w-full Fullmenu"
            : "z-[0] md:z-[40] md:w-[60px] w-0 overflow-hidden Iconmenu"
        }`}
      >
        <ul
          className={`sideNav b-5 text-white gap-y-2 bg-sec h-full fixed shadow-xl pt-4 flex flex-col`}
        >
          <span
            className={`absolute cursor-pointer z-10 left-52 top-3.5 rounded-full w-8 h-8 border-2 border-theme ${
              show ? "block" : "hidden"
            }`}
            onClick={props.close}
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="20" height="20" />
              <path
                d="M14 14L34 34"
                stroke="#ff6a00"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 34L34 14"
                stroke="#ff6a00"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>

          <li className="md:min-h-58 min-h-48">
            <div className="flex justify-center items-center">
              <Link to="/user" className="h-12">
                <img
                  src={WingsLogo}
                  alt="ico"
                  className={`w-12 ${show ? "block" : "hidden"}`}
                />
              </Link>
              <Link to="/user" className="h-10">
                <img
                  src={WingsLogo}
                  alt="ico"
                  className={`w-10 ${show ? "hidden" : "block"}`}
                />
              </Link>
            </div>
          </li>
          <li className="sm:min-h-42 min-h-35">
            <NavLink
              to="/user/dashboard"
              className="leading-4 whitespace-nowrap overflow-hidden px-3 sm:py-3 py-2 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
            >
              <svg
                
                className="mx-2"
                width="18"
                height="18"
                viewBox="0 0 15.634 15.634"
              >
                <path
                  d="M4.5,13.186h6.948V4.5H4.5Zm0,6.948h6.948V14.923H4.5Zm8.686,0h6.948V11.448H13.186Zm0-15.634V9.711h6.948V4.5Z"
                  transform="translate(-4.5 -4.5)"
                  fill="#fff"
                />
              </svg>
              <span>Dashboard</span>
            </NavLink>
          </li>
          {/* <li className="min-h-18">
            </li> */}
          <li className="sm:min-h-42 min-h-35">
            <NavLink
              to="/user/court-rental"
              className="leading-4 whitespace-nowrap overflow-hidden px-3 sm:py-3 py-2 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
            >
              <svg
                
                className="mx-2"
                width="18"
                height="18"
                viewBox="0 0 17.154 12.988"
              >
                <g transform="translate(-600.459 166.418)">
                  <path
                    id="Path_2265"
                    data-name="Path 2265"
                    d="M642.545-81.99H636.03c-.35,0-.439-.089-.439-.438,0-.467,0-.935,0-1.4a.328.328,0,0,1,.368-.376h13.066a1.3,1.3,0,0,1,.19.006.3.3,0,0,1,.282.317q.006.788,0,1.576a.3.3,0,0,1-.315.317c-.161.006-.323,0-.484,0Z"
                    transform="translate(-33.509 -78.413)"
                    fill="#fff"
                  />
                  <path
                    id="Path_2266"
                    data-name="Path 2266"
                    d="M658.4,68.813c.141-.511.278-1,.415-1.5.051-.183.113-.364.152-.549.027-.129.086-.16.211-.16q2.99.006,5.98,0c1.417,0,2.834,0,4.252,0a.2.2,0,0,1,.232.179c.167.629.347,1.255.522,1.882.012.044.02.089.032.148Z"
                    transform="translate(-55.261 -222.261)"
                    fill="#fff"
                  />
                  <path
                    id="Path_2267"
                    data-name="Path 2267"
                    d="M680.36-22.378v3.455h-4.784l.3-1.065q.313-1.123.624-2.247c.027-.1.052-.158.177-.157,1.187.005,2.373,0,3.56,0C680.272-22.389,680.311-22.382,680.36-22.378Z"
                    transform="translate(-71.648 -137.373)"
                    fill="#fff"
                  />
                  <path
                    id="Path_2268"
                    data-name="Path 2268"
                    d="M793.169-18.919v-3.459c.061,0,.117-.01.172-.01,1.158,0,2.315,0,3.473,0,.131,0,.187.033.223.167.285,1.05.581,2.1.873,3.147.014.049.024.1.038.159Z"
                    transform="translate(-183.809 -137.375)"
                    fill="#fff"
                  />
                  <path
                    id="Path_2269"
                    data-name="Path 2269"
                    d="M604.235-22.321c-.228.822-.448,1.616-.668,2.409-.346,1.243-.7,2.485-1.035,3.729a.218.218,0,0,1-.258.194c-.478-.012-.956,0-1.434-.005-.322,0-.451-.186-.341-.489q1.033-2.851,2.072-5.7c.02-.054.08-.132.122-.133C603.2-22.325,603.7-22.321,604.235-22.321Z"
                    transform="translate(0 -137.441)"
                    fill="#fff"
                  />
                  <path
                    id="Path_2270"
                    data-name="Path 2270"
                    d="M890.4-22.322c.521,0,1.027,0,1.533.007.044,0,.1.09.126.15q.813,2.223,1.619,4.448c.147.406.3.812.443,1.218.116.323-.011.5-.356.5-.49,0-.979,0-1.469,0-.1,0-.153-.015-.184-.127q-.848-3.064-1.7-6.126A.586.586,0,0,1,890.4-22.322Z"
                    transform="translate(-276.552 -137.441)"
                    fill="#fff"
                  />
                  <path
                    id="Path_2271"
                    data-name="Path 2271"
                    d="M730.948-165.476c.081-.293.153-.577.243-.855.014-.043.114-.08.174-.081.576-.006,1.151,0,1.727,0,.84,0,1.681,0,2.521,0,.132,0,.194.033.225.167.059.257.137.51.21.775Z"
                    transform="translate(-124.462)"
                    fill="#fff"
                  />
                  <path
                    id="Path_2272"
                    data-name="Path 2272"
                    d="M796.07-130.362h-2.846v-1.568h2.406Z"
                    transform="translate(-183.862 -32.896)"
                    fill="#fff"
                  />
                  <path
                    id="Path_2273"
                    data-name="Path 2273"
                    d="M720.477-132.209v1.573h-2.843l.146-.527c.085-.305.166-.612.258-.915.016-.051.079-.125.121-.126C718.924-132.211,719.69-132.209,720.477-132.209Z"
                    transform="translate(-111.763 -32.629)"
                    fill="#fff"
                  />
                  <path
                    id="Path_2274"
                    data-name="Path 2274"
                    d="M677.154-166.324l-.294,1.054c-.179.644-.361,1.288-.535,1.933-.03.111-.069.16-.2.157-.38-.01-.759,0-1.157,0,.024-.07.039-.124.06-.176q.532-1.351,1.066-2.7c.094-.239.16-.285.411-.286.2,0,.392,0,.588,0A.281.281,0,0,1,677.154-166.324Z"
                    transform="translate(-71.071 -0.068)"
                    fill="#fff"
                  />
                  <path
                    id="Path_2275"
                    data-name="Path 2275"
                    d="M850.435-166.287c.26,0,.519-.015.774.009a.335.335,0,0,1,.224.183c.391.965.769,1.935,1.15,2.9a.212.212,0,0,1,0,.062c-.4,0-.805,0-1.207,0a.141.141,0,0,1-.1-.082q-.424-1.5-.838-3.009A.287.287,0,0,1,850.435-166.287Z"
                    transform="translate(-238.43 -0.12)"
                    fill="#fff"
                  />
                </g>
              </svg>
              <span>Court Rental</span>
            </NavLink>
          </li>

          <li className="sm:min-h-42 min-h-35">
            <NavLink
              to="/user/drop-in"
              className="leading-4 whitespace-nowrap overflow-hidden px-3 sm:py-3 py-2 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mx-2"
                width="18"
                height="18"
                viewBox="0 0 27.072 28.28"
              >
                <g transform="translate(-686.031 -229.899)">
                  <path
                    id="Path_5092"
                    data-name="Path 5092"
                    d="M720.024,250.547a1.211,1.211,0,0,0-.094.21q-.534,2.287-1.062,4.575c-.144.624-.28,1.249-.436,1.87a1.238,1.238,0,0,1-1.288.974,1.315,1.315,0,0,1-1.207-1.15,1.472,1.472,0,0,1,.041-.48q.711-3.135,1.431-6.27a1.408,1.408,0,0,0,.033-.411q-.27-3.567-.55-7.133c-.025-.323-.062-.646-.066-.969,0-.2-.071-.284-.272-.314-.4-.059-.8-.145-1.207-.217a.8.8,0,0,1-.67-.6c-.456-1.522-.913-3.043-1.352-4.569a1.722,1.722,0,0,1,.031-.54,1.249,1.249,0,0,0,.013-.391c-.1-.364-.23-.719-.339-1.081-.036-.121-.083-.177-.223-.17a1.831,1.831,0,0,1-1.825-1.138,2.059,2.059,0,0,1,.171-2.233,1.57,1.57,0,0,1,1.808-.517,2.169,2.169,0,0,1,1.174,2.977,2.628,2.628,0,0,1-.6.655c-.11.1-.161.155-.111.3.131.377.251.758.373,1.134a.926.926,0,0,1,1.215.773c.358,1.228.715,2.458,1.083,3.683a.335.335,0,0,0,.208.2c.444.1.892.18,1.341.256a.47.47,0,0,0,.265-.041,4.549,4.549,0,0,1,4.108.053,12.324,12.324,0,0,1,1.358.79.992.992,0,0,1,.312.391c.531,1.062,1.057,2.128,1.565,3.2a.881.881,0,0,1,.058.536q-.421,1.8-.881,3.6a.861.861,0,0,1-1.033.636.878.878,0,0,1-.582-1.068c.254-1.017.518-2.032.775-3.049a.338.338,0,0,0,.016-.2,8,8,0,0,0-.541-1.029c-.014.128-.03.255-.04.383-.147,1.939-.3,3.877-.43,5.818a9.647,9.647,0,0,0,.082,1.361c.046.584.092,1.169.154,1.753a1.887,1.887,0,0,0,.143.529c.389.915.791,1.826,1.183,2.74a1.226,1.226,0,0,1-.191,1.326,1.178,1.178,0,0,1-1.2.435,1.217,1.217,0,0,1-.94-.745c-.46-1.061-.94-2.116-1.342-3.2a5.561,5.561,0,0,1-.191-1.39c-.071-.737-.125-1.475-.186-2.212Z"
                    transform="translate(-12.223)"
                    fill="#fff"
                  />
                  <path
                    id="Path_5093"
                    data-name="Path 5093"
                    d="M691.278,240.432c.187-.094.352-.175.514-.259a5.263,5.263,0,0,1,5.143.033,1.719,1.719,0,0,1,.452.352c.39.427.768.866,1.142,1.308a.256.256,0,0,0,.334.094c.433-.161.872-.308,1.312-.451a.815.815,0,0,1,.914.214.832.832,0,0,1,.221.854.774.774,0,0,1-.507.5c-.707.245-1.414.488-2.125.72a.8.8,0,0,1-.892-.285c-.22-.245-.43-.5-.678-.788-.009.114-.014.179-.019.243a13.257,13.257,0,0,0,.43,3.709c.29,1.452.568,2.907.857,4.359a.2.2,0,0,1-.12.257,7.727,7.727,0,0,1-7.721.053.313.313,0,0,1-.2-.4q.614-3.087,1.213-6.176a3.538,3.538,0,0,0,.065-.619c.009-.586,0-1.173,0-1.76,0-.082-.007-.164-.013-.283-.143.07-.251.124-.359.175a.874.874,0,0,1-1.333-.588c-.41-1.39-.812-2.783-1.223-4.173a.968.968,0,0,1,.17-.915.337.337,0,0,0,.053-.262c-.2-.647-.422-1.29-.632-1.935a.177.177,0,0,0-.222-.139,1.991,1.991,0,0,1-2.017-2.219,1.774,1.774,0,0,1,1.333-1.674,1.61,1.61,0,0,1,1.3.241,2.252,2.252,0,0,1,.811,2.7,1.591,1.591,0,0,1-.664.751c-.112.064-.152.119-.106.254.219.634.423,1.273.643,1.907a.284.284,0,0,0,.173.171.882.882,0,0,1,.786.712c.292,1,.576,2,.863,3C691.214,240.22,691.243,240.314,691.278,240.432Z"
                    transform="translate(0 -0.217)"
                    fill="#fff"
                  />
                  <path
                    id="Path_5094"
                    data-name="Path 5094"
                    d="M695.089,273.978a6.87,6.87,0,0,0,2.461.475c-.057.428-.15.891-.173,1.357a3.022,3.022,0,0,1-.676,1.707c-.473.628-.9,1.292-1.357,1.93a1.264,1.264,0,0,1-2.237-.427,1.307,1.307,0,0,1,.24-1.136c.434-.609.852-1.231,1.291-1.835a1.355,1.355,0,0,0,.25-.656C694.95,274.911,695.024,274.433,695.089,273.978Z"
                    transform="translate(-3.48 -21.813)"
                    fill="#fff"
                  />
                  <path
                    id="Path_5095"
                    data-name="Path 5095"
                    d="M703.682,274.279a6.284,6.284,0,0,0,2.458-.581c.016.036.055.116.085.2q.755,2.062,1.507,4.124a1.311,1.311,0,0,1-.424,1.554,1.267,1.267,0,0,1-1.9-.536C704.823,277.451,704.252,275.855,703.682,274.279Z"
                    transform="translate(-8.735 -21.675)"
                    fill="#fff"
                  />
                  <path
                    id="Path_5096"
                    data-name="Path 5096"
                    d="M729.154,241.548a2.116,2.116,0,1,1-2.091-2.149A2.12,2.12,0,0,1,729.154,241.548Z"
                    transform="translate(-19.246 -4.701)"
                    fill="#fff"
                  />
                  <path
                    id="Path_5097"
                    data-name="Path 5097"
                    d="M702.547,241.531a2.129,2.129,0,0,1-2.13,2.111,2.155,2.155,0,0,1-2.1-2.127,2.115,2.115,0,1,1,4.23.016Z"
                    transform="translate(-6.08 -4.701)"
                    fill="#fff"
                  />
                  <path
                    id="Path_5098"
                    data-name="Path 5098"
                    d="M699.355,230.513l1.095,2.368c-.373.055-.712.108-1.052.155-.389.054-.778.1-1.168.154a.584.584,0,0,1-.666-.337.62.62,0,0,1,.125-.818q.728-.676,1.461-1.348C699.2,230.636,699.264,230.589,699.355,230.513Z"
                    transform="translate(-5.659 -0.304)"
                    fill="#fff"
                  />
                </g>
              </svg>
              <span>Drop In</span>
            </NavLink>
          </li>
          <li className="sm:min-h-42 min-h-35">
            <NavLink
              to="/user/membership"
              className="leading-4 whitespace-nowrap overflow-hidden px-3 sm:py-3 py-2 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
            >
              <FiAward className="mx-2" />
              <span>Membership Plans</span>
            </NavLink>
          </li>
          <li className="sm:min-h-42 min-h-35">
            <NavLink
              to="/user/coaching"
              className="leading-4 whitespace-nowrap overflow-hidden px-3 sm:py-3 py-2 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
            >
              <FiCast className="mx-2" />
              <span>Coaching Plans</span>
            </NavLink>
          </li>
          <li className="sm:min-h-42 min-h-35">
            <NavLink
              to="/user/bookings"
              className="leading-4 whitespace-nowrap overflow-hidden px-3 sm:py-3 py-2 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
            >
              <FiCalendar className="mx-2" />
              <span>My Bookings</span>
            </NavLink>
          </li>
          <li className="sm:min-h-42 min-h-35">
            <NavLink
              to="/user/schedules"
              className="leading-4 whitespace-nowrap overflow-hidden px-3 sm:py-3 py-2 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
            >
              <FiClock className="mx-2" />
              <span>My Schedules</span>
            </NavLink>
          </li>
          <li className="sm:min-h-42 min-h-35">
            <NavLink
              to="/user/transactions"
              className="leading-4 whitespace-nowrap overflow-hidden px-3 sm:py-3 py-2 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
            >
              <FiShoppingCart className="mx-2" />
              <span>Transactions</span>
            </NavLink>
          </li>
          <li className="sm:min-h-42 min-h-35">
            <NavLink
              to="/user/"
              className="md:absolute leading-4 md:bottom-34 whitespace-nowrap overflow-hidden px-3 sm:py-3 py-2 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
            >
              <FiHome className="mx-2" />
              <span>Home</span>
            </NavLink>
          </li>
          <li className="sm:min-h-42 min-h-35">
            <NavLink
              to="/user/profile"
              className="md:absolute leading-4 md:bottom-22 whitespace-nowrap overflow-hidden px-3 sm:py-3 py-2 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
            >
              <FiUser className="mx-2" />
              <span>Profile</span>
            </NavLink>
          </li>
          <li className="sm:min-h-42 min-h-35">
            <NavLink
              onClick={logout}
              className="md:absolute leading-4 md:bottom-9 whitespace-nowrap overflow-hidden px-3 sm:py-3 py-2 flex items-center w-full rounded-tr-full rounded-br-full hover:bg-theme focus:bg-theme"
            >
              <FiLogOut className="mx-2" />
              <span>Log Out</span>
            </NavLink>
          </li>
          {/* <li className="min-h-42 ">
                <div className="md:absolute md:bottom-0"></div>
            </li> */}
        </ul>
      </nav>
      <div
        className={`fixed left-0 top-0 inset-0 bg-gray-500 transition-opacity z-[99] bg-opacity-75 w-full md:w-0 ${
          show ? "block" : "hidden"
        }`}
        onClick={props.close}
      />
    </>
  );
};

export default SideMenu;
