import React from "react";
import User from "../../../assets/username.svg";
import { requestPasswordReset } from "../../../apis/user.api";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { toastConf } from "../../AdminDashboard/Dropins";
import { useNavigate } from "react-router-dom";

function RequestPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const onLoginCall = async (data) => {
    setLoading(true);
    try {
      await requestPasswordReset(data);
      setLoading(false);
      navigate("/auth/login");
      toast("Email sent successfully!");
    } catch (error) {
      setLoading(false);
      toast.error(
        error.response.data.message ?? "Something went wrong, try again.",
        toastConf
      );
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto max-w-md">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="modal-bg bg-cover flex items-center justify-between p-5 bg-sec text-white border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl font-semibold">FORGOT PASSWORD</h3>
            </div>

            {/*body*/}
            <div className="relative p-6 flex-auto">
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={LoginSchema}
                onSubmit={(values) => {
                  onLoginCall(values);
                }}
                validateOnBlur={false}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                }) => (
                  <Form>
                    {
                      <div className="grid gap-4  grid-cols-1">
                        <div>
                          {/* <label htmlFor="first_name" className="block mb-2 text-base font-semibold text-sec">Username / username Id</label> */}
                          <div className="relative flex w-full flex-wrap items-stretch">
                            <input
                              type="email"
                              onChange={handleChange("email")}
                              onBlur={handleBlur("email")}
                              value={values.email}
                              placeholder="Enter your email"
                              className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                            />
                            <span className="z-10 h-full leading-snug font-normal absolute text-center text-white bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                              <img src={User} alt="" />
                            </span>
                          </div>
                          {errors.email && touched.email && (
                            <span className="text-red-600 text-xs">
                              {errors.email}
                            </span>
                          )}
                        </div>

                        <button
                          type="submit"
                          onClick={handleSubmit}
                          disabled={loading}
                          className="login flex items-center justify-center relative h-14 text-lg text-white rounded-lg bg-theme mt-3"
                        >
                          {loading ? <Spinner /> : "Request"}
                          <svg
                            width="8.227"
                            height="14.388"
                            viewBox="0 0 8.227 14.388"
                            className="absolute right-5"
                          >
                            <path
                              id="Icon_ionic-ios-arrow-forward"
                              data-name="Icon ionic-ios-arrow-forward"
                              d="M16.993,13.388,11.548,7.947a1.024,1.024,0,0,1,0-1.452,1.037,1.037,0,0,1,1.457,0l6.169,6.164a1.026,1.026,0,0,1,.03,1.418l-6.194,6.207a1.028,1.028,0,1,1-1.457-1.452Z"
                              transform="translate(-11.246 -6.196)"
                              fill="#fff"
                            />
                          </svg>
                        </button>
                        <div className="flex justify-between">
                          <div className="form-check"></div>
                          <button
                            onClick={() => navigate("/auth/login")}
                            className="text-theme text-sm"
                          >
                            Back To Login
                          </button>
                        </div>
                      </div>
                    }
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="opacity-50 fixed inset-0 z-40 bg-black"></div> */}
    </>
  );
}

export default RequestPassword;
const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is Required."),
});
