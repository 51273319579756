import React, { useContext } from "react";
import { getAllCourses, getMyEnrollments } from "../../../apis/booking.api";
import { format, parseISO } from "date-fns";
import { CartContext } from "../../../context/Cart.context";

export default function CoachingPlans() {
  const { addToCart, cart, removeFromCart } = useContext(CartContext);
  const [courses, setCourses] = React.useState([]);
  const [myEnrollment, setEnrollments] = React.useState([]);
  const initCall = async () => {
    const myEnrollments = await getMyEnrollments({ type: "course" });
    setEnrollments(myEnrollments);
    const courseAPI = await getAllCourses();
    setCourses(courseAPI);
    // console.log(dropinAPI);
    return;
  };

  const isInCart = (id) => {
    const course = cart.filter((item) => item.dependentID === id);

    if (course.length > 0) {
      return true;
    }
    return false;
  };

  const getCartItemID = (id) => {
    const course = cart.filter((item) => item.dependentID === id);

    return course[0].id;
  };

  React.useEffect(() => {
    initCall();
  }, []);

  const BTNComponent = (item) => {
    if (isInCart(item.courseID)) {
      return (
        <button
          className="text-right flex-end"
          onClick={() => {
            removeFromCart(getCartItemID(item.courseID));
          }}
        >
          <p className=" w-fit rounded-full border-2 border-theme text-base font-bold mb-1.5 px-3 py-1 bg-theme text-white ">
            Remove From Cart
          </p>
        </button>
      );
    }
    if (
      myEnrollment.filter((f) => f.dependentID === item.courseID).length > 0
    ) {
      return (
        <button
          disabled
          className={`rounded-full border-2 border-theme text-base font-bold  mb-1.5 px-3 py-1 bg-theme text-white`}
        >
          Already Enrolled
        </button>
      );
    }
    return (
      <button
        className="text-right flex-end"
        onClick={() => {
          addToCart({ type: "course", dependentID: item.courseID });
        }}
      >
        <p className=" w-fit rounded-full border-2 border-theme text-base font-bold text-theme mb-1.5 px-3 py-1 hover:bg-theme hover:text-white ">
          Enroll
        </p>
      </button>
    );
  };

  return (
    <div className="py-2">
      <div className="container-fluid">
        {courses.map((c, i) => (
          <div
            className="bg-stone-100 shadow rounded-md border md:p-4 p-2 mb-3"
            key={i}
          >
            <div className="md:flex justify-between mb-2">
              <div className="md:text-lg text-base text-theme font-semibold inline-flex">
                <svg
                  className="mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.889"
                  height="18.469"
                  viewBox="0 0 14.889 18.469"
                >
                  <g
                    id="Group_3609"
                    data-name="Group 3609"
                    transform="matrix(0.951, -0.309, 0.309, 0.951, 0, 2.439)"
                  >
                    <path
                      id="Path_5131"
                      data-name="Path 5131"
                      d="M3.442,6.974l.281-2.889a4.085,4.085,0,0,1,.4.592,8.514,8.514,0,0,1,1.185,5.547L5.886,8.64c.046.13.091.264.137.4h0A54.391,54.391,0,0,1,7.891,16.6a29.552,29.552,0,0,0-1.369-2.9,50.643,50.643,0,0,0-3.543-5.72C2.132,6.792,1.162,5.535.061,4.243c0,0,.713-1.2-.061-4.243C0,0,3.2,1.507,3.442,6.974Z"
                      transform="translate(0)"
                      fill="#ff6a00"
                    />
                    <path
                      id="Path_5132"
                      data-name="Path 5132"
                      d="M1.977,6.428l.971-2.221a3.4,3.4,0,0,1,.164.574,7.111,7.111,0,0,1-.495,4.711l.87-1.108c0,.115,0,.233.005.353h0a45.448,45.448,0,0,1-.474,6.488,24.717,24.717,0,0,0-.335-2.66A42.294,42.294,0,0,0,1.349,7.108C.984,5.945.54,4.7,0,3.385,0,3.385.875,2.62,1.05,0,1.05,0,3.2,2.026,1.977,6.428Z"
                      transform="translate(6.048 3.594)"
                      fill="#222"
                    />
                  </g>
                </svg>
                Course: {c.title}
              </div>
              <div className="card-point w-fit">
                {/* <button className="">
                  <p className="rounded-full border-2 border-theme text-base font-bold text-theme mb-1.5 px-3 py-1 hover:bg-theme hover:text-white md:block hidden">
                    Enroll
                  </p>
                </button> */}

                {BTNComponent(c)}
              </div>
            </div>
            <div className="md:flex">
              <div className="md:w-3/12">
                <p className="mb-2">
                  <b>Course Amount :</b> {c.price} CAD
                </p>
                <p className="md:mb-0 mb-2">
                  <b>Starts At :</b>{" "}
                  {format(
                    parseISO(`${c.chart[0]?.date}T23:59:59`),
                    "dd MMM, yyyy"
                  )}
                </p>
              </div>
              <div className="md:w-9/12">
                <p className="text-justify md:mb-0 mb-2">
                  <b>Description : {c.description}</b>
                </p>
              </div>
            </div>
            <div className="flex justify-end md:hidden bblock">
              <div className="card-point pt-2">
                {BTNComponent(c)}
                {/* <button className="text-right flex-end">
                  <p className=" w-fit rounded-full border-2 border-theme text-base font-bold text-theme mb-1.5 px-3 py-1 hover:bg-theme hover:text-white ">
                    Enroll
                  </p>
                </button> */}
              </div>
            </div>
          </div>
        ))}
        {/* <div className="bg-stone-100 md:p-4 p-2 mb-3">
          <div className="md:flex justify-between mb-2">
            <div className="md:text-lg text-base text-theme font-semibold inline-flex">
              <svg
                className="mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="14.889"
                height="18.469"
                viewBox="0 0 14.889 18.469"
              >
                <g
                  id="Group_3609"
                  data-name="Group 3609"
                  transform="matrix(0.951, -0.309, 0.309, 0.951, 0, 2.439)"
                >
                  <path
                    id="Path_5131"
                    data-name="Path 5131"
                    d="M3.442,6.974l.281-2.889a4.085,4.085,0,0,1,.4.592,8.514,8.514,0,0,1,1.185,5.547L5.886,8.64c.046.13.091.264.137.4h0A54.391,54.391,0,0,1,7.891,16.6a29.552,29.552,0,0,0-1.369-2.9,50.643,50.643,0,0,0-3.543-5.72C2.132,6.792,1.162,5.535.061,4.243c0,0,.713-1.2-.061-4.243C0,0,3.2,1.507,3.442,6.974Z"
                    transform="translate(0)"
                    fill="#ff6a00"
                  />
                  <path
                    id="Path_5132"
                    data-name="Path 5132"
                    d="M1.977,6.428l.971-2.221a3.4,3.4,0,0,1,.164.574,7.111,7.111,0,0,1-.495,4.711l.87-1.108c0,.115,0,.233.005.353h0a45.448,45.448,0,0,1-.474,6.488,24.717,24.717,0,0,0-.335-2.66A42.294,42.294,0,0,0,1.349,7.108C.984,5.945.54,4.7,0,3.385,0,3.385.875,2.62,1.05,0,1.05,0,3.2,2.026,1.977,6.428Z"
                    transform="translate(6.048 3.594)"
                    fill="#222"
                  />
                </g>
              </svg>
              Year Membership Monday To Friday 6 AM To 4 PM
            </div>
            <div className="card-point w-fit">
              <button className="">
                <p className="rounded-full border-2 border-theme text-base font-bold text-theme mb-1.5 px-3 py-1 hover:bg-theme hover:text-white md:block hidden">
                  Buy Membership
                </p>
              </button>
            </div>
          </div>
          <div className="md:flex">
            <div className="md:w-3/12">
              <p className="mb-2">
                <b>Subscription Amount :</b> 350 CAD
              </p>
              <p className="md:mb-0 mb-2">
                <b>Plan for :</b> Signal Adult
              </p>
            </div>
            <div className="md:w-9/12">
              <p className="text-justify md:mb-0 mb-2">
                <b>Description : </b>Detail information about this plan. Example
                (Per Day usage limit 3 hours) Detail information about this
                plan. Example (Per Day usage limit 3 hours)Detail information
                about this plan. Example (Per Day usage limit 3 hours)
              </p>
            </div>
          </div>
          <div className="flex justify-end md:hidden bblock">
            <div className="card-point pt-2">
              <button className="text-right flex-end">
                <p className=" w-fit rounded-full border-2 border-theme text-base font-bold text-theme mb-1.5 px-3 py-1 hover:bg-theme hover:text-white ">
                  Buy Membership
                </p>
              </button>
            </div>
          </div>
        </div>
        <div className="bg-stone-100 md:p-4 p-2 mb-3">
          <div className="md:flex justify-between mb-2">
            <div className="md:text-lg text-base text-theme font-semibold inline-flex">
              <svg
                className="mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="14.889"
                height="18.469"
                viewBox="0 0 14.889 18.469"
              >
                <g
                  id="Group_3609"
                  data-name="Group 3609"
                  transform="matrix(0.951, -0.309, 0.309, 0.951, 0, 2.439)"
                >
                  <path
                    id="Path_5131"
                    data-name="Path 5131"
                    d="M3.442,6.974l.281-2.889a4.085,4.085,0,0,1,.4.592,8.514,8.514,0,0,1,1.185,5.547L5.886,8.64c.046.13.091.264.137.4h0A54.391,54.391,0,0,1,7.891,16.6a29.552,29.552,0,0,0-1.369-2.9,50.643,50.643,0,0,0-3.543-5.72C2.132,6.792,1.162,5.535.061,4.243c0,0,.713-1.2-.061-4.243C0,0,3.2,1.507,3.442,6.974Z"
                    transform="translate(0)"
                    fill="#ff6a00"
                  />
                  <path
                    id="Path_5132"
                    data-name="Path 5132"
                    d="M1.977,6.428l.971-2.221a3.4,3.4,0,0,1,.164.574,7.111,7.111,0,0,1-.495,4.711l.87-1.108c0,.115,0,.233.005.353h0a45.448,45.448,0,0,1-.474,6.488,24.717,24.717,0,0,0-.335-2.66A42.294,42.294,0,0,0,1.349,7.108C.984,5.945.54,4.7,0,3.385,0,3.385.875,2.62,1.05,0,1.05,0,3.2,2.026,1.977,6.428Z"
                    transform="translate(6.048 3.594)"
                    fill="#222"
                  />
                </g>
              </svg>
              Year Membership Monday To Friday 6 AM To 4 PM
            </div>
            <div className="card-point w-fit">
              <button className="">
                <p className="rounded-full border-2 border-theme text-base font-bold text-theme mb-1.5 px-3 py-1 hover:bg-theme hover:text-white md:block hidden">
                  Buy Membership
                </p>
              </button>
            </div>
          </div>
          <div className="md:flex">
            <div className="md:w-3/12">
              <p className="mb-2">
                <b>Subscription Amount :</b> 350 CAD
              </p>
              <p className="md:mb-0 mb-2">
                <b>Plan for :</b> Signal Adult
              </p>
            </div>
            <div className="md:w-9/12">
              <p className="text-justify md:mb-0 mb-2">
                <b>Description : </b>Detail information about this plan. Example
                (Per Day usage limit 3 hours) Detail information about this
                plan. Example (Per Day usage limit 3 hours)Detail information
                about this plan. Example (Per Day usage limit 3 hours)
              </p>
            </div>
          </div>
          <div className="flex justify-end md:hidden bblock">
            <div className="card-point pt-2">
              <button className="text-right flex-end">
                <p className=" w-fit rounded-full border-2 border-theme text-base font-bold text-theme mb-1.5 px-3 py-1 hover:bg-theme hover:text-white ">
                  Buy Membership
                </p>
              </button>
            </div>
          </div>
        </div>
        <div className="bg-stone-100 md:p-4 p-2 mb-3">
          <div className="md:flex justify-between mb-2">
            <div className="md:text-lg text-base text-theme font-semibold inline-flex">
              <svg
                className="mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="14.889"
                height="18.469"
                viewBox="0 0 14.889 18.469"
              >
                <g
                  id="Group_3609"
                  data-name="Group 3609"
                  transform="matrix(0.951, -0.309, 0.309, 0.951, 0, 2.439)"
                >
                  <path
                    id="Path_5131"
                    data-name="Path 5131"
                    d="M3.442,6.974l.281-2.889a4.085,4.085,0,0,1,.4.592,8.514,8.514,0,0,1,1.185,5.547L5.886,8.64c.046.13.091.264.137.4h0A54.391,54.391,0,0,1,7.891,16.6a29.552,29.552,0,0,0-1.369-2.9,50.643,50.643,0,0,0-3.543-5.72C2.132,6.792,1.162,5.535.061,4.243c0,0,.713-1.2-.061-4.243C0,0,3.2,1.507,3.442,6.974Z"
                    transform="translate(0)"
                    fill="#ff6a00"
                  />
                  <path
                    id="Path_5132"
                    data-name="Path 5132"
                    d="M1.977,6.428l.971-2.221a3.4,3.4,0,0,1,.164.574,7.111,7.111,0,0,1-.495,4.711l.87-1.108c0,.115,0,.233.005.353h0a45.448,45.448,0,0,1-.474,6.488,24.717,24.717,0,0,0-.335-2.66A42.294,42.294,0,0,0,1.349,7.108C.984,5.945.54,4.7,0,3.385,0,3.385.875,2.62,1.05,0,1.05,0,3.2,2.026,1.977,6.428Z"
                    transform="translate(6.048 3.594)"
                    fill="#222"
                  />
                </g>
              </svg>
              Year Membership Monday To Friday 6 AM To 4 PM
            </div>
            <div className="card-point w-fit">
              <button className="">
                <p className="rounded-full border-2 border-theme text-base font-bold text-theme mb-1.5 px-3 py-1 hover:bg-theme hover:text-white md:block hidden">
                  Buy Membership
                </p>
              </button>
            </div>
          </div>
          <div className="md:flex">
            <div className="md:w-3/12">
              <p className="mb-2">
                <b>Subscription Amount :</b> 350 CAD
              </p>
              <p className="md:mb-0 mb-2">
                <b>Plan for :</b> Signal Adult
              </p>
            </div>
            <div className="md:w-9/12">
              <p className="text-justify md:mb-0 mb-2">
                <b>Description : </b>Detail information about this plan. Example
                (Per Day usage limit 3 hours) Detail information about this
                plan. Example (Per Day usage limit 3 hours)Detail information
                about this plan. Example (Per Day usage limit 3 hours)
              </p>
            </div>
          </div>
          <div className="flex justify-end md:hidden bblock">
            <div className="card-point pt-2">
              <button className="text-right flex-end">
                <p className=" w-fit rounded-full border-2 border-theme text-base font-bold text-theme mb-1.5 px-3 py-1 hover:bg-theme hover:text-white ">
                  Buy Membership
                </p>
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
