import apis from "./api";

const prefix = "analytics";

export const getStats = () => {
  return apis.get(`${prefix}/stats`, {});
};

export const getUseMatrics = () => {
  return apis.get(`${prefix}/matrics/users`, {});
};

export const getBookingMatrics = () => {
  return apis.get(`${prefix}/matrics/bookings`, {});
};

export const getSalesMatrics = () => {
  return apis.get(`${prefix}/matrics/sales`, {});
};
