import { format, parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import {
  getCheckout,
  deleteFromMyCart,
  goCheckout,
} from "../../../apis/booking.api";
import { CartContext } from "../../../context/Cart.context";
import { formatedTime } from "../../../utils/time";
import { useNavigate } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import { MdRemoveShoppingCart } from "react-icons/md";
import {
  getMyCards,
  getPaymentStatus,
  paymentIntent,
} from "../../../apis/payment.api";
import {
  FaCcAmex,
  FaCcDinersClub,
  FaCcDiscover,
  FaCcJcb,
  FaCcMastercard,
  FaCcVisa,
} from "react-icons/fa";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const weekDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const tags = (tag) => {
  const _t = {
    court: "Court Rental",
    membership: "Membership",
    dropin: "Drop-in",
    course: "Course",
  };
  return _t[tag] ?? "";
};
export default function Checkout() {
  const navigate = useNavigate();
  const elements = useElements();
  const stripe = useStripe();

  const [myCards, setMyCards] = React.useState([]);

  const { initCall: callCart } = useContext(CartContext);
  const [isLoading, setLoading] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [loadingState, setLoadingState] = useState({
    wallet: false,
  });
  const [paymentsMethod, setPaymentsMethod] = useState(null);

  const [checkoutItem, setCheckoutItems] = React.useState({
    cart: [],
    total: 0,
    tax: 0,
    taxedTotal: 0,
    grandTotal: 0,
    wallet: 0,
  });

  const initCall = async () => {
    const itemsAPI = await getCheckout();
    setCheckoutItems(itemsAPI);

    const cards = await getMyCards();
    setMyCards(cards);
  };
  React.useEffect(() => {
    initCall();
    return () => {
      //   console.log("returned");
      callCart();
    };
  }, []);

  const removeFromCart = async (id) => {
    // var _c = [...checkoutItem.cart];
    // setLoading(true);

    try {
      await deleteFromMyCart(id);
      initCall();
      //   setLoading(false);
    } catch (error) {
      //   setLoading(false);
    }

    // _c = _c.filter((item) => item.id !== id);
    // setCheckoutItems({ ...checkoutItem, cart: _c });
  };

  const deleteFromCart = async (items) => {
    toast.success("Removing from cart.");
    items.forEach((i, index) => {
      //   console.log(i, pos);
      removeFromCart(i.id, items.length > 1 ? true : null);
    });
  };

  const renderedCart = () => {
    const courtItem = checkoutItem.cart.filter((item) => item.type === "court");
    const nonCourtItem = checkoutItem.cart.filter(
      (item) => item.type !== "court"
    );

    const groups = courtItem.reduce((groups, item) => {
      const date = item.date;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push({
        ...item,
        sortTime: parseInt(item.time.replace(":", "")),
      });
      return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        type: "court",
        items: groups[date]
          .sort((a, b) => a.sortTime - b.sortTime)
          .reduce((r, n) => {
            const lastSubArray = r[r.length - 1];
            const currentSlot = parseInt(n.time.split(":")[0]);

            if (
              !lastSubArray ||
              parseInt(
                lastSubArray[lastSubArray?.length - 1].time.split(":")[0]
              ) !==
                currentSlot - 1
            ) {
              r.push([]);
            }

            r[r.length - 1].push(n);

            return r;
          }, []),
      };
    });
    return [...groupArrays, ...nonCourtItem];
  };

  const onCheckout = async () => {
    setLoading(true);
    try {
      await goCheckout();
      toast.success("Booking Successful.");
      // setLoading(false);
      setPaymentModal(false);
      setLoadingState({
        wallet: false,
      });
      navigate("/user/bookings");
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message ?? "Something went wrong.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null || paymentsMethod === null) {
      return;
    }
    setLoadingState({
      ...loadingState,
      wallet: true,
    });

    const intentCall = async (id) => {
      try {
        const response = await paymentIntent({
          amount: parseFloat(checkoutItem.grandTotal * 100).toFixed(2),
          id,
          paymentMethod: id,
        });

        if (response.success) {
          const { error } = await stripe.confirmCardPayment(
            response.clientSecret
          );
          if (error) {
            setLoadingState({
              ...loadingState,
              wallet: false,
            });
            return toast.error(error.message);
          }
          // console.log("Successful Payment");
          var status = "pending";
          const interval = setInterval(async () => {
            if (status === "success" || status === "failed") {
              clearInterval(interval);
              setPaymentModal(false);
              setLoadingState({
                ...loadingState,
                wallet: false,
              });
              // callWallet();
              onCheckout();
            } else {
              const data = await getPaymentStatus(response.transID);
              status = data.status;
            }
          }, 3000);
        }
      } catch (error) {
        // console.log("Error", error);
        setLoadingState({
          ...loadingState,
          wallet: false,
        });
        toast.error(
          error.response.data.message ?? "Something went wrong, try again."
        );
      }
    };
    if (paymentsMethod === "new") {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });
      if (!error) {
        const { id } = paymentMethod;
        // console.log(id);
        await intentCall(id);
      }
    } else {
      intentCall(paymentsMethod);
    }

    // else {
    //   console.log(error.message);
    //   toast.error(
    //     error.response.data.message ?? "Something went wrong, try again.",
    //     {
    //       position: "top-right",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       progress: undefined,
    //       theme: "light",
    //     }
    //   );
    // }
  };
  return (
    <>
      <div className="min-h-screen  p-12">
        <div className=" min-h-[90vh] max-w-[500px] bg-white overflow-hidden drop-shadow z-[110]  w-full m-auto">
          <div className="">
            <div className="flex items-center justify-between p-5 bg-sec text-white">
              <h3 className="text-xl font-semibold">
                <span className="inline-flex ml-2">CHECKOUT</span>
              </h3>
              {/* <button
              onClick={() => setcartpanel(false)}
              className="p-1 ml-auto border-0 text-white flex justify-center items-center text-3xl leading-none font-semibold outline-none focus:outline-none"
            >
              ×
            </button> */}
            </div>
          </div>
          {renderedCart().length > 0 ? (
            <>
              <div className="p-4">
                <ul className="cart-items-list ">
                  {renderedCart().map(
                    ({ date, items, type, metadata, id }, i) => {
                      // if (items.type === "court") {
                      const _d = new Date(`${date}T12:00:00Z`);
                      const today = new Date();
                      const dayToday = formatInTimeZone(
                        today,
                        "America/Toronto",
                        "dd-MM-yyyy-HH-mm"
                      )
                        .split("-")
                        .map((i) => parseInt(i));
                      // const dayRen = date
                      //   ? date?.split("-").map((i) => parseInt(i))
                      //   : [0, 0, 0];
                      const dayRen = () => {
                        if (type === "court") {
                          return date?.split("-").map((i) => parseInt(i));
                        }
                        if (type === "dropin") {
                          return metadata.date
                            ?.split("-")
                            .map((i) => parseInt(i));
                        }
                        if (type === "course") {
                          return metadata.chart[0].date
                            ?.split("-")
                            .map((i) => parseInt(i));
                        }
                        return [0, 0, 0];
                      };
                      if (type !== "court") {
                        const time =
                          type === "dropin"
                            ? metadata?.hours[0]?.time
                                .split(":")
                                .map((i) => parseInt(i))
                            : [23, 59, 59];
                        const isExpired = () => {
                          if (type === "membership") {
                            return false;
                          }
                          if (
                            dayToday[2] > dayRen[0] ||
                            dayToday[1] > dayRen[1] ||
                            dayToday[0] > dayRen[2]
                          ) {
                            return true;
                          }
                          if (
                            dayToday[0] === dayRen[2] &&
                            dayToday[1] === dayRen[1] &&
                            dayToday[2] === dayRen[0] &&
                            dayToday[3] >= time[0]
                          ) {
                            return true;
                          }
                          return false;
                        };
                        return (
                          <li key={i}>
                            <div
                              className={`cart-item flex ${
                                isExpired() ? "bg-red-100 rounded shadow" : null
                              }`}
                            >
                              <div
                                className={`item-date rounded ${
                                  isExpired() ? "bg-red-500" : "bg-sec"
                                } inline-flex justify-center items-center mr-2`}
                              >
                                <span className="item-day text-white text-md uppercase">
                                  {type === "dropin" && (
                                    <span>
                                      {format(new Date(metadata.date), "EEE")}
                                    </span>
                                  )}
                                  {type === "membership" && (
                                    <span className="text-xs">
                                      {metadata.days} Days
                                    </span>
                                  )}
                                </span>
                              </div>
                              <div className="item-desc relative flex-grow">
                                {isExpired() && (
                                  <div className="text-red-500 text-xs absolute right-2 bottom-2">
                                    This can't be booked!
                                  </div>
                                )}
                                <div className=" text-xs absolute right-2 top-2">
                                  <button
                                    className="bg-red-500 rounded shadow text-white px-2 py-1"
                                    onClick={() => removeFromCart(id)}
                                  >
                                    Delete
                                  </button>
                                </div>
                                {/* <div>
                            Date:{" "}
                            <span className="text-gray-400">
                              {_d.getDate()} {month[_d.getMonth()]}{" "}
                              {time()}
                            </span>
                          </div> */}
                                {type === "dropin" && (
                                  <div>
                                    Date: {dayRen()[2]} {month[dayRen()[1] - 1]}{" "}
                                    <span className="text-gray-400">
                                      {formatedTime(metadata.hours[0].time)} -{" "}
                                      {formatedTime(
                                        metadata.hours[
                                          metadata.hours.length - 1
                                        ].endTime
                                      )}{" "}
                                      ({metadata.hours.length}h)
                                    </span>
                                  </div>
                                )}
                                <div>
                                  Event:{" "}
                                  <span className="text-gray-400">
                                    {tags(type)}
                                  </span>
                                </div>
                                <div>
                                  Price: <span>$ {metadata.price}</span>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      }
                      return (
                        <React.Fragment key={i}>
                          {items.map((_items, ind) => {
                            const price = _items.reduce((total, item) => {
                              return (total += parseFloat(item.price));
                            }, 0);

                            const endTime = () => {
                              return `${formatedTime(
                                _items[0].time
                              )} - ${formatedTime(
                                _items[_items.length - 1].endTime
                              )} (${_items.length}h)`;
                            };
                            const time = _items[0].time
                              .split(":")
                              .map((i) => parseInt(i));

                            // console.log(
                            //   dayToday[2],
                            //   dayRen[0],
                            //   dayToday[1],
                            //   dayRen[1],
                            //   dayToday[0],
                            //   dayRen[2]
                            // );

                            const isExpired = () => {
                              if (
                                dayToday[2] > dayRen[0] ||
                                dayToday[1] > dayRen[1] ||
                                dayToday[0] > dayRen[2]
                              ) {
                                return true;
                              }
                              if (
                                dayToday[0] === dayRen[2] &&
                                dayToday[1] === dayRen[1] &&
                                dayToday[2] === dayRen[0] &&
                                dayToday[3] >= time[0]
                              ) {
                                return true;
                              }
                              return false;
                            };

                            return (
                              <li key={ind} className={""}>
                                <div
                                  className={`cart-item flex ${
                                    isExpired()
                                      ? "bg-red-100 rounded shadow"
                                      : null
                                  }`}
                                >
                                  <div
                                    className={`item-date rounded ${
                                      isExpired() ? "bg-red-500" : "bg-sec"
                                    } inline-flex justify-center items-center mr-2`}
                                  >
                                    <span className="item-day text-white text-md uppercase">
                                      {weekDay[_d.getDay()]}
                                    </span>
                                  </div>
                                  <div className="item-desc relative flex-grow">
                                    {/* {JSON.stringify(_items)} */}
                                    {isExpired() && (
                                      <div className="text-red-500 text-xs absolute right-2 bottom-2">
                                        This can't be booked!
                                      </div>
                                    )}
                                    <div className=" text-xs absolute right-2 top-2">
                                      <button
                                        className="bg-red-500 rounded shadow text-white px-2 py-1"
                                        onClick={() => deleteFromCart(_items)}
                                      >
                                        Delete
                                      </button>
                                    </div>
                                    <div>
                                      Date: {dayRen()[2]}{" "}
                                      {month[dayRen()[1] - 1]}
                                      <span className="text-gray-400">
                                        {dayRen[2]} {month[dayRen[1] - 1]}{" "}
                                        {endTime()}
                                      </span>
                                    </div>
                                    <div>
                                      Event:{" "}
                                      <span className="text-gray-400">
                                        {tags(type)}
                                      </span>
                                    </div>
                                    <div>
                                      Price: <span>$ {price}</span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              //   <li key={ind}>
                              //     <div className="cart-item flex">
                              //       <div className="item-date rounded bg-sec inline-flex justify-center items-center mr-2">
                              //         <span className="item-day text-white text-md uppercase">
                              //           {weekDay[_d.getDay()]}
                              //         </span>
                              //       </div>
                              //       <div className="item-desc">
                              //         <div>
                              //           Date:{" "}
                              //           <span className="text-gray-400">
                              //             {_d.getDate()} {month[_d.getMonth()]} {time()}
                              //           </span>
                              //         </div>
                              //         <div>
                              //           Event:{" "}
                              //           <span className="text-gray-400">
                              //             Court Rental
                              //           </span>
                              //         </div>
                              //         <div>
                              //           Price: <span>$ {price}</span>
                              //         </div>
                              //       </div>
                              //     </div>
                              //   </li>
                            );
                          })}
                        </React.Fragment>
                      );
                      // }
                    }
                  )}
                  {/* {JSON.parse({})} */}
                </ul>
                <div className="border-b-2 border-theme py-2"></div>
                <div className="cart-total flex justify-between bg-neutral-200 w-full p-4">
                  <div>
                    <p className="text-base">
                      Total
                      <span className="text-xs font-normal ml-1">
                        (without any tax)
                      </span>
                    </p>
                  </div>
                  <div>$ {checkoutItem.total.toFixed(2)}</div>
                </div>
              </div>

              <div className="cart-footer p-4 w-full relative bottom-0 left-0">
                <div>
                  <span className="inline-flex text-lg font-bold py-4">
                    Internet Handling Fees
                  </span>
                </div>
                {/* <div className="flex justify-between">
              <span className="text-gray-500">Convenience Fee</span>
              <span className="text-lg">$ 0.00</span>
            </div> */}
                <div className="flex justify-between">
                  <span className="text-gray-500">Tax 13% 33AAC50VDR</span>
                  <span className="text-lg">$ {checkoutItem.tax}</span>
                </div>
              </div>
              <div className="border-b-2 border-theme pt-2"></div>
              <div className="cart-total bg-neutral-200 w-full p-4">
                <div className=" flex justify-between mb-2">
                  <div>
                    <p className="text-base">Total</p>
                  </div>
                  <div className="font-bold">
                    $ {parseFloat(checkoutItem.taxedTotal).toFixed(2)}
                  </div>
                </div>
                <div className=" flex justify-between my-2">
                  <div>
                    <p className="text-base">From Wallet Balance</p>
                  </div>
                  <div className="font-bold">
                    $ {parseFloat(checkoutItem.wallet).toFixed(2)}
                  </div>
                </div>
                <div className=" flex justify-between mt-2">
                  <div>
                    <p className="text-base">Grand Total</p>
                  </div>
                  <div className="font-bold">
                    $ {parseFloat(checkoutItem.grandTotal).toFixed(2)}
                  </div>
                </div>
              </div>
              <div className="px-4 pb-4">
                <button
                  onClick={() =>
                    parseFloat(checkoutItem.grandTotal) === 0
                      ? onCheckout()
                      : setPaymentModal(true)
                  }
                  disabled={isLoading}
                  className="mt-4 pl-1 pr-2 border-theme rounded-lg w-full h-12 border-2 bg-theme text-lg text-white inline-flex items-center justify-center shadow hover:bg-white hover:text-theme hover:border-theme"
                >
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      {parseFloat(checkoutItem.grandTotal) === 0
                        ? "Confirm Booking"
                        : `Pay ($ ${parseFloat(checkoutItem.grandTotal).toFixed(
                            2
                          )})`}
                    </>
                  )}
                </button>
              </div>
            </>
          ) : (
            <div className="flex justify-center flex-col ">
              <h1 className="flex items-center justify-center mt-18">
                <MdRemoveShoppingCart className="text-theme text-8xl center inline m-auto" />
              </h1>
              <h3 className="text-center text-gray-400 font-bold">
                Empty Cart
              </h3>
              <button
                className="px-3 py-2 rounded-md bg-theme text-white text-md shadow text-center m-auto mt-10"
                onClick={() => navigate("/user/")}
              >
                Go Back
              </button>
            </div>
          )}
        </div>
      </div>
      <Modal show={paymentModal}>
        {loadingState.wallet && (
          <div className="absolute flex-col w-full rounded-lg h-full top-0 left-0 bg-[rgba(255,255,255,0.04)] z-100 backdrop-blur-sm flex justify-center items-center">
            <Spinner size="lg" />
            <p className="my-5 w-[60%] text-center">
              Processing... Please Wait & Don't Close This Window.
            </p>
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title>Checkout</Modal.Title>
        </Modal.Header>
        <Modal.Body className="relative">
          <div className="lg:w-6/12 md:mb-0 mb-5 pb-4">
            <label
              htmlFor="first_name"
              className="block mb-2 text-base font-semibold text-sec"
            >
              Amount
            </label>
            <div className="relative flex w-full flex-wrap items-stretch">
              <input
                type="number"
                value={checkoutItem.grandTotal}
                min={1}
                disabled
                className="font-normal h-12 px-3 outline-0 text-black rounded-lg bg-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
              />
            </div>
          </div>

          {myCards.map((c) => (
            <label className="flex flex-row items-center" key={c.id}>
              <input
                type="radio"
                name="card"
                value={c.id}
                checked={paymentsMethod === c.id}
                onChange={() => setPaymentsMethod(c.id)}
              />{" "}
              <span className="text-sm">
                {c.card.brand === "visa" && (
                  <FaCcVisa className="inline w-14 h-7 my-2" />
                )}
                {c.card.brand === "mastercard" && (
                  <FaCcMastercard className="inline w-14 h-7 my-2" />
                )}
                {c.card.brand === "amex" && (
                  <FaCcAmex className="inline w-14 h-7 my-2" />
                )}
                {c.card.brand === "jcb" && (
                  <FaCcJcb className="inline w-14 h-7 my-2" />
                )}
                {c.card.brand === "discover" && (
                  <FaCcDiscover className="inline w-14 h-7 my-2" />
                )}
                {c.card.brand === "diners" && (
                  <FaCcDinersClub className="inline w-14 h-7 my-2" />
                )}
                ●●●● {c.card.last4}
                <span className="px-4 italic">
                  {c.card.exp_month}/{c.card.exp_year}
                </span>
              </span>
            </label>
          ))}
          <label className="flex flex-row items-center mb-3">
            <input
              type="radio"
              name="card"
              value={"new"}
              checked={paymentsMethod === "new"}
              onChange={() => setPaymentsMethod("new")}
            />{" "}
            <span className="text-sm px-3">New card</span>
          </label>
          {paymentsMethod === "new" && (
            <div className="font-normal h-10 w-full p-3 outline-0 text-sec rounded-lg bg-stone-200 placeholder-black-800 hover:border-theme focus:border-theme">
              <CardElement
                options={{
                  hidePostalCode: true,
                }}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setPaymentModal(false)}>Close</button>
          <button
            onClick={handleSubmit}
            className="bg-theme ml-3 rounded px-3 py-1 text-white"
            disabled={loadingState.wallet || paymentsMethod === null}
          >
            {loadingState.wallet ? (
              <span>
                <Spinner size="sm" /> Processing...
              </span>
            ) : (
              <span>Pay</span>
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
// {renderedCart().map(({ date, items }, i) => {
//     const _d = new Date(date);
//     const today = new Date();
//     return (
//       <React.Fragment key={i}>
//         {items.map((_items, ind) => {
//           const price = _items.reduce((total, item) => {
//             return (total += parseFloat(item.price));
//           }, 0);

//           const formatedTime = (time) => {
//             const _c = time.split(":").map((i) => {
//               return parseInt(i);
//             });
//             return `${_c[0]}:${("0" + _c[1]).slice(-2)}`;
//           };
//           const time = () => {
//             return `${formatedTime(_items[0].time)} - ${formatedTime(
//               _items[_items.length > 0 ? _items.length - 1 : 0]
//                 .endTime
//             )} (${_items.length}h)`;
//           };
//           return (
//             <li key={ind} className={""}>
//               <div
//                 className={`cart-item flex ${
//                   today.getDate() > _d.getDate()
//                     ? "bg-red-100 rounded shadow"
//                     : null
//                 }`}
//               >
//                 <div
//                   className={`item-date rounded ${
//                     today.getDate() > _d.getDate()
//                       ? "bg-red-500"
//                       : "bg-sec"
//                   } inline-flex justify-center items-center mr-2`}
//                 >
//                   <span className="item-day text-white text-md uppercase">
//                     {weekDay[_d.getDay()]}
//                   </span>
//                 </div>
//                 <div className="item-desc relative flex-grow">
//                   {/* {JSON.stringify(_items)} */}
//                   {today.getDate() > _d.getDate() && (
//                     <div className="text-red-500 text-xs absolute right-2 bottom-2">
//                       This day can't be booked!{" "}
//                     </div>
//                   )}
//                   <div className=" text-xs absolute right-2 top-2">
//                     <button
//                       className="bg-red-500 rounded shadow text-white px-2 py-1"
//                       onClick={() => deleteFromCart(_items)}
//                     >
//                       Delete
//                     </button>
//                   </div>
//                   <div>
//                     Date:{" "}
//                     <span className="text-gray-400">
//                       {_d.getDate()} {month[_d.getMonth()]} {time()}
//                     </span>
//                   </div>
//                   <div>
//                     Event:{" "}
//                     <span className="text-gray-400">
//                       Court Rental
//                     </span>
//                   </div>
//                   <div>
//                     Price: <span>$ {price}</span>
//                   </div>
//                 </div>
//               </div>
//             </li>
//           );
//         })}
//       </React.Fragment>
//     );
//   })}
