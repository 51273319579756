import React, { useState } from "react";
import AdminLayout from "../../../components/AdminLayout";
import { FiChevronLeft, FiEye } from "react-icons/fi";
import {
  getAUser,
  getAUserMetadata,
  updateUserMetadataViaAdmin,
  updateUserViaAdmin,
} from "../../../apis/user.api";
import { useParams, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import { getUserBookingsByAdmin } from "../../../apis/booking.api";
import { format } from "date-fns";
import { parseISO } from "date-fns";

export default function ViewUser() {
  let { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({
    fullName: "",
    email: "",
    phone: "",
    avatar: "",
  });

  const [metadata, setMetadata] = useState({});
  const [bookings, setBookings] = useState([]);
  const [bookingCount, setBookingCount] = useState(0);
  const [bookingPage, setBookingPage] = useState(1);
  const [transCount, setTransCount] = useState(0);
  const [transPage, setTransPage] = useState(1);

  const initCall = async () => {
    const response = await getAUser(id);
    const metaAPI = await getAUserMetadata(id);
    const bookings = await getUserBookingsByAdmin(id);
    setBookingCount(bookings.count);
    setUser(response);
    setMetadata(metaAPI);
    setBookings(bookings.data);
    setLoading(false);
  };

  React.useEffect(() => {
    initCall();

    //eslint-disable-next-line
  }, []);

  const bookingPager = () => {
    const _c = [];
    const c = Math.floor(bookingCount / 10);
    let i = 0;
    while (i <= c) {
      _c.push(i);
      i++;
    }
    return _c;
  };
  const transPager = () => {
    const _c = [];
    const c = Math.floor(transCount / 10);
    let i = 0;
    while (i <= c) {
      _c.push(i);
      i++;
    }
    return _c;
  };

  const onUserUpdate = async (data) => {
    try {
      await updateUserViaAdmin(id, data);
      initCall();
      toast.success("Profile Updated");
    } catch (error) {
      toast.error(error.response.data.message ?? "Something Went Wrong.");
    }
  };
  const onUserMetadataUpdate = async (data) => {
    try {
      await updateUserMetadataViaAdmin(id, data);
      toast.success("Profile Updated");
      initCall();
    } catch (error) {
      toast.error(error.response.data.message ?? "Something Went Wrong.");
    }
  };
  return (
    <AdminLayout>
      {loading ? (
        <div className=" mx-5">Loading...</div>
      ) : (
        <>
          <div className="justify-center items-center rounded-lg flex mx-5 inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                <div className="flex items-center justify-between bg-theme text-white p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-lg font-semibold ml-3">View User</h3>
                  <button
                    className="p-1 flex flex-row items-center ml-auto bg-transparent text-white  border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => navigate(`/admin/users/`)}
                  >
                    <span className="bg-theme opacity-1 text-xl inline outline-none focus:outline-none">
                      <FiChevronLeft />
                    </span>
                    <span className="text-sm">Back</span>
                  </button>
                </div>
                <div className="my-3.5" />
                <Tabs defaultActiveKey="profile">
                  <Tab
                    eventKey="profile"
                    title="Profile"
                    className="bg-white pt-4"
                  >
                    <>
                      <Formik
                        initialValues={{
                          fullName: user.fullName,
                          email: user.email,
                          phone: user.phone,
                          gender: user.gender,
                          birthday: user.birthday,
                          type: user.type,
                          avatar: user.avatar,
                          address: metadata.address,
                        }}
                        onSubmit={(values) => {
                          onUserUpdate(values);
                        }}
                        validateOnBlur={false}
                      >
                        {({
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          values,
                        }) => (
                          <>
                            {/* Body */}
                            <div className="relative p-6 mb-6 flex-auto">
                              <form>
                                <div className="grid gap-4">
                                  <div className="grid gap-4 grid-cols-3">
                                    <div>
                                      <label
                                        htmlFor="first_name"
                                        className="block mb-2 text-base font-semibold text-sec"
                                      >
                                        Full Name
                                      </label>
                                      <div className="relative flex w-full flex-wrap items-stretch">
                                        <input
                                          type="text"
                                          placeholder="First name"
                                          value={values["fullName"]}
                                          onChange={handleChange("fullName")}
                                          className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                                        />
                                      </div>
                                    </div>
                                    <div>
                                      <label
                                        htmlFor="first_name"
                                        className="block mb-2 text-base font-semibold text-sec"
                                      >
                                        Email ID
                                      </label>
                                      <div className="relative flex w-full flex-wrap items-stretch">
                                        <input
                                          type="text"
                                          placeholder="user@email.com"
                                          value={values["email"]}
                                          onChange={handleChange("email")}
                                          className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                                        />
                                      </div>
                                    </div>
                                    <div>
                                      <label
                                        htmlFor="phone_no"
                                        className="block mb-2 text-base font-semibold text-sec"
                                      >
                                        Birthday
                                      </label>
                                      <div className="relative flex flex-wrap w-full items-stretch">
                                        <input
                                          type="date"
                                          placeholder="2000-01-01"
                                          value={values["birthday"]}
                                          onChange={handleChange("birthday")}
                                          max={
                                            new Date()
                                              .toISOString()
                                              .split("T")[0]
                                          }
                                          className="inline-flex font-normal w-full h-12 px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="grid gap-4 grid-cols-4">
                                    <div>
                                      <label
                                        htmlFor="first_name"
                                        className="block mb-2 text-base font-semibold text-sec"
                                      >
                                        Phone
                                      </label>
                                      <div className="relative flex flex-row w-full items-stretch">
                                        <select className="inline-flex border-2 border-stone-200 rounded-lg mr-3 px-3 py-2">
                                          <option value="sun">+1</option>
                                        </select>
                                        <input
                                          type="text"
                                          placeholder="(000) 123 - 1132"
                                          value={values["phone"]}
                                          onChange={handleChange("phone")}
                                          className="inline-flex font-normal w-full h-12 px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                                        />
                                      </div>
                                    </div>
                                    <div>
                                      <label
                                        htmlFor="phone_no"
                                        className="block mb-2 text-base font-semibold text-sec"
                                      >
                                        Gender
                                      </label>
                                      <div className="relative flex w-full flex-wrap items-stretch">
                                        <select
                                          value={values["gender"]}
                                          onChange={handleChange("gender")}
                                          className="w-full h-12 rounded-lg border-2 border-stone-200 placeholder-gray-400 px-3 py-2"
                                        >
                                          <option value={null}>
                                            No Gender
                                          </option>
                                          <option value="male">Male</option>
                                          <option value="female">Female</option>
                                          <option value="other">Other</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-span-2">
                                      <label
                                        htmlFor="first_name"
                                        className="block mb-2 text-base font-semibold text-sec"
                                      >
                                        Address
                                      </label>
                                      <div className="relative flex flex-wrap w-full items-stretch">
                                        <input
                                          type="text"
                                          placeholder="ABC, XYZ Road, CA"
                                          value={values["address"]}
                                          onChange={handleChange("address")}
                                          className="inline-flex font-normal w-full h-12 px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                            {/*footer*/}
                            <div className="flex items-center pt-0 p-6 rounded-b">
                              <button
                                className="min-w-130 bg-theme text-white active:bg-emerald-600 font-bold text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={handleSubmit}
                              >
                                Update User Profile
                              </button>
                            </div>
                          </>
                        )}
                      </Formik>
                      <hr />
                      <Formik
                        initialValues={{
                          password: "",
                          cPassword: "",
                        }}
                        onSubmit={(values) => {
                          if (values.password !== values.cPassword) {
                            return toast.error("Password Doesn't Match");
                          }
                          onUserUpdate({ password: values.password });
                        }}
                        validateOnBlur={false}
                      >
                        {({
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          values,
                        }) => (
                          <>
                            {/* Body */}
                            <div className="relative p-6 mb-6 flex-auto">
                              <form>
                                <div className="grid gap-4">
                                  <div className="grid gap-4 grid-cols-4">
                                    <div>
                                      <label
                                        htmlFor="first_name"
                                        className="block mb-2 text-base font-semibold text-sec"
                                      >
                                        Password
                                      </label>
                                      <div className="relative flex w-full flex-wrap items-stretch">
                                        <input
                                          type="password"
                                          value={values["password"]}
                                          onChange={handleChange("password")}
                                          className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                                        />
                                      </div>
                                    </div>
                                    <div>
                                      <label
                                        htmlFor="cPassword"
                                        className="block mb-2 text-base font-semibold text-sec"
                                      >
                                        New Password
                                      </label>
                                      <div className="relative flex w-full flex-wrap items-stretch">
                                        <input
                                          type="password"
                                          value={values["cPassword"]}
                                          onChange={handleChange("cPassword")}
                                          className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                            {/*footer*/}
                            <div className="flex items-center pt-0 p-6 rounded-b">
                              <button
                                className="min-w-130 bg-theme text-white active:bg-emerald-600 font-bold text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={handleSubmit}
                              >
                                Update Password
                              </button>
                            </div>
                          </>
                        )}
                      </Formik>
                    </>
                  </Tab>

                  <Tab
                    eventKey="metadata"
                    title="Metadata"
                    className="bg-white pt-4"
                  >
                    <Formik
                      initialValues={{
                        alternatePhone: metadata.alternatePhone,
                        relation: metadata.relation,
                        relationName: metadata.relationName,
                      }}
                      onSubmit={(values) => {
                        onUserMetadataUpdate(values);
                      }}
                      validateOnBlur={false}
                    >
                      {({
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        values,
                      }) => (
                        <>
                          {/* Body */}
                          <div className="relative p-6 mb-6 flex-auto">
                            <form>
                              <div className="grid gap-4">
                                <div className="grid gap-4 grid-cols-3">
                                  <div>
                                    <label
                                      htmlFor="first_name"
                                      className="block mb-2 text-base font-semibold text-sec"
                                    >
                                      Alternative Phone
                                    </label>
                                    <div className="relative flex pr-3 w-full items-stretch flex-row">
                                      <select className="inline-flex border-2 border-stone-200 rounded-lg mr-3 px-3 py-2">
                                        <option value="+1">+1</option>
                                      </select>
                                      <input
                                        type="text"
                                        placeholder="(000) 123 - 1234"
                                        value={values["alternatePhone"]}
                                        onChange={handleChange(
                                          "alternatePhone"
                                        )}
                                        className="inline-flex w-full font-normal h-12 px-3 outline-0 bg-transparent text-black rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <label
                                      htmlFor="first_name"
                                      className="block mb-2 text-base font-semibold text-sec"
                                    >
                                      Relation Name
                                    </label>
                                    <div className="relative flex w-full flex-wrap items-stretch">
                                      <input
                                        type="text"
                                        placeholder="Relation name"
                                        value={values["relationName"]}
                                        onChange={handleChange("relationName")}
                                        className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                                      />
                                    </div>
                                  </div>

                                  <div>
                                    <label
                                      htmlFor="phone_no"
                                      className="block mb-2 text-base font-semibold text-sec"
                                    >
                                      Relation Type
                                    </label>
                                    <div className="relative flex w-full flex-wrap items-stretch">
                                      <select
                                        value={values["relation"]}
                                        onChange={handleChange("relation")}
                                        className="w-full h-12 rounded-lg border-2 border-stone-200 placeholder-gray-400 px-3 py-2"
                                      >
                                        <option value=""></option>
                                        <option value="Mother">Mother</option>
                                        <option value="Father">Father</option>
                                        <option value="Brother">Brother</option>
                                        <option value="Sister">Sister</option>
                                        <option value="Uncle">Uncle</option>
                                        <option value="Aunt">Aunt</option>
                                        <option value="Other">Other</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          {/*footer*/}
                          <div className="flex items-center pt-0 p-6 rounded-b">
                            <button
                              className="min-w-130 bg-theme text-white active:bg-emerald-600 font-bold text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              onClick={handleSubmit}
                            >
                              Update User Metadata
                            </button>
                          </div>
                        </>
                      )}
                    </Formik>
                  </Tab>
                  <Tab
                    eventKey="booking"
                    title="Booking"
                    className="bg-white pt-4"
                  >
                    <div className="px-3 pb-5">
                      <table className="table-auto w-full rounded-lg border-collapse p-4 mt-4 mb-8">
                        <thead>
                          <tr className="text-left bg-neutral-200 p-8">
                            <th className="py-3 px-2 mb-4">Booking ID</th>
                            <th>Booking Date</th>
                            <th>Total</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bookings.map((us) => (
                            <tr key={us.userID} className="py-2">
                              <td className="py-5 px-2">
                                #{("OD0000" + us.id).slice(-8)}
                              </td>

                              {/* <td>Kundagol</td> */}
                              <td>
                                {format(
                                  parseISO(us.createdAt),
                                  "dd MMM yyyy | HH:mm"
                                )}{" "}
                                UTC
                              </td>
                              <td>
                                <span className="text-xs font-semibold mr-2">
                                  CAD
                                </span>
                                {us.grandTotal}
                              </td>
                              <td>
                                <button
                                  className="inline-flex bg-orange-100 rounded-full p-4 mr-2"
                                  onClick={() =>
                                    navigate(`/admin/bookings/${us.bookingID}`)
                                  }
                                >
                                  <FiEye />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <nav aria-label="Page navigation example">
                        <ul class="list-style-none flex">
                          {bookingPager().map((p) => (
                            <li>
                              <button
                                onClick={() => initCall(p + 1)}
                                class={`${
                                  p + 1 === bookingPage
                                    ? "bg-gray-500 text-white"
                                    : "bg-transparent text-neutral-600"
                                } relative block rounded  px-3 py-1.5 text-sm transition-all duration-300 hover:bg-neutral-100  dark:hover:bg-neutral-700 dark:hover:text-white`}
                              >
                                {p + 1}
                              </button>
                            </li>
                          ))}
                          {/* <li aria-current="page">
              <button class="relative block rounded bg-primary-100 px-3 py-1.5 text-sm font-medium text-primary-700 transition-all duration-300">
                2
                <span class="absolute -m-px h-px w-px overflow-hidden whitespace-nowrap border-0 p-0 [clip:rect(0,0,0,0)]">
                  (current)
                </span>
              </button>
            </li>
            <li>
              <button class="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100">
                3
              </button>
            </li> */}
                        </ul>
                      </nav>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="transaction"
                    title="Transactions"
                    className="bg-white pt-4"
                  ></Tab>
                </Tabs>
              </div>
            </div>
          </div>
          {/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
        </>
      )}
    </AdminLayout>
  );
}
