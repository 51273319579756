import React from "react";
import { formatedTime, getDay } from "../../../utils/time";
import { FiDelete, FiEye, FiTrash, FiZap } from "react-icons/fi";

export default function DropinEvents({ events, onDelete }) {
  return (
    <div>
      <div className="md:overflow-auto overflow-x-scroll block">
        <table className="whitespace-nowrap w-full rounded-lg md:text-base text-sm p-4">
          <tbody>
            {/* {_i.dropins.map((item) => ())} */}
            {events.map((dpis, i) => (
              <tr
                className="border-b border-gray-200 shadow-md bg-gray-50"
                key={i}
              >
                <td className="p-2">
                  <div className="font-medium inline-flex items-center md:mr-5 mr-2">
                    <svg
                      className="mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.889"
                      height="18.469"
                      viewBox="0 0 14.889 18.469"
                    >
                      <g
                        id="Group_3609"
                        data-name="Group 3609"
                        transform="matrix(0.951, -0.309, 0.309, 0.951, 0, 2.439)"
                      >
                        <path
                          id="Path_5131"
                          data-name="Path 5131"
                          d="M3.442,6.974l.281-2.889a4.085,4.085,0,0,1,.4.592,8.514,8.514,0,0,1,1.185,5.547L5.886,8.64c.046.13.091.264.137.4h0A54.391,54.391,0,0,1,7.891,16.6a29.552,29.552,0,0,0-1.369-2.9,50.643,50.643,0,0,0-3.543-5.72C2.132,6.792,1.162,5.535.061,4.243c0,0,.713-1.2-.061-4.243C0,0,3.2,1.507,3.442,6.974Z"
                          transform="translate(0)"
                          fill="#ff6a00"
                        />
                        <path
                          id="Path_5132"
                          data-name="Path 5132"
                          d="M1.977,6.428l.971-2.221a3.4,3.4,0,0,1,.164.574,7.111,7.111,0,0,1-.495,4.711l.87-1.108c0,.115,0,.233.005.353h0a45.448,45.448,0,0,1-.474,6.488,24.717,24.717,0,0,0-.335-2.66A42.294,42.294,0,0,0,1.349,7.108C.984,5.945.54,4.7,0,3.385,0,3.385.875,2.62,1.05,0,1.05,0,3.2,2.026,1.977,6.428Z"
                          transform="translate(6.048 3.594)"
                          fill="#222"
                        />
                      </g>
                    </svg>
                    <span>Event #{i + 1}</span>
                  </div>
                </td>
                <td className="p-2">
                  <div className="font-medium inline-flex items-center md:mr-5 mr-2">
                    <span>{getDay(dpis.day)}</span>
                  </div>
                </td>
                <td className="p-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 19 19"
                    className="inline-flex mr-2"
                  >
                    <g
                      id="Group_3450"
                      data-name="Group 3450"
                      transform="translate(-169 -395)"
                    >
                      <rect
                        id="Rectangle_2254"
                        data-name="Rectangle 2254"
                        width="19"
                        height="19"
                        rx="4"
                        transform="translate(169 395)"
                        fill="#fff3eb"
                      />
                      <path
                        id="Icon_material-access-time"
                        data-name="Icon material-access-time"
                        d="M8.432,3a5.437,5.437,0,1,0,5.443,5.437A5.435,5.435,0,0,0,8.432,3Zm.005,9.787a4.35,4.35,0,1,1,4.35-4.35A4.349,4.349,0,0,1,8.437,12.787Zm.272-7.068H7.893V8.981l2.855,1.713.408-.669L8.709,8.573Z"
                        transform="translate(170.063 396.063)"
                        fill="#ff6a00"
                      />
                    </g>
                  </svg>
                  {dpis.hours.length > 0 ? (
                    <span className="font-medium mr-5">
                      {formatedTime(dpis.hours[0].time)} To{" "}
                      {formatedTime(dpis.hours[dpis.hours.length - 1].endTime)}{" "}
                      ({dpis.hours.length}h)
                    </span>
                  ) : (
                    "-"
                  )}
                  {/* 19:00 - 23:00 */}
                </td>
                <td className="p-2">
                  <svg
                    className="inline-flex mr-2"
                    id="Group_3530"
                    data-name="Group 3530"
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 29 29"
                  >
                    <rect
                      id="Rectangle_2253"
                      data-name="Rectangle 2253"
                      width="29"
                      height="29"
                      rx="4"
                      fill="#fff3eb"
                    />
                    <path
                      id="Icon_awesome-dollar-sign"
                      data-name="Icon awesome-dollar-sign"
                      d="M6.72,7.5,3.25,6.483a.948.948,0,0,1,.267-1.857h2.13a1.961,1.961,0,0,1,1.1.337A.5.5,0,0,0,7.372,4.9L8.49,3.807a.521.521,0,0,0-.058-.787,4.543,4.543,0,0,0-2.779-.964V.514A.516.516,0,0,0,5.139,0H4.111A.516.516,0,0,0,3.6.514V2.056h-.08A3.52,3.52,0,0,0,.015,5.9,3.678,3.678,0,0,0,2.707,9L6,9.966a.948.948,0,0,1-.267,1.857H3.6a1.961,1.961,0,0,1-1.1-.337.5.5,0,0,0-.626.064L.76,12.642a.521.521,0,0,0,.058.787,4.543,4.543,0,0,0,2.779.964v1.542a.516.516,0,0,0,.514.514H5.139a.516.516,0,0,0,.514-.514V14.387a3.629,3.629,0,0,0,3.4-2.336A3.528,3.528,0,0,0,6.72,7.5Z"
                      transform="translate(10.625 6.275)"
                      fill="#ff6a00"
                    />
                  </svg>
                  <span className="font-semibold">
                    <span className="text-theme">{dpis.price} CAD</span>
                  </span>
                </td>
                <td>
                  <span className="font-semibold"><FiZap className="inline" /> {dpis.dropins}</span>
                </td>

                <td className="p-2 text-center">
                  <button
                    className="inline-flex bg-white rounded-full p-3 shadow mr-2"
                    // onClick={() => navigate(`/admin/dropins/${dpis.dropinID}`)}
                  >
                    <FiEye />
                  </button>
                  <button
                    className="inline-flex bg-white rounded-full p-3 shadow"
                    onClick={() => onDelete(dpis.eventID)}
                  >
                    <FiTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
