import React, { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { getAdminMyself } from "../apis/user.api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./Auth.context";
import { toast } from "react-toastify";
import { toastConf } from "../pages/AdminDashboard/Dropins";

export const AdminZoneContext = React.createContext();

export default function AdminZoneProvider({ children }) {
  const { isAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  // const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getAdminSession = async () => {
    setIsLoading(true);
    try {
      const admin = await getAdminMyself();
      setUser(admin);
      setIsLoading(false);
    } catch (error) {
      if (error.response.status === 403) {
        setIsLoading(false);
        toast.error("Access Denied", toastConf);
        return navigate("/");
      }
    }
  };

  React.useEffect(() => {
    if (!isAuth) {
      setIsLoading(false);
      return navigate("/admin/login");
    }
    getAdminSession();
    return;
    //eslint-disable-next-line
  }, [navigate, isAuth]);
  return (
    <AdminZoneContext.Provider value={{ user, getAdminSession }}>
      {isLoading ? <>Loading...</> : <>{children}</>}
    </AdminZoneContext.Provider>
  );
}
