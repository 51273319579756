import React, { useState } from "react";
import { getBookings } from "../../apis/booking.api";
// import SideMenu from "../../components/SideMenu";
import { format, parseISO } from "date-fns";
import { formatedTime } from "../../utils/time";

const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export default function MyBookings() {
  // const [navbar, setNavbar] = useState(false);
  // const [setCancelModal] = useState(false);
  const [showPdfModal, setPdfModal] = useState(false);
  const [bookings, setBookings] = React.useState([]);

  const [open, setOpen] = useState(1);
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const initialCall = async () => {
    const bookingCall = await getBookings();
    setBookings(bookingCall.data);
  };
  React.useEffect(() => {
    initialCall();
  }, []);

  const renderedItem = (items) => {
    const courtItem = items.filter((item) => item.type === "court");
    const nonCourtItem = items.filter((item) => item.type !== "court");

    const groups = courtItem.reduce((groups, item) => {
      const date = item.date;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push({
        ...item,
        sortTime: parseInt(item.time.replace(":", "")),
      });
      return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        type: "court",
        items: groups[date]
          .sort((a, b) => a.sortTime - b.sortTime)
          .reduce((r, n) => {
            const lastSubArray = r[r.length - 1];
            const currentSlot = parseInt(n.time.split(":")[0]);

            if (
              !lastSubArray ||
              parseInt(
                lastSubArray[lastSubArray?.length - 1].time.split(":")[0]
              ) !==
                currentSlot - 1
            ) {
              r.push([]);
            }

            r[r.length - 1].push(n);

            return r;
          }, []),
      };
    });
    return [...groupArrays, ...nonCourtItem];
  };

  return (
    <>
      <div className="flex flex-wrap gap-y-4 mb-4 py-2">
        <div className="w-full">
          <div className="card-block bg-white border-2 border-white rounded hover:shadow-md focus:shadow-md position-relative mb-3">
            <div className="p-2">
              <div className="w-full space-y-2">
                <div className="rounded-lg">
                  <div className="relative" onClick={() => handleOpen(1)}>
                    <span className="font-semibold md:text-lg text-base cursor-pointer">
                      Booking List
                    </span>
                  </div>
                  <div className={`md:overflow-auto overflow-x-scroll block`}>
                    <table className="table-auto whitespace-nowrap w-full rounded-lg md:text-base text-sm p-4 mt-4 mb-8">
                      <thead>
                        <tr className="text-center bg-neutral-200 p-8">
                          <th className="py-3 px-2 mb-4">#</th>
                          <th className="py-3 px-2 mb-4">Name</th>
                          <th>Booking Date</th>
                          <th>Booked By</th>
                          <th>Costing</th>
                          <th>Method</th>
                          <th>Status</th>
                          <th>Invoice</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookings.map((item, i) => (
                          <>
                            <tr>
                              <td className="py-3">
                                <span className="font-bold">{i + 1}.</span>
                              </td>
                              <td className="py-3">
                                Booking{" "}
                                <span className="font-bold">
                                  #{("OD0000" + item.id).slice(-8)}
                                </span>
                              </td>
                              <td className="text-center">
                                {format(
                                  parseISO(item.createdAt),
                                  "dd-MM-yyyy | HH:mm"
                                )}
                                <span className="text-xs px-2 font-semibold">
                                  (UTC)
                                </span>
                              </td>
                              <td className="text-center"> Self</td>
                              <td className="text-center">
                                $ {item.grandTotal}{" "}
                              </td>
                              <td className="text-center"> Wallet</td>
                              <td className="text-center">
                                <button className="paid rounded px-2 py-1">
                                  Paid
                                </button>
                              </td>
                              <td>
                                <button className="bg-gray-200 text-gray-600 rounded px-2 py-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="inline-flex "
                                    viewBox="0 0 16 16"
                                  >
                                    {" "}
                                    <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />{" "}
                                    <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />{" "}
                                  </svg>
                                </button>
                              </td>
                            </tr>
                            {renderedItem(item.items).map((it) => {
                              console.log(it);
                              const date =
                                it.type === "dropin" || it.type === "court"
                                  ? it?.date.split("-").map((e) => parseInt(e))
                                  : [0, 0, 0];
                              // const date = [2023, 5, 6];

                              return (
                                <tr className="border-b border-gray-400 bg-gray-100">
                                  <td></td>
                                  <td className="py-2 px-2 text-left">
                                    {it.type === "court" &&
                                      `Court Rental | ${date[2]} ${
                                        month[date[1] - 1]
                                      }, ${date[0]} | `}
                                    {it.type === "membership" &&
                                      `Membership  - ${it.metadata.title} (${it.metadata.days} Days)`}
                                    {it.type === "dropin" &&
                                      `Drop In | ${date[2]} ${
                                        month[date[1] - 1]
                                      }, ${date[0]} | `}
                                    {it.type === "course" &&
                                      `Course - ${it.metadata.title}`}
                                    {/* {formatedTime(it.items[0][0].time)} -{" "}
                                  {formatedTime(
                                    it.items[0][it.items.lenght - 1].endTime
                                  )} */}
                                    {/* 08:00 AM To 10:00 AM (2h) */}
                                    {/*  */}
                                    {it.type === "court" && (
                                      <>
                                        {formatedTime(it.items[0][0].time)} -{" "}
                                        {formatedTime(
                                          it.items[0][it.items[0].length - 1]
                                            .endTime
                                        )}{" "}
                                        ({it.items[0].length}h)
                                      </>
                                    )}
                                    {it.type === "dropin" && (
                                      <>
                                        {formatedTime(
                                          it.metadata.hours[0].time
                                        )}{" "}
                                        -{" "}
                                        {formatedTime(
                                          it.metadata.hours[
                                            it.metadata.hours.length - 1
                                          ].endTime
                                        )}{" "}
                                        ({it.metadata.hours.length}h)
                                      </>
                                    )}
                                    {/* {JSON.stringify(it)} */}
                                  </td>
                                  <td colSpan={2}></td>
                                  {/* {JSON.stringify(it)} */}
                                  <td className="text-center">
                                    $
                                    {it.type === "court" && (
                                      <>
                                        {it?.items &&
                                          it?.items[0]?.reduce((total, i) => {
                                            total += parseFloat(i.price);
                                            return total;
                                          }, 0)}
                                      </>
                                    )}
                                    {it.type === "dropin" ||
                                    it.type === "membership" ||
                                    it.type === "course"
                                      ? it.price
                                      : null}
                                  </td>
                                  <td></td>
                                  <td>{it.status}</td>
                                  <td></td>
                                </tr>
                              );
                            })}
                          </>
                        ))}
                        {/* <tr className="border-b border-gray-400 text-center">
                              <td className="py-5 px-2">
                                Court Rental | 23/01/2023 | 08:00 AM To 11:00 AM
                                (3h)
                              </td>
                              <td>23/01/2023 | 08:00 AM</td>
                              <td>Admin</td>
                              <td>$70</td>
                              <td>Wallet</td>
                              <td>
                                <button className="paid rounded px-2 py-1">
                                  Paid
                                </button>
                              </td>
                              <td>
                                <button className="bg-gray-200 text-gray-600 rounded px-2 py-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="inline-flex "
                                    viewBox="0 0 16 16"
                                  >
                                    {" "}
                                    <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />{" "}
                                    <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />{" "}
                                  </svg>
                                </button>
                              </td>
                            </tr>
                            <tr className="border-b border-gray-400 text-center">
                              <td className="py-5 px-2">
                                Court Rental | 23/01/2023 | 08:00 AM To 12:00 PM
                                (4h)
                              </td>
                              <td>23/01/2023 | 08:00 AM</td>
                              <td>Admin</td>
                              <td>$70</td>
                              <td>Cash</td>
                              <td>
                                <button className="unpaid rounded px-2 py-1">
                                  UnPaid
                                </button>
                              </td>
                              <td>
                                <button className="bg-gray-200 text-gray-600 rounded px-2 py-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="inline-flex "
                                    viewBox="0 0 16 16"
                                  >
                                    {" "}
                                    <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />{" "}
                                    <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />{" "}
                                  </svg>
                                </button>
                              </td>
                            </tr>
                            <tr className="border-b border-gray-400 text-center">
                              <td className="py-5 px-2">
                                Court Rental | 23/01/2023 | 08:00 AM To 10:00 AM
                                (2h)
                              </td>
                              <td>23/01/2023 | 08:00 AM</td>
                              <td>Admin</td>
                              <td>$70</td>
                              <td>E-Transfer</td>
                              <td>
                                <button className="paid rounded px-2 py-1">
                                  Paid
                                </button>
                              </td>
                              <td>
                                <button
                                  className="bg-gray-200 text-gray-600 rounded px-2 py-1"
                                  onClick={() => setCancelModal(true)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="inline-flex "
                                    viewBox="0 0 16 16"
                                  >
                                    {" "}
                                    <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />{" "}
                                    <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />{" "}
                                  </svg>
                                </button>
                              </td>
                            </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPdfModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-md">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="flex justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="84.214"
                      height="84.214"
                      viewBox="0 0 84.214 84.214"
                    >
                      <g id="cross-circle" transform="translate(0.75 0.75)">
                        <path
                          id="Path_13181"
                          data-name="Path 13181"
                          d="M31.4,8.544a2.857,2.857,0,0,0-4.039,0L19.97,15.931,12.583,8.544a2.856,2.856,0,1,0-4.039,4.039l7.387,7.387L8.544,27.357A2.856,2.856,0,1,0,12.583,31.4l7.387-7.387L27.357,31.4A2.856,2.856,0,1,0,31.4,27.357L24.009,19.97,31.4,12.583A2.857,2.857,0,0,0,31.4,8.544Z"
                          transform="translate(21.387 21.387)"
                          fill="red"
                        />
                        <path
                          id="Path_13182"
                          data-name="Path 13182"
                          d="M41.907,0A41.907,41.907,0,1,0,83.814,41.907,41.907,41.907,0,0,0,41.907,0Zm0,76.83A34.923,34.923,0,1,1,76.83,41.907,34.923,34.923,0,0,1,41.907,76.83Z"
                          transform="translate(-0.55 -0.55)"
                          fill="red"
                          stroke="#ffe8e8"
                          strokeWidth="0.4"
                        />
                      </g>
                    </svg>
                  </p>
                  <p className="text-2xl font-semibold text-center text-red-600 mt-4 mb-4">
                    Are You Sure?
                  </p>
                  <p className="mt-4 text-center">
                    If You Paid Your Money Will Refund In A Next
                  </p>
                  <p className="mb-4 text-center">5 Working Days</p>
                  <div className="flex items-center justify-center rounded-b">
                    <button
                      className="min-w-130 bg-theme text-white active:bg-emerald-600 font-bold text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setPdfModal(true)}
                    >
                      Yes
                    </button>
                    <button
                      className="min-w-130 bg-gray-200 text-black rounded-lg font-bold px-6 py-3 text-sm outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setPdfModal(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

{
  /* <div className="bg-white flex flex-wrap items-center relative mb-3 lg:mb-2 shadow p-2">
  <div className="flex items-center">
    <button
      className="py-2 rounded-md outline-none mr-2 text-bgdark hover:text-theme focus:text-theme md:hidden block"
      onClick={() => setNavbar(!navbar)}
    >
      <svg width="27" height="19" viewBox="0 0 27 19">
        <g transform="translate(-84 -23)">
          <line
            id="Line_2"
            data-name="Line 2"
            x2="17"
            transform="translate(85.5 24.5)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <line
            id="Line_3"
            data-name="Line 3"
            x2="24"
            transform="translate(85.5 32.5)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <line
            id="Line_4"
            data-name="Line 4"
            x2="20"
            transform="translate(85.5 40.5)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="3"
          />
        </g>
      </svg>
    </button>
    <div className="title-set">
      <h3 className="text-sec">
        <svg
          className="inline-flex md:mr-3 mr-1"
          width="18"
          height="18"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 18.392 18.386"
        >
          <path
            id="Path_13183"
            data-name="Path 13183"
            d="M94.552-1190.658h15.6a.16.16,0,0,1,.16.16v5.555a.16.16,0,0,1-.186.158,4.218,4.218,0,0,0-1.753.067.161.161,0,0,1-.2-.154v-1.328a.16.16,0,0,0-.16-.16H106.53a.16.16,0,0,0-.16.16v1.485a.16.16,0,0,0,.16.16h.717a.16.16,0,0,1,.09.292,3.984,3.984,0,0,0-1.7,4.721.16.16,0,0,1-.152.2h-9.8a1.236,1.236,0,0,1-1.29-1.3q0-4.911,0-9.821v-.04A.16.16,0,0,1,94.552-1190.658Zm3.358.929h-1.5a.16.16,0,0,0-.16.16v1.475a.16.16,0,0,0,.16.16h1.5a.16.16,0,0,0,.16-.16v-1.475A.16.16,0,0,0,97.91-1189.729Zm3.522,1.639v-1.477a.16.16,0,0,0-.16-.16H99.783a.16.16,0,0,0-.16.16v1.477a.16.16,0,0,0,.16.16h1.489A.16.16,0,0,0,101.432-1188.09Zm-3.519,1.732h-1.5a.16.16,0,0,0-.16.16v1.467a.16.16,0,0,0,.16.16h1.5a.16.16,0,0,0,.16-.16v-1.467A.16.16,0,0,0,97.913-1186.359Zm3.521,1.635v-1.479a.16.16,0,0,0-.16-.16H99.788a.16.16,0,0,0-.16.16v1.479a.16.16,0,0,0,.16.16h1.485A.16.16,0,0,0,101.434-1184.723ZM97.909-1183H96.415a.16.16,0,0,0-.16.16v1.476a.16.16,0,0,0,.16.16h1.494a.16.16,0,0,0,.16-.16v-1.476A.16.16,0,0,0,97.909-1183Zm3.38,1.8a.16.16,0,0,0,.16-.16c0-.517,0-1.018-.006-1.519a.19.19,0,0,0-.129-.134c-.507-.009-1.015-.007-1.529-.006a.161.161,0,0,0-.16.16v1.5a.16.16,0,0,0,.16.16Zm1.864-6.737h1.479a.16.16,0,0,0,.16-.16v-1.481a.16.16,0,0,0-.16-.16h-1.479a.16.16,0,0,0-.16.16v1.481A.16.16,0,0,0,103.154-1187.931Zm4.848-1.8h-1.477a.16.16,0,0,0-.16.16v1.473a.16.16,0,0,0,.16.16H108a.16.16,0,0,0,.16-.16v-1.473A.16.16,0,0,0,108-1189.728Zm-4.846,5.155h1.478a.16.16,0,0,0,.16-.16v-1.471a.16.16,0,0,0-.16-.16h-1.478a.16.16,0,0,0-.16.16v1.471A.16.16,0,0,0,103.155-1184.573Zm-.156,1.729v1.487a.16.16,0,0,0,.16.16h1.473a.16.16,0,0,0,.16-.16v-1.487a.16.16,0,0,0-.16-.16h-1.473A.16.16,0,0,0,103-1182.844Z"
            transform="translate(-94.386 1195.883)"
            fill="#ff6a00"
          />
          <path
            id="Path_13184"
            data-name="Path 13184"
            d="M94.294-1299.109c0-.658-.031-1.3.009-1.935a1.213,1.213,0,0,1,1.247-1.124c.29-.006.581,0,.9,0,0-.3,0-.58,0-.855a.62.62,0,0,1,.6-.676.626.626,0,0,1,.609.672c0,.532,0,1.063,0,1.595a.718.718,0,0,1-.329.757.656.656,0,0,0,.94-.637c0-.216.009-.432,0-.647-.008-.158.041-.218.207-.213.362.011.724,0,1.086,0,.075,0,.15-.007.248-.012,0-.277,0-.537,0-.8,0-.433.247-.719.609-.721a.643.643,0,0,1,.614.717q0,.786,0,1.572a.719.719,0,0,1-.333.733.654.654,0,0,0,.939-.635c0-.275,0-.551,0-.849h1.846c0-.3,0-.572,0-.846a.634.634,0,0,1,.616-.691.631.631,0,0,1,.606.678c0,.531,0,1.063,0,1.595a.711.711,0,0,1-.34.752.675.675,0,0,0,.96-.676c0-.261,0-.523,0-.812h1.53c0-.31,0-.593,0-.875a.62.62,0,0,1,.6-.662.621.621,0,0,1,.616.663c0,.516,0,1.032,0,1.548s0,.5-.329.824c.591.142.953-.132.955-.723,0-.252,0-.5,0-.75a1.227,1.227,0,0,1,1.513,1.076c.052.641.011,1.29.011,1.952Z"
            transform="translate(-94.282 1303.699)"
            fill="#ff6a00"
          />
          <path
            id="Path_13185"
            data-name="Path 13185"
            d="M352.175-1050.856a3.381,3.381,0,0,1-.213,4.967,3.383,3.383,0,0,1-4.349-.008,3.381,3.381,0,0,1-.2-4.966A3.367,3.367,0,0,1,352.175-1050.856Zm-.882.436c-.394.394-.777.771-1.152,1.155a.5.5,0,0,1-.413.195c-.1-.006-.207.082-.314.12a.83.83,0,0,1-.308.085c-.4-.03-.808-.082-1.228-.127l-.066.6c.416.047.813.083,1.207.142a.576.576,0,0,1,.3.158.62.62,0,0,0,.624.2.511.511,0,0,0,.437-.476.753.753,0,0,1,.311-.577c.257-.255.512-.511.768-.767l.275-.275Z"
            transform="translate(-334.768 1063.486)"
            fill="#ff6a00"
          />
        </svg>
        <span className="font-bold inline-flex md:text-lg text-base">
          My Bookings
        </span>
      </h3>
    </div>
  </div>
</div> */
}
