import React, { useState } from "react";
// import { NavLink, Link } from "react-router-dom";
// import Logo from "../assets/wlogo.svg";
// import LogoIco from "./assets/logoIco.svg";
// import CalendarIco from "./assets/calendarIco.svg";
// import SaveIco from "./assets/saveIco.svg";
// import UserIco from "./assets/userIco.svg";
// import UserImg from "./assets/userImg.png";
// import Graph from "../assets/graph.png";
// import Noti from "../assets/notification.svg";
// import WingsLogo from "../../assets/wings-logo.svg";
import AdminLayout from "../../../components/AdminLayout";
// import { Formik } from "formik";
import {
  addCourt,
  deleteACourt,
  getAllCourts,
  updateACourt,
} from "../../../apis/booking.api";
import { toast } from "react-toastify";
import { toastConf } from "../Dropins";

export default function Spaces() {
  // const [showModal, setShowModal] = useState(false);

  const [courts, setCourts] = useState([]);

  // const [showModal, setShowModal] = useState(false);

  const initCall = async () => {
    const courts = await getAllCourts({ filter: "all" });
    setCourts(courts);
    return;
  };

  React.useEffect(() => {
    initCall();
  }, []);

  const onCourtAdd = async (data) => {
    try {
      await addCourt(data);
      initCall();
      // setShowModal(false);

      return toast.success("New Court Added Successfully.", toastConf);
    } catch (error) {
      toast.error(
        error?.response?.data?.message[0] ?? "Something went wrong.",
        toastConf
      );
    }
  };

  const onCourtRemove = async (id) => {
    try {
      await deleteACourt(id);
      initCall();
      return toast.success("Court Removed.", toastConf);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Something went wrong.",
        toastConf
      );
    }
  };

  const onCourtUpdate = async (id, data) => {
    try {
      await updateACourt(id, data);
      setCourts(
        courts.map((c) => {
          if (c.id === id) {
            return { ...c, ...data };
          }
          return c;
        })
      );
      return toast.success("Court Updated.", toastConf);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "Something went wrong.",
        toastConf
      );
    }
  };

  // const [currentVisibility, setCurrentVisibility] = useState("all");
  // const changeVisibility = (newVisibility) => {
  //   setCurrentVisibility(newVisibility);
  // };

  return (
    <AdminLayout>
      <div className="bg-white rounded-lg m-4 p-5">
        <div className="flex items-center justify-between">
          <div className="text-xl text-black font-bold">Manage Spaces</div>
          <div>
            <button
              className="flex items-center bg-theme rounded-l-full py-2 px-2"
              // onClick={() => setShowModal(true)}
              onClick={() => onCourtAdd({ visibility: true })}
            >
              <div className="flex bg-white rounded-full p-2 mr-2">
                <svg
                  id="Icon_ionic-md-time"
                  data-name="Icon ionic-md-time"
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.948"
                  height="13.948"
                  viewBox="0 0 13.948 13.948"
                >
                  <path
                    id="Path_4638"
                    data-name="Path 4638"
                    d="M10.342,3.375a6.974,6.974,0,1,0,6.981,6.974A6.971,6.971,0,0,0,10.342,3.375Zm.007,12.553a5.579,5.579,0,1,1,5.579-5.579A5.579,5.579,0,0,1,10.349,15.928Z"
                    transform="translate(-3.375 -3.375)"
                    fill="#ff6a00"
                  />
                  <path
                    id="Path_4639"
                    data-name="Path 4639"
                    d="M17.584,10.688H16.537v4.184l3.661,2.2.523-.858-3.138-1.861Z"
                    transform="translate(-10.261 -7.201)"
                    fill="#ff6a00"
                  />
                </svg>
              </div>
              <div className="text-xl text-white">Add More Court</div>
            </button>
          </div>
        </div>
        <table className="table-auto w-full rounded-lg border-collapse p-4 mt-4 mb-8">
          <thead>
            <tr className="text-left bg-neutral-200 p-8">
              <th className="py-3 px-2 mb-4">Courts</th>
              <th>Visibility</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {courts.map((court, i) => (
              <tr>
                <td className="py-5 px-2">Court #{i + 1}</td>
                <td>
                  <select
                    className="rounded-lg bg-lgtheme px-3 py-2"
                    value={court.visibility}
                    onChange={(e) =>
                      onCourtUpdate(court.id, {
                        ...court,
                        visibility: JSON.parse(e.target.value),
                      })
                    }
                  >
                    {/* <option value={false}>Draft</option> */}
                    <option value={true}>Live</option>
                  </select>
                </td>

                <td>
                  <button
                    onClick={() => onCourtRemove(court.id)}
                    className="inline-flex bg-red-100 rounded-full p-4 ml-4"
                  >
                    <svg
                      id="Group_2613"
                      data-name="Group 2613"
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 9.991 12.206"
                    >
                      <path
                        id="Path_4629"
                        data-name="Path 4629"
                        d="M-943.763,160.817c0-1.04,0-2.081,0-3.121a.54.54,0,0,1,.461-.6.547.547,0,0,1,.643.572c.007.555,0,1.11,0,1.665q0,2.185,0,4.37a1.065,1.065,0,0,0,1.149,1.152q2.172,0,4.343,0A1.068,1.068,0,0,0-936,163.7q0-2.978,0-5.956c0-.414.219-.658.573-.649a.526.526,0,0,1,.525.478c.015.146.008.294.008.442q0,2.835,0,5.67a2.156,2.156,0,0,1-2.282,2.281q-2.146,0-4.291,0a2.158,2.158,0,0,1-2.3-2.289Q-943.764,162.247-943.763,160.817Z"
                        transform="translate(944.321 -153.761)"
                        fill="#ec0000"
                      />
                      <path
                        id="Path_4630"
                        data-name="Path 4630"
                        d="M-947.264,94.716h1.724c.173,0,.347-.006.52,0a.542.542,0,0,1,.526.537.558.558,0,0,1-.506.562,1.709,1.709,0,0,1-.182,0h-8.607c-.052,0-.1,0-.156,0a.559.559,0,0,1-.54-.556.537.537,0,0,1,.544-.545c.624-.01,1.248,0,1.872,0h.353c0-.317,0-.616,0-.915,0-.575.173-.75.739-.75q1.521,0,3.042,0c.466,0,.665.2.669.664C-947.262,94.035-947.264,94.355-947.264,94.716Zm-3.32-.015h2.184v-.528h-2.184Z"
                        transform="translate(954.485 -93.05)"
                        fill="#ec0000"
                      />
                      <path
                        id="Path_4631"
                        data-name="Path 4631"
                        d="M-889.4,180.835c0,.615,0,1.23,0,1.845,0,.4-.216.646-.552.645s-.553-.253-.554-.647q0-1.858,0-3.717a.566.566,0,0,1,.542-.632c.337-.008.561.24.563.634C-889.4,179.587-889.4,180.211-889.4,180.835Z"
                        transform="translate(893.842 -173.892)"
                        fill="#ec0000"
                      />
                      <path
                        id="Path_4632"
                        data-name="Path 4632"
                        d="M-846.659,180.826c0,.641,0,1.282,0,1.923a.558.558,0,0,1-.443.565.54.54,0,0,1-.6-.3.771.771,0,0,1-.057-.3q-.005-1.884,0-3.769a.552.552,0,0,1,.557-.614.565.565,0,0,1,.548.626C-846.657,179.579-846.659,180.2-846.659,180.826Z"
                        transform="translate(853.32 -173.891)"
                        fill="#ec0000"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AdminLayout>
  );
}
