import React from "react";
import User from "../../../assets/username.svg";
import Password from "../../../assets/password.svg";
import Apple from "../../../assets/apple.svg";
import Facebook from "../../../assets/facebook.svg";
import Twitter from "../../../assets/twitter.svg";
import Google from "../../../assets/google.svg";
// import { GoogleLogin } from "react-google-login";
import Email from "../../../assets/email.svg";
import Call from "../../../assets/call.svg";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { registerUser, verifyUser } from "../../../apis/user.api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import OTPInput from "react-otp-input";
import { toastConf } from "../../AdminDashboard/Dropins";

function Register() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [otpScreen, setOTPScreen] = React.useState(false);

  const onRegiser = async (data) => {
    setLoading(true);
    try {
      await registerUser(data);
      setLoading(false);
      toast.success("OTP Sent.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        progress: undefined,
        theme: "light",
      });
      setOTPScreen(true);
      //
    } catch (error) {
      // console.log(error);
      setLoading(false);
      toast.error(
        error.response.data.message ?? "Something went wrong, try again.",
        toastConf
      );
    }
  };

  const onVerifyCall = async (data) => {
    setLoading(true);
    try {
      await verifyUser(data);
      toast.success("Account Registered Successfully.", toastConf);
      navigate("/auth/login");
      // onLoginCall(data);
    } catch (error) {
      setLoading(false);
      toast.error(
        error.response.data.message ?? "Something went wrong, try again.",
        toastConf
      );
    }
  };
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none pt-24">
        <div className="relative w-full my-6 mx-auto max-w-md">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="modal-bg bg-cover flex items-center justify-between p-5 bg-sec text-white border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl font-semibold">
                SIGN UP TO WINGS BADMINTON CLUB
              </h3>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <Formik
                initialValues={{
                  fullName: null,
                  phone: null,
                  email: null,
                  password: null,
                  confirmPassword: null,
                  otp: "",
                }}
                validationSchema={SignupSchema}
                onSubmit={(values) => {
                  otpScreen
                    ? onVerifyCall({
                        username: values["email"],
                        otp: values["otp"],
                      })
                    : onRegiser({ ...values, otp: undefined });
                }}
                validateOnBlur={false}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                }) => (
                  <Form>
                    {otpScreen ? (
                      <>
                        <p className="mb-3 text-center">
                          <small>
                            A OTP has been sent to your email{" "}
                            {values["username"]}.
                          </small>
                        </p>
                        <OTPInput
                          value={values["otp"]}
                          onChange={handleChange("otp")}
                          numInputs={6}
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => (
                            <input
                              className="w-full border border-gray-50"
                              {...props}
                            />
                          )}
                          inputStyle={{
                            width: "2rem",
                            border: "1px solid #cfcfcf",
                            borderRadius: 5,
                            margin: "auto 5px",
                            padding: "5px",
                          }}
                          placeholder="00000000"
                          containerStyle={{ justifyContent: "center" }}
                        />
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          disabled={loading}
                          className="login w-full flex items-center justify-center relative h-14 text-lg text-white rounded-lg bg-theme mt-3"
                        >
                          {loading ? <Spinner /> : "Verify"}
                          <svg
                            width="8.227"
                            height="14.388"
                            viewBox="0 0 8.227 14.388"
                            className="absolute right-5"
                          >
                            <path
                              id="Icon_ionic-ios-arrow-forward"
                              data-name="Icon ionic-ios-arrow-forward"
                              d="M16.993,13.388,11.548,7.947a1.024,1.024,0,0,1,0-1.452,1.037,1.037,0,0,1,1.457,0l6.169,6.164a1.026,1.026,0,0,1,.03,1.418l-6.194,6.207a1.028,1.028,0,1,1-1.457-1.452Z"
                              transform="translate(-11.246 -6.196)"
                              fill="#fff"
                            />
                          </svg>
                        </button>
                      </>
                    ) : (
                      <div className="grid gap-4 mb-6 grid-cols-1">
                        <div>
                          {/* <label htmlFor="first_name" className="block mb-2 text-base font-semibold text-sec">Full Name</label> */}
                          <div className="relative flex w-full flex-wrap items-stretch">
                            <input
                              type="text"
                              onChange={handleChange("fullName")}
                              onBlur={handleBlur("fullName")}
                              value={values["fullName"]}
                              placeholder="Enter your full name"
                              className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                            />
                            <span className="z-10 h-full leading-snug font-normal absolute text-center text-white bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                              <img src={User} alt="" />
                            </span>
                          </div>
                          {errors.fullName && touched.fullName && (
                            <span className="text-red-600 text-xs">
                              {errors["fullName"]}
                            </span>
                          )}
                        </div>
                        <div>
                          {/* <label htmlFor="email" className="block mb-2 text-base font-semibold text-sec">Email Id</label> */}
                          <div className="relative flex w-full flex-wrap items-stretch">
                            <input
                              type="email"
                              onChange={handleChange("email")}
                              onBlur={handleBlur("email")}
                              value={values["email"]}
                              placeholder="Enter your email id"
                              className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                            />
                            <span className="z-10 h-full leading-snug font-normal absolute text-center text-white bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                              <img src={Email} alt="" />
                            </span>
                          </div>
                          {errors.email && touched.email && (
                            <span className="text-red-600 text-xs">
                              {errors.email}
                            </span>
                          )}
                        </div>
                        <div>
                          {/* <label htmlFor="email" className="block mb-2 text-base font-semibold text-sec">Email Id</label> */}
                          <div className="relative flex w-full flex-wrap items-stretch">
                            <input
                              type="number"
                              onChange={handleChange("phone")}
                              onBlur={handleBlur("phone")}
                              value={values["phone"]}
                              placeholder="Enter your phone"
                              className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                            />
                            <span className="z-10 h-full leading-snug font-normal absolute text-center text-white bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                              <img src={Call} alt="" />
                            </span>
                          </div>
                          {errors.phone && touched.phone && (
                            <span className="text-red-600 text-xs">
                              {errors.phone}
                            </span>
                          )}
                        </div>
                        <div>
                          {/* <label htmlFor="password" className="block mb-2 text-base font-semibold text-sec">Password</label> */}
                          <div className="relative flex w-full flex-wrap items-stretch">
                            <input
                              type="password"
                              onChange={handleChange("password")}
                              onBlur={handleBlur("password")}
                              value={values["password"]}
                              placeholder="Enter password"
                              className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                            />
                            <span className="z-10 h-full leading-snug font-normal absolute text-center text-white bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                              <img src={Password} alt="" />
                            </span>
                          </div>
                          {errors.password && touched.password && (
                            <span className="text-red-600 text-xs">
                              {errors.password}
                            </span>
                          )}
                        </div>
                        <div>
                          {/* <label htmlFor="password" className="block mb-2 text-base font-semibold text-sec">Re Enter Password</label> */}
                          <div className="relative flex w-full flex-wrap items-stretch">
                            <input
                              type="password"
                              onChange={handleChange("confirmPassword")}
                              onBlur={handleBlur("confirmPassword")}
                              value={values["confirmPassword"]}
                              placeholder="Confirm password"
                              className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                            />
                            <span className="z-10 h-full leading-snug font-normal absolute text-center text-white bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                              <img src={Password} alt="" />
                            </span>
                          </div>
                          {errors.confirmPassword &&
                            touched.confirmPassword && (
                              <span className="text-red-600 text-xs">
                                {errors.confirmPassword}
                              </span>
                            )}
                        </div>
                        <div>
                          <div className="flex w-full text-xs">
                            By creating an account, you agree to our Terms &
                            Conditions and Privacy Policy
                          </div>
                        </div>

                        <button
                          type="submit"
                          disabled={loading}
                          className="login flex items-center justify-center relative h-14 text-lg text-white rounded-lg bg-theme mt-3"
                        >
                          {loading ? (
                            <Spinner />
                          ) : (
                            <>
                              CREATE ACCOUNT
                              <svg
                                width="8.227"
                                height="14.388"
                                viewBox="0 0 8.227 14.388"
                                className="absolute right-5"
                              >
                                <path
                                  id="Icon_ionic-ios-arrow-forward"
                                  data-name="Icon ionic-ios-arrow-forward"
                                  d="M16.993,13.388,11.548,7.947a1.024,1.024,0,0,1,0-1.452,1.037,1.037,0,0,1,1.457,0l6.169,6.164a1.026,1.026,0,0,1,.03,1.418l-6.194,6.207a1.028,1.028,0,1,1-1.457-1.452Z"
                                  transform="translate(-11.246 -6.196)"
                                  fill="#fff"
                                />
                              </svg>
                            </>
                          )}
                        </button>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
            {/*footer*/}
            {otpScreen ? null : (
              <>
                <div className="flex flex-wrap items-center justify-center">
                  <ul className="inline-flex gap-6">
                    <li>
                      <button className="border-2 border-solid rounded-lg hover:border-theme h-10 w-10 inline-flex p-2">
                        <img src={Google} alt="" />
                      </button>
                    </li>
                    <li>
                      <button className="border-2 border-solid rounded-lg hover:border-theme h-10 w-10 inline-flex p-2">
                        <img src={Facebook} alt="" />
                      </button>
                    </li>
                    <li>
                      <button className="border-2 border-solid rounded-lg hover:border-theme h-10 w-10 inline-flex p-2">
                        <img src={Apple} alt="" />
                      </button>
                    </li>
                    <li>
                      <button className="border-2 border-solid rounded-lg hover:border-theme h-10 w-10 inline-flex p-2">
                        <img src={Twitter} alt="" />
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="flex items-center justify-center p-6 ">
                  <button
                    className="text-sec background-transparent font-bold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Already have an accounts ? Go to{" "}
                    <Link to="/auth/login" className="text-theme">
                      Login
                    </Link>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* <div className="opacity-50 fixed inset-0 z-40 bg-black"></div> */}
    </>
  );
}

export default Register;

const SignupSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Email is Required."),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars minimum"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required.")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  phone: Yup.string()
    .required("Phone cannot be blank.")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    ),
});
