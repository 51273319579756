import React, { useContext } from "react";
import User from "../../assets/username.svg";
import Password from "../../assets/password.svg";
import shape from "../../assets/shape.png";
import { toast } from "react-toastify";
import { toastConf } from "./Dropins";
import { loginUser } from "../../apis/user.api";

import { AuthContext } from "../../context/Auth.context";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const { onLogin } = useContext(AuthContext);

  const [loading, setLoading] = React.useState(false);

  const onLoginCall = async (data) => {
    setLoading(true);
    try {
      const loginAPI = await loginUser(data);
      onLogin(loginAPI.access_token);
      setLoading(false);

      // setCookies("session_token", loginAPI.access_token, {
      //   path: "/",
      //   domain: process.env.REACT_APP_DOMAIN,
      //   maxAge: 60 * 60 * 3,
      // });
      // localStorage.setItem("token", loginAPI.access_token);
      // setAuth(true);

      navigate("/admin");
    } catch (error) {
      // console.log(error);
      setLoading(false);
      toast.error(
        error.response.data.message ?? "Something went wrong, try again.",
        toastConf
      );
    }
  };
  return (
    <>
      <div
        className="flex items-center justify-center px-3 h-screen"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="w-full max-w-lg rounded-lg shadow-xl relative z-10 overflow-hidden">
          <div className="bg-cover flex items-center justify-between p-5 bg-sec text-white">
            <h3 className="text-xl font-semibold">
              LOG IN TO WINGS BADMINTON CLUB
            </h3>
          </div>
          <div className="relative p-6 flex-auto">
            <Formik
              initialValues={{
                username: "",
                password: "",
              }}
              validationSchema={LoginSchema}
              onSubmit={(values) => {
                onLoginCall(values);
                // console.log(values);
              }}
              validateOnBlur={false}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                values,
              }) => (
                <Form>
                  <div className="grid gap-4 grid-cols-1">
                    <div>
                      <div className="relative flex w-full flex-wrap items-stretch">
                        <input
                          type="email"
                          onChange={handleChange("username")}
                          onBlur={handleBlur("username")}
                          value={values.username}
                          placeholder="Admin Email"
                          className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                        />

                        <span className="z-10 h-full leading-snug font-normal absolute text-center text-white bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                          <img src={User} alt="ico" />
                        </span>
                      </div>
                      {errors.username && touched.username && (
                        <span className="text-red-600 text-xs">
                          {errors.username}
                        </span>
                      )}
                    </div>

                    <div>
                      <div className="relative flex w-full flex-wrap items-stretch">
                        <input
                          type="password"
                          onChange={handleChange("password")}
                          onBlur={handleBlur("password")}
                          value={values.password}
                          placeholder="Admin Password"
                          className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                        />
                        <span className="z-10 h-full leading-snug font-normal absolute text-center text-white bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                          <img src={Password} alt="ico" />
                        </span>
                      </div>
                      {errors.password && touched.password && (
                        <span className="text-red-600 text-xs">
                          {errors["password"]}
                        </span>
                      )}
                    </div>
                    <div className="flex justify-between">
                      <div className="form-check">
                        <input
                          className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                      </div>
                      <button onClick={() => navigate("/auth/request")} className="text-theme text-sm">Forgot Password?</button>
                    </div>

                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="login flex items-center justify-center relative h-14 text-lg text-white rounded-lg bg-theme mt-3"
                    >
                      {loading ? <Spinner /> : "Login"}
                      <svg
                        width="8.227"
                        height="14.388"
                        viewBox="0 0 8.227 14.388"
                        className="absolute right-5"
                      >
                        <path
                          id="Icon_ionic-ios-arrow-forward"
                          data-name="Icon ionic-ios-arrow-forward"
                          d="M16.993,13.388,11.548,7.947a1.024,1.024,0,0,1,0-1.452,1.037,1.037,0,0,1,1.457,0l6.169,6.164a1.026,1.026,0,0,1,.03,1.418l-6.194,6.207a1.028,1.028,0,1,1-1.457-1.452Z"
                          transform="translate(-11.246 -6.196)"
                          fill="#fff"
                        />
                      </svg>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <img src={shape} alt="" className="absolute right-10 bottom-10" />
      </div>
    </>
  );
}
const LoginSchema = Yup.object().shape({
  username: Yup.string().email("Invalid email").required("Email is Required."),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars minimum"),
});
