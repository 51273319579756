import React from "react";
import Password from "../../../assets/password.svg";
import { setPasswordActivate } from "../../../apis/user.api";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

import { toastConf } from "../../AdminDashboard/Dropins";
import { useNavigate, useParams } from "react-router-dom";

function Invite() {
  const { id } = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const onVerifyCall = async (data) => {
    setLoading(true);
    try {
      await setPasswordActivate(data);
      setLoading(false);
      navigate("/auth/login");
      toast.success("Password Set Successful");
    } catch (error) {
      // console.log(error);
      setLoading(false);
      toast.error(
        error.response.data.message ?? "Something went wrong, try again.",
        toastConf
      );
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto max-w-md">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="modal-bg bg-cover flex items-center justify-between p-5 bg-sec text-white border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl font-semibold">Invitation</h3>
            </div>

            {/*body*/}
            <div className="relative p-6 flex-auto">
              <Formik
                initialValues={{
                  token: id,
                  confirmPassword: "",
                  password: "",
                }}
                validationSchema={InviteSchema}
                onSubmit={(values) => {
                  onVerifyCall(values);
                  // console.log(values);
                }}
                validateOnBlur={false}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                }) => (
                  <Form>
                    <div className="grid gap-4  grid-cols-1">
                      <div>
                        {/* <label htmlFor="password" className="block mb-2 text-base font-semibold text-sec">Password</label> */}
                        <div className="relative flex w-full flex-wrap items-stretch">
                          <input
                            type="password"
                            onChange={handleChange("password")}
                            onBlur={handleBlur("password")}
                            value={values.password}
                            placeholder="Enter your password"
                            className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                          />
                          <span className="z-10 h-full leading-snug font-normal absolute text-center text-white bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                            <img src={Password} alt="" />
                          </span>
                        </div>
                        {errors.password && touched.password && (
                          <span className="text-red-600 text-xs">
                            {errors["password"]}
                          </span>
                        )}
                      </div>

                      <div>
                        {/* <label htmlFor="password" className="block mb-2 text-base font-semibold text-sec">Password</label> */}
                        <div className="relative flex w-full flex-wrap items-stretch">
                          <input
                            type="password"
                            onChange={handleChange("confirmPassword")}
                            onBlur={handleBlur("confirmPassword")}
                            value={values.confirmPassword}
                            placeholder="Enter your confirm password"
                            className="font-normal h-12 w-full px-3 outline-0 bg-transparent text-sec rounded-lg border-2 border-stone-200 placeholder-black-800 hover:border-theme focus:border-theme"
                          />
                          <span className="z-10 h-full leading-snug font-normal absolute text-center text-white bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                            <img src={Password} alt="" />
                          </span>
                        </div>
                        {errors.confirmPassword && touched.confirmPassword && (
                          <span className="text-red-600 text-xs">
                            {errors["confirmPassword"]}
                          </span>
                        )}
                      </div>

                      <button
                        type="submit"
                        onClick={handleSubmit}
                        disabled={loading}
                        className="login flex items-center justify-center relative h-14 text-lg text-white rounded-lg bg-theme mt-3"
                      >
                        {loading ? <Spinner /> : "Join"}
                        <svg
                          width="8.227"
                          height="14.388"
                          viewBox="0 0 8.227 14.388"
                          className="absolute right-5"
                        >
                          <path
                            id="Icon_ionic-ios-arrow-forward"
                            data-name="Icon ionic-ios-arrow-forward"
                            d="M16.993,13.388,11.548,7.947a1.024,1.024,0,0,1,0-1.452,1.037,1.037,0,0,1,1.457,0l6.169,6.164a1.026,1.026,0,0,1,.03,1.418l-6.194,6.207a1.028,1.028,0,1,1-1.457-1.452Z"
                            transform="translate(-11.246 -6.196)"
                            fill="#fff"
                          />
                        </svg>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            {/* <div className="flex items-center justify-center p-6 ">
                <button
                  className="text-sec background-transparent font-bold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setLoginModal(false)}
                >
                  No account ? Go to <a href="#"className="text-theme" onClick={() => setSignupModal(true)}>Sign Up</a>
                </button>
                
              </div> */}
          </div>
        </div>
      </div>
      {/* <div className="opacity-50 fixed inset-0 z-40 bg-black"></div> */}
    </>
  );
}

export default Invite;
const InviteSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars minimum"),

  confirmPassword: Yup.string()
    .required("Confirm Password is required.")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});
