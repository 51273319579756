import React, { useState } from "react";
// import { NavLink, Link } from "react-router-dom";
// import Logo from "../assets/wlogo.svg";
// import LogoIco from "./assets/logoIco.svg";
// import CalendarIco from "./assets/calendarIco.svg";
// import SaveIco from "./assets/saveIco.svg";
// import UserIco from "./assets/userIco.svg";
// import UserImg from "./assets/userImg.png";
// import Graph from "../assets/graph.png";
// import WingsLogo from "../../assets/wings-logo.svg";
// import "../App.css";
// import { Calendar } from "@fullcalendar/core";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import DatePicker from "react-datepicker";
import AdminLayout from "../../components/AdminLayout";
import { getAllCourts, getCourtForDay } from "../../apis/booking.api";
import FullCalendar from "@fullcalendar/react";
import { Spinner } from "react-bootstrap";

// import "react-datepicker/dist/react-datepicker.css";

export default function CourtLayout() {
  // const [navbar, setNavbar] = useState(false);
  const calendarRef = React.useRef();
  const [courtBookings, setCourtBookings] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [courts, setCourts] = React.useState([]);

  const initialCall = async () => {
    const apiCall = await getCourtForDay();
    const courts = await getAllCourts();
    setCourts(courts);
    // const d = apiCall.map((data) => {
    //   return {
    //     ...data,
    //     resourceId: data.court,
    //     start: `${data.date}T${data.time}-05:30`,
    //     end: `${data.date}T${data.endTime}-05:30`,
    //   };
    // });
    // console.log(d);
    setLoading(false);
    setCourtBookings(apiCall);
  };

  React.useEffect(() => {
    initialCall();
  }, []);

  const onDateChange = async (date) => {
    // setLoading(true);
    try {
      const apiCall = await getCourtForDay({ date });
      setLoading(false);
      setCourtBookings(apiCall);
    } catch (error) {}
  };

  const [startDate, setStartDate] = useState(new Date());

  // setTimeout(function () {
  //   const calendarEl = document.getElementById("calendar");
  //   const calendar = new Calendar(calendarEl, {
  //     plugins: [resourceTimeGridPlugin],
  //     // schedulerLicenseKey: "GPL-My-Projects-Is-Open-Source",
  //     schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
  //     timeZone: "Toronto",
  //     initialView: "resourceTimeGridDay",
  //     slotDuration: "01:00",
  //     allDaySlot: false,
  //     slotLabelFormat: { hour: "2-digit", minute: "2-digit", hour12: "true" },
  //     slotMinTime: "06:00",
  //     expandRows: true,
  //     height: "100vh",
  //     windowResize: "true",
  //     resources: [
  //       { id: 1, title: "Court 1" },
  //       { id: 2, title: "Court 2" },
  //       { id: 3, title: "Court 3" },
  //       { id: 4, title: "Court 4" },
  //       { id: 5, title: "Court 5" },
  //     ],
  //     events: courtBookings,

  //     // [
  //     //   {
  //     //     resourceId: 1,
  //     //     title: "event 1",
  //     //     start: "2023-05-19",
  //     //     end: "2023-06-18",
  //     //   },
  //     //   {
  //     //     resourceId: 2,
  //     //     title: "event 3",
  //     //     start: "2023-05-19T12:00:00-05:30",
  //     //     end: "2023-04-18T06:00:00-05:30",
  //     //   },
  //     //   {
  //     //     resourceId: 3,
  //     //     title: "event 4",
  //     //     start: "2023-05-19T07:30:00-05:30",
  //     //     end: "2023-05-19T09:30:00-05:30",
  //     //   },
  //     //   {
  //     //     resourceId: 4,
  //     //     title: "event 5",
  //     //     start: "2023-05-19T10:00:00-05:30",
  //     //     end: "2023-05-19T15:00:00-05:30",
  //     //   },
  //     //   {
  //     //     resourceId: 1,
  //     //     title: "event 2",
  //     //     start: "2023-05-19T09:00:00-05:30",
  //     //     end: "2023-05-19T14:00:00-05:30",
  //     //   },
  //     // ],
  //   });
  //   calendar.render();
  //   //console.log(calendar);
  //   calendar.gotoDate(startDate);
  // }, 10);

  // const someMethod = () => {
  //   let calendarApi = calendarRef.current.getApi();
  //   debugger;
  //   calendarApi.next();
  // };
  return (
    <AdminLayout>
      {/* {JSON.stringify(courtBookings, 2)} */}
      {loading ? (
        <Spinner />
      ) : (
        <div className="w-full flex flex-col relative px-3">
          {/* <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          /> */}
          <FullCalendar
            ref={calendarRef}
            plugins={[resourceTimeGridPlugin]}
            resources={[
              ...courts.map((c, i) => {
                return {
                  id: i + 1,
                  title: `Court ${i + 1}`,
                  link: `/bookings/${c?.booking?.bookingID}`,
                };
              }),
            ]}
            initialView="resourceTimeGridDay"
            schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
            timeZone="Toronto"
            slotDuration="01:00"
            allDaySlot={false}
            slotLabelFormat={{
              hour: "2-digit",
              minute: "2-digit",
              hour12: "true",
            }}
            slotMinTime="06:00"
            expandRows={true}
            height="100vh"
            windowResize="true"
            events={courtBookings}
            datesSet={(d) => onDateChange(d.startStr.split("T")[0])}
            eventClick={(e) => console.log(e)}
          />
          {/* <div id="calendar" className="w-full"></div> */}
        </div>
      )}
    </AdminLayout>
  );
}
// {/* <div className="w-full min-w-88 max-w-88">
//   <div className="inline-flex flex-wrap mr-1">
//     <div className="w-full h-10 flex justify-center items-center">Time Slot</div>
//     <div className="w-full h-12 bg-gray-300 rounded flex justify-center items-center text-black mt-1">04:00 AM</div>
//     <div className="w-full h-12 bg-orange-200 rounded flex justify-center items-center text-theme mt-1">05:00 AM</div>
//     <div className="w-full h-12 bg-orange-200 rounded flex justify-center items-center text-theme mt-1">06:00 AM</div>
//     <div className="w-full h-12 bg-orange-200 rounded flex justify-center items-center text-theme mt-1">07:00 AM</div>
//     <div className="w-full h-12 bg-orange-200 rounded flex justify-center items-center text-theme mt-1">08:00 AM</div>
//     <div className="w-full h-12 bg-orange-200 rounded flex justify-center items-center text-theme mt-1">09:00 AM</div>
//     <div className="w-full h-12 bg-orange-200 rounded flex justify-center items-center text-theme mt-1">10:00 AM</div>
//     <div className="w-full h-12 bg-orange-200 rounded flex justify-center items-center text-theme mt-1">11:00 AM</div>
//     <div className="w-full h-12 bg-orange-200 rounded flex justify-center items-center text-theme mt-1">12:00 PM</div>
//     <div className="w-full h-12 bg-orange-200 rounded flex justify-center items-center text-theme mt-1">01:00 PM</div>
//     <div className="w-full h-12 bg-orange-200 rounded flex justify-center items-center text-theme mt-1">02:00 PM</div>
//     <div className="w-full h-12 bg-orange-200 rounded flex justify-center items-center text-theme mt-1">03:00 PM</div>
//     <div className="w-full h-12 bg-gray-300 rounded flex justify-center items-center text-black mt-1">04:00 PM</div>
//     <div className="w-full h-12 bg-gray-300 rounded flex justify-center items-center text-black mt-1">05:00 PM</div>
//     <div className="w-full h-12 bg-orange-200 rounded flex justify-center items-center text-theme mt-1">06:00 PM</div>
//     <div className="w-full h-12 bg-orange-200 rounded flex justify-center items-center text-theme mt-1">07:00 PM</div>
//     <div className="w-full h-12 bg-orange-200 rounded flex justify-center items-center text-theme mt-1">08:00 PM</div>
//     <div className="w-full h-12 bg-orange-200 rounded flex justify-center items-center text-theme mt-1">09:00 PM</div>
//     <div className="w-full h-12 bg-orange-200 rounded flex justify-center items-center text-theme mt-1">10:00 PM</div>
//     <div className="w-full h-12 bg-orange-200 rounded flex justify-center items-center text-theme mt-1">11:00 PM</div>
//   </div>
// </div>
// <div className="w-full flex flex-nowrap overflow-x-scroll">
//   <div className="w-full md:min-w-182 min-w-110 inline-flex flex-wrap mr-1">
//     <div className="court bg-theme text-white w-full md:min-w-182 min-w-110 h-10 flex items-center justify-center mb-1">Court 01</div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//   </div>
//   <div className="w-full md:min-w-182 min-w-110 inline-flex flex-wrap mr-1">
//     <div className="court bg-theme text-white w-full md:min-w-182 min-w-110 h-10 flex items-center justify-center mb-1">Court 02</div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//   </div>
//   <div className="w-full md:min-w-182 min-w-110 inline-flex flex-wrap mr-1">
//     <div className="court bg-theme text-white w-full md:min-w-182 min-w-110 h-10 flex items-center justify-center mb-1">Court 03</div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//   </div>
//   <div className="w-full md:min-w-182 min-w-110 inline-flex flex-wrap mr-1">
//     <div className="court bg-theme text-white w-full md:min-w-182 min-w-110 h-10 flex items-center justify-center mb-1">Court 04</div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//   </div>
//   <div className="w-full md:min-w-182 min-w-110 inline-flex flex-wrap mr-1">
//   <div className="court bg-theme text-white w-full md:min-w-182 min-w-110 h-10 flex items-center justify-center mb-1">Court 05</div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//   </div>
//   <div className="w-full md:min-w-182 min-w-110 inline-flex flex-wrap mr-1">
//     <div className="court bg-theme text-white w-full md:min-w-182 min-w-110 h-10 flex items-center justify-center mb-1">Court 06</div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//   </div>
//   <div className="w-full md:min-w-182 min-w-110 inline-flex flex-wrap mr-1">
//     <div className="court bg-theme text-white w-full md:min-w-182 min-w-110 h-10 flex items-center justify-center mb-1">Court 07</div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//   </div>
//   <div className="w-full md:min-w-182 min-w-110 inline-flex flex-wrap mr-1">
//     <div className="court bg-theme text-white w-full md:min-w-182 min-w-110 h-10 flex items-center justify-center mb-1">Court 08</div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//   </div>
//   <div className="w-full md:min-w-182 min-w-110 inline-flex flex-wrap mr-1">
//     <div className="court bg-theme text-white w-full md:min-w-182 min-w-110 h-10 flex items-center justify-center mb-1">Court 09</div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//   </div>
//   <div className="w-full md:min-w-182 min-w-110 inline-flex flex-wrap mr-1">
//     <div className="court bg-theme text-white w-full md:min-w-182 min-w-110 h-10 flex items-center justify-center mb-1">Court 10</div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//   </div>
//   <div className="w-full md:min-w-182 min-w-110 inline-flex flex-wrap mr-1">
//     <div className="court bg-theme text-white w-full md:min-w-182 min-w-110 h-10 flex items-center justify-center mb-1">Court 11</div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//   </div>
//   <div className="w-full md:min-w-182 min-w-110 inline-flex flex-wrap mr-1">
//     <div className="court bg-theme text-white w-full md:min-w-182 min-w-110 h-10 flex items-center justify-center mb-1">Court 12</div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//   </div>
//   <div className="w-full md:min-w-182 min-w-110 inline-flex flex-wrap mr-1">
//     <div className="court bg-theme text-white w-full md:min-w-182 min-w-110 h-10 flex items-center justify-center mb-1">Court 13</div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-6 flex border-2 border-gray-300 items-center justify-center"></div>
//     <div className="court w-full md:min-w-182 min-w-110 h-7 flex border-2 border-gray-300 items-center justify-center"></div>
//   </div>
// </div> */}
