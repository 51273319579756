import React from "react";
import AdminLayout from "../../../components/AdminLayout";
import { getADropin, getAMembership } from "../../../apis/booking.api.js";
import { useParams, useNavigate } from "react-router-dom";
import { formatedDate, formatedTime } from "../../../utils/time.js";
import { FiMinusCircle } from "react-icons/fi";

const splitDate = (date) => date.split("-");

export default function ViewMembership() {
  let { id } = useParams();
  const navigate = useNavigate();

  const [membership, setMembership] = React.useState();
  const [loading, setLoading] = React.useState(true);

  const initCall = async () => {
    const membershipAPI = await getAMembership(id);
    setMembership(membershipAPI);
    // console.log(membershipAPI);
    setLoading(false);
    return;
  };

  React.useEffect(() => {
    initCall();
  }, []);
  return (
    <AdminLayout>
      {/* {JSON.stringify(membership)} */}
      {loading ? (
        "Loading... "
      ) : (
        <div
          className="card-block bg-white border-2 border-white rounded hover:shadow-md focus:shadow-md position-relative mb-3 shadow-sm mx-5"
          key={0}
        >
          <div className="p-2">
            <div className="w-full space-y-2">
              <div className="rounded-lg">
                <div
                  className={`flex items-center font-semibold md:text-lg text-base cursor-pointer mb-2`}
                >
                  {/* {formatedDate(
                    splitDate(membership.date)[2],
                    splitDate(membership.date)[1],
                    splitDate(membership.date)[0]
                  )} */}
                </div>
                {/* <div>
                  <p>
                    {dropin.hours.length > 0 ? (
                      <span className="font-medium mr-5">
                        {formatedTime(dropin.hours[0].time)} To{" "}
                        {formatedTime(
                          dropin.hours[dropin.hours.length - 1].endTime
                        )}{" "}
                        ({dropin.hours.length}h)
                      </span>
                    ) : (
                      "-"
                    )}
                  </p>
                </div> */}

                <div className="md:overflow-auto overflow-x-scroll block">
                  <table className="whitespace-nowrap w-full rounded-lg md:text-base text-sm p-4 mt-4">
                    <tbody>
                      {membership.enrollments.map((user, i) => (
                        <tr
                          className="border-b border-gray-200 shadow-md bg-gray-50"
                          key={i}
                        >
                          <td className="p-2">
                            <div className="font-medium inline-flex items-center md:mr-5 mr-2">
                              <span>{user.user.fullName}</span>
                            </div>
                          </td>
                          <td className="p-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              viewBox="0 0 19 19"
                              className="inline-flex mr-2"
                            >
                              <g
                                id="Group_3450"
                                data-name="Group 3450"
                                transform="translate(-169 -395)"
                              >
                                <rect
                                  id="Rectangle_2254"
                                  data-name="Rectangle 2254"
                                  width="19"
                                  height="19"
                                  rx="4"
                                  transform="translate(169 395)"
                                  fill="#fff3eb"
                                />
                                <path
                                  id="Icon_material-access-time"
                                  data-name="Icon material-access-time"
                                  d="M8.432,3a5.437,5.437,0,1,0,5.443,5.437A5.435,5.435,0,0,0,8.432,3Zm.005,9.787a4.35,4.35,0,1,1,4.35-4.35A4.349,4.349,0,0,1,8.437,12.787Zm.272-7.068H7.893V8.981l2.855,1.713.408-.669L8.709,8.573Z"
                                  transform="translate(170.063 396.063)"
                                  fill="#ff6a00"
                                />
                              </g>
                            </svg>

                            {user.enrolledOn}
                          </td>
                          <td className="p-2">
                            <span className="font-semibold">
                              <span className="text-theme">
                                {user.price} CAD
                              </span>
                            </span>
                          </td>

                          <td className="p-2">
                            <div className="flex items-center">
                              <div className="md:w-full block md:justify-center mr-3">
                                Booking ID: {user.booking.bookingID}
                              </div>
                            </div>
                          </td>
                          <td className="p-2">
                            <div className="flex items-center">
                              <div className="md:w-full block md:justify-center mr-3">
                                
                              </div>
                            </div>
                          </td>

                          <td className="p-2 text-center">
                            <button
                              className="inline-flex bg-white rounded-full p-3 shadow mr-2"
                              // onClick={() =>
                              //   navigate(`/admin/dropins/${user.userID}`)
                              // }
                            >
                              <FiMinusCircle />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </AdminLayout>
  );
}
